//------------------------------------------------------ */
// ! Bootstrap-like GRID Layout (legacy)
//------------------------------------------------------ */
.row .row {
	margin-left: 0;
	margin-right: 0;
}

.container-fluid {
	max-width: @screen-xl;
}

//mixin to create hpe template equal gutter system rules per side per breakpoint
//param @gutterValue is the width of desired gutter
.hpe-breakpoint-gutter(@gutterValue) {
	.container-gutter {
		padding-right: @gutterValue;
		padding-left: @gutterValue;
	}
}

@media screen and (max-width: @screen-xs-max) {
	.hpe-breakpoint-gutter(@template-xs-gutter);
}

@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.hpe-breakpoint-gutter(@template-sm-gutter);
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	.hpe-breakpoint-gutter(@template-md-gutter);
}

@media screen and (min-width: @screen-lg-min) {
	.hpe-breakpoint-gutter(@template-lg-gutter);
}
