@import "@exadel/esl/modules/esl-scrollbar/core.mixin.less";

// ESL
.esl-scrollbar-init();
.esl-scrollable-content-init();

.esl-scrollbar {
  touch-action: none;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE, Edge
}

.drop-main-page-scroll {
  overflow: hidden;
  max-height: 100vh;

  &.ios {
    position: fixed;
  }
}
