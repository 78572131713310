// TODO: make typo independent
.typo3, .typo4 {
  hr {
    margin: 0;
    border-color: #e5e5e5;
    .style-bg-gray-3 & {
      border-color: #dadada;
    }
  }
  &.style-bg-gray-3 {
    hr {
      border-color: #dadada;
    }
  }
}
