@import (reference) "../../../ui/dependencies.less";

//DRAWER HEAD
.drawer-head {
  background-color: #E7E7E7;
  user-select: none;
  border-top-width: 3px;
  &:not([class*="coloredline"]) {
    border-top: 3px solid #BFBFBF;
  }
  position: relative;
  align-items: center;
  display: flex;
  height: 77px;
  cursor: pointer;
  margin-top: 50px;
  &:first-child {
    margin-top: 0;
  }

  .tag-h4 {
    margin-left: 76px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media @mq--XS-MD {
      margin-left: 74px;
    }
    @media @mq--XS {
      margin-left: 52px;
    }
  }
  .accordion-arrow-icon {
    margin: 0 30px;
    @media @mq--XS {
      margin: 0 20px;
    }
  }

  @media @mq--XS-MD {
    height: 71px;
  }
  @media @mq--XS-SM {
    margin-top: 40px;
  }
  @media @mq--XS {
    height: 59px;
  }
}

//DRAWER ICON
.drawer-icon {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 22px;
  top: 20px;
  @media @mq--XS-MD {
    width: 34px;
    height: 34px;
    top: 18px;
  }
  @media @mq--XS {
    width: 22px;
    height: 22px;
    left: 17px;
    top: 17px;
  }
}

//DRAWER BODY
.drawer-body {
  background-color: #E7E7E7;
  .hpe-carousel {
    margin: 0 74px;
    &.dots-outside:not(.no-dots) .owl-carousel {
      padding-bottom: 72px;
    }
    &.arrows-outside .owl-nav {
      .owl-prev {
        left: -70px;
      }
      .owl-next {
        right: -70px;
      }
      @media @mq--XS-MD {
        .owl-prev, .owl-next {
          width: 50px;
          height: 50px;
          margin-top: -60.5px;
        }
        .owl-prev {
          left: -46px;
        }
        .owl-next {
          right: -46px;
        }
      }
    }
    @media @mq--XS-MD {
      margin: 0 48px;
    }
    @media @mq--XS {
      margin: 0 3px;
      .owl-nav {
        display: none;
      }
    }
  }
  .around-hpe-card {
    min-width: 200px;
  }
}
