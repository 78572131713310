.generic-modal {
  .columns-component {
    background-color: transparent;
  }

  .columns-component .container-gutter {
    padding: 0;
    max-width: none;
    margin: 0;
  }
}
