@import "@exadel/esl/modules/esl-popup/core.mixin.less";
@import (reference) "../../../../hpe-gn/components/gn-header-v4/ui/gn-header-v4.variables.less";

@POPUP_ARROW_SIZE: 20px;
@POPUP_BACKGROUND_COLOR: @primary9;
@POPUP_BORDER_COLOR: #dedcdc;
@POPUP_BORDER_WIDTH: 1px;
@POPUP_Z_INDEX: @GN4_BODY_OVERLAY_Z_INDEX - 5; //for the secondary nav v4 compatability

.esl-popup-init(
  esl-popup,
  esl-popup-arrow,
  @POPUP_ARROW_SIZE,
  @POPUP_BACKGROUND_COLOR,
  @POPUP_BORDER_COLOR,
  @POPUP_BORDER_WIDTH,
  @POPUP_Z_INDEX
);

.esl-popup {
  padding: 15px;
  width: 100%;

  .modal-open &,
  .base-modal-opened & {
    &[open] {
      z-index: 1100; //to cover bootstrap @zindex-modal
    }
  }
}
