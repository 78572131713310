.slide-carousel-control {
  display: none;

  position: absolute;
  z-index: 1;

  top: 50%;
  transform: translateY(-50%);

  margin: 0;
  padding: 0;
  border: 0;
  background: none;

  text-align: center;

  // Themes
  &, .slide-dark & {
    color: rgba(0, 0, 0, .5);
    &:hover {
      color: rgba(0, 0, 0, .8);
    }
  }
  .slide-light & {
    color: rgba(255, 255, 255, .6);
    &:hover {
      color: rgba(255, 255, 255, .9);
    }
  }

  // Arrow padding
  width: 68px;
  height: 68px;
  line-height: 68px;

  .icon-arrow-left,
  .icon-arrow-right {
    vertical-align: middle;
    font-size: 68px;
  }

  @media @mq--XL {
    &.left {
      left: 25px; // 100 - 68 - 7
    }
    &.right {
      right: 25px; // 100 - 68 - 7
    }
  }

  @media (min-width: 1800px) {
    &.left {
      left: ~'calc(50% - 875px)'; // (100 - 68 - 7) + (100% - 1800) / 2
    }
    &.right {
      right: ~'calc(50% - 875px)'; // (100 - 68 - 7) + (100% - 1800) / 2
    }
  }

  @media @mq--MD-LG {
    &.left {
      left: 5px; // 80 - 68 - 7
    }
    &.right {
      right: 5px; // 80 - 68 - 7
    }
  }

  @media @mq--SM {
    width: 50px;
    height: 50px;
    line-height: 50px;

    .icon-arrow-left,
    .icon-arrow-right {
      font-size: 50px;
    }

    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }
  }

  @media @mq--XS {
    width: 30px;
    height: 30px;
    line-height: 30px;

    .icon-arrow-left,
    .icon-arrow-right {
      font-size: 30px;
    }

    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }
  }
}

.controls .slide-carousel-control {
  display: block;
}
