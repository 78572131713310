.hpe-carousel {
  &.a11y {
    .owl-carousel {
      .owl-nav:not(.cloned) .owl-prev,
      .owl-nav.cloned .owl-next {
        display: none;
      }
    }
  }
}

body:not(.disabled-focus) .hpe-carousel.a11y {
  &:not([data-carousel-mode]) {
    &:not(.transition) .owl-item {
      visibility: hidden;
      transition: visibility 0ms 500ms;
    }
    &.transition .owl-item, .owl-item.active {
      visibility: visible !important;
      transition: visibility 0ms 0ms !important;
    }
  }

  &[data-carousel-mode="slider"] {
    .owl-item {
      visibility: hidden;
      transition: visibility 0ms 300ms;
    }
    .owl-item.active {
      visibility: visible;
      transition: visibility 0ms 0ms;
    }
  }
}
