@import (reference) "../../../ui/dependencies.less";

esl-alert {
  display: block;
  text-align: center;

  position: fixed;
  bottom: 20px;
  padding: 20px;

  width: 70%;
  right: 15%;

  @media @mq--SM {
    width: 40%;
    right: 30%;
  }
  @media @mq--MD-XL {
    width: 30%;
    right: 35%;
  }

  font-family: @f-typo4;
  font-weight: @fw-semibold;
  // = Body Copy Small
  .setFontByList(@typo4-text-mobile);
  @media @mq--SM-MD {
    .setFontByList(@typo4-text-tablet);
  }
  @media @mq--LG-XL {
    .setFontByList(@typo4-text-desktop);
  }

  // to appear above subscription bar
  z-index: 10002;

  opacity: 0;
  visibility: hidden;

  transition: opacity .3s ease .05s, visibility 0s linear .35s;
  background-color: @primary21;
  color: @primary9;

  &[open] {
    opacity: 1;
    visibility: visible;
  }
}

.esl-alert-content {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  // Fix ios incorrect rendering
  transform: translate3d(0, 0, 0);

  > svg,
  > .alert-icon {
    fill: @primary9;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.75em;
    background-repeat: no-repeat;
    background-position: center center;
  }
  > .alert-icon.alert-icon-sm {
    width: 1em;
    height: 1em;
  }
}

html[dir=rtl] {
  .esl-alert-content > svg,
  .esl-alert-content > .alert-icon {
    margin-left: 0.75em;
    margin-right: 0;
  }
}
