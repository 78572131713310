@import (reference) "./icons/arrow-icon.less";
//---------------------------------------
// Icon with arrow
//---------------------------------------

.buildArrowLinkColoredTheme(@name, @color) {
  .hpe-arrow-link.color-@{name} {
    color: @color;
    fill: @color;

    &:visited,
    &.hovered,
    &:hover,
    &:focus {
      color: @color;
      fill: @color;
    }

    .arrow {
      .buildShortArrowIcon(@color);
    }
  }
}

.applyColoredThemes() {
  .buildArrowLinkColoredTheme(~"light-blue", @primary17);
  .buildArrowLinkColoredTheme(~"medium-blue", @primary18);
  .buildArrowLinkColoredTheme(~"dark-blue", @primary19);
  .buildArrowLinkColoredTheme(~"aruba-orange", @primary20);
  .buildArrowLinkColoredTheme(~"light-purple", @primary21);
  .buildArrowLinkColoredTheme(~"medium-purple", @primary22);
  .buildArrowLinkColoredTheme(~"yellow", @primary23);
  .buildArrowLinkColoredTheme(~"black", @primary10);
  .buildArrowLinkColoredTheme(~"white", @primary9);
}

.hpe-arrow-link {
  position: relative;
  .setFont(20px, 24px);

  font-family: @boldFont, Arial, sans-serif;
  cursor: pointer;

  color: @primary1;
  fill: @primary1;
  text-decoration: none;

  padding-right: 5px; // Arrow shift in active state size, for correct drawing focus outline

  .text {
    color: inherit;
  }

  &:visited,
  &.hovered,
  &:hover,
  &:focus {
    color: @primary1;
    fill: @primary1;
    text-decoration: none;
  }

  &:visited,
  &.hovered,
  &:hover,
  body:not(.disabled-focus) &:focus {
    .arrow {
      transform: translateX(5px);
    }
  }

  &.white-type:not(#add-spec) {
    color: @primary9;
    fill: @primary1;

    &:visited,
    &.hovered,
    &:hover,
    &:focus {
      color: @primary9;
      fill: @primary1;
    }

    .arrow,
    .arrow-wrapper {
      color: inherit;
      fill: @primary1;
    }
  }

  .arrow-wrapper {
    white-space: nowrap;
  }

  .arrow {
    display: inline-block;
    width: 1em;
    height: 1em;

    margin-left: 5px;
    vertical-align: text-bottom;
    transition: transform 150ms ease-in-out;

    &:extend(.arrow-icon);
  }

  @media @mq--MD {
    .setFont(19px, 23px);
  }
  @media @mq--XS-SM {
    .setFont(18px, 20px);
  }

  &.body-copy-small {
    @media @mq--SM-XL {
      .setFont(18px, 20px);
    }
    @media @mq--XS {
      .setFont(17px, 20px);
    }
  }

  &:hover,
  body:not(.disabled-focus) &:focus,
  :is(.clickable-area:hover, .clickable-area:focus) ~ .clickable-area-wrapper & {
    .arrow {
      transform: translateX(5px);
    }
  }
}

.applyColoredThemes();

//ARROW LINKS
.hpe-arrow-link {
  &.typo3, &.btn-v3 {
    .setFont(22px, 26px);
    @media @mq--LG {
      .setFont(20px, 24px);
    }
    @media @mq--XS-MD {
      .setFont(18px, 22px);
    }

    &.body-copy-small {
      font-family: @boldFont, Arial, sans-serif;
      color: @primary1;
      .setFont(17px, 20px);
      @media @mq--XS-MD {
        .setFont(16px, 19px);
      }
    }

    &.large {
      .setFont(24px, 26px);
      @media @mq--LG {
        .setFont(22px, 24px);
      }
      @media @mq--XS-MD {
        .setFont(16px, 18px);
      }
    }
  }
}

.dark-theme,
.light-theme,
body .dark-theme-prespecific &,
body .light-theme-prespecific &,
html body .dark-theme-specific,
html body .light-theme-specific {
  .hpe-arrow-link {
    color: @primary1;
    fill: @primary1;

    &:visited,
    &.hovered,
    &:hover,
    &:focus {
      color: @primary1;
      fill: @primary1;
    }

    .arrow,
    .arrow-wrapper,
    .hpe-chat-hide-online,
    .hpe-chat-hide-offline {
      color: inherit;
      fill: @primary1;
    }
  }

  .applyColoredThemes();
}

//ICON LINKS
.icon-lnk.typo3 {
  .icon-wrap {
    &::before {
      font-size: 25px;
      top: ~"calc(50% - 12.5px)";
      left: ~"calc(50% - 12.5px)";
    }
  }
}

.dark-theme, body .dark-theme-prespecific &, html body .dark-theme-specific {
  .icon-lnk {
    &.typo3 {
      .icon-wrap::after {
        border-color: @primary1;
      }
    }
  }
}


.hpe-arrow-link-back {
  display: inline-block;
  padding: 0;

  .arrow {
    transform: rotate(180deg) translateX(5px);
  }

  &:hover {
    .arrow {
      transform: rotate(180deg) translateX(10px);
    }
  }
}
