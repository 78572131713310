/** @deprecated */
.close-icon-sm {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  color: @primary9;
  text-decoration: none;
  font-size: 20px;
  line-height: 1;
  margin: 15px 15px 0 0;
  opacity: 0.9;
  &::after {
    content: "\f128";
    font-family: 'hpe-glyphicons';
  }
  &:hover {
    opacity: 1;
  }
}
