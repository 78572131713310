@import (reference) "../../../../core/ui/dependencies.less";

//---------------------------------------
// Icon links
//---------------------------------------
@icon_radius: 46px;
//NOTE: renamed icon-link -> icon-lnk due to compatibility with old code
.icon-lnk {
  color: #333;
  display: inline-flex;
  align-items: center;
  font-family: @boldFont, Arial, sans-serif;
  .setFont(19px, 1.2em);

  //Quirk for outline-offset because it's not supported by IE11
  padding: 2px;
  margin: -2px;

  .icon-wrap {
    position: relative;
    flex-basis: @icon_radius;
    flex-shrink: 0;
    height: @icon_radius;
    width: @icon_radius;
    text-align: center; //added for images

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      border: 2px solid @primary1;
      width: @icon_radius;
      height: @icon_radius;
      top: ~"calc(50% - 23px)";
      z-index: 1;
    }

    img.icon {
      vertical-align: top;
    }

    .icon {
      top: ~"calc(50% - 12px)";
      position: relative;
      width: 24px;
      height: 24px;
    }

    //icon placeholder (when use classname)
    &::before {
      position: absolute;
      font-size: 26px;
      top: ~"calc(50% - 13px)";
      left: ~"calc(50% - 13px)";
    }
  }

  &.circle-off {
    .icon-wrap {
      &::after {
        display: none;
      }
    }
  }

  .icon-desc {
    padding-left: 10px;
  }

  &:hover, &:focus {
    .icon-wrap::after {
      border-width: 4px;
      left: -2px;
      top: ~"calc(50% - 25px)";
      width: @icon_radius + 4px;
      height: @icon_radius + 4px;
    }
  }
}

//---------------------------------------
// Colors/themes
//---------------------------------------
// by default - light theme
.dark-theme, body .dark-theme-prespecific &, html body .dark-theme-specific {
  .icon-lnk {
    .icon-wrap::after {
      border-color: @secondary12;
    }
  }
}

.light-theme, body .light-theme-prespecific &, html body .light-theme-specific {
  .icon-lnk {
    .icon-wrap::after {
      border-color: @primary1;
    }
  }
}

//---------------------------------------
// Icon links style redefinitions (can be dropped after moving from bootstrap)
//---------------------------------------
.icon-lnk {
  &:hover, &:focus {
    color: @primary10;
  }
}
