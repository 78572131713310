//
// Component animations
// --------------------------------------------------
.fade {
    opacity: 0;
    transition: opacity 0.2s linear;

    &.in {
        opacity: 1;
    }
}

.collapse {
  display: none;
  visibility: hidden;

  &.in      { display: block; visibility: visible; }
  tr&.in    { display: table-row; }
  tbody&.in { display: table-row-group; }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
}
