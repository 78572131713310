.slide-carousel-dots {
  display: none;

  position: absolute;
  z-index: 1;

  left: 0;
  top: auto;
  bottom: 30px;

  width: 100%;
  height: auto;
  line-height: 11px;

  font-size: 0;
  text-align: center;

  & > .dot-nav {
    display: inline-block;

    width: 9px;
    height: 9px;
    border-radius: 6px;

    margin: 0 5px;
    padding: 0;
    vertical-align: middle;

    background: rgba(118,118,118,.5);
    border: none;
    &.active {
      width: 11px;
      height: 11px;

      cursor: default;
      border: 1px solid #bababa;
      background: rgba(255,255,255,.5);
    }
  }
  .slide-carousel.dots-outside & {
    position: static;
    padding: 30px 0;
  }
}

.dots-style-contrast {
  &.slide-dark {
    .slide-carousel-dots .dot-nav {
      border: 1px solid #BABABA;
      background: rgba(118, 118, 118, .5);

      &.active {
        background: rgba(255, 255, 255, .95);
      }
    }
  }
  &.slide-light {
    .slide-carousel-dots .dot-nav {
      border: 1px solid #A3A3A3;
      background: rgba(118, 118, 118, .95);

      &.active {
        border: 1px solid #BABABA;
        background: rgba(255, 255, 255, .95);
      }
    }
  }

  .slide-carousel-dots .dot-nav {
    transition: background-color .25s linear;
  }
}

.controls .slide-carousel-dots {
  display: block;
}
