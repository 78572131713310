@import "../../core/ui/less/variables.less";

:root {
  @media @mq--XL {
    --container-offset-x: 100px;
  }

  @media @mq--MD-LG {
    --container-offset-x: 80px;
  }

  @media @mq--SM {
    --container-offset-x: 50px;
  }

  @media @mq--XS {
    --container-offset-x: 30px;
  }
}

.hf-centered-content {
  display: flex;
  position: relative;

  width: 100%;
  max-width: 1800px;

  @media @mq--XS-SM {
    display: block;
  }
}

.hf-centered-content, .centered-content {
  margin: 0 auto;
  padding: 0 var(--container-offset-x, 0);

  @media @mq--XL {
    max-width: 1800px;
  }
}

.container-fluid.container-gutter {
  padding-left: var(--container-offset-x, 0);
  padding-right: var(--container-offset-x, 0);

  @media @mq--XL {
    max-width: 1800px;
  }
}
