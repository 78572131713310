.hpe-note-wrapper {
  white-space: nowrap;
}

hpe-note {
  display: inline-block;
  position: relative;
  margin: 0.4em 0.2em 0;
  border: 1px solid @primary1;
  border-radius: 6px;
  cursor: pointer;
  padding: 1px 3px;
  min-width: 15px;
  vertical-align: text-top;
  text-align: center;
  line-height: 9px !important;
  font-family: @boldFont;
  font-size: 11px !important;
  font-style: normal;

  .firefox & {
    padding: 0 3px 2px;
  }

  .tag-h1 &, h1 & {
    margin-top: 1.7em;
  }

  .tag-h2 &, h2 & {
    margin-top: 1.4em;
  }

  .tag-h2-small & {
    margin-top: 1.2em;
  }

  .tag-h3 &, h3 & {
    margin-top: 1em;
  }

  .tag-h4 &, h4 & {
    margin-top: 0.7em;
  }

  .tag-h5 &, h5 & {
    margin-top: 0.5em;
  }

  .body-copy-large & {
    margin-top: 0.6em;
  }

  .tagline-small &,
  .body-copy-xsmall & {
    margin-top: 0.3em;
  }

  @media @mq--MD-LG {
    .tag-h1 &, h1 & {
      margin-top: 1.4em;
    }

    .tag-h2 &, h2 & {
      margin-top: 1.1em;
    }

    .tag-h2-small & {
      margin-top: 0.8em;
    }

    .tag-h3 &, h3 & {
      margin-top: 0.7em;
    }

    .body-copy-large & {
      margin-top: 0.5em;
    }
  }

  @media @mq--SM-MD {
    .tag-h1 &, h1 & {
      margin-top: 1em;
    }

    .tag-h2 &, h2 & {
      margin-top: 0.9em;
    }

    .tag-h2-small & {
      margin-top: 0.8em;
    }

    .tag-h3 &, h3 & {
      margin-top: 0.6em;
    }

    .tag-h4 &, h4 & {
      margin-top: 0.5em;
    }

    .tag-h5 &, h5 & {
      margin-top: 0.4em;
    }
  }

  @media @mq--XS-SM {
    .tag-h1 &, h1 & {
      margin-top: 0.7em;
    }

    .tag-h4 &, h4 & {
      margin-top: 0.5em;
    }

    .body-copy-xsmall & {
      margin-top: 0;
    }

    .body-copy-large & {
      margin-top: 0.4em;
    }
  }

  @media @mq--XS {
    .tag-h2 &, h2 & {
      margin-top: 0.7em;
    }

    .tag-h2-small & {
      margin-top: 0.7em;
    }

    .tag-h3 &, h3 & {
      margin-top: 0.5em;
    }

    .tag-h5 &, h5 & {
      margin-top: 0.4em;
    }
  }

  &, .light-theme &, body .light-theme-prespecific &, html body .light-theme-specific & {
    border-color: @primary1;
    background: @primary9;
    color: @primary1;

    &.active, &:hover, &.highlight {
      background: @primary1;
      color: @primary9;
    }
  }

  .dark-theme &,
  body .dark-theme-prespecific &,
  html body .dark-theme-specific &,
  [class*="style-bg-"]:not(.style-bg-none):not([class*="style-bg-gray"]):not([class*="style-bg-grey"]):not(.style-bg-white):not(.style-bg-dark-steel) & {
    border-color: @primary9;
    background: transparent;
    color: @primary9;

    &.active, &:hover, &.highlight {
      background: @primary9;
      color: @primary1;
    }
  }
}

@import "hpe-note.typo4.less";
@import "hpe-note.typo5.less";

@media not print {
  .esl-note-link {
    border: none !important;
    &, &:hover {
      color: inherit !important;
    }
  }
}
@media print {
  hpe-note {
    border: none;
    padding: 0;
    min-width: auto;
    color: inherit !important;
  }
}
