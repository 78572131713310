.buildBookmarkIcon(@stroke: @primary1, @fill: 'none') {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.08 14.45"><polygon points="0.52 0.52 0.52 13.55 6.04 10.45 11.56 13.55 11.56 0.52 0.52 0.52" fill="@{fill}" stroke="@{stroke}" stroke-miterlimit="10" stroke-width="1.05"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.bookmark-icon {
  .buildBookmarkIcon();
}

.bookmark-white-icon {
  .buildBookmarkIcon(@primary9, @primary9);
}

.active-bookmark-icon {
  .buildBookmarkIcon(@primary1, @primary1);
}
