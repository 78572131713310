.accordion-arrow-icon {
  width: 28px;
  height: 17px;
  display: inline-block;
  position: relative;
  &:after,
  &:before {
    content: '';
    top: 6px;
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: black;
    display: inline-block;
    transition: transform .2s ease;
    will-change: transfrom;
  }
  &:after {
    left: -2px;
    transform: rotate(45deg);
  }
  &:before {
    right: -2px;
    transform: rotate(-45deg);
  }
  .active & {
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }

  @media @mq--XS-MD {
    width: 25px;
    height: 15px;
    &:after,
    &:before {
      width: 18px;
    }
  }
  @media @mq--XS-SM {
    width: 22px;
    height: 12px;
    &:after,
    &:before {
      top: 5px;
      width: 16px;
    }
  }
  @media @mq--XS {
    width: 15px;
    height: 9px;
    &:after,
    &:before {
      top: 3px;
      width: 12px;
    }
  }
}
