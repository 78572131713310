// ------------------------------------------------------
// BUTTONS
//---------------------------------------
// Design Specs: https://razorfish.app.box.com/files/0/f/3433464518/1/f_31893937593
// ------------------------------------------------------
.btn {
    background: @primary1;
    border-radius: 0;
    font-family: @regularFont, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    white-space: normal;
    width: auto;
    text-transform: uppercase; // All CTA's will be uppercase
    // Default margin & padding values on the component
    margin-top: 15px;
    // padding: 6px; // Added to account for the button height to stay restricted to 40px
    // POD CR: Update button padding
    padding: 10px 30px;
}
//---------------------------------------
// BUTTONS: SHARED PROPERTIES
//---------------------------------------
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-contact,
.btn-icon {
    min-height: 40px;
    min-width: 200px;
    max-width: 400px;
    // ****************************************************************************
    //  * Media Query: XSmall Breakpoint
    //  ****************************************************************************
    @media screen and (max-width: @screen-xs-max) {
        min-width: 125px;
        max-width: 250px;
    }
}
//---------------------------------------
// PRIMARY BUTTON
//---------------------------------------
.btn-primary {
    color: @primary10;
    background: @primary1;
    border: solid 2px @primary1;
    border-collapse: collapse;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle.btn-default {
        background: @primary1;
        border-color: @primary1;
        box-shadow: none;
        color: @primary10;
        outline: 2px solid @primary1;
    }
    // Primary with Alpha value
    //==============
    &.alpha {
        @btn-alpha-color: fade(@primary1, 60%);
        border-color: transparent;
        background: @btn-alpha-color;
        color: @primary9;
        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        .open > .dropdown-toggle.btn-default {
            outline: 2px solid @btn-alpha-color;
            .safari & {
                outline: none;
                box-shadow: 0 0 0 2px @btn-alpha-color;
            }
        }
    }
}
//---------------------------------------
// SECONDARY BUTTON
//---------------------------------------
.btn-secondary {
    background: none;
    color: @primary10;
    outline: none;
    border: solid 2px @primary1;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle.btn-default {
        background: none;
        box-shadow: none;
        color: @primary10;
        outline: 2px solid @primary1;
    }
    // Secondary with Alpha Value
    //==============
    &.alpha {
        @btn-alpha-color: fade(@primary10, 50%);
        background: @btn-alpha-color;
        border-color: @primary1;
        color: @primary9;
        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        .open > .dropdown-toggle.btn-default {
            outline: 2px solid @primary1;
        }
    }
}
//---------------------------------------
// TERTIARY BUTTON
//---------------------------------------
// Legacy ButtonCTA + redirect component
.btn-tertiary {
    color: @primary10;
    background: none;
    border: solid 2px fade(@primary10, 20%);
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle.btn-default {
        background: none;
        outline: solid 2px fade(@primary10, 20%);
        color: @primary10;
        box-shadow: none;
        .safari & {
            outline: none;
            box-shadow: 0 0 0 2px fade(@primary10, 20%);
        }
    }
    // Tertiary with Alpha Value
    //==============
    &.alpha {
        @btn-alpha-color: fade(@primary9, 60%);
        background: none;
        border-color: @btn-alpha-color;
        color: @primary9;
        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        .open > .dropdown-toggle.btn-default {
            outline: 2px solid @btn-alpha-color;
            .safari & {
                outline: none;
                box-shadow: 0 0 0 2px @btn-alpha-color;
            }
        }
    }
}
//---------------------------------------
// CONTACT BUTTON
//---------------------------------------
.btn-contact {
    color: @primary10;
    background: none;
    border: solid 2px @primary7;
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > .dropdown-toggle.btn-default {
        background: @primary7;
        border-color: @primary7;
        box-shadow: none;
        color: @primary10;
        outline: 2px solid @primary7;
    }
}

.disabled-focus {
    .btn-primary,
    .btn-primary.alpha,
    .btn-secondary,
    .btn-secondary.alpha,
    .btn-tertiary,
    .btn-tertiary.alpha,
    .btn-contact {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

//---------------------------------------
// BUTTON DISABLED
//---------------------------------------
.btn {
    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover,
    &.disabled:focus,
    &[disabled]:focus,
    &.disabled.focus,
    &[disabled].focus,
    &.disabled:active,
    &[disabled]:active,
    &.disabled.active,
    &[disabled].active {
        background: none;
        border-color: @primary5;
        color: @primary5;
    }
}
//---------------------------------------
// CTA LINKS
//---------------------------------------
.text-center .cta-link {
    //display inline-block within text-center elements
    //this prevents icon from not being centered with text
    display: inline-block;
}
.cta-link {
    font-family: @regularFont, Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    color: @primary12;
    text-decoration: none;
    cursor: pointer;
    display: block;
    // Default margin & padding values on the component
    margin-top: 15px;
    padding: 0;
    //creating padding so that wrapped text does not wrap beneath icon
    padding-left: 1.6em;
    .icon-arrow-circle-right,
    .icon {
        margin-right: 2px;
        padding-left: 1px; // To avoid the icon from being clipped off
        //Horizontal Alignment
        top: 1px;
        //position absolute to move into cta-link padding
        //this avoids text wrapping beneath icon
        position: absolute;
        left: 0;
        //preventing icon edge from being cut off
        width: 100%;
        text-align: left;
        &:before {
            color: @primary1;
        }
    }
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: @primary12;
        text-decoration: none;
        .icon-arrow-circle-right,
        .icon {
            &:before {
                @btn-alpha-color: fade(@primary12, 60%);
                color: @btn-alpha-color;
            }
        }
    }
    // Cta-Link with Alpha Value
    //==============
    &.alpha {
        color: @primary9;
        .icon-arrow-circle-right {
            &:before {
                color: @primary9;
            }
        }
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: fade(@primary9, 60%);
            .icon-arrow-circle-right,
            .icon {
                &:before {
                    color: fade(@primary9, 60%);
                }
            }
        }
    }
}

// PRIMARY LINK
.primary-link {
    font-family: @boldFont, Arial, sans-serif;
    font-size: 19px;
    line-height: 23px;
    position: relative;
    color: @primary1;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s ease;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: @primary10;
        text-decoration: none;
    }
    &.alpha {
        color: fade(@primary9, 60%);
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: @primary9;
        }
    }
}

/* SECONDARY LINK */
.secondary-link {
    font-family: @boldFont, Arial, sans-serif;
    /* Default padding using Regular */
    padding-left: 23px;
    display: inline-block;
    transition: color .2s ease, fill .2s ease;
    em {
        position: absolute;
        color: @primary1;
        font-size: 14px;
        /* Top Adjustment needed */
        top: 2px;
        left: 0;
        transition: left .2s ease;
    }
    span {
        font-family: @boldFont, Arial, sans-serif;
    }
    /* Default State using Regular */
    .secondary-link-wrapper:hover & em,
    .secondary-link-wrapper:focus & em,
    .secondary-link-wrapper:active & em {
      left: 5px;
    }
    &:hover em,
    &:focus em,
    &.focus em,
    &:active em,
    &.active em {
        left: 5px;
        color: @primary1;
    }
    &.medium-font {
        font-family: @mediumFont, Arial, sans-serif;
    }
    /* Default Font Size using Regular */
    font-size: 18px;
    line-height: 22px;
    position: relative;
    color: @primary12;
    text-decoration: none;
    cursor: pointer;
    &.regular {
        font-size: 18px;
        line-height: 22px;
        em {
            font-size: 14px;
        }
    }
    &.small {
        font-size: 15px;
        line-height: 18px;
        em {
            font-size: 12px;
        }
    }
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: @primary10;
        text-decoration: none;
    }
    &.alpha {
        color: fade(@primary9, 60%);
        em {
            color: fade(@primary9, 60%);
        }
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        &:hover em,
        &:focus em,
        &.focus em,
        &:active em,
        &.active em {
            color: @primary9;
        }
    }
    &.regular {
        padding-left: 23px;
        &:hover em,
        &:focus em,
        &.focus em,
        &:active em,
        &.active em {
            left: 5px;
        }
    }
    &.small {
        padding-left: 18px;
        &:hover em,
        &:focus em,
        &.focus em,
        &:active em,
        &.active em {
            left: 3px;
        }
    }
    /* --------------------------------------------------------------- */
    /* Secondary Link Font Size: Phone/Tablet/Phablet - 768px or 992px */
    /* --------------------------------------------------------------- */
    @media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        /* Default Font Size using Regular */
        font-size: 17px;
        line-height: 21px;
        &.regular {
            font-size: 17px;
            line-height: 21px;
        }
    }
    /* ------------------------------------------------- */
    /*  Secondary Link Font Size: Mobile - 767px or less */
    /* ------------------------------------------------- */
    @media screen and (max-width: @screen-xs-max) {
        /* Default Font Size using Regular */
        font-size: 16px;
        line-height: 19px;
        &.regular {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

/* SECONDARY LINK in hovered components */
.hovered {
    .secondary-link {
        color: @primary10;
        text-decoration: none;
        &.alpha {
            color: @primary9;
            em {
                color: @primary9;
            }
        }
        em {
            left: 5px;
            color: @primary1;
        }
        &.regular {
            em {
                left: 5px;
            }
        }
        &.small {
            em {
                left: 3px;
            }
        }
    }
}

.secondary-link.button-cta {
    padding-left: 21px;
    margin-top: 9px;
    color: #333;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: @primary10;
    }
    &.alpha {
        color: fade(@primary9, 90%);
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: @primary9;
        }
    }
    @media screen and (max-width: @screen-md-max) {
        padding-left: 20px;
        font-size: 17px;
        line-height: 21px;
        em {
            font-size: 13px;
        }
    }
    @media screen and (max-width: @screen-sm-max) {
        padding-left: 19px;
        margin-top: 11px;
        font-size: 16px;
        line-height: 19px;
        em {
            font-size: 12px;
        }
    }
}

/* TERTIARY LINK */
.tertiary-link {
    font-family: @lightFont, Arial, sans-serif;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease, border-color .2s ease, fill .2s ease;

    &, &.default {
      color: inherit;
      border-bottom: 1px solid @primary1;

      &:hover,
      &:focus-visible,
      &.focus,
      &:active,
      &.active {
        color: @primary10;
        text-decoration: none;
        border-bottom: 1px solid @primary12;
      }
    }

    &.alpha {
        @btn-alpha-color: fade(@primary9, 60%);
        color: @primary9;
        border-bottom: 1px solid @primary12; // RGBA Fallback
        border-bottom: 1px solid @btn-alpha-color;
        &:hover,
        &:focus-visible,
        &.focus,
        &:active,
        &.active {
            color: @primary9;
            border-bottom: 1px solid @primary9;
        }
    }

    // Fix focus styles
    &:focus {
      transform: translateZ(0);
    }
}
