@import "@exadel/esl/modules/esl-image-utils/core/esl-image-utils.container.less";
@import "@exadel/esl/modules/esl-image-utils/core/esl-image-utils.fade.less";

// Default image styles
img {
  text-align: center;
  vertical-align: middle;
}

.img-container {
  background: url("../images/loader.gif") 50% 50% no-repeat transparent;
  background-size: 40px;
  box-shadow: inset 1px 1px 0 0 #eee, inset -1px -1px 0 0 #eee;

  &::after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  // Fade in image on load
  &[esl-image-container] {
    > img {
      opacity: 0;
    }
    &.img-container-loaded > img {
      opacity: 1;
      transition: opacity 0.4s;
    }
  }

  &.img-noratio {
    img {
      position: relative;
    }
  }
}

.img-container-loaded {
  background: none;
  border: none;
  box-shadow: none;
}

.img-container-1-1 {
  &::after {
    padding-top: @ratio-1-1;
  }
}

.img-container-3-1 {
  &::after {
    padding-top: @ratio-3-1;
  }
}

.img-container-6-9 {
  &::after {
    padding-top: @ratio-6-9;
  }
}

.img-container-9-6 {
  &::after {
    padding-top: @ratio-9-6;
  }
}

.img-container-16-9 {
  &::after {
    padding-top: @ratio-16-9;
  }
}

.img-container-16-10 {
  &::after {
    padding-top: @ratio-16-10;
  }
}

.img-container-4-3 {
  &::after {
    padding-top: @ratio-4-3;
  }
}

.img-container-26-9 {
  &::after {
    padding-top: @ratio-26-9;
  }
}

// Legacy ESL Image (aka Smart Image) styles
@import "./esl.image.less";
