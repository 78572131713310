// ****************************************************************************
//  * columns component: HPE-136
//  ****************************************************************************
.columns-component {
  // Default padding & margin values on the component
  margin: 0;
  padding: 0;
  .container-gutter {
    max-width: 1600px;
    margin: 0 auto;
    .container-gutter {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .container-gutter.container-fluid {
    max-width: 1800px;
  }
}

.columns-component-col {
  .make-xs-column(12);
  width: 100%;

  //specila scenario for vertical text aligment
  &.valign-txt-only {
    .textOnlyComponent {
      height: 100%;
    }
  }
}

// two evenly spaced columns
.column-content-2 {
  > .container-gutter {
    > .row {
      > .columns-component-col {
        .make-sm-column(6);
      }
    }
  }
}

// two columns: 33% / 66%
.column-content-2-33-66 {
  > .container-gutter {
    > .row {
      > .columns-component-col {
        .make-sm-column(8);
        &:first-child {
          .make-sm-column(4);
        }
      }
    }
  }
}

// two columns: 66% / 33%
.column-content-2-66-33 {
  > .container-gutter {
    > .row {
      > .columns-component-col {
        .make-sm-column(4);
        &:first-child {
          .make-sm-column(8);
        }
      }
    }
  }
}

// three evenly spaced columns
.column-content-3 {
  > .container-gutter {
    > .row {
      > .columns-component-col {
        .make-sm-column(4);
      }
    }
  }
}

// 4 evenly spaced columns or at smaller breakpoints,
//   2 evenly spaced columns, 2 rows
.column-content-4 {
  .columns-component-col {
    .make-sm-column(6);
    .make-md-column(3);
  }
}

// ****************************************************************************
//  * child contents overrides
//  ****************************************************************************
.columns-component {
  .hpe-text-only {
    word-wrap: break-word;
  }
}

// ****************************************************************************
//  * columns component breakpoint: XS
//  ****************************************************************************
@media screen and (max-width: @screen-xs-max) {
  .columns-component {
    .container-gutter {
      .container-gutter {
        .columns-component-col {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

// ****************************************************************************
//  * columns component breakpoint: SMALL
//  ****************************************************************************
@media screen and (min-width: @screen-sm-min) {
  .columns-component {
    .container-gutter {
      .container-gutter {
        margin-right: (@grid-gutter-width / -2);
        margin-left: (@grid-gutter-width / -2);
      }
    }

    // ****************************************************************************
    //  * child contents overrides
    //  ****************************************************************************
    // Rule Horizontal Component - HPE-2698
    .hpe-rule-hz .container-gutter {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// ****************************************************************************
//  * columns component breakpoint: SMALL ONLY
//  ****************************************************************************
@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .column-content-4 {
    .columns-component-col {
      float: none;
      display: inline-block;
      margin: 0 -0.125em;
      vertical-align: top;
    }
  }
}

@import "hpe.columns.container.modal.less";
