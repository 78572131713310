@import (reference) "../../../../core/ui/dependencies.less";

.hpe-avatar-size(@size) {
  width: @size;
  min-width: @size;
  height: @size;
  min-height: @size;
}

.hpe-avatar-text-size(@size) {
  .setFont(@size, @size);
}

hpe-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: @primary18;
  .hpe-avatar-size(100px);

  &.with-image {
    background-color: transparent;
  }

  .hpe-avatar-text {
    .hpe-avatar-text-size(40px);
    font-family: @f-typo4;
    font-weight: @fw-regular;
    text-transform: uppercase;
    color: @primary10;
  }
}
