@import (reference) "../../../../core/ui/dependencies.less";
@import (reference) "./../../../../styleguide/ui/icons/circle-alert-icon.less";

@NOTIFY_ICON_SIZE: .7em;
@NOTIFY_ICON_MARGIN: 10px;

hpe-notify {
  display: none;
  margin-left: @NOTIFY_ICON_MARGIN;

  &.ready {
    display: inline;
  }

  &::before {
    .circle-alert-icon();
    content: '';
    display: inline-block;
    height: @NOTIFY_ICON_SIZE;
    width: @NOTIFY_ICON_SIZE;
    cursor: pointer;
  }
}

.esl-popup.nf-popup {
  width: 240px;
  border-bottom: 4px solid @primary1;

  font-family: @lightFont;
  font-size: 17px;
  line-height: 20px;

  &[placed-at=top] {
    border-top: 4px solid @primary1;
    border-bottom: none;
  }

  &[placed-at=left] {
    border-left: 4px solid @primary1;
    border-bottom: none;
  }

  &[placed-at=right] {
    border-right: 4px solid @primary1;
    border-bottom: none;
  }
}

