@import (reference) "./typography-typo5.less";
@import (reference) "./icons/arrow-icon-unicorn.less";

.hpe-arrow-link.typo5 {
  font-weight: @fw-semibold;
  .setFontByList(@typo5-body-mobile);
  @media @mq--MD-LG {
    .setFontByList(@typo5-body-tablet);
  }
  @media @mq--XL {
    .setFontByList(@typo5-body-desktop);
  }

  .arrow {
    background: url('data:image/svg+xml,@{arrowIconUc}') no-repeat;
    width: .7em;
    height: .85em;
    transition: transform 150ms ease-in-out;

    margin-left: 8px;
    @media @mq--SM-XL {
      margin-left: 10px;
    }
  }

  .link-text {
    color: inherit;
  }

  &.large {
    .setFontByList(@typo5-large-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-large-body-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-large-body-desktop);
    }

    .arrow {
      margin-left: 10px;
      @media @mq--SM-XL {
        margin-left: 12px;
      }
    }
  }

  &.small {
    .setFontByList(@typo5-small-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-small-body-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-small-body-desktop);
    }

    .arrow {
      @media @mq--MD-XL {
        margin-left: 8px;
      }
    }
  }

  &.white-link:not(.add-spec) {
    color: #fff;

    .arrow {
      background: url('data:image/svg+xml,@{whiteArrowIconUc}') no-repeat;
    }
  }

  &.black-link:not(.add-spec) {
    color: @primary31;

    .arrow {
      background: url('data:image/svg+xml,@{blackArrowIconUc}') no-repeat;
    }
  }
}
