@import (reference) "./../../../ui/dependencies.less";
@import "./../../video-info-module/ui/video-info-module.less";

// ****************************************************************************
// * Video Overlay: HPE-1377
// ****************************************************************************
video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &::before, &::after {
    content: '';
    flex: 1 0 auto;
    min-height: 40px;
  }

  z-index: 120;

  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  background: rgba(0, 0, 0, 0.65);
  transition: opacity .3s ease .05s, visibility 0s linear .35s;

  &[active] {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease .05s, visibility 0s linear 0s;
  }

  &[loading] .video-container {
    &::after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: #000 url(@spinnerGif) 50% 50% no-repeat;
      background-size: 40px;
    }
  }

  .video-info-wrapper:empty {
    min-height: 60px;
  }

  .video-container {
    position: relative;
    display: flex;
    flex-direction: column;

    flex: 0 0 auto;
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;

    background-color: @primary10;
    box-shadow: 0 1px 5px 2px rgba(198, 201, 202, .2);
  }

  .video-content {
    flex: 0 0 auto;
    overflow: hidden;
    background: none;
    box-shadow: none;
  }

  &[is-audio] {
    .video-content:before {
      display: block;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='60px' height='60px' viewBox='0 0 512 512'%3E%3Cg fill='%23fff'%3E%3Cpath d='M231.5 147.2c-.3.7-.4 50.3-.3 110.3l.3 109h20v-220l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM201.5 187.2c-.3.7-.4 32.3-.3 70.3l.3 69h20v-140l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM261.5 187.2c-.3.7-.4 32.3-.3 70.3l.3 69h20v-140l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM141 256.5V307h20V206h-20v50.5zM291.5 217.2c-.3.7-.4 18.9-.3 40.3l.3 39h20v-80l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM382 256.5V297h20v-81h-20v40.5zM111 256.5V287h20v-61h-20v30.5zM171 256.5V287h20v-61h-20v30.5zM322 256.5V287h20v-61h-20v30.5zM412.5 232.2c-.3.8-.4 12.1-.3 25.3l.3 24h20v-50l-9.8-.3c-7.3-.2-9.9.1-10.2 1zM80.5 237.2c-.3.8-.4 9.9-.3 20.3l.3 19h20v-40l-9.8-.3c-7.3-.2-9.9.1-10.2 1zM352 256.5V277h20v-41h-20v20.5zM50.4 247.3c-.2.7-.4 5.4-.2 10.3l.3 8.9h20v-20l-9.8-.3c-7.5-.2-9.9 0-10.3 1.1zM442.4 247.3c-.2.7-.4 5.4-.2 10.3l.3 8.9h20v-20l-9.8-.3c-7.5-.2-9.9 0-10.3 1.1z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 60px;
      background-position: center;
      width: 100%;
      height: 40px;
    }
    smart-media {
      top: auto;
      bottom: 0;
    }

    .video-content::after {
      padding-top: 42px;
    }
  }

  .close-video-btn {
    .button-reset();

    position: absolute;
    top: -40px;
    right: -12px;

    width: 46px;
    height: 46px;
    padding: 13px;
    font-size: 17px;

    color: @primary9;

    @media @mq--SM-XL {
      top: -37px;
      right: -3px;

      width: 34px;
      height: 34px;
      padding: 6px;
      font-size: 22px;
    }
  }

  // ---- Device Adaptations ----
  @media @mq--LG-XL {
    .video-container {
      max-width: 65vw;
    }
  }
  @media @mq--SM-MD {
    .video-container {
      max-width: 80vw;
    }
  }
  @media @mq--XS and (min-width: 550px) and (max-height: 450px) {
    &::before, &::after {
      min-height: 10px;
    }
    .video-container {
      max-width: 75vw;
    }
    // Move X to the right
    .close-video-btn {
      top: -12px;
      right: -40px;
    }
  }
  @media @mq--SM and (max-height: 600px), @mq--MD and (max-height: 700px) {
    &::before, &::after {
      min-height: 10px;
    }
    .video-container {
      max-width: 75vw;
    }
    // Move X to the right
    .close-video-btn {
      top: -3px;
      right: -37px;
    }
  }
}

.disabled-focus {
  video-overlay .video-container iframe {
    outline: none;
  }
}
