.play-icon {
  height: auto;
  display: inline-block;
  line-height: 0;
  position: relative;
  width: 44px;

  button& {
    border: none;
    padding: 0;
    background: none;
    box-shadow: none;
    appearance: none;
  }

  &::before {
    content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='rgba(0, 0, 0, 0.6)' stroke='white' stroke-width='6px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='36,58 60,44 36,30 '%3e%3c/polygon%3e%3c/svg%3e");
    display: inline-block;
    width: 100%;
    height: auto;
    line-height: 0;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='none' stroke='white' stroke-width='8px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='35,28.3 35,59.7 62,44'%3e%3c/polygon%3e%3c/svg%3e");
    opacity: 0;
    height: auto;
    line-height: 0;
    transition: opacity 200ms;
  }

  // Specific to case when used with UC Image Only
  .clickable-area + .clickable-area-wrapper & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    max-width: 120px;
  }
  .clickable-area:hover + .clickable-area-wrapper &::after,
  &:hover::after {
    opacity: 1;
  }
}
