.zoom-icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: 42px;
    background-position: center;
    width: 21px;
    height: 21px;
  }
  &.zoom-in::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23707070' d='M17.426 19.926a6 6 0 1 1 1.5-1.5L23 22.5 21.5 24l-4.074-4.074z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M11 16v-2h6v2z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M13 12h2v6h-2z'%3E%3C/path%3E%3C/svg%3E%0A");
  }
  &.zoom-out::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23707070' d='M17.426 19.926a6 6 0 1 1 1.5-1.5L23 22.5 21.5 24l-4.074-4.074z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M11 16v-2h6v2z'%3E%3C/path%3E%3C/svg%3E");
  }
}
