.buildElementIcon(@fill: @primary1) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" fill="@{fill}" viewBox="0 0 138 39.64"><path d="M0,0V39.64H138V0ZM129.38,31H8.63V8.63H129.38Z"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.element-icon {
  .buildElementIcon(@primary1);
}

.white-element-icon {
  .buildElementIcon(@primary9);
}
