@import (reference) "../../../ui/dependencies.less";

.disabled-focus {
  *:focus,
  .btn-v3:focus,
  .btn-typo4:focus,
  #hf-footer .hf-links-list .hf-link:focus .link-text,
  .carousel-item:focus .focusable-mask,
  .filterlist-filter-item .checkbox-input:focus .focusable-mask,
  .hpe-product-gallery .item-type-video .btn-play:focus .focusable-mask {
    outline: none;
  }
}

*:focus {
  outline: @FOCUS_OUTLINE_STYLE;
}
