
.opp {
  @main-color: #559ae6;

  position: relative;
  border: 4px solid @main-color;

  .opp-info {
    background-color: @main-color;
    color: #FFF;
    padding: 5px;
    font-size: 13px;
    width: 90%;
    max-width: 250px;
    word-break: break-all;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: auto;
    right: -4px;
    z-index: 10;

    a {
      color: inherit;
    }
  }

  .opp-ref-id {
    display: block;
    margin-top: -5px;
  }

  .opp-info-txt {
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      height: 100%;
      background: linear-gradient(to right, fade(@main-color, 0%), @main-color 100%);
    }
  }

  &:hover .opp-info-txt {
    white-space: normal;
    overflow: inherit;

    &:after {
      display: none;
    }
  }
}

