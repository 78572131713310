// ------------------------------------------------------ */
// TYPOGRAPHY: FontFace                                   */
// ------------------------------------------------------ */

@import (reference) "../../core/ui/reference.less";

//---------------------------------------
// MetricHPEXS
//---------------------------------------
@font-face {
  // Thin
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXSThin-Regular.woff2') format('woff2'),
  url('@{PATH_TO_FONTS}hpexs/MetricHPEXSThin-Regular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Light
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXSLight-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXSLight-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Regular (Normal)
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXS-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXS-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Medium
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXSMedium-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXSMedium-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Semibold
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXSSemibold-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXSSemibold-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Bold
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXS-Bold.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXS-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  // Black
  font-family: 'MetricHPEXS';
  src: url('@{PATH_TO_FONTS}hpexs/MetricHPEXSBlack-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}hpexs/MetricHPEXSBlack-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

//---------------------------------------
// TODO: remove when MetricHPEXS will be officially approved for typo5
// MetricHPE
//---------------------------------------
//@font-face {
//  // Light
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}Metric-Light.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}Metric-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}
//
//@font-face {
//  // Regular (Normal)
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}Metric-Regular.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}Metric-Regular.woff') format('woff');
//  font-weight: 400;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}
//
//@font-face {
//  // Medium
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}Metric-Medium.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}Metric-Medium.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}
//
//@font-face {
//  // Semibold
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}Metric-Semibold.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}Metric-Semibold.woff') format('woff');
//  font-weight: 600;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}
//
//@font-face {
//  // Bold
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}MetricHPE-Web-Bold.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}MetricHPE-Web-Bold.woff') format('woff');
//  font-weight: 700;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}
//
//@font-face {
//  // Black
//  font-family: 'MetricHPE';
//  src: url('@{PATH_TO_FONTS}MetricHPE-Web-Black.woff2') format('woff2'),
//       url('@{PATH_TO_FONTS}MetricHPE-Web-Black.woff') format('woff');
//  font-weight: 900;
//  font-style: normal;
//  font-stretch: normal;
//  font-display: swap;
//}

//---------------------------------------
// Metric
//---------------------------------------
@font-face {
  font-family: 'Metric Light';
  src: url('@{PATH_TO_FONTS}Metric-Light.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}Metric-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metric Regular';
  src: url('@{PATH_TO_FONTS}Metric-Regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}Metric-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metric Medium';
  src: url('@{PATH_TO_FONTS}Metric-Medium.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}Metric-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metric Semibold';
  src: url('@{PATH_TO_FONTS}Metric-Semibold.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}Metric-Semibold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metric Bold';
  src: url('@{PATH_TO_FONTS}MetricHPE-Web-Bold.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}MetricHPE-Web-Bold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MetricHPE Black';
  src: url('@{PATH_TO_FONTS}MetricHPE-Web-Black.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}MetricHPE-Web-Black.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

//---------------------------------------
// HP Simplified
//---------------------------------------

//@font-face {
//  font-family: 'Simple Light';
//  src:  url('@{PATH_TO_FONTS}lineto-simple-pro-light.woff2') format('woff2'),
//        url('@{PATH_TO_FONTS}lineto-simple-pro-light.woff') format('woff');
//  font-style: normal;
//  font-weight: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Simple Regular';
  src: url('@{PATH_TO_FONTS}lineto-simple-pro-regular.woff2') format('woff2'),
       url('@{PATH_TO_FONTS}lineto-simple-pro-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'Simple Bold';
//  src:  url('@{PATH_TO_FONTS}lineto-simple-pro-bold.woff2') format('woff2'),
//        url('@{PATH_TO_FONTS}lineto-simple-pro-bold.woff') format('woff');
//  font-style: normal;
//  font-weight: normal;
//  font-display: swap;
//}



//---------------------------------------
// HTML Tags
//---------------------------------------

body {
  font-family: @lightFont, Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
}

// Adjustment for typography flickering on Webkit Safari
// Note: Only enabled for specific elements because the * selector
//       was causing problems with icons, tables and input elements
.safari {
  a, p, div, span, li, code,
  h1, h2, h3, h4, h5, h6 {
    -webkit-backface-visibility: hidden;
  }
}


// Un-Ordered Lists
ul, ol {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

ul li {
  list-style-type: none;
  font-family: @lightFont;
}

// Ordered Lists
ol li {
  color: @primary5;
  font-family: @lightFont;
}


// Links inside paragraphs / content
//==============
p a {
    color: @secondary1;
    text-decoration: none;

    &:hover {
        color: @secondary7;
        text-decoration: underline;
    }
}


pre {
  _white-space: pre;
  _white-space: pre-wrap;
  _word-wrap: break-word;
}



// It changes the visual appearance of the <strong> tag
// by using a bolder font-family
//==============
b,
strong {
    font-family: @boldFont, Arial, sans-serif;
}

// Form field styles
//==================

label { font-family: @lightFont, Arial, sans-serif; }
input { font-family: @regularFont, Arial, sans-serif; }

//---------------------------------------
// HEADINGS
//---------------------------------------

// Shared properties for the headings
//==============
h1, h2, h3, h4, h5, h6,
.tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6 {
    color: @primary10;
    font-family: @boldFont, Arial, sans-serif;
    font-style: normal;
    .break-words();
}


// Headings: Individual Properties
//==============

//TODO: Update these font-size values to em units
h1,
.tag-h1 {
    font-size: 60px;  // CR-HPE-3518 //72px;
    line-height: 55px; // CR-HPE-3518 //76px;
}

h2,
.tag-h2 {
    font-size: 40px;
    line-height: 42px;
}

h3,
.tag-h3 {
    font-size: 30px;
    line-height: 33px;
}

h4,
.tag-h4 {
    font-size: 20px;
    line-height: 23px;
}

h5,
.tag-h5 {
    font-size: 15px;
    line-height: 19px;
}

h6,
.tag-h6 {
    font-size: 25px;
    line-height: 29px;
}

//---------------------------------------
// Typography: class based
//---------------------------------------

.body-copy-large {
    color: @primary10;
    font-family: @lightFont, Arial, sans-serif;
    font-size: 23px;
    font-style: normal;
    line-height: 25px;
}

.body-copy {
    color: @primary10;
    font-family: @lightFont, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 22px;
}

.body-copy-small {
    color: @primary10;
    font-family: @lightFont, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    line-height: 19px;
}

.caption {
    color: @primary10;
    font-family: @regularFont, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
}

//---------------------------------------
// Typography: Phone/Tablet/Phablet - 768px or 992px
//md only
//---------------------------------------
@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

    //---------------------------------------
    // HTML Tags - Media Query SM
    //---------------------------------------

    body {
        font-size: 17px;
        line-height: 21px;
    }

    //---------------------------------------
    // HEADINGS - Media Query SM
    //---------------------------------------
    h1,
    .tag-h1 {
        font-size: 45px;
        line-height: 45px;
    }

    h2,
    .tag-h2 {
        font-size: 32px;
        line-height: 34px;
    }

    h3,
    .tag-h3 {
        font-size: 25px;
        line-height: 27px;
    }

    h4,
    .tag-h4 {
        font-size: 18px;
        line-height: 20px;
    }

    // h5 - No change for medium
    h6,
    .tag-h6 {
        font-size: 22px;
        line-height: 23px;
    }



    //---------------------------------------
    // Typography: class based - Media Query SM
    //---------------------------------------
    .body-copy-large {
        font-size: 21px;
        line-height: 24px;
    }

    .body-copy {
        font-size: 18px;
        line-height: 21px;
    }
}

//---------------------------------------
// Typography: Mobile - 767px or less
//---------------------------------------
@media screen and (max-width: @screen-xs-max) {

    //---------------------------------------
    // HTML Tags - Media Query SM
    //---------------------------------------
    body {
        font-size: 16px;
        line-height: 20px;
    }

    //---------------------------------------
    // HEADINGS - Media Query SM
    //---------------------------------------

    h1,
    .tag-h1 {
        font-size: 45px;
        line-height: 45px;
    }

    h2,
    .tag-h2 {
        font-size: 25px;
        line-height: 26px;
    }

    h3,
    .tag-h3 {
        font-size: 20px;
        line-height: 22px;
    }

    h4,
    .tag-h4 {
        font-size: 17px;
        line-height: 19px;
    }

    h5,
    .tag-h5 {
        font-size: 12px;
        line-height: 16px;
    }

    h6,
    .tag-h6 {
        font-size: 19px;
        line-height: 21px;
    }

    //---------------------------------------
    // Typography: class based - Media Query SM
    //---------------------------------------
    .body-copy-large {
        font-size: 19px;
        line-height: 22px;
    }

    .body-copy {
        font-size: 16px;
        line-height: 20px;
    }
}


//---------------------------------------
// Typography: Custom - 510px or less
//---------------------------------------
@media screen and (max-width: 510px) {
    h1,
    .tag-h1 {
        font-size: 32px;   // CR-HPE-3518  // 40px;
        line-height: 32px; // CR-HPE-3518  // 44px;
    }
}
