.transform-to-open-state-hpecard() {
  transform: translateY(-68px);
  -ms-transform: translateY(-68px);
}

.hpe-card {
  @playButtonSize: 64px;

  white-space: normal;
  cursor: pointer;
  position: relative;
  display: block;
  background: @primary9;

  &.imageless {
    min-height: 100px !important;
    .text-content {
      .body-copy-small {
        padding-bottom: 0;
      }
      @media @mq--XS-MD {
        transform: none;
        margin-top: 0;
      }
    }

    .link-holder {
      background-color: transparent;
    }
  }

  &.animate-nav {
    &:hover .text-content, &.hover .text-content {
      .transform-to-open-state-hpecard();
    }
    .nav-block {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
    }
  }

  div.logo {
    height: 30px;
    img {
      max-height: 100%;
      height: 30px;
      width: auto;
    }
    margin-bottom: 20px;
  }

  .link-holder {
    padding: 0 45px 0 20px;
    background: #f1f1f1;
    height: 61px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .link-text {
      color: @primary1;

      .arrow-wrapper {
        color: @primary1;
      }
    }
  }

  .text-content {
    z-index: 1;
    position: relative;
    padding: 20px 20px 0 20px;
    background: @primary9;
    transition: transform 0.5s ease;

    .body-copy-small {padding-bottom: 25px;}

    .title {
      margin-bottom: 16px;
      margin-top: 0;
      &.only-title {
        padding-bottom: 25px;
        margin-bottom: 0;
      }
    }
  }

  .asset-type {
    font-family: @lightFont;
    .setFont(14px, 16px);
    color: @primary10;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: -5px;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  time,
  .time,
  .tagline-wrapper {
    font-family: @lightFont;
    .setFont(14px, 16px);
    color: @primary12;
    margin-bottom: 7px;
    margin-top: -3px;
  }

  .typo4 & .asset-type,
  .typo4 & time,
  .typo4 & .time,
  .typo4 & .tagline-wrapper {
    font-family: @f-typo4;
    font-weight: @fw-regular;
  }

  time, .time {
    display: block;
  }

  .tagline-wrapper {
    time, span:not(.text-hide) {
      display: inline;
      font-family: inherit;
      color: inherit;
    }

    // To prevent inheritance of wrong font-size
    // for tagline migrated to typo4
    time, span:not(.text-hide, .body-copy-xsmall) {
      font-size: inherit;
    }

    span:not(.text-hide) {
      text-transform: uppercase;
    }
  }

  .img-container {
    box-shadow: none;
  }

  .label-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    max-width: 100%;
  }
  .label {
    background: rgba(0, 0, 0, 0.7);
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .label:not(.body-copy-xsmall) {
    font-size: 1px;
  }
  .label-text {
    position: relative;
    text-transform: uppercase;
    color: @primary9;
    padding: 7px 20px;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
  }
  .label-text:not(.body-copy-xsmall) {
    .setFont(12px, 12px);
  }

  &.label-red .label {
    background: rgba(255, 0, 0, 1);
  }
  &.label-purple .label {
    background: rgba(113, 0, 139, 1);
  }
  &.label-gray .label {
    background: rgba(66, 85, 99, 1);
  }

  esl-share {
    --icon-share-size: 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    .icon-share-global {
      color: inherit;
    }

    @media @mq--XS-SM {
      --icon-share-size: 17px;
    }

    @media @mq--XS {
      --icon-share-size: 18px;
    }
  }

  @media @mq--XS-MD {
    .text-content {
      .transform-to-open-state-hpecard();
      margin-top: 68px;
      .body-copy-small {padding-bottom: 0;}
    }
    .link-holder {
      background-color: @primary9;
    }
  }
}

body:not(.disabled-focus) {
  .hpe-card {
    &.animate-nav:focus-within .text-content {
      .transform-to-open-state-hpecard();
    }
  }
}
