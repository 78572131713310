// ------------------------------------------------------ */
// TABLE
// ------------------------------------------------------ */

table {
  border: 0;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  &.fixed-col {
    table-layout: fixed;
  }

  th {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    white-space: nowrap;
    vertical-align: bottom;
    padding: 8px 10px 8px 0;

    &.center {
      text-align: center;
    }
  }

  td {
    border-bottom: 1px solid #666;
    background: #fff;
    vertical-align: top;
    padding: 16px 10px 16px 0;
  }

  tr td:first-child,
  tr th:first-child {
    padding-left: 10px;
  }

  tr td:last-child,
  tr th:last-child {
    padding-right: 10px;
  }

  tr:hover td,
  tr.active td {
    background: #eee;
  }

  tr:last-child td {
    border-bottom-color: #999;
  }
}
