.bulleted-list,
.rich-text-container {
  ul li {
    padding-left: 13px;
    position: relative;
    margin-bottom: 11px;

    &:before {
      display: block;
      content: "";
      width: 5px;
      height: 5px;
      position: absolute;
      left: 0;
      top: 9px;
      background-color: @primary5;

      @media @mq--XS-SM {
        top: 8px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.body-copy .bulleted-list,
.rich-text-container.body-copy,
.rich-text-container.typo3 {
  ul li::before {
    top: 11px;

    @media @mq--LG {
      top: 10px;
    }

    @media @mq--XS-MD {
      top: 9px;
    }
  }
}

.body-copy-small .bulleted-list,
.rich-text-container.body-copy-small {
  ul li::before {
    top: 8px;

    @media @mq--XS-MD {
      top: 7px;
    }
  }
}

.body-copy-xsmall .bulleted-list,
.rich-text-container.body-copy-xsmall {
  ul li::before {
    top: 6px;

    @media @mq--XS-MD {
      top: 5px;
    }
  }
}

.body-copy-large .bulleted-list,
.rich-text-container.body-copy-large {
  ul li::before {
    top: 13px;

    @media @mq--LG {
      top: 10px;
    }

    @media @mq--XS-MD {
      top: 8px;
    }
  }
}

@import "bulleted-list-typo5.less";
