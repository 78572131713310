//---------------------------------------
// HTML Tags
//---------------------------------------
a {
  &, &:hover, &:focus {
    text-decoration: none;
  }
}

// Un-Ordered Lists
ul, ol {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

ul li {
  list-style-type: none;
}
