@import (reference) "../../../../../ui/dependencies.less";

smart-media[media-type="bc-audio"] {
  height: 42px;
  width: 100%;
  background: #000 url(@spinnerGif) 50% 50% no-repeat;
  background-size: 30px;

  &:not([ready]) .video-js {
    display: none;
  }

  .video-js:not(#speciffic) {
    // Hide controls
    .vjs-contextmenu-ui-menu,
    .vjs-fullscreen-control,
    .vjs-picture-in-picture-control,
    .vjs-remaining-time,
    .vjs-subs-caps-button,
    .vjs-big-play-button,
    .vjs-tech,
    .vjs-poster,
    .vjs-dock-text {
      display: none;
    }
    .vjs-duration,
    .vjs-current-time,
    .vjs-time-divider {
      display: block;
    }

    .vjs-loading-spinner {
      width: 25px;
      height: 25px;
      margin-top: -10px;
      border-width: 3px;
    }

    .vjs-control-bar {
      display: flex;
      transform: none;
      opacity: 1;
      height: 100%;
      padding-top: 6px; // Progress bar offset
      font-size: 12px; // Fix font
    }

    // Styles for the control bar title
    .vjs-control-dock {
      display: flex;
      width: auto;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    .vjs-progress-control {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      z-index: 2;

      .vjs-progress-holder {
        margin: 0;
      }
    }
    // Progress bar grow below on hover
    .vjs-progress-control:hover {
      &,
      .vjs-progress-holder,
      .vjs-play-progress,
      .vjs-load-progress,
      .vjs-tooltip-progress-bar,
      .vjs-load-progress div {
        height: 6px;
      }
    }

    .vjs-current-time {
      padding-right: 0;
    }
    .vjs-duration {
      padding-left: 0;
    }

    &.vjs-mouse {
      .vjs-volume-panel:not(.vjs-hover) {
        width: 3em;
      }
      .vjs-volume-panel.vjs-hover {
        width: 9.5em;
      }
      .vjs-volume-control {
        display: block;
      }
    }
    &:not(.vjs-mouse) {
      .vjs-volume-panel {
        width: 9.5em;
        @media screen and (max-width: 450px) {
          width: 8em;
        }
      }
      .vjs-volume-control {
        display: block;
        opacity: 1;
      }
    }

    .vjs-play-progress,
    .vjs-volume-level {
      background-color: @primary7;
      &:before {
        content: '';
      }
    }

    .vjs-time-tooltip {
      top: .7em;
    }
  }

  .vjs-error-display {
    overflow: hidden;
    .vjs-errors-content-container {
      display: none;
    }
    .vjs-errors-ok-button-container {
      bottom: 5px;
    }
  }
}
