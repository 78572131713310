@import "slide.carousel.dots.less";
@import "slide.carousel.controls.less";

@SLIDE_CAROUSEL_ANIMATION: .3s ease-in-out;
@SLIDE_CAROUSEL_FADE_ANIMATION: .7s ease-in-out;

.slide-carousel {
  position: relative;
  touch-action: pan-y;

  // Variable to control slide shift in layout-3-items mode
  --sc-siblings-shift: 7%;
}

.slide-carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;

  & > .item {
    position: relative;
    display: none;
    backface-visibility: hidden;

    &.left,
    &.right {
      transition: transform @SLIDE_CAROUSEL_ANIMATION;
    }

    &.next,
    &.active.right {
      left: 0;
      transform: translate3d(100%, 0, 0);
    }

    &.prev,
    &.active.left {
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }

    &.next.left,
    &.prev.right,
    &.active {
      left: 0;
      transform: translate3d(0, 0, 0);
    }

    &.geipreset:not(.active) {
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      opacity: 0;

      height: auto;
      min-height: auto;
    }
  }

  & > .active,
  & > .next,
  & > .prev {
    display: block;
  }
}

.slide-carousel-inner > .next,
.slide-carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.slide-carousel-inner > .next,
.slide-carousel-inner > .active.right {
  left: 100%;
}

.slide-carousel-inner > .prev,
.slide-carousel-inner > .active.left {
  left: -100%;
}

.slide-carousel-inner > .active,
.slide-carousel-inner > .next.left,
.slide-carousel-inner > .prev.right {
  left: 0;
}

// 3 items layout
.slide-carousel-inner.layout-3-items {
  & > .item {
    &.next.left,
    &.prev.right,
    &.active {
      z-index: 1;
    }
  }

  > .item.visible-next-next,
  > .item.visible-prev-prev,
  > .item.visible-next,
  > .item.visible-prev {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .item.visible-next,
  > .item.visible-prev {
    z-index: 2;
  }

  > .item.visible-prev-prev,
  > .item.visible-next-next {
    z-index: 3;
  }

  & > .item {
    &.next,
    &.active.right {
      left: 0;
      transform: translate3d(calc(~"100% - var(--sc-siblings-shift)"), 0, 0);
    }

    &.prev,
    &.active.left {
      left: 0;
      transform: translate3d(calc(~"var(--sc-siblings-shift) - 100%"), 0, 0);
    }
  }

  & > .next,
  & > .active.right {
    left: calc(~"100% - var(--sc-siblings-shift)");
  }

  & > .prev,
  & > .active.left {
    left: calc(~"var(--sc-siblings-shift) - 100%");
  }

  & > .item.visible-prev {
    left: calc(~"var(--sc-siblings-shift) - 100%");
  }
  & > .item.visible-next {
    left: calc(~"100% - var(--sc-siblings-shift)");
  }

  & > .item.visible-prev-prev {
    left: calc(~"(-2 * (100% - var(--sc-siblings-shift)))");
  }
  & > .item.visible-next-next {
    left: calc(~"(2 * (100% - var(--sc-siblings-shift)))");
  }

  & > .item.visible-prev,
  & > .item.visible-next,
  & > .item.visible-prev-prev,
  & > .item.visible-next-next {
    transform: translate3d(0, 0, 0);
    &.left {
      transform: translate3d(calc(~"var(--sc-siblings-shift) - 100%"), 0, 0);
    }
    &.right {
      transform: translate3d(calc(~"100% - var(--sc-siblings-shift)"), 0, 0);
    }
  }
}

// Fade animation variant
.slide-carousel-fade .slide-carousel-inner {
  > .item {
    transition: opacity @SLIDE_CAROUSEL_FADE_ANIMATION;
  }

  > .item,
  > .active.left,
  > .active.right {
    z-index: 1;
    opacity: 0;
  }

  > .active,
  > .next.left,
  > .prev.right {
    z-index: 2;
    opacity: 1;
  }

  > .next,
  > .prev,
  > .active.left,
  > .active.right {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.slide-carousel.whole-component-video {
  & > .embedded-video {
    display: none;
  }

  &.video-is-playing {
    background-color: #000;
    position: relative;

    .slide-carousel-inner {
      opacity: 0;
    }

    > .embedded-video.is-playing {
      display: block;
      position: absolute;
      top: 0;
      z-index: 9;
      bottom: 0;
      height: 100%;
    }
  }
}
