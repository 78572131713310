@import "../../core/ui/less/variables.less";

.dashed-text {
  margin-bottom: 30px;
  padding-bottom: 28px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 6px;
    background: @primary1;
    .dash-center&, .text-center& {
      left: 50%;
      margin-left: -45px;
    }
  }
  @media @mq--XS-LG {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  @media @mq--SM-MD {
    &:after {
      width: 70px;
      .dash-center&, .text-center& {
        margin-left: -35px;
      }
    }
  }
  @media @mq--XS-SM {
    &:after {
      width: 60px;
      .dash-center&, .text-center& {
        margin-left: -30px;
      }
    }
  }
}

h1, h2, h3, h4, h5 {
  &.disable-dash {
    margin-bottom: 20px;
    @media @mq--XS-SM {
      margin-bottom: 25px;
    }
  }
}
