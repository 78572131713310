.buildDownloadIcon(@fill: @primary1) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14"><path fill="@{fill}" d="M7.59992 9.58579L11.2928 5.89289L12.707 7.30711L7.30703 12.7071L6.59992 13.4142L5.89282 12.7071L0.492818 7.30711L1.90703 5.89289L5.59992 9.58579L5.59992 0L7.59992 0L7.59992 9.58579Z"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.download-icon {
  .buildDownloadIcon(@primary1);
}

.white-download-icon {
  .buildDownloadIcon(@primary9);
}
