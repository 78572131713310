/*
  Icon Font: hpe-glyphicons
*/

@font-face {
  font-family: "hpe-glyphicons";
  src: url("../fonts/hpe-glyphicons.woff2") format("woff2"),
       url("../fonts/hpe-glyphicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "hpe-glyphicons";
    src: url("../fonts/hpe-glyphicons.svg#hpe-glyphicons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-arrow-bottom:before,
.icon-arrow-circle-right:before,
.icon-arrow-left:before,
.icon-arrow-link-right:before,
.icon-arrow-pointer-left:before,
.icon-arrow-right:before,
.icon-arrow-top:before,
.icon-camera_icon:before,
.icon-check-mark:before,
.icon-circle-full:before,
.icon-circle-half:before,
.icon-download:before,
.icon-downloader-embed-code:before,
.icon-filter:before,
.icon-grid:before,
.icon-hpe-logo:before,
.icon-link-external:before,
.icon-nav-close-menu:before,
.icon-nav-contrast:before,
.icon-nav-glass-search:before,
.icon-nav-glass-zoom-in:before,
.icon-play-btn:before,
.icon-play-btn-black:before,
.icon-play-circle:before,
.icon-plus-sign:before,
.icon-print-btn:before,
.icon-questionmark-circle-close:before,
.icon-quote-circle-open:before,
.icon-search-advanced:before,
.icon-share-global:before,
.icon-social-askfm:before,
.icon-social-facebook:before,
.icon-social-googleplus:before,
.icon-social-gree:before,
.icon-social-instagram:before,
.icon-social-lineme:before,
.icon-social-linkedin:before,
.icon-social-mail:before,
.icon-social-mail-v2:before,
.icon-social-mixi:before,
.icon-social-qzone:before,
.icon-social-reddit:before,
.icon-social-renren:before,
.icon-social-tencentwrbo:before,
.icon-social-twitter:before,
.icon-social-weibo:before,
.icon-social-youtube:before,
.icon-toggle-circle-close:before,
.icon-toggle-circle-open:before {
  display: inline-block;
  font-family: "hpe-glyphicons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-arrow-bottom:before { content: "\f11d"; }
.icon-arrow-circle-right:before { content: "\f130"; }
.icon-arrow-left:before { content: "\f126"; }
.icon-arrow-link-right:before { content: "\f152"; }
.icon-arrow-pointer-left:before { content: "\f12f"; }
.icon-arrow-right:before { content: "\f11c"; }
.icon-arrow-top:before { content: "\f127"; }
.icon-camera_icon:before { content: "\f153"; }
.icon-check-mark:before { content: "\f15e"; }
.icon-circle-full:before { content: "\f15f"; }
.icon-circle-half:before { content: "\f160"; }
.icon-download:before { content: "\f13d"; }
.icon-downloader-embed-code:before { content: "\f158"; }
.icon-filter:before { content: "\f15d"; }
.icon-grid:before { content: "\f162"; }
.icon-hpe-logo:before { content: "\f161"; }
.icon-link-external:before { content: "\f12e"; }
.icon-nav-close-menu:before { content: "\f128"; }
.icon-nav-contrast:before { content: "\f12a"; }
.icon-nav-glass-search:before { content: "\f13c"; }
.icon-nav-glass-zoom-in:before { content: "\f14e"; }
.icon-play-btn:before { content: "\f11b"; }
.icon-play-btn-black:before { content: "\f122"; }
.icon-play-circle:before { content: "\f163"; }
.icon-plus-sign:before { content: "\f151"; }
.icon-print-btn:before { content: "\f15a"; }
.icon-questionmark-circle-close:before { content: "\f13f"; }
.icon-quote-circle-open:before { content: "\f137"; }
.icon-search-advanced:before { content: "\f13e"; }
.icon-share-global:before { content: "\f140"; }
.icon-social-askfm:before { content: "\f141"; }
.icon-social-facebook:before { content: "\f142"; }
.icon-social-googleplus:before { content: "\f150"; }
.icon-social-gree:before { content: "\f143"; }
.icon-social-instagram:before { content: "\f144"; }
.icon-social-lineme:before { content: "\f15c"; }
.icon-social-linkedin:before { content: "\f145"; }
.icon-social-mail:before { content: "\f146"; }
.icon-social-mail-v2:before { content: "\f164"; }
.icon-social-mixi:before { content: "\f147"; }
.icon-social-qzone:before { content: "\f148"; }
.icon-social-reddit:before { content: "\f149"; }
.icon-social-renren:before { content: "\f14a"; }
.icon-social-tencentwrbo:before { content: "\f14b"; }
.icon-social-twitter:before { content: "\f14c"; }
.icon-social-weibo:before { content: "\f14f"; }
.icon-social-youtube:before { content: "\f14d"; }
.icon-toggle-circle-close:before { content: "\f139"; }
.icon-toggle-circle-open:before { content: "\f13a"; }
