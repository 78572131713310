#HpeChatEmailButton {
  .button-reset();

  background-color: @primary7;
  max-width: 44px;
  height: 44px;
  position: fixed;
  right: 20px;
  bottom: 50px;
  border-radius: 44px;
  cursor: pointer;
  padding: 8px 11px;
  z-index: 20;
  overflow: hidden;

  transition: max-width 0.3s ease-in-out;
  box-shadow: 0 0 1px 1px @primary10;
  animation: 5s expand 3s ease-in-out;

  @keyframes expand {
    0%, 100% {
      max-width: 44px;
    }
    6%, 94% {
      max-width: 300px;
    }
  }

  span {
    display: block;
    position: relative;
    font-size: 16px;
    overflow: hidden;
    height: 28px;
    line-height: 28px;
    color: @primary10;
    text-transform: uppercase;
    padding-right: 11px;

    &::before {
      float: left;

      content: "\f164";
      font-size: 22px;
      font-family: "hpe-glyphicons";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      color: @primary10;

      position: relative;
      margin-right: 18px;
      top: 3px;
    }

    [dir="rtl"] & {
      padding-left: 11px;
      padding-right: 0;

      &::before {
        float: right;
        margin-left: 18px;
        margin-right: 0;
      }
    }
  }

  &:hover {
    max-width: 500px;

    span {
      &::before {
        left: 0;
      }
    }
  }

  @media screen and (max-width: @screen-md) {
    display: block;
    &:hover {
      max-width: 44px;
    }
  }

  @media screen and (max-width: @screen-xs-max) {
    right: 10px !important;
  }
}

.iphone, .ipad {
  #HpeChatEmailButton {
    span {
      top: 2px;
    }
  }
}

.on-mobile {
  #HpeChatEmailButton {
    &:hover {
      max-width: 44px;
    }
  }
}

.hpe-chats-disabled {
  #HpeChatEmailButton,
  #drift-frame-controller,
  .hpe-drift-icon {
    display: none !important;
  }
}

.hpe-email-button-added {
  #drift-frame-controller, .hpe-drift-icon {
    display: none !important;
  }
}

#drift-frame-controller:not(.drift-chat-open) {
  z-index: 1000 !important;
}

.gn-primary-mobile-opened.gn-search-opened {
  #drift-frame-controller, #drift-frame-chat, .hpe-drift-icon {
    display: none !important;
  }
}

.gn-flyout-opened {
  @media @mq--XS-MD {
    #drift-frame-controller, #drift-frame-chat, .hpe-drift-icon {
      display: none !important;
    }
  }
}
