@import (reference) "../../core/ui/dependencies.less";
@import "./../../core/components/carousel/ui/carousel.less";

.hpe-carousel {
  .owl-carousel {
    button.owl-dot {
      background: rgba(118, 118, 118, .5);
      border: none;
      width: 9px;
      height: 9px;
      &.active {
        border: 1px solid #bababa;
        background: rgba(255, 255, 255, .5);
        width: 11px;
        height: 11px;
      }
    }
    .owl-dots {
      height: auto;
    }
  }

  @dotsContainerSize: 71px; // 30+30+11
  &.dots-outside:not(.no-dots) {
    .owl-carousel {
      padding-bottom: @dotsContainerSize;
      .owl-dots {
        bottom: 30px;
      }
    }
  }

  .arrowsSize(@size, @space) {
    .owl-nav button.owl-prev,
    .owl-nav button.owl-next {
      width: @size;
      height: @size;
      margin-top: (-@size - @dotsContainerSize)/2;
    }
    .arrows-outside& {
      .owl-nav .owl-prev {
        left: -@size - @space;
      }
      .owl-nav .owl-next {
        right: -@size - @space;
      }
    }
  }

  .arrowsSize(68px, 7px);
  @media @mq--SM {
    .arrowsSize(50px, 0px);
  }
  @media @mq--XS {
    .arrowsSize(30px, 0px) !important;
  }
}

.hpe-carousel.is-ready .owl-nav > button {
  transition-duration: .01s;
}
.owl-nav, .slide-carousel {
  > button {
    opacity: .5 !important;
    &:hover {
      opacity: .8 !important;
    }
    .slide-light& {
      opacity: .6 !important;
      &:hover {
        opacity: .9 !important;
      }
    }
  }
}
.slide-carousel, .hpe-carousel {
  .icon-arrow-left,
  .icon-arrow-right {
    display: block;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='%23000' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &::before {
      content: '';
    }
  }
  .icon-arrow-right {
    transform: scaleX(-1);
  }

  &.slide-light, .dark-theme & {
    .icon-arrow-left,
    .icon-arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='white' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
    }
  }

  .light-theme-prespecific & {
    .icon-arrow-left,
    .icon-arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='%23000' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
    }
  }

  &.slide-dark {
    .icon-arrow-left,
    .icon-arrow-right {
      // IE11 ... by some reason it tries to cache image but fails, next line is almost duplicates default.
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='black' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
    }
  }
}
