@import (reference) "./../../../../core/ui/dependencies.less";

@SL-DESCRIPTION-COLOR: #757575;
@SL-DESCRIPTION-COLOR-MOBILE: #7C7C7C;

.service-link {
  display: flex;

  .sl-icon {
    flex-shrink: 0;

    width: 32px;
    height: 32px;
    smart-image {
      border-radius: 25%;
      //Alt text
      .setFont(10px, 10px);

      @media @mq--XS-MD {
        .setFont(8px, 8px);
      }
    }
  }

  &:not(.sl-layout-column) {
    .sl-icon {
      margin-right: 10px;
    }
  }

  .sl-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .setFont(16px, 16px);
    .hpe-arrow-link {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .sl-title {
    font-weight: @fw-semibold;
  }

  .sl-description {
    font-weight: @fw-regular;
    color: @SL-DESCRIPTION-COLOR;

    @media @mq--XS-SM {
      color: @SL-DESCRIPTION-COLOR-MOBILE;
    }
  }

  .sl-link .hpe-arrow-link {
    color: @primary10;
    padding-right: 0;

    .arrow {
      position: absolute;
      bottom: 0;
      visibility: hidden;
      margin: 0.15em 0 0;
    }
  }

  &:hover,
  &:active,
  body:not(.disabled-focus) &:focus {
    .sl-link .hpe-arrow-link {
      color: @primary1;
      text-decoration: none;

      .arrow {
        visibility: visible;
        transform: translateX(5px);
      }
    }
  }

  &.sl-layout-column {
    flex-direction: column;
    align-items: center;

    .sl-icon {
      margin-bottom: 10px;
    }

    .sl-description, .sl-link {
      text-align: center;
    }

    .sl-link {
      justify-content: center;
    }
  }
}

.dark-theme .service-link {
  .sl-link .hpe-arrow-link {
    color: @primary9;
  }
  &:hover,
  &:focus {
    .sl-link .hpe-arrow-link {
      color: @primary1;
    }
  }
}
