.typo4 {
  hpe-note {
    font-family: @f-typo4;
    font-weight: @fw-semibold;

    margin-top: 0;
  }

  h1 hpe-note,
  .tag-h1 hpe-note {
    margin-top: 0.4em;
    @media @mq--SM-MD {
      margin-top: 0.5em;
    }
    @media @mq--LG-XL {
      margin-top: 0.7em;
    }
  }

  h1.xlarge hpe-note,
  .tag-h1.xlarge hpe-note {
    margin-top: 0.5em;
    @media @mq--SM-MD {
      margin-top: 1.2em;
    }
    @media @mq--LG-XL {
      margin-top: 1.95em;
    }
  }

  h2 hpe-note,
  .tag-h2 hpe-note {
    margin-top: 0.4em;
    @media @mq--SM-MD {
      margin-top: 0.5em;
    }
    @media @mq--LG-XL {
      margin-top: 0.6em;
    }
  }

  h2.small hpe-note,
  .tag-h2.small hpe-note {
    margin-top: 0.2em;
    @media @mq--SM-MD {
      margin-top: 0.35em;
    }
    @media @mq--LG-XL {
      margin-top: 0.5em;
    }
  }

  h3 hpe-note,
  .tag-h3 hpe-note {
    margin-top: 0.2em;
    @media @mq--SM-MD {
      margin-top: 0.35em;
    }
    @media @mq--LG-XL {
      margin-top: 0.5em;
    }
  }

  h4 hpe-note,
  .tag-h4 hpe-note {
    margin-top: 0.3em;
    @media @mq--LG-XL {
      margin-top: 0.4em;
    }
  }

  h5 hpe-note,
  .tag-h5 hpe-note {
    margin-top: 0.2em;
    @media @mq--LG-XL {
      margin-top: 0.3em;
    }
  }

  .body-copy hpe-note {
    margin-top: 0.2em;
  }

  .body-copy-small hpe-note {
    margin-top: 0.2em;
  }

  .body-copy-large hpe-note {
    margin-top: 0.2em;
    @media @mq--LG-XL {
      margin-top: 0.4em;
    }
  }
}
