@import (reference) "../../../../core/ui/dependencies.less";
@import (reference) "@exadel/esl/modules/esl-media/core.mixin.less";

.esl-media-init(smart-media);
.esl-media-container-init(smart-media);

smart-media {
  background: #000 url(@spinnerGif) 50% 50% no-repeat;
  background-size: 40px;
  &[ready] {
    background: #000;
  }
  &.no-bg {
    background: transparent;
  }

  // It overrides the pinkish color int he caption list
  .vjs-menu-button .vjs-texttrack-settings {
    border-color: @primary1;
  }

  // It hides the text for the player buttons
  .vjs-control-text {
    display: none;
  }

  .vjs-dock-text {
    display: none;
  }

  //HPED-5723 -IE11/Edge: Subtitles are broken for BC video player
  .vjs-subs-caps-button .vjs-menu-content {
    overflow-x: hidden;
  }
}

// Providers customizations
@import "./providers/brightcove/brightcove-audio-provider.less";
@import "./providers/brightcove/brightcove-video-provider.less";
