@import (reference) "./../../../ui/dependencies.less";

.video-info-module {
  @VIM_UTILITY_CONTAINER_MAX_W: 80px + 15px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  min-height: 62px;
  padding: 15px 30px;

  @media @mq--XS {
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: center;
  }

  .vim-title {
    width: 100%;
    margin: 0;
    @media @mq--SM-XL {
      flex: 1 1 auto;
      width: calc(~'100% - @{VIM_UTILITY_CONTAINER_MAX_W}');
    }
  }
  .vim-description {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .vim-description {
    flex: 0 0 auto;
    width: 100%;

    p:last-of-type {
      margin-bottom: 0;
    }

    .text-collapsible {
      overflow: hidden;
    }

    .tc-trigger {
      position: relative;
      display: none;
      .button-reset();

      font-size: 14px;
      line-height: 16px;

      &,
      & .label-collapsed,
      & .label-expanded {
        color: @primary1;
      }

      @media @mq--SM-XL {
        font-size: 16px;
        line-height: 18px;
      }
    }
    &.tc-enabled {
      .tc-trigger {
        display: inline-block;
        border-bottom: 1px solid @primary1;
      }
    }
    &.tc-enabled:not(.tc-expanded) {
      .label-expanded { display: none; }
    }
    &.tc-enabled.tc-expanded {
      .label-collapsed { display: none; }
    }
  }

  .vim-links {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @mq--SM-XL {
      flex: 1 1 auto;
      width: calc(~'100% - @{VIM_UTILITY_CONTAINER_MAX_W}');
      align-items: flex-start;
    }

    .vim-link {
      max-width: 100%;
    }

    .vim-link + .vim-link {
      margin-top: 15px;
    }
  }

  .vim-title + .vim-description {
    margin-top: 13px;
  }

  .vim-title + .vim-links,
  .vim-description + .vim-links {
    margin-top: 15px;
  }

  .vim-utility-container {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    margin-top: 15px;

    esl-share {
      opacity: 0;
      visibility: hidden;
      &[ready] {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s linear;
      }
    }

    .esl-share {
      display: flex;
      flex-direction: column;
      align-items: center;

      flex: 0 0 auto;
      max-width: 80px;

      margin: -5px 0;
    }

    .icon-share-global {
      flex: 0 0 auto;
      height: 23px;
      color: @primary1;
      font-size: 23px;
      line-height: 1;
    }

    .share-text {
      text-align: center;
    }
  }

  @media @mq--SM-XL {
    .vim-utility-container {
      margin-left: 15px;
    }
    .vim-title + .vim-utility-container {
      margin-top: 0;
    }
    .vim-description + .vim-utility-container {
      margin-top: 15px;
      margin-left: auto;
    }
  }
}
