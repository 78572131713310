.hpe-carousel:not([data-carousel-mode]) {
  &:not(.is-ready) {
    overflow: hidden;
  }
  .owl-carousel {
    display: block;
    white-space: nowrap;
    > .carousel-item {
      display: inline-block;
      vertical-align: top;
      white-space: initial;
      float: none;
    }
    .owl-item > .carousel-item {
      width: 100%;
      white-space: initial;
      &.col-xs-12 {
        @media @mq--XS {
          padding: 0;
        }
      }
    }
  }
}
