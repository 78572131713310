@import (reference) "../../../../core/ui/dependencies.less";

@-webkit-keyframes drawAndReverse {
  0% {
    stroke-dashoffset: 151px
  }

  100% {
    stroke-dashoffset: -131px
  }
}

@keyframes drawAndReverse {
  0% {
    stroke-dashoffset: 151px
  }

  100% {
    stroke-dashoffset: -131px
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: rotate(0);
  }
  50% {
    opacity: .75;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

.hpe-spinner, .grommet-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .9);
  z-index: 16;
}

.hpe-spinner {
  background: rgba(255, 255, 255, .9) url(@spinnerGif) 50% 50% no-repeat;
  background-size: 40px;
  pointer-events: none;

  &.in {
    pointer-events: auto;
  }

  &.hpe-spinner-dark {
    background-color: rgba(0, 0, 0, .4);
  }

  body > & {
    position: fixed;
    z-index: 99999;
  }
}

.spinner-container {
  width: 100%;
}

.hpe-grommet-spinner {
  margin: 10px auto;
  z-index: 100;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-width: 4px;
  border-style: solid;
  border-color: @primary1 @primary1 rgba(0, 0, 0, .04);
  border-image: initial;
  min-width: 0;
  min-height: 0;
  flex-direction: column;
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 100%;
  transform: rotate(0);
  animation: 1s linear 0s infinite normal none running rotate;
}

.grommet-spinner {
  width: 24px;
  height: 24px;
  stroke-dasharray: 151px 151px;
  stroke-dashoffset: 0;
  transform: rotate(90deg);
  -webkit-animation: drawAndReverse 4s alternate infinite ease-in-out;
  animation: drawAndReverse 4s alternate infinite ease-in-out;

  circle {
    stroke: #979797;
  }
}
