@import (reference) "../../core/ui/reference.less";

@import "./corner-style.less";

@typo5-h1-large-desktop: 96px, 96px;
@typo5-h1-large-tablet: 72px, 72px;
@typo5-h1-large-mobile: 48px, 48px;

@typo5-h1-desktop: 72px, 72px;
@typo5-h1-tablet: 60px, 60px;
@typo5-h1-mobile: 48px, 48px;

@typo5-h2-desktop: 60px, 60px;
@typo5-h2-tablet: 48px, 48px;
@typo5-h2-mobile: 36px, 36px;

@typo5-h3-desktop: 48px, 48px;
@typo5-h3-tablet: 36px, 36px;
@typo5-h3-mobile: 24px, 24px;

@typo5-h4-desktop: 36px, 36px;
@typo5-h4-tablet: 24px, 24px;
@typo5-h4-mobile: 18px, 18px;


@typo5-large-body-desktop: 36px, 42px;
@typo5-large-body-tablet: 28px, 32px;
@typo5-large-body-mobile: 24px, 30px;

@typo5-body-desktop: 24px, 30px;
@typo5-body-tablet: 20px, 24px;
@typo5-body-mobile: 18px, 22px;

@typo5-small-body-desktop: 18px, 22px;
@typo5-small-body-tablet: 17px, 20px;
@typo5-small-body-mobile: 16px, 19px;

@typo5-xsmall-body-desktop: 16px, 20px;
@typo5-xsmall-body-tablet: 15px, 18px;
@typo5-xsmall-body-mobile: 14px, 16px; // Fixed from 13px for LH


@typo5-large-quote-desktop: 72px, 80px;
@typo5-large-quote-tablet: 60px, 68px;
@typo5-large-quote-mobile: 48px, 56px;

@typo5-quote-desktop: 60px, 68px;
@typo5-quote-tablet: 48px, 56px;
@typo5-quote-mobile: 36px, 42px;

@typo5-small-quote-desktop: 48px, 56px;
@typo5-small-quote-tablet: 36px, 42px;
@typo5-small-quote-mobile: 28px, 34px;

.typo5 {
  &, [class*='body-copy'] {
    font-family: @f-typo4;
    font-weight: @fw-regular;
    font-style: normal;
  }

  .rich-text-container,
  &.rich-text-container {
    color: var(--typo-text-color, inherit);
    font-weight: @fw-regular;

    .setFontByList(@typo5-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-body-tablet);
    }
    @media @mq--XL {
      font-weight: @fw-light;
      .setFontByList(@typo5-body-desktop);
    }

    &, a, p, i, b, strong, u, sub, sup, ul, ol, li, span, div {
      font-family: @f-typo4;
    }
  }

  .rich-text-container.rich-text-container {
    p, a, i, b, strong, u, sub, sup, ul, ol, li, span, div {
      color: inherit;
    }

    u {
      border-color: inherit;
    }

    a {
      border-bottom-color: var(--typo-link-underline-color);

      &:hover {
        border-bottom-color: var(--typo-link-hover-underline-color);
      }
    }
  }

  .inline-link {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  .body-copy {
    color: var(--typo-text-color, inherit);
    // Body Copy
    & {
      font-weight: @fw-regular;
      .setFontByList(@typo5-body-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-body-tablet);
      }
      @media @mq--XL {
        font-weight: @fw-light;
        .setFontByList(@typo5-body-desktop);
      }
    }

    // Body Copy Large
    &-large {
      color: var(--typo-text-color, inherit);
      font-weight: @fw-light;
      .setFontByList(@typo5-large-body-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-large-body-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-large-body-desktop);
      }
    }

    // Body Copy Small
    &-small {
      color: var(--typo-text-color, inherit);
      font-weight: @fw-regular;
      .setFontByList(@typo5-small-body-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-small-body-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-small-body-desktop);
      }
    }

    // Body Copy XSmall = Disclaimer
    &-xsmall {
      color: var(--typo-text-color, inherit);
      font-weight: @fw-regular;
      .setFontByList(@typo5-xsmall-body-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-xsmall-body-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-xsmall-body-desktop);
      }
    }
  }

  .quote {
    color: var(--typo-quote-color, inherit);
    // Quote
    & {
      font-weight: @fw-light;
      .setFontByList(@typo5-quote-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-quote-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-quote-desktop);
      }
    }

    // Large Quote
    &.large {
      color: var(--typo-quote-color, inherit);
      .setFontByList(@typo5-large-quote-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-large-quote-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-large-quote-desktop);
      }
    }

    // Small Quote
    &.small {
      color: var(--typo-quote-color, inherit);
      .setFontByList(@typo5-small-quote-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-small-quote-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-small-quote-desktop);
      }
    }
  }

  h1, h2, h3, h4, h5, h6,
  .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6 {
    font-family: @f-typo4;
    text-transform: inherit;
    color: var(--typo-heading-color, inherit);
  }

  h1, .tag-h1 {
    font-weight: @fw-regular;
    .setFontByList(@typo5-h1-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-h1-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-h1-desktop);
    }

    &.large {
      .setFontByList(@typo5-h1-large-mobile);
      @media @mq--MD-LG {
        .setFontByList(@typo5-h1-large-tablet);
      }
      @media @mq--XL {
        .setFontByList(@typo5-h1-large-desktop);
      }
    }
  }

  h2, .tag-h2 {
    font-weight: @fw-regular;
    .setFontByList(@typo5-h2-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-h2-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-h2-desktop);
    }
  }

  h3, .tag-h3 {
    font-weight: @fw-medium;
    .setFontByList(@typo5-h3-mobile);
    @media @mq--MD-LG {
      font-weight: @fw-regular;
      .setFontByList(@typo5-h3-tablet);
    }
    @media @mq--XL {
      font-weight: @fw-regular;
      .setFontByList(@typo5-h3-desktop);
    }
  }

  h4, .tag-h4 {
    font-weight: @fw-medium;
    .setFontByList(@typo5-h4-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-h4-tablet);
    }
    @media @mq--XL {
      font-weight: @fw-regular;
      .setFontByList(@typo5-h4-desktop);
    }
  }

  h5, .tag-h5 {
    font-weight: @fw-medium;
    .setFontByList(@typo5-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-body-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-body-desktop);
    }
  }

  h6, .tag-h6 {
    font-weight: @fw-medium;
    .setFontByList(@typo5-small-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-small-body-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-small-body-desktop);
    }
  }

  ul li,
  ol li {
    font-family: @f-typo4;
    font-weight: inherit;
  }

  ol li {
    color: inherit;
  }

  b, strong {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
  }

  // Slim text modifier definition
  slim-text {
    font-family: @f-typo4;
    font-weight: @fw-light;
  }

  .icon-lnk {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
  }

  .text-light {
    font-family: inherit;
    font-weight: @fw-light;
  }

  .text-regular {
    font-family: inherit;
    font-weight: @fw-regular;
  }

  .text-semibold {
    font-family: inherit;
    font-weight: @fw-semibold;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-bold {
    font-family: inherit;
    font-weight: @fw-bold;
  }

  // set to alt text Default text values
  smart-image {
    .setFontByList(@typo5-small-body-mobile);
    @media @mq--MD-LG {
      .setFontByList(@typo5-small-body-tablet);
    }
    @media @mq--XL {
      .setFontByList(@typo5-small-body-desktop);
    }
  }

  .hpe-note-wrapper {
    color: inherit;
  }

  :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) {
    i, b, strong, u, span {
      color: inherit;
    }
  }

  /** HPE Metrics font:
      em-size: 1000
      ascent: 794
      descent: 206
      cap-height: 614
      x-height: 444
      line-gap: 200

      highest point: 660 (relatively to baseline, letter f)
      lowest point: -180 (relatively to baseline, letter g)
  */
  --f-crop-enable: 1;
  /** ratio to crop to X-HEIGHT */
  --f-extra-space-top-ratio: 0.134;
  --f-extra-space-bottom-ratio: 0.026;
  /** ratio to crop to CAPITAL */
  --f-extra-space-top-ratio-cap: 0.180;
  --f-extra-space-bottom-ratio-cap: 0.206;

  .crop-top-extra-space {
    .crop-top-extra-space();
  }
  .crop-bottom-extra-space {
    .crop-bottom-extra-space();
  }
  .crop-extra-space {
    .crop-extra-space();
  }
  .crop-to-capital {
    &.crop-top-extra-space {
      .crop-top-extra-space(true);
    }
    &.crop-bottom-extra-space {
      .crop-bottom-extra-space(true);
    }
    &.crop-extra-space {
      .crop-extra-space(true);
    }
  }
}

.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --typo-text-color: @primary9;
  --typo-quote-color: @primary9;
  --typo-heading-color: @primary9;
  --typo-icons-color: @primary9;
  --typo-link-underline-color: rgba(255, 255, 255, 0.6);
  --typo-link-hover-underline-color: @primary9;
}

body,
.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --typo-text-color: @secondary8;
  --typo-quote-color: @primary31;
  --typo-heading-color: @primary31;
  --typo-icons-color: @primary10;
  --typo-link-underline-color: @primary1;
  --typo-link-hover-underline-color: @primary12;
}
