.video-meta {
  display: none; //display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 65px;
  height: 25px;
  text-align: center;

  background: rgba(0, 0, 0, 0.7);
  color: #fff;

  vertical-align: middle;
  > span {
    color: inherit !important;
  }
  @media @mq--XS-SM {
    > span {
      vertical-align: middle;
    }
  }
  @media @mq--LG {
    right: 10px;
    bottom: 10px;
  }
  @media @mq--XS {
    bottom: 5px;
    right: 5px;
  }
}

// Fix Brightcove Player information popup styles
.video-js-brightcove {
  table td,
  table tr:active td,
  table tr:hover td {
    background: transparent;
  }
}

// Video - Brightcove - SM
//--------------
@media screen and (max-width: 480px) {
  // HPE-11579: Navigation Buttons are not fully displayed
  .vjs-control-bar {
    // It hides the video-total-duration and the divider next to it
    .vjs-duration,
    .vjs-time-divider {
      display: none;
    }

    // Extra space between current time and progress-bar
    .vjs-current-time {
      margin-right: 6px;
    }

    // It reduces the width for all the buttons
    button.vjs-control {
      width: 40px;
    }
    .vjs-remaining-time {
      padding-left: .2em;
      padding-right: .2em;
    }
  }
}
