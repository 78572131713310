@import (reference) "../../../../styleguide/ui/icons/arrow-icon.less";
@import "./cta-group-mixin.less";
@import "./play-icon.less";
@import "./play-button-extended.less";

@ARROW_LINK_ICON_SIZE: 35px;
@ARROW_LINK_ICON_SIZE_MOBILE: 25px;

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cta {
  display: inline-block;

  appearance: none;
  border: none;
  background: none;
  box-shadow: none;

  &.pull-center {
    align-self: center;
    justify-content: center;
    text-align: center;
  }
  &.pull-right {
    align-self: flex-end;
    text-align: right;
    float: none !important;
  }
  &.btn-v3.pull-right {
    text-align: center;
  }
  &.pull-right.hpe-arrow-link {
    justify-content: flex-end;
    &.btn-v3 {
      justify-content: center;
    }
  }
  &.pull-left {
    float: none !important;
  }

  &.hpe-arrow-link.white-arrow {
    .arrow {
      &:extend(.white-arrow-icon);
    }
  }

  &.hpe-arrow-link.live-chat-cta {
    .btn-arrow {
      &::after {
        content: "";
        display: inline-block;
        min-width: 1em;
        min-height: 1em;
        margin-left: 5px;
        vertical-align: middle;
        transform: translateX(0);
        transition: transform 150ms ease-in-out;
        &:extend(.arrow-icon);
      }
    }

    &:visited,
    &.hovered,
    &:hover,
    &:focus {
      .btn-arrow::after {
        transform: translateX(5px);
      }
    }
  }

  &.hpe-arrow-link.hpe-icon-link {
    max-width: 100%;
    min-width: 0;

    .icon {
      display: inline-block;
      width: @ARROW_LINK_ICON_SIZE;
      height: @ARROW_LINK_ICON_SIZE;
      min-width: @ARROW_LINK_ICON_SIZE;
      min-height: @ARROW_LINK_ICON_SIZE;
      max-width: @ARROW_LINK_ICON_SIZE;
      max-height: @ARROW_LINK_ICON_SIZE;
      vertical-align: middle;
      margin-right: 10px;

      @media @mq--XS-MD {
        width: @ARROW_LINK_ICON_SIZE_MOBILE;
        height: @ARROW_LINK_ICON_SIZE_MOBILE;
        min-width: @ARROW_LINK_ICON_SIZE_MOBILE;
        min-height: @ARROW_LINK_ICON_SIZE_MOBILE;
        max-width: @ARROW_LINK_ICON_SIZE_MOBILE;
        max-height: @ARROW_LINK_ICON_SIZE_MOBILE;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      vertical-align: middle;
    }
  }

  &.btn-v3.hpe-arrow-link {
    .btn-arrow {
      padding-right: 0;
    }
    .btn-arrow::after {
      content: none;
    }
  }
}
