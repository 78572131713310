@import (reference) "../../../../core/ui/dependencies.less";
@import (reference) "../../../ui/icons/arrow-icon-unicorn.less";

// Color Palette for buttons - Variables
@darkBlue: #03394F;
@darkGreen: #085541;
@darkOrange: #7F4001;
@darkPurple: #3A0975;
@lightGreen: #1cd3a8;

@gradient-green: linear-gradient(60deg, @primary1 0% 13%, @lightGreen 20%, @primary1 27%, @primary1);
@gradient-green-2: linear-gradient(60deg, @lightGreen 0% 13%, @primary1 20%, @lightGreen 40%, @primary1 50%);
@gradient-white: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0% 17%, @primary9 20%, rgba(255, 255, 255, 0.8) 23%, rgba(255, 255, 255, 0.8));

// Mixin to add button color theme
.addBtnColorTheme(@name, @mainColor, @hoverColor) {
  &.color-@{name} {
    background: @mainColor;
    border-color: @mainColor;

    .has-hover &:hover {
      background: @hoverColor;
      border-color: @hoverColor;
    }
  }
}

.btn-typo4 {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center;
  border-radius: 4px;

  &, &.typo4, &.typo5 {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
  }

  .setFont(16px, 25px);

  height: 37px;
  padding: 4px 23px;

  &.rounded {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-width: 0;

    .setFont(18px, 24px);
    min-height: 36px;
    padding: 4px calc(~'18px - var(--btn-v4-border-width)');
    border-radius: 100px; // very high value, unattainable in real cases

    @media @mq--MD-LG {
      .setFont(20px, 20px);
      min-height: 44px;
      padding: 9px calc(~'24px - var(--btn-v4-border-width)');
    }

    @media @mq--XL {
      .setFont(24px, 24px);
      min-height: 60px;
      padding: 14px calc(~'36px - var(--btn-v4-border-width)');
    }

    &:not(.adaptive) {
      max-width: 400px;
      @media @mq--LG-XL {
        max-width: 650px;
      }
    }

    @media @mq--XS {
      &:not(.small) {
        width: 100%;
        max-width: 100%;
      }
    }

    .text {
      text-align: left;
    }

    body:not(.disabled-focus) &:focus {
      &::before {
        content: '';
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        box-shadow: 0 0 4px 1px @primary30;
        border-radius: inherit;
      }
    }
  }

  &.primary, &.secondary, &.rounded {
    --btn-v4-border-width: 2px;
    @media @mq--MD-LG {
      --btn-v4-border-width: 3px;
    }
    @media @mq--XL {
      --btn-v4-border-width: 4px;
    }

    &.small {
      --btn-v4-border-width: 2px;
      @media @mq--XL {
        --btn-v4-border-width: 3px;
      }
    }
  }

  &.primary {
    color: @primary9;
    border: var(--btn-v4-border-width) solid transparent;
    background: @gradient-green;
    background-size: 200% 200%;
    background-position: 99% 50%; // 1% to avoid empty space
    transition: background-position .5s;

    &:hover,
    .clickable-area:hover ~ .clickable-area-wrapper & {
      background-position: 0 50%;
      color: @primary9;
    }

    &:focus, &:visited {
      color: @primary9;
    }
  }

  &.primary.grey {
    color: @primary31;
    background: @gradient-white;
    backdrop-filter: blur(24px);
    background-size: 200% 200%;
    background-position: 99% 50%; // 1% to avoid empty space

    &:hover,
    .clickable-area:hover ~ .clickable-area-wrapper & {
      background-position: 0 50%;
      color: @primary31;
    }

    &:focus, &:visited {
      color: @primary31;
    }
  }

  &.primary.green {
    .icon {
      filter: brightness(0) invert(1);
    }
  }

  &.secondary {
    border: var(--btn-v4-border-width) solid @primary1;
    background-color: transparent;

    &::before {
      content: '';
      position: absolute;
      inset: ~'calc(var(--btn-v4-border-width) * -1)';

      border: var(--btn-v4-border-width) solid transparent;
      border-radius: inherit;

      background: @gradient-green-2 border-box;
      background-size: 200% 200%;
      background-position: 99% 0; // 1% to avoid empty space

      -webkit-mask: ~'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)';
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;

      pointer-events: none;

      transition: background-position ease-in-out .5s;
    }

    .icon {
      transition: filter ease-in-out .5s;
    }

    body:not(.disabled-focus) &:focus,
    body:not(.disabled-focus) .clickable-area:focus ~ .clickable-area-wrapper &,
    &:hover,
    .clickable-area:hover ~ .clickable-area-wrapper & {
      &::before {
        background-position: 0 0;
      }

      .icon {
        filter: brightness(130%);
      }
    }

    &,
    &:hover,
    &:focus,
    &:visited,
    .clickable-area:hover ~ .clickable-area-wrapper & {
      color: var(--btn-v4-color);
    }
  }

  .text,
  .hpe-chat-hide-online,
  .hpe-chat-hide-offline {
    color: inherit;
  }

  .icon {
    width: .7em;
    height: .7em;
    min-width: .7em;
    min-height: .7em;
    transition: transform 150ms ease-in-out;

    margin-left: 12px;
    @media @mq--XL {
      margin-left: 18px;
    }
  }

  .icon.arrow-icon {
    background: url('data:image/svg+xml,@{arrowIconUc}') no-repeat;
  }

  .icon.white-arrow-icon {
    background: url('data:image/svg+xml,@{whiteArrowIconUc}') no-repeat;
  }

  .element-icon,
  .white-element-icon {
    transition: none;

    height: 15px;
    min-height: 15px;
    width: 50px;
    min-width: 50px;
    @media @mq--XL {
      height: 21px;
      min-height: 21px;
      width: 75px;
      min-width: 75px;
    }
  }

  &.small .element-icon,
  &.small .white-element-icon {
    @media @mq--XL {
      height: 15px;
      min-height: 15px;
      width: 50px;
      min-width: 50px;
    }
  }

  .none-icon {
    display: none;
  }

  // Sizes
  &.small {
    .setFont(18px, 24px);
    min-height: 36px;
    padding-top: 4px;
    padding-bottom: 4px;
    @media @mq--MD-XL {
      .setFont(18px, 18px);
      min-height: 42px;
      padding: 9px calc(~'24px - var(--btn-v4-border-width)');
    }

    .icon {
      margin-left: 12px;
    }
  }

  &.large {
    .setFont(20px, 20px);
    min-height: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    @media @mq--MD-LG {
      .setFont(24px, 24px);
      min-height: 48px;
      padding-top: 9px;
      padding-bottom: 9px;
    }
    @media @mq--XL {
      .setFont(30px, 30px);
      min-height: 66px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  // Not related to the rounded buttons (will be removed in future)
  &.xlarge { // move to secondary or ?
    height: 48px;
    padding: 8px 28px;
    line-height: 28px;
  }

  // Applying color themes
  .addBtnColorTheme(~"aruba-orange", @primary20, @darkOrange);
  .addBtnColorTheme(~"medium-purple", @primary22, @darkPurple);
  .addBtnColorTheme(~"hpe-green", @primary1, @darkGreen);
  .addBtnColorTheme(~"blue", @primary14, @darkBlue);
}

.btn-cta-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.left-alignment {
    align-items: flex-start;
  }

  &.center-alignment {
    align-items: center;
  }

  &.right-alignment {
    align-items: flex-end;
  }

  &.inline-layout {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &.left-alignment {
      justify-content: flex-start;
    }

    &.center-alignment {
      justify-content: center;
    }

    &.right-alignment {
      justify-content: flex-end;
    }
  }
}

.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --btn-v4-color: @primary9;
}

.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --btn-v4-color: @primary31;
}
