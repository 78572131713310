@import (reference) "../../../../core/ui/dependencies.less";

.hpe-modal-form {
  @media @mq--XS {
    &::before,
    &::after {
      flex: 1 1 auto;
    }
  }

  .error-message {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;

    @media @mq--XS {
      position: relative;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }

  & > .base-modal-container {
    min-width: 600px;
    width: auto;
    max-width: 830px;
    min-height: 300px;

    @media @mq--XS {
      margin: 0;
      min-width: 100%;
      min-height: 100%;

      padding-right: 30px;
      padding-left: 30px;

      .close-btn {
        left: 30px;
        right: auto;

        &::before {
          content: "\f12f";
        }
      }
    }

    .form-modal-content {
      @media @mq--SM-XL {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
