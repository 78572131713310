//INLINE LINKS
.inline-link, .inline-link-small {
  //NOTE: font size in typography
  font-family: @lightFont, Arial, sans-serif;
  color: @primary10;
  border-bottom: 1px solid @primary1;
  transition: all .1s ease;
  &:hover {
    color: @primary10;
    border-color: @primary12;
  }
  .dark-theme &, body .dark-theme-prespecific &, html body .dark-theme-specific & {
    border-color: rgba(255, 255, 255, 0.6);
    &:hover {
      border-color: rgba(255, 255, 255, 1);
    }
  }

  .light-theme &, body .light-theme-prespecific &, html body .light-theme-specific & {
    border-color: @primary1;
    &:hover {
      border-color: @primary12;
    }
  }
}

