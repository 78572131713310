[hpe-gradient-mixin] {
  --gradient-offset: 0;
  --gradient-offset-top: var(--gradient-offset);
  --gradient-offset-left: var(--gradient-offset);
  --gradient-offset-bottom: 0;
  --gradient-offset-right: 0;

  position: relative;
  padding: var(--gradient-offset-bottom) var(--gradient-offset-left) var(--gradient-offset-top) var(--gradient-offset-right);

  > .hpe-gradient-accent {
    position: absolute;
    top: var(--gradient-offset-top);
    bottom: var(--gradient-offset-bottom);
    left: var(--gradient-offset-left);
    right: var(--gradient-offset-right);
    z-index: -1;
  }

  &[hpe-gradient-mixin*="top"] {
    --gradient-offset-top: 0;
    --gradient-offset-bottom: var(--gradient-offset);
  }
  &[hpe-gradient-mixin*="left"] {
    --gradient-offset-left: 0;
    --gradient-offset-right: var(--gradient-offset);
  }
}
