.buildVideoIcon(@fill: @primary1) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16"><path fill="@{fill}" d="M2.179 1.204 1.25.688V15.312l.929-.515 11.25-6.25.983-.547-.984-.546-11.25-6.25Z"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.video-icon {
  .buildVideoIcon(@primary1);
}

.white-video-icon {
  .buildVideoIcon(@primary9);
}
