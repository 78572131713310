@import (reference) "../../../../core/ui/dependencies.less";
@import (reference) "../../../../styleguide/ui/icons/arrow-icon.less";

//---------------------------------------
// Buttons
//---------------------------------------
@ctaTransition: all .2s ease;
@ctaOutlineWidth: 2px;

@btn-font-size-xl: 20px;
@btn-font-size-lg: 18px;
@btn-font-size-md: 16px;

@btn-line-height-xl: 22px;
@btn-line-height-lg: 20px;
@btn-line-height-md: 18px;

// mixin to add box-shadow on hover
.addBoxShadow(@color) {
  &::after {
    box-shadow: 0 0 0 @ctaOutlineWidth*2 @color;
  }
}

.btn-v3 {
  --btnMinHeight: 60px;
  --btnMinWidth: 242px;
  --btnMaxWidth: 450px;

  padding: 10px 22px;
  min-height: var(--btnMinHeight);
  min-width: var(--btnMinWidth);
  max-width: var(--btnMaxWidth);
  .setFont(@btn-font-size-xl, @btn-line-height-xl);

  //==== SPINNER
  .grommet-spinner {
    margin: 2px 0 0 -1 * @btn-line-height-xl;
    display: none;
    width: @btn-line-height-xl;
    height: @btn-line-height-xl;
    circle {
      stroke: #82cec0;
    }
  }

  &.spinner-shown {
    pointer-events: none;
    flex-wrap: nowrap;
    .button-text {
      opacity: 0.5;
    }
    .grommet-spinner {
      display: block;
    }
  }

  //==== BREAKPOINTS
  @media @mq--XS-LG {
    .setFont(@btn-font-size-lg, @btn-line-height-lg);
    --btnMinHeight: 44px;
    --btnMaxWidth: 400px;
    content: ''; //to fix issue of webpack optimization

    .grommet-spinner {
      margin: 2px 0 0 -1 * @btn-line-height-lg;
      width: @btn-line-height-lg;
      height: @btn-line-height-lg;
    }
  }

  @media @mq--XS-MD {
    .setFont(@btn-font-size-md, @btn-line-height-md);
    --btnMinHeight: 40px;
    --btnMinWidth: 200px;
    content: ''; //to fix issue of webpack optimization

    .grommet-spinner {
      margin: 2px 0 0 -1 * @btn-line-height-md;
      width: @btn-line-height-md;
      height: @btn-line-height-md;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    content: ''; //to fix issue of webpack optimization
  }

  background: @primary1;
  font-family: @boldFont, Arial, sans-serif;
  text-align: center;

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 2px solid @primary1;
  border-radius: 4px; // rounded corners from typo4
  cursor: pointer; // for button tag

  &::after {
    display: none;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 0 @ctaOutlineWidth*2 @primary1;
    pointer-events: none;
  }

  &:hover, &.hover {
    &::after {
      display: inline-block;
    }

    .button-icon {
      &.slide-right::before {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .button-icon {
    position: relative;

    &.slide-right::before {
      transition: @ctaTransition;
    }

    &::before {
      margin-right: 10px;
    }
  }

  &[class*="sec-"] {
    background: transparent;

    .grommet-spinner circle {
      stroke: @primary10;
    }
  }

  &.sec-white {
    border-color: @primary10;
    .addBoxShadow(@primary10);

    &.dark-bkg {
      border-color: @primary9;
      .addBoxShadow(@primary9);
    }
  }

  &.sec-orange { // NOTE: used like Aruba
    border-color: @primary20;
    .addBoxShadow(@primary20);
  }

  &.sec-light-purple {
    border-color: @primary21;
    .addBoxShadow(@primary21);
  }

  &.sec-medium-blue {
    border-color: @primary18;
    .addBoxShadow(@primary18);
  }

  &.sec-medium-purple {
    border-color: @primary22;
    .addBoxShadow(@primary22);
  }

  &.sec-light-blue {
    border-color: @primary17;
    .addBoxShadow(@primary17);
  }

  &.sec-dark-blue {
    border-color: @primary19;
    .addBoxShadow(@primary19);
  }

  &.sec-yellow {
    border-color: @primary23;
    .addBoxShadow(@primary23);
  }

  // Brand Refresh - https://centerpoint.int.hpe.com:8443/browse/HPED-20920
  .button-text {
    color: @primary9; // for primary always should be white text (fixed problem with themes)
  }

  &[class*="sec-"] .button-text {
    color: inherit;
  }

  .btn-arrow {
    position: relative;
    white-space: nowrap;
    padding-right: 1.33em;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 2px;
      width: 1em;
      height: 1em;
      transition: transform 150ms ease-in-out;
      &:extend(.white-arrow-icon);

      @media @mq--XL {
        top: 3px;
      }
    }
  }

  &:hover,
  &:focus {
    .btn-arrow::after {
      transform: translateX(5px);
    }
  }

  &[class*="sec-"] .btn-arrow::after { // DEFAULT FALLBACK, GREEN COLOR
    &:extend(.arrow-icon);
  }

  &.sec-orange .btn-arrow::after { // NOTE: used like Aruba
    &:extend(.arrow-icon-orange);
  }

  &.sec-light-purple .btn-arrow::after {
    &:extend(.arrow-icon-light-purple);
  }

  &.sec-medium-blue .btn-arrow::after {
    &:extend(.arrow-icon-medium-blue);
  }

  &.sec-medium-purple .btn-arrow::after {
    &:extend(.arrow-icon-medium-purple);
  }

  &.sec-dark-blue .btn-arrow::after {
    &:extend(.arrow-icon-dark-blue);
  }

  &.sec-light-blue .btn-arrow::after {
    &:extend(.arrow-icon-light-blue);
  }

  &.sec-yellow .btn-arrow::after {
    &:extend(.arrow-icon-yellow);
  }

  &.sec-white .btn-arrow::after {
    &:extend(.arrow-icon-black);
  }

  &.dark-bkg .btn-arrow::after {
    &:extend(.white-arrow-icon);
  }
}

.btn-white {
  background: @primary9;
  color: @primary10;
  .addBoxShadow(@primary9);
}

//---------------------------------------
// Buttons/Icon links style redefinitions (can be dropped after moving from bootstrap)
//---------------------------------------
.btn-v3 {
  &, &:hover, &.hover, &:focus, &:visited {
    color: @primary9;
  }

  &:focus {
    outline: @FOCUS_OUTLINE_STYLE;
  }

  &[class*="sec-"]:not(.dark-bkg) {
    &, &:hover, &.hover, &:focus, &:visited {
      color: @primary10;
    }
  }
}

.btn-white {
  &:hover, &.hover, &:focus, &:visited {
    color: @primary10;
  }
}

// WORKS ONLY FOR PRIMARY BUTTON
.light-theme, body .light-theme-prespecific &, html body .light-theme-specific {
  .btn-v3 {
    color: @primary9;

    &:focus, &:hover, &:active, &:visited {
      color: @primary9;
    }
  }
}

#buttons() {
  // Applies inherited color theme to secondary buttons.
  // Use it if you don't want to manually handle button theme through the "dark-bkg" class.
  .inheritTheme() {
    #buttons.inheritDarkTheme();
    #buttons.inheritLightTheme();
  }

  .inheritDarkTheme() {
    &.dark-theme,
    .dark-theme &,
    body .dark-theme-prespecific &,
    html body .dark-theme-specific & {
      .btn-v3[class*="sec-"] {
        &, &:hover, &.hover, &:focus, &:visited {
          color: @primary9;
        }

        .btn-arrow::after {
          .buildShortArrowIcon(@primary9);
        }
      }

      .btn-v3.sec-white {
        border-color: @primary9;
        .addBoxShadow(@primary9);
      }
    }
  }

  .inheritLightTheme() {
    @themeToColor: // Map
      // button theme alias - arrow color
      'light-purple' @primary21,
      'medium-purple' @primary22,
      'medium-blue' @primary18,
      'dark-blue' @primary19,
      'light-blue' @primary17,
      'orange' @primary20,
      'yellow' @primary23,
      'white' @primary10; // sec-white button contains black arrow

    &.light-theme,
    .light-theme &,
    body .light-theme-prespecific &,
    html body .light-theme-specific & {
      .btn-v3[class*="sec-"] {
        &, &:hover, &.hover, &:focus, &:visited {
          color: @primary10;
        }

        .btn-arrow::after {
          .buildShortArrowIcon(@primary1);
        }
      }

      .loop(@i: 1) when (@i <= length(@themeToColor)) {
        @pair: extract(@themeToColor, @i);
        @theme: e(extract(@pair, 1));
        @color: extract(@pair, 2);

        .btn-v3.sec-@{theme} {
          .btn-arrow::after {
            .buildShortArrowIcon(@color);
          }
        }
        .loop(@i + 1);
      };
      .loop();
    }
  }
}
