.close-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  color: @primary1;

  text-decoration: none;
  font-size: 20px;
  line-height: 1;

  &::after {
    content: "\f128";
    font-family: 'hpe-glyphicons';
  }
}
