@import (reference) "../../../ui/dependencies.less";

@GM-VERTICAL-MARGIN-DESKTOP: 100px;
@GM-VERTICAL-MARGIN-MOBILE: 30px;

@SCROLL_THUMB_LIGHT_BG: #b5b5b5;
@SCROLL_TRACK_DARK_BG: #404040;
@SCROLL_THUMB_DARK_BG: #888;

.generic-modal {
  --modal-container-offset-y: 40px;
  @media @mq--SM-XL {
    --modal-container-offset-y: 60px;
  }

  &.base-modal {
    overflow: hidden;
    z-index: 120; // For overlay videos
  }

  .generic-modal-dialog {
    position: relative;
    z-index: 1;
    width: 1600px;
    height: ~"calc(100% - 2*@{GM-VERTICAL-MARGIN-DESKTOP})";
    max-height: ~"calc(100% - 2*@{GM-VERTICAL-MARGIN-DESKTOP})";

    @media @mq--XS-SM {
      width: 100%;
      height: ~"calc(100% - 2*@{GM-VERTICAL-MARGIN-MOBILE})";
      max-height: ~"calc(100% - 2*@{GM-VERTICAL-MARGIN-MOBILE})";
    }

    @media @mq--MD {
      width: ~"calc(100% - 60px)";
    }

    @media @mq--LG {
      width: ~"calc(100% - 120px)";
    }
  }

  .generic-modal-content {
    position: relative;
    height: 100%;
    background-color: #fff;
    background-clip: padding-box;

    .scrollable-content {
      height: 100%;
      padding: 0 60px;

      &::before, &::after {
        content: '';
        display: block;
        height: 0;
      }
      &::before {
        margin-bottom: var(--modal-container-offset-y, 0);
      }
      &::after {
        margin-top: var(--modal-container-offset-y, 0);
      }
      &.omit-top-offset::before {
        display: none;
      }
      &.omit-bottom-offset::after {
        display: none;
      }
    }
  }

  .esl-footnotes-items {
    margin-bottom: var(--modal-container-offset-y, 0);
  }

  .generic-modal-scrollbar {
    top: 45px;
    bottom: 15px;
    right: 16px;
    width: 16px;
    padding: 5px;

    &:dir(rtl) & {
      left: 16px;
      right: auto;
    }

    .scrollbar-track {
      margin: 0;
      background-color: @secondary15;
    }
    .scrollbar-thumb {
      background-color: #b5b5b5;
    }
  }

  .generic-modal-header {
    padding-top: 60px;
  }

  .generic-modal-title {
    .dropTopExtraSpace(@typo4-h2-desktop);
  }

  .generic-modal-description {
    .setTopMargin(20px, @typo4-text-large-desktop);
    .dropBottomExtraSpace(@typo4-text-large-desktop);
  }

  .generic-modal-close {
    .button-reset();
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    color: @primary10;
    font-size: 17px;
    line-height: 0;
    padding: 6px;
    text-shadow: #eee 0 0 5px;
  }

  &.dark-theme {
    .generic-modal-content {
      background-color: @primary10;
    }

    .generic-modal-scrollbar {
      .scrollbar-track {
        background-color: @SCROLL_TRACK_DARK_BG;
      }
      .scrollbar-thumb {
        background-color: @SCROLL_THUMB_DARK_BG;
      }
    }

    .generic-modal-close {
      color: @primary9;
      text-shadow: #111 0 0 5px;
    }
  }

  .container-fluid.container-gutter {
    margin: 0;
    padding: 0;
    max-width: none;
  }
}

// UC Modal uses container offset overrides
.generic-modal.uc-modal {
  --container-offset-x: 60px;

  .generic-modal-dialog {
    height: auto;
  }
  .generic-modal-content {
    .scrollable-content {
      padding-inline: 0;
    }
  }
}
