@import (reference) "../../../../core/ui/dependencies.less";

@accent-color-height: 5px;

base-modal {
  display: none;
}

.base-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 121; // greater than anchor-nav-v3 = 101 and generic-modal = 120
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--100vh, 100vh);
  overflow-y: scroll;
  animation: fade-in .2s linear;

  &:not(.open) {
    display: none;
  }

  // Flex spacer top/bottom
  &::before, &::after {
    content: '';
    flex: 1 0 30px;
  }

  .close-btn {
    .button-reset();
    position: absolute;
    right: 20px;
    top: 20px;
    color: @primary12;
    font-size: 21px;
    text-shadow: none;
    line-height: 0;
  }

  .base-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }

  .base-modal-container {
    flex: 0 0 auto;
    position: relative;
    padding: 40px 50px 50px;
    width: 700px;
    max-width: calc(~'100vw - 40px');
    background-color: #fefefe;
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: -@accent-color-height;
      left: 0;
      right: 0;

      height: @accent-color-height;
      width: 100%;
    }

    @media @mq--XS {
      margin: 30px 20px;
    }
  }

  &.container-gutter {
    margin: 0;
    padding: 0;
    max-width: 100vw;
    .base-modal-container {
      width: 100%;
      max-width: calc(~'100% - 30px');
      @media @mq--SM-MD {
        max-width: calc(~'100% - 60px');
      }
      @media @mq--LG {
        max-width: calc(~'100% - 100px');
      }
      @media @mq--XL {
        max-width: 1400px;
      }
    }
  }

  &.xs-full-page {
    @media @mq--XS {
      .base-modal-container {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }

      &::before, &::after {
        display: none;
      }
    }
  }

  .black-gradient-modal-bd & .base-modal-backdrop {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%);
    opacity: 0.4;

    @media @mq--SM-XL {
      background-color: @primary10;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
