.hpe-carousel {
  // Common Navigation Rules
  @navDisappearSpeed: opacity .5s ease-out;

  &.no-arrows .owl-nav,
  &.no-dots .owl-dots {
    display: none;
  }

  .owl-prev, .owl-next, .owl-dots {
    opacity: 0;
    transition: @navDisappearSpeed;
    .is-ready& {
      opacity: 1;
    }
  }

  // Arrows navigation
  @navsColor: #ccc;
  @arrowSize: 50px;
  @xsArrowSize: 36px;
  @arrowShift: .7;
  @xsArrowSizeShift: @xsArrowSize * @arrowShift;
  *:not([class*="style-bg-"]) & .owl-nav {
    color: @navsColor;
  }
  .owl-nav button.owl-prev,
  .owl-nav button.owl-next {

    position: absolute;
    top: 50%;
    display: block;
    z-index: 1;

    // Size
    width: @arrowSize;
    height: @arrowSize;
    margin-top: -@arrowSize/2; // Compensation
    font-size: @arrowSize;

    &.disabled {
      opacity: 0;
      cursor: default;
    }

    @media @mq--XS {
      width: @xsArrowSize;
      height: @xsArrowSize;
      margin-top: -@xsArrowSize/2; // Compensation
      font-size: @xsArrowSize;
    }
  }
  .owl-prev {
    left: 0;
    .arrows-outside& {
      left: -@arrowSize * @arrowShift;
    }
    @media @mq--XS {
      left: -8px; // magic for no-gutters containers
      .container-gutter &, .hf-centered-content & {
        left: -@xsArrowSizeShift !important;
      }
    }
  }
  .owl-next {
    right: 0;
    .arrows-outside& {
      right: -@arrowSize * @arrowShift;
    }
    @media @mq--XS {
      right: -8px; // magic for no-gutters containers
      .container-gutter &, .hf-centered-content & {
        right: -@xsArrowSizeShift !important;
      }
    }
  }

  // Dots navigation
  @dotContainerSize: 25px;
  @dotSize: 14px;
  .owl-carousel {
    .owl-dots {
      position: absolute;
      width: 100%;
      height: @dotSize;
      line-height: 0;
      bottom: round((@dotContainerSize - @dotSize) / 2); // +2 -> border top + border bottom
      text-align: center;
    }
    button.owl-dot {
      width: @dotSize - 2px; // 2px -> border
      height: @dotSize - 2px; // 2px -> border
      display: inline-block;
      margin: 0 5px;

      background: transparent;
      cursor: pointer;
      border: 1px solid @navsColor;
      border-radius: 24px;
      vertical-align: middle;
      &.active {
        background: @navsColor;
        cursor: default;
      }
    }
  }
  &.dots-outside:not(.no-dots) {
    .owl-carousel {
      padding-bottom: @dotContainerSize;
    }
  }

}
