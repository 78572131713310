//
// Forms
// --------------------------------------------------


// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
  // so we reset that to ensure it behaves more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359.
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: @line-height-computed;
  font-size: (@font-size-base * 1.5);
  line-height: inherit;
  color: @legend-color;
  border: 0;
  border-bottom: 1px solid @legend-border-color;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}
