.buildCheckedItemIcon(@color) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" stroke="@{color}" width="21.87" height="15.95" viewBox="0 0 21.87 15.95"><polyline points="1.06 6.64 8.19 13.82 20.81 1.05" fill="none" stroke-miterlimit="10" stroke-width="3"/></svg>');
  background-image: url('data:image/svg+xml,@{content}');
}

.rich-text-container:not(#specific) {
  ul:first-child .check-item:first-child {
    margin-top: 0;
  }

  ul:last-child .check-item:last-child {
    margin-bottom: 0;
  }

  ul:last-child > li:last-child:empty {
    display: none;
  }

  ul {
    .check-item {
      display: list-item;
      position: relative;
      text-align: left;
      padding-left: 0;
      margin-left: 27px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 22px;
      }

      &:first-child {
        margin-top: 27px;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: transparent;
      }

      .tag-h5, .body-copy {
        display: block;
        transform: translateY(-2px);
        margin-bottom: -4px;

        @media @mq--XL {
          transform: translateY(-3px);
        }
      }

      .tag-h5 {
        font-weight: normal;
      }

      .body-copy-large {
        display: block;
        transform: translateY(-7px);
        margin-bottom: -5px;

        @media @mq--LG {
          transform: translateY(-4px);
        }
        @media @mq--MD {
          transform: translateY(-2px);
        }
        @media @mq--XS-SM {
          transform: translateY(-1px);
          margin-bottom: 0;
        }
      }
    }

    .checked {
      font-weight: bold;
    }

    .checked::before {
      height: 15.95px;
      width: 21.87px;
      top: 0.08em;
      left: -27px;
      .buildCheckedItemIcon(@primary1);

      @media @mq--XS-MD {
        top: -0.1em;
      }
    }

    .unchecked::before {
      height: 13px;
      width: 13px;
      top: 0.23em;
      left: -24px;
      opacity: 60%;
      @content: escape('<svg xmlns="http://www.w3.org/2000/svg" stroke="@{secondary5}" viewBox="0 0 13 13"><line x1="1.06" y1="1.06" x2="11.88" y2="11.88" fill="none" stroke-miterlimit="10" stroke-width="3"/><line x1="1.06" y1="11.88" x2="11.88" y2="1.06" fill="none" stroke-miterlimit="10" stroke-width="3"/></svg>');
      background-image: url('data:image/svg+xml,@{content}');

      @media @mq--MD {
        top: 0.15em;
      }
      @media @mq--XS-SM {
        top: 0.13em;
      }
    }
  }
}

.coloredtheme-dark-blue, .coloredtheme-specific-dark-blue:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary19)
  }
}

.coloredtheme-medium-blue, .coloredtheme-specific-medium-blue:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary18)
  }
}

.coloredtheme-light-blue, .coloredtheme-specific-light-blue:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary17)
  }
}

.coloredtheme-medium-purple, .coloredtheme-specific-medium-purple:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary22)
  }
}

.coloredtheme-light-purple, .coloredtheme-specific-light-purple:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary21)
  }
}

.coloredtheme-aruba-orange, .coloredtheme-specific-aruba-orange:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary20)
  }
}

.coloredtheme-yellow, .coloredtheme-specific-yellow:not(#specific) {
  .rich-text-container:not(#specific) .checked::before {
    .buildCheckedItemIcon(@primary23)
  }
}

// Colors for the numeric list
.coloredtheme-dark-blue, .coloredtheme-specific-dark-blue:not(#specific) {
  .rich-text-container ol li::before {
    color: @primary19;
  }
}

.coloredtheme-medium-blue, .coloredtheme-specific-medium-blue:not(#specific) {
  ol li::before {
    color: @primary18;
  }
}

.coloredtheme-light-blue, .coloredtheme-specific-light-blue:not(#specific) {
  ol li::before {
    color: @primary17;
  }
}

.coloredtheme-medium-purple, .coloredtheme-specific-medium-purple:not(#specific) {
  ol li::before {
    color: @primary22;
  }
}

.coloredtheme-light-purple, .coloredtheme-specific-light-purple:not(#specific) {
  .rich-text-container ol li::before {
    color: @primary21;
  }
}

.coloredtheme-aruba-orange, .coloredtheme-specific-aruba-orange:not(#specific) {
  .rich-text-container ol li::before {
    color: @primary20;
  }
}

.coloredtheme-yellow, .coloredtheme-specific-yellow:not(#specific) {
  .rich-text-container ol li::before {
    color: @primary23;
  }
}

.coloredtheme-specific-none:not(#specific) {
  ol li::before {
    color: inherit !important;
  }
  ul li::before {
    background-color: @primary5 !important;
  }
}
