.play-button-extended.cta {
  display: flex;
  align-items: center;
  gap: 12px;
  @media @mq--MD-XL {
    gap: 20px;
  }

  .play-button-icon {
    line-height: 0;
    position: relative;
    width: 44px;
    @media @mq--MD-XL {
      width: 70px;
    }

    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 71 71'%3E%3Cpath fill='%23fff' d='M35.472 68.801c18.225 0 32.999-14.845 32.999-33.158 0-18.313-14.774-33.158-32.999-33.158-18.224 0-32.998 14.845-32.998 33.158 0 18.313 14.774 33.158 32.998 33.158Z' opacity='.8'/%3E%3Cpath fill='%23000' d='m28.873 47.25 19.8-11.605-19.8-11.606V47.25Z'/%3E%3C/svg%3E");
      display: inline-block;
      width: 100%;
    }

    &::after {
      position: absolute;
      inset: 0;
      width: 100%;
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='white' stroke='white' stroke-width='8px' cx='44' cy='44' r='40' %3e%3c/circle%3e%3cpolygon fill='black' points='35,28.3 35,59.7 62,44'%3e%3c/polygon%3e%3c/svg%3e");
      opacity: 0;
      transition: opacity 200ms;
    }
  }

  &:hover .play-button-icon::after,
  .clickable-area:hover + .clickable-area-wrapper & .play-button-icon::after {
    opacity: 1;
  }

  .play-button-content {
    text-align: left;

    .play-button-label {
      font-weight: 700;
    }
  }
}
