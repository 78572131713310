@import "@exadel/esl/modules/esl-share/core.less";
@import (reference) "../../../ui/dependencies.less";

esl-share {
  color: @primary1;
  .icon-share-global.icon-share-global {
    color: inherit;
    font-size: var(--icon-share-size, 20px);
  }
}

esl-share-popup.esl-popup {
  --esl-share-icon-size: 28px;
  justify-content: center;
  padding: 20px;
  width: auto;
  min-width: 70px;
  max-width: 262px;
  gap: 20px;
  border-block: 4px solid var(--hpe-share-accent-color, @primary1);

  @media @mq--MD-XL {
    padding: 15px;
    min-width: 60px;
    max-width: 212px;
    gap: 10px;
  }

  &.open.open {
    z-index: 16384;
  }

  &[placed-at="top"] {
    border-bottom: none;
  }

  &[placed-at="bottom"] {
    border-top: none;
  }
}

esl-share-button .esl-share-icon {
  fill: #fff;
}

.esl-share-alert {
  .esl-alert-content {
    &::before {
      content: '';
      margin-right: 8px;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 9.69l-7.43 7.43 1.18 1.18 7.43-7.43L17 9.69z' fill='%23FFFFFF'/%3E%3Cpath d='M4.31 17.8c-.481.481-.48 1.29.00138 1.77l4.02 4.02c.481.481 1.29.483 1.77.00138l4.95-4.95c.481-.481.481-1.29-7e-7-1.78l-4.02-4.02c-.481-.481-1.29-.481-1.78 0l-4.95 4.95zm1.47.887l4.36-4.36 3.44 3.44-4.36 4.36-3.44-3.44zm7-9.37c-.481.481-.481 1.29 2.8e-7 1.78l4.02 4.02c.481.481 1.29.481 1.78 0l4.95-4.95c.481-.481.48-1.29-.00138-1.77l-4.02-4.02c-.481-.481-1.29-.483-1.77-.00138l-4.95 4.95zm1.47.889l4.36-4.36 3.44 3.44-4.36 4.36-3.44-3.44z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      width: 26px;
      height: 26px;
      background-position: center center;
    }
  }
}
