// TODO: harmful styles, needs to be replaced with a safe css properties cascade

//---------------------------------------
// Dark theme (.style-bg-bronze, .style-bg-dark-steel, .style-bg-purple, .style-bg-slate)
//---------------------------------------
.dark-theme {
  &, body &-prespecific, html body &-specific {
    // Default Text and HTML tags, css helpers overrides
    //==============
    p, span, ul, li, div, strong, b, i, em, u,
    h2, h3, h4, h5, h6,
    .body-copy-large,
    .body-copy,
    .body-copy-small,
    .caption,
    a,
    a:hover,
    a:focus,
    a:visited {
      color: @primary9;
    }
  }
}

//---------------------------------------
// Light theme (default) - not need any classes here
//---------------------------------------

.light-theme {
  &, body &-prespecific, html body &-specific {
    // Default Text and HTML tags, css helpers overrides
    //==============
    p, span, ul, li, div, strong, b, i, em, u,
    h2, h3, h4, h5, h6,
    .body-copy-large,
    .body-copy,
    .body-copy-small,
    .caption,
    a,
    a:hover,
    a:focus,
    a:visited {
      color: @primary10;
    }
  }
}
