//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination-list {
    background-color: @primary9;
    a {
        position: relative;
        display: inline-block;
        width: 80px;
        height: 40px;
        color: @primary12;
        overflow: hidden;
    	span {
			padding-top: 4em;
		}
    }

    a:link, a:hover {
        text-decoration: none;
    }

    a:hover {
        color: @primary10;
    }

    a.disabled {
        pointer-events: none;
        cursor: default;
        color: @primary5;
    }

    a:before {
        .style-hpe-glyphicons();
        position: absolute;
        top: -4px;
        width: 80px;
        height: 40px;
        font-size: 25px;
        padding-top: 11px;
        background-color: @primary9;
    }

    a.pagination-list-next:before {
        content: '\f11c';
        left: 0;
    }

    a.pagination-list-previous:before {
        content: '\f126';
        right: 0;
    }
}

.pagination-list-offset-top {
    margin-top: 5px;
}

.pagination-list-content {
	display: inline-block;
    font-family: @regularFont;
    padding: 10px;
	a, span, em {
	    vertical-align: middle;
	    color: @primary12;
	    font-size: 15px;
	    display: inline-block;
	}
	span, em {
	    height: 1.8em;
	}
    .pagination-list-em,
	em {
		font-family: @boldFont;
		font-style: normal;
	}
}


// ****************************************************************************
// * breakpoint: XS only
// ****************************************************************************
@media screen and (max-width: @screen-xs-max) {
    .pagination-list a {
        width: 60px;
    }
}

