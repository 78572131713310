@import (reference) "./../../../../core/ui/dependencies.less";

.img-accent-gradient {
  display: none;
}

.img-container[hpe-img-accent] {
  --img-size-rel: 1;
  --img-accent-size: 0px;

  --img-accent-x: 0px;
  --img-accent-y: 0px;
  display: block;

  &[hpe-img-accent*="right"] {
    --img-accent-x: var(--img-accent-size);
  }

  &[hpe-img-accent*="bottom"] {
    --img-accent-y: var(--img-accent-size);
  }

  .img-accent-gradient,
  & > .accented-content,
  & > :is(img, smart-image, smart-media) {
    position: absolute;
    width: calc(~'100% - var(--img-accent-size)');
    height: calc(~'100% - var(--img-accent-size)');
  }

  .img-accent-gradient {
    display: block;
    transition: opacity 0.4s;
    top: var(--img-accent-y);
    left: var(--img-accent-x);
  }

  & > .accented-content,
  & > :is(img, smart-image, smart-media) {
    top: calc(~'var(--img-accent-size) - var(--img-accent-y)');
    left: calc(~'var(--img-accent-size) - var(--img-accent-x)');
  }

  &:not(.img-container-loaded),
  &[hpe-img-accent="none"] {
    // To allow image to accept whole img-container area for lazy loading
    --img-accent-x: 0px !important;
    --img-accent-y: 0px !important;
    --img-accent-size: 0px !important;

    .img-accent-gradient {
      opacity: 0;
    }
  }

  &[hpe-img-accent="none"] {
    .img-accent-gradient {
      opacity: 1;
    }
  }

  &.img-container-1-1::after {
    padding-top: calc(~'@{ratio-1-1} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-3-1::after {
    padding-top: calc(~'@{ratio-3-1} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-6-9::after {
    padding-top: calc(~'@{ratio-6-9} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-9-6::after {
    padding-top: calc(~'@{ratio-9-6} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-16-9::after {
    padding-top: calc(~'@{ratio-16-9} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-4-3::after {
    padding-top: calc(~'@{ratio-4-3} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-26-9::after {
    padding-top: calc(~'@{ratio-26-9} * var(--img-size-rel) + var(--img-accent-size)');
  }

  &.img-container-original::after {
    padding-top: calc(~'var(--img-ratio) * var(--img-size-rel) + var(--img-accent-size)');
  }
}
