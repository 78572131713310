.typo5 {
  .rich-text-container ul {
    list-style-type: none;
    list-style-position: outside;

    li {
      padding-left: calc(var(--bullet-width) + var(--list-padding));
      &::before {
        width: var(--bullet-width, 0px);
        height: var(--bullet-height, 0px);
        background-color: var(--bullet-color, @primary1);
      }
    }

    &.type-checkmark {
      li::before {
        clip-path: polygon(30% 77%, 90% 0, 100% 13%, 33% 100%, 0 70%, 10% 57%);
      }
    }
  }

  .rich-text-container:not(.add-spec) {
    ul li::before {
      // TODO: remove this when the minimum supported version of Safari is >=16.3
      --bullet-top: calc((var(--lh) - var(--bullet-height)) / 2);
      // TODO: uncomment after updating cssnano version that will include postcss-calc that supports lh unit size
      // @supports (line-height: 1lh) {
      //   --bullet-top: calc((1lh - var(--bullet-height)) / 2);
      // }
      top: var(--bullet-top, 0);
      & > span {
        vertical-align: middle;
      }
    }
  }

  &, .body-copy {
    --list-padding: 10px;
    @media @mq--XL {
      --list-padding: 12px;
    }
  }
  .body-copy-large {
    --list-padding: 12px;
    @media @mq--XL {
      --list-padding: 18px;
    }
  }
  .body-copy-small {
    --list-padding: 8px;
    @media @mq--XL {
      --list-padding: 10px;
    }
  }
}

// define size of bullet
ul:not(.type-checkmark) {
  > li {
    --bullet-height: var(--bullet-width);
  }

  &,
  .body-copy > &,
  > li.body-copy {
    --bullet-width: 6px;
    @media @mq--XL {
      --bullet-width: 7px;
    }
  }

  .body-copy-large > &,
  > li.body-copy-large {
    --bullet-width: 7px;
    @media @mq--XL {
      --bullet-width: 9px;
    }
  }

  .body-copy-small > &,
  > li.body-copy-small {
    --bullet-width: 5px;
    @media @mq--XL {
      --bullet-width: 6px;
    }
  }
}

// define size of checkmark
ul.type-checkmark {
  > li {
    --bullet-height: calc(var(--bullet-width) - 3px);
  }

  &,
  .body-copy > &,
  > li.body-copy {
    --bullet-width: 20px;
    @media @mq--MD-LG {
      --bullet-width: 22px;
    }
    @media @mq--XL {
      --bullet-width: 26px;
    }
  }

  .body-copy-large > &,
  > li.body-copy-large {
    --bullet-width: 26px;
    @media @mq--XL {
      --bullet-width: 32px;
      --bullet-height: calc(var(--bullet-width) - 4px);
    }
  }

  .body-copy-small > &,
  & > li.body-copy-small {
    --bullet-width: 18px;
    @media @mq--XL {
      --bullet-width: 20px;
    }
  }
}
