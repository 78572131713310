@import (reference) "./../../../../core/ui/dependencies.less";

.typo5 {
  hpe-note {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
    margin-top: 0;

    &:not(#add-specific) {
      border-color: var(--note-border-color);
      background: var(--note-bg-color);
      color: var(--note-fg-color);

      &.active, &:hover, &.highlight {
        background: var(--note-bg-active-color);
        color: var(--note-fg-active-color);
      }
    }
  }
}

.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --note-border-color: @primary9;
  --note-bg-color: transparent;
  --note-fg-color: @primary9;
  --note-bg-active-color: @primary9;
  --note-fg-active-color: @primary1;
}

body,
.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --note-border-color: @primary1;
  --note-bg-color: @primary9;
  --note-fg-color: @primary1;
  --note-bg-active-color: @primary1;
  --note-fg-active-color: @primary9;
}
