@import (less) "@exadel/esl/modules/esl-image/core.mixin.less";

.esl-image-init(smart-image);

.img-container {
  smart-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
  }
  smart-image[mode="origin"] {
    position: relative;
    width: auto;
    height: auto;
  }
  &.img-noratio {
    smart-image {
      position: relative;
    }
  }

  smart-image[lazy] {
    opacity: 0;
    &[ready] {
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}
