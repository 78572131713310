[cta-group-mixin] {
  .cta.cta {
    width: var(--width);
    height: var(--height);
  }

  .cta > :is(a, button) {
    width: 100%;

    &:not(.hpe-arrow-link) {
      height: 100%;
    }
  }
}
