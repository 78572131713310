.buildShortArrowIcon(@fill: @primary1) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471.2 471.2"><path fill="@{fill}" d="M456.57 248.48a18.45 18.45 0 0 0 5.43-12.9 17.88 17.88 0 0 0-5.43-12.9L340.08 106.19a18.34 18.34 0 0 0-25.93 25.93l85.26 85.13H27.53a18.33 18.33 0 1 0 0 36.66h371.88l-85.26 85.26a18.34 18.34 0 0 0 25.93 25.93z"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.buildLongArrowIcon(@fill: @primary1) {
  @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="@{fill}" d="M506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284L443.558 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"/></svg>');
  background: url('data:image/svg+xml,@{content}') no-repeat;
}

.arrow-icon {
  .buildShortArrowIcon(@primary1);
}

.arrow-icon-orange {
  .buildShortArrowIcon(@primary20);
}

.arrow-icon-light-purple {
  .buildShortArrowIcon(@primary21);
}

.arrow-icon-medium-blue {
  .buildShortArrowIcon(@primary18);
}

.arrow-icon-medium-purple {
  .buildShortArrowIcon(@primary22);
}

.arrow-icon-dark-blue {
  .buildShortArrowIcon(@primary19);
}

.arrow-icon-light-blue {
  .buildShortArrowIcon(@primary17);
}

.arrow-icon-yellow {
  .buildShortArrowIcon(@primary23);
}

.arrow-icon-black {
  .buildShortArrowIcon(@primary10);
}

.white-arrow-icon {
  .buildShortArrowIcon(@primary9);
}

.grey-arrow-icon {
  .buildShortArrowIcon(@primary16);
}

.long-arrow-icon {
  .buildLongArrowIcon(@primary9)
}
