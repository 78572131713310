@import (inline) "./libs/owl.carousel.css";
@import (inline) "./libs/owl.theme.default.css";

.owl-carousel {
  touch-action: pan-y;
}

@import "_carousel-navs";
@import "_carousel-default";
@import "_carousel-slider";
@import "_carousel-a11y";
