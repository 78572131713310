.hpe-footnotes {
  .esl-footnotes-items {
    margin-bottom: 40px;
  }

  &.disable-top-line {
    .esl-footnotes-item:first-child {
      border-top: 0;
    }
  }

  .esl-footnotes-item {
    &:first-child {
      border-top: 1px solid @secondary9;
      padding-top: 40px;
    }

    display: flex;
    margin-bottom: 5px;
    max-width: 100%;
    overflow: hidden; //fix mobile issue in Japan

    .dark-theme &, body .dark-theme-prespecific &, html body .dark-theme-specific & {
      .esl-footnotes-index {
        color: @primary9;
      }
      .esl-footnotes-back-to-note {
        color: @primary9;
        .buildReturnBackIcon(transparent, @primary9);
        border: 1px solid @primary9;
        &:hover {
          color: @primary1;
          .buildReturnBackIcon(@primary9, @primary1);
        }
      }
    }

    &, body .light-theme-prespecific &, html body .light-theme-specific & {
      .esl-footnotes-index {
        color: @primary10;
        position: relative;
        top: -3px;
        padding-right: 3px;
        font-size: 12px;
        white-space: nowrap;
      }
      .esl-footnotes-back-to-note {
        display: inline-block;
        border-radius: 6px;
        border: 1px solid @primary1;
        cursor: pointer;
        height: 12px;
        width: 15px;
        line-height: 18px;
        margin-left: .2em;

        .buildReturnBackIcon(transparent, @primary1);
        &:hover {
          .buildReturnBackIcon(@primary1, @primary9);
        }
      }
    }

    .esl-back-to-note-wrapper {
      white-space: nowrap;
    }

    &.multiple-items {
      margin: 1em 0;
      flex-direction: column;
      .esl-footnotes-index {
        top: 0;
      }

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    // to prevent the back icon from being transferred to the next line
    .esl-footnotes-text > p:last-child {
      display: inline;
    }
  }

  .buildReturnBackIcon(@bgColor, @color) {
    @content: escape('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35"><path fill="@{color}" d="M.4 9.7l12.7 9.2V.5z"/><path fill="@{color}" d="M26 34.5H5.5v-5.2H26c4.7 0 8.5-3.8 8.5-8.5s-3.8-8.5-8.5-8.5H9.4V7.2H26c7.5 0 13.7 6.1 13.7 13.7S33.5 34.5 26 34.5z"/></svg>');
    background: @bgColor url('data:image/svg+xml,@{content}') center center no-repeat;
    background-size: 7px;
  }
}

@import "./hpe-note.less";

@media print {
  .esl-back-to-note-wrapper {
    display: none;
  }
}
