[hpe-chat]:not([hpe-chat-status]),
.hpe-chat-scope:not([hpe-chat-status]) {
  &.hpe-chat-hide-offline, & .hpe-chat-hide-offline {
    display: none;
  }
}
[hpe-chat-status='online'] {
  &.hpe-chat-hide-online, & .hpe-chat-hide-online {
    display: none;
  }
}
[hpe-chat-status='offline'] {
  &.hpe-chat-hide-offline, & .hpe-chat-hide-offline {
    display: none;
  }
}
