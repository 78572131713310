//PLAY BUTTON
.embedded-video {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
  background: black;

  .play-button {
    background: none;
    border: none;
    padding: 0;

    z-index: 2;
    transition: opacity 200ms;
    &.off {
      opacity: 0;
    }
  }

  //ABSOLUTE CONTAINER
  .absolute-container {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
  }

  .centered-wrapper {
    position: relative;

    width: 100%;
    height: auto;
    max-width: 1250px;
    max-height: 100%;

    &::before {
      display: block;
      content: '';
      padding-top: @ratio-16-9;
    }
  }

  //PLAYER WRAPPER
  .player-wrapper {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    opacity: 0;
    visibility: hidden;
  }
  .embedded-player {
    width: 100%;
    height: 100%;
  }

  //VIDEO BACKGROUND (IMG, VIDEO)
  .video-bkg {
    transition: opacity 300ms;
    z-index: 1;
    position: relative;
  }

  //PLAY BUTTON POSITIONS
  @cardWidth: 600px;
  .play-button.icon-right,
  &.icon-right .play-button {
    margin-left: @cardWidth/2;
  }
  .play-button.icon-left,
  &.icon-left .play-button {
    margin-left: -@cardWidth/2;
  }

  //ANIMATIONS
  &.video-showing-animation {
    .video-bkg {
      opacity: 0;
      z-index: 0;
    }
    .play-button {
      opacity: 0;
    }
    .player-wrapper {
      visibility: visible;
    }
  }

  //CLOSE BUTTON
  .close-button {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 10px;
    right: -45px;
    display: none;
    &::before, &::after {
      position: absolute;
      left: 12px;
      top: -3px;
      content: '';
      height: 33.35px;
      width: 2px;
      background-color: white;
      opacity: 0.9;
    }
    &:hover {
      &::before, &::after {
        opacity: 1;
      }
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media @mq--XS-MD {
      right: -40px;
    }
    @media @mq--XS {
      right: 0;
      top: -30px;
      width: 20px;
      height: 20px;
      &::before, &::after {
        left: 9px;
        top: -3px;
        height: 26.3px;
      }
    }
  }

  //SHARE
  esl-share {
    --icon-share-size: 25px;
    position: absolute;
    top: 55px;
    right: -47px;
    color: #fff;

    @media @mq--SM-MD {
      right: -43px;
    }

    @media @mq--XS {
      --icon-share-size: 20px;
      top: -29px;
      right: 35px;
    }

    .icon-share-global {
      opacity: 0.9;
    }

    &:hover .icon-share-global {
      opacity: 1;
    }
  }

  //RESPONSIVE STYLES
  @media @mq--XS-MD {
    height: 420px;
    @cardWidth: 400px;
    .play-button.icon-right,
    &.icon-right .play-button {
      margin-left: @cardWidth/2;
    }
    .play-button.icon-left,
    &.icon-left .play-button {
      margin-left: -@cardWidth/2;
    }
  }

  @media @mq--XS {
    height: 280px;
    .play-button.icon-right,
    &.icon-right .play-button {
      margin-left: 0;
    }
    .play-button.icon-left,
    &.icon-left .play-button {
      margin-left: 0;
    }
  }
}

.img-container > .embedded-video {
  [class*="img-container-"]& {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
  }
  .close-button {
    z-index: 1;
  }
}
