@plugin "./plugins/utils";

.setFont (@size, @height) when not(ispixel(@height)), not(ispixel(@size)) {
  font-size: @size;
  line-height: @height;
  // TODO: remove this when the minimum supported version of Safari is >=16.3
  --lh: @height;
}

.setFont (@size, @height) when (ispixel(@height)) and (ispixel(@size)) {
  font-size: @size;
  line-height: unit((@height / @size), em);
  // TODO: remove this when the minimum supported version of Safari is >=16.3
  --lh: unit((@height / @size), em);
  // Does not set font-size directly, used as base font size for overrides instead
  --f-base-size: unit(@size, px);
}

.setFontByList(@list) {
  .setFont(extract(@list, 1), extract(@list, 2));
}

.installFonts(@var) {
  @resolutions: lg, md, sm;

  @xl: ~"@{var}-xl";
  .setFontByList(@@xl);

  .loop(@i: length(@resolutions)) when (@i > 0) {
    @screenSize: extract(@resolutions, @i);
    @varName: ~"@{var}-@{screenSize}";
    @mqVarEnd: uppercase(@screenSize);
    @mqVar: ~"mq--@{mqVarEnd}";
    @media @@mqVar {
      .setFontByList(@@varName);
    }
    .loop((@i - 1));
  } .loop;

  @xs: ~"@{var}-xs";
  @media @mq--XS {
    .setFontByList(@@xs);
  }
}

.calcExtraSpace(@fontConfig) {
  @lineHeight: extract(@fontConfig, 2) / extract(@fontConfig, 1);
  @extraSpace: (@lineHeight - 1) / 2 + 0.18em;
}

.dropExtraSpace(@fontConfig) {
  .calcExtraSpace(@fontConfig);
  margin: -@extraSpace 0;
}

.dropTopExtraSpace(@fontConfig) {
  .calcExtraSpace(@fontConfig);
  margin-top: -@extraSpace;
}

.dropBottomExtraSpace(@fontConfig) {
  .calcExtraSpace(@fontConfig);
  margin-bottom: -@extraSpace;
}

.setBottomMargin(@value, @fontConfig) {
  .calcExtraSpace(@fontConfig);
  margin-bottom: ~"calc(@{value} - @{extraSpace})";
}

.setTopMargin(@value, @fontConfig) {
  .calcExtraSpace(@fontConfig);
  margin-top: ~"calc(@{value} - @{extraSpace})";
}
.setBottomPadding(@value, @fontConfig) {
  .calcExtraSpace(@fontConfig);
  padding-bottom: ~"calc(@{value} - @{extraSpace})";
}

.setTopPadding(@value, @fontConfig) {
  .calcExtraSpace(@fontConfig);
  padding-top: ~"calc(@{value} - @{extraSpace})";
}

// Legacy mixin
.setMaxLines(@value, @fontConfig: false) when not(@fontConfig = false) {
  max-height: @value * extract(@fontConfig, 2);
}

// UC (custom property based mixin)
.setMaxLines(@value, @fontConfig: false) when (@fontConfig = false) {
  // TODO: remove this when the minimum supported version of Safari is >=16.3
  max-height: ~"calc(var(--lh) * @{value})";
  // TODO: uncomment after updating cssnano version that will include postcss-calc that supports lh unit size
  // @supports (line-height: 1lh) {
  //   max-height: ~"calc(1lh * @{value})";
  // }
}

.setBulletTop(@fontConfig) {
  --line-height: extract(@fontConfig, 2);
  --bullet-top: calc((var(--line-height) - var(--bullet-height)) / 2);
}

//-----------------------------------
// Crop extra spaces
//-----------------------------------
._crop-top-extra-space {
  // TODO: remove this when the minimum supported version of Safari is >=16.3
  margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
  // TODO: uncomment after updating cssnano version that will include postcss-calc that supports lh unit size
  // @supports (line-height: 1lh) {
  //   margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (1lh - 1em) / 2) * var(--f-crop-enable, 0));
  // }
}
.crop-top-extra-space(@mode) when (@mode = true) {
  --_ratioT: var(--f-extra-space-top-ratio-cap, 0);
  ._crop-top-extra-space();
}
.crop-top-extra-space {
  --_ratioT: var(--f-extra-space-top-ratio, 0);
  ._crop-top-extra-space();
}

._crop-bottom-extra-space() {
  // TODO: remove this when the minimum supported version of Safari is >=16.3
  margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
  // TODO: uncomment after updating cssnano version that will include postcss-calc that supports lh unit size
  // @supports (line-height: 1lh) {
  //   margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (1lh - 1em) / 2) * var(--f-crop-enable, 0));
  // }
}
.crop-bottom-extra-space(@mode) when (@mode = true) {
  --_ratioB: var(--f-extra-space-bottom-ratio-cap, 0);
  ._crop-bottom-extra-space();
}
.crop-bottom-extra-space() {
  --_ratioB: var(--f-extra-space-bottom-ratio, 0);
  ._crop-bottom-extra-space();
}

.crop-extra-space(@mode) when (@mode = true) {
  .crop-top-extra-space(true);
  .crop-bottom-extra-space(true);
}
.crop-extra-space {
  .crop-top-extra-space();
  .crop-bottom-extra-space();
}
