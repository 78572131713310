@import (reference) "./../icons/bookmark-icon.less";

.bookmark-btn {
  padding: 0;
  border: none;
  background-color: @primary9;

  .bookmark-icon();

  &:hover,
  &.active {
    .active-bookmark-icon();
  }
}
