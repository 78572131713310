.alert-success {
  color: #2d4821 !important;
}

.alert-info {
  color: #214c62 !important;
}

.alert-warning {
  color: #6c4a00 !important;
  background-color: #f9f1c6;
}

.alert-danger {
  color: #d2322d !important;
  &:hover {
    color: #a82824
  }
}

.sr-only {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
}
