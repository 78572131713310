@import (reference) "../../../../../ui/dependencies.less";

smart-media[media-type="brightcove"] {
  .video-js {
    &.vjs-layout-small .vjs-audio-button {
      display: inline-block;
    }
  }

  .light-theme & {
    .video-js {
      span {
        color: @primary9;
      }
    }
  }
}
