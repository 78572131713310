esl-scrollbar {
  display: inline-block;
  position: absolute;
  cursor: default;
  overflow: hidden;
}
esl-scrollbar[inactive] {
  visibility: hidden;
}
esl-scrollbar.page-scrollbar {
  position: fixed;
}
esl-scrollbar .scrollbar-track {
  position: relative;
  border-radius: 3px;
  background: #e7e7e7;
}
esl-scrollbar .scrollbar-thumb {
  position: absolute;
  cursor: pointer;
  border-radius: 3px;
  background: #b5b5b5;
}
esl-scrollbar .scrollbar-thumb::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
}
esl-scrollbar[dragging] .scrollbar-thumb {
  background: #9a9a9a;
}
esl-scrollbar:not([horizontal]) {
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 5px;
}
esl-scrollbar:not([horizontal]) .scrollbar-track {
  top: 0;
  width: 6px;
  height: 100%;
}
esl-scrollbar:not([horizontal]) .scrollbar-thumb {
  width: 100%;
  height: 33.33%;
  min-height: 10px;
  touch-action: pan-x;
}
esl-scrollbar[horizontal] {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 0;
}
esl-scrollbar[horizontal] .scrollbar-track {
  bottom: 0;
  height: 6px;
  width: 100%;
}
esl-scrollbar[horizontal] .scrollbar-thumb {
  height: 100%;
  width: 33.33%;
  min-width: 10px;
  touch-action: pan-y;
}
.esl-scrollable-content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.esl-scrollable-content::-webkit-scrollbar {
  display: none;
}
.esl-scrollbar {
  touch-action: none;
}
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.drop-main-page-scroll {
  overflow: hidden;
  max-height: 100vh;
}
.drop-main-page-scroll.ios {
  position: fixed;
}
@media print {
  * {
    color-adjust: exact;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  #HpeChatEmailButton,
  #drift-frame-controller,
  .hpe-drift-icon {
    display: none !important;
  }
}
.img-container {
  position: relative;
}
.img-container > img {
  width: 100%;
}
.img-container > img:is(.img-stretch, .img-cover, .img-contain) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
img.img-cover {
  object-fit: cover;
}
img.img-contain {
  object-fit: contain;
}
picture.img-container {
  display: block;
}
.img-fade {
  opacity: 0;
}
.img-fade.img-container-loaded,
.img-container-loaded .img-fade {
  opacity: 1;
  transition: opacity 0.4s;
}
img {
  text-align: center;
  vertical-align: middle;
}
.img-container {
  background: url("../images/loader.gif") 50% 50% no-repeat transparent;
  background-size: 40px;
  box-shadow: inset 1px 1px 0 0 #eee, inset -1px -1px 0 0 #eee;
}
.img-container::after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
}
.img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.img-container[esl-image-container] > img {
  opacity: 0;
}
.img-container[esl-image-container].img-container-loaded > img {
  opacity: 1;
  transition: opacity 0.4s;
}
.img-container.img-noratio img {
  position: relative;
}
.img-container-loaded {
  background: none;
  border: none;
  box-shadow: none;
}
.img-container-1-1::after {
  padding-top: 100%;
}
.img-container-3-1::after {
  padding-top: 33.33333333%;
}
.img-container-6-9::after {
  padding-top: 150%;
}
.img-container-9-6::after {
  padding-top: 66.66666667%;
}
.img-container-16-9::after {
  padding-top: 56.25%;
}
.img-container-16-10::after {
  padding-top: 62.5%;
}
.img-container-4-3::after {
  padding-top: 75%;
}
.img-container-26-9::after {
  padding-top: 34.61538462%;
}
smart-image {
  display: block;
  width: 100%;
  padding: 0;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
smart-image[lazy]:not([ready]) {
  min-height: 1px;
}
smart-image[mode='origin'] {
  display: inline-block;
  width: auto;
  height: auto;
}
smart-image[mode='cover'] {
  height: 100%;
}
smart-image[mode='save-ratio'] {
  height: 0;
}
smart-image[mode='inner-svg'] svg {
  max-width: 100%;
  max-height: 100%;
}
smart-image .inner-image {
  max-width: 100%;
}
smart-image::after {
  display: none;
  content: attr(alt);
}
smart-image[error]::after {
  display: inline-block;
}
smart-image[loaded]::after {
  display: none;
}
smart-image[mode='fit'][error]::after,
smart-image[mode='origin'][error]::after {
  display: none;
}
smart-image.bg-flex {
  background-size: 100% 100%;
}
smart-image.bg-inscribe {
  background-size: contain;
}
smart-image.bg-v-top {
  background-position-y: top;
}
smart-image.bg-v-center {
  background-position-y: center;
}
smart-image.bg-v-bottom {
  background-position-y: bottom;
}
smart-image.bg-h-left {
  background-position-x: left;
}
smart-image.bg-h-center {
  background-position-x: center;
}
smart-image.bg-h-right {
  background-position-x: right;
}
.img-container smart-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
}
.img-container smart-image[mode="origin"] {
  position: relative;
  width: auto;
  height: auto;
}
.img-container.img-noratio smart-image {
  position: relative;
}
.img-container smart-image[lazy] {
  opacity: 0;
}
.img-container smart-image[lazy][ready] {
  opacity: 1;
  transition: opacity 0.4s;
}
smart-media {
  display: block;
}
smart-media .esl-media-inner {
  display: block;
  width: 100%;
}
smart-media .esl-media-inner:not(audio) {
  height: 100%;
}
smart-media iframe.esl-media-inner {
  border: 0;
}
smart-media.esl-media-bg {
  position: relative;
}
smart-media.esl-media-bg .esl-media-inner {
  z-index: -1;
  pointer-events: none;
}
smart-media.esl-media-bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
smart-media[fill-mode='cover'],
smart-media[fill-mode='inscribe'] {
  position: relative;
  overflow: hidden;
  height: 100%;
}
smart-media[fill-mode='cover'] .esl-media-inner,
smart-media[fill-mode='inscribe'] .esl-media-inner {
  position: absolute;
  top: var(--esl-media-y-offset, 50%);
  left: var(--esl-media-x-offset, 50%);
  transform: translate(calc(var(--esl-media-x-offset, 50%) * -1), calc(var(--esl-media-y-offset, 50%) * -1));
  min-width: 1px;
  min-height: 1px;
}
smart-media:not([wide])[fill-mode='cover'] .esl-media-inner,
smart-media[wide][fill-mode='inscribe'] .esl-media-inner {
  width: auto;
  height: 100%;
}
smart-media:not([wide])[fill-mode='inscribe'] .esl-media-inner,
smart-media[wide][fill-mode='cover'] .esl-media-inner {
  width: 100%;
  height: auto;
}
smart-media.esl-media-top {
  --esl-media-y-offset: 0%;
}
smart-media.esl-media-bottom {
  --esl-media-y-offset: 100%;
}
smart-media.esl-media-left {
  --esl-media-x-offset: 0%;
}
smart-media.esl-media-right {
  --esl-media-x-offset: 100%;
}
.img-container > smart-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
smart-media {
  background: #000 url('/apps/hpeweb-ui/images/loader.gif') 50% 50% no-repeat;
  background-size: 40px;
}
smart-media[ready] {
  background: #000;
}
smart-media.no-bg {
  background: transparent;
}
smart-media .vjs-menu-button .vjs-texttrack-settings {
  border-color: #01A982;
}
smart-media .vjs-control-text {
  display: none;
}
smart-media .vjs-dock-text {
  display: none;
}
smart-media .vjs-subs-caps-button .vjs-menu-content {
  overflow-x: hidden;
}
smart-media[media-type="bc-audio"] {
  height: 42px;
  width: 100%;
  background: #000 url('/apps/hpeweb-ui/images/loader.gif') 50% 50% no-repeat;
  background-size: 30px;
}
smart-media[media-type="bc-audio"]:not([ready]) .video-js {
  display: none;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-contextmenu-ui-menu,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-fullscreen-control,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-picture-in-picture-control,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-remaining-time,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-subs-caps-button,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-big-play-button,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-tech,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-poster,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-dock-text {
  display: none;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-duration,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-current-time,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-time-divider {
  display: block;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-loading-spinner {
  width: 25px;
  height: 25px;
  margin-top: -10px;
  border-width: 3px;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-control-bar {
  display: flex;
  transform: none;
  opacity: 1;
  height: 100%;
  padding-top: 6px;
  font-size: 12px;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-control-dock {
  display: flex;
  width: auto;
  text-overflow: ellipsis;
  pointer-events: none;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  z-index: 2;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control .vjs-progress-holder {
  margin: 0;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover .vjs-progress-holder,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover .vjs-play-progress,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover .vjs-load-progress,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover .vjs-tooltip-progress-bar,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-progress-control:hover .vjs-load-progress div {
  height: 6px;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-current-time {
  padding-right: 0;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-duration {
  padding-left: 0;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic).vjs-mouse .vjs-volume-panel:not(.vjs-hover) {
  width: 3em;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic).vjs-mouse .vjs-volume-panel.vjs-hover {
  width: 9.5em;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic).vjs-mouse .vjs-volume-control {
  display: block;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic):not(.vjs-mouse) .vjs-volume-panel {
  width: 9.5em;
}
@media screen and (max-width: 450px) {
  smart-media[media-type="bc-audio"] .video-js:not(#speciffic):not(.vjs-mouse) .vjs-volume-panel {
    width: 8em;
  }
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic):not(.vjs-mouse) .vjs-volume-control {
  display: block;
  opacity: 1;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-play-progress,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-volume-level {
  background-color: #FF8D6D;
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-play-progress:before,
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-volume-level:before {
  content: '';
}
smart-media[media-type="bc-audio"] .video-js:not(#speciffic) .vjs-time-tooltip {
  top: 0.7em;
}
smart-media[media-type="bc-audio"] .vjs-error-display {
  overflow: hidden;
}
smart-media[media-type="bc-audio"] .vjs-error-display .vjs-errors-content-container {
  display: none;
}
smart-media[media-type="bc-audio"] .vjs-error-display .vjs-errors-ok-button-container {
  bottom: 5px;
}
smart-media[media-type="brightcove"] .video-js.vjs-layout-small .vjs-audio-button {
  display: inline-block;
}
.light-theme smart-media[media-type="brightcove"] .video-js span {
  color: #FFFFFF;
}
hpe-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #32DAC8;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
}
hpe-avatar.with-image {
  background-color: transparent;
}
hpe-avatar .hpe-avatar-text {
  font-size: 40px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 40px;
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #000000;
}
.hpe-footnotes .esl-footnotes-items {
  margin-bottom: 40px;
}
.hpe-footnotes.disable-top-line .esl-footnotes-item:first-child {
  border-top: 0;
}
.hpe-footnotes .esl-footnotes-item {
  display: flex;
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
}
.hpe-footnotes .esl-footnotes-item:first-child {
  border-top: 1px solid #E5E5E5;
  padding-top: 40px;
}
.dark-theme .hpe-footnotes .esl-footnotes-item .esl-footnotes-index,
body .dark-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-index,
html body .dark-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-index {
  color: #FFFFFF;
}
.dark-theme .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note,
body .dark-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note,
html body .dark-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note {
  color: #FFFFFF;
  background: transparent url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2040%2035%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M.4%209.7l12.7%209.2V.5z%22/%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M26%2034.5H5.5v-5.2H26c4.7%200%208.5-3.8%208.5-8.5s-3.8-8.5-8.5-8.5H9.4V7.2H26c7.5%200%2013.7%206.1%2013.7%2013.7S33.5%2034.5%2026%2034.5z%22/%3E%3C/svg%3E') center center no-repeat;
  background-size: 7px;
  border: 1px solid #FFFFFF;
}
.dark-theme .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover,
body .dark-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover,
html body .dark-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover {
  color: #01A982;
  background: #FFFFFF url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2040%2035%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M.4%209.7l12.7%209.2V.5z%22/%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M26%2034.5H5.5v-5.2H26c4.7%200%208.5-3.8%208.5-8.5s-3.8-8.5-8.5-8.5H9.4V7.2H26c7.5%200%2013.7%206.1%2013.7%2013.7S33.5%2034.5%2026%2034.5z%22/%3E%3C/svg%3E') center center no-repeat;
  background-size: 7px;
}
.hpe-footnotes .esl-footnotes-item .esl-footnotes-index,
body .light-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-index,
html body .light-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-index {
  color: #000000;
  position: relative;
  top: -3px;
  padding-right: 3px;
  font-size: 12px;
  white-space: nowrap;
}
.hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note,
body .light-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note,
html body .light-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #01A982;
  cursor: pointer;
  height: 12px;
  width: 15px;
  line-height: 18px;
  margin-left: 0.2em;
  background: transparent url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2040%2035%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M.4%209.7l12.7%209.2V.5z%22/%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M26%2034.5H5.5v-5.2H26c4.7%200%208.5-3.8%208.5-8.5s-3.8-8.5-8.5-8.5H9.4V7.2H26c7.5%200%2013.7%206.1%2013.7%2013.7S33.5%2034.5%2026%2034.5z%22/%3E%3C/svg%3E') center center no-repeat;
  background-size: 7px;
}
.hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover,
body .light-theme-prespecific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover,
html body .light-theme-specific .hpe-footnotes .esl-footnotes-item .esl-footnotes-back-to-note:hover {
  background: #01A982 url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2040%2035%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M.4%209.7l12.7%209.2V.5z%22/%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M26%2034.5H5.5v-5.2H26c4.7%200%208.5-3.8%208.5-8.5s-3.8-8.5-8.5-8.5H9.4V7.2H26c7.5%200%2013.7%206.1%2013.7%2013.7S33.5%2034.5%2026%2034.5z%22/%3E%3C/svg%3E') center center no-repeat;
  background-size: 7px;
}
.hpe-footnotes .esl-footnotes-item .esl-back-to-note-wrapper {
  white-space: nowrap;
}
.hpe-footnotes .esl-footnotes-item.multiple-items {
  margin: 1em 0;
  flex-direction: column;
}
.hpe-footnotes .esl-footnotes-item.multiple-items .esl-footnotes-index {
  top: 0;
}
.hpe-footnotes .esl-footnotes-item.multiple-items:first-child {
  margin-top: 0;
}
.hpe-footnotes .esl-footnotes-item.multiple-items:last-child {
  margin-bottom: 0;
}
.hpe-footnotes .esl-footnotes-item .esl-footnotes-text > p:last-child {
  display: inline;
}
.hpe-note-wrapper {
  white-space: nowrap;
}
hpe-note {
  display: inline-block;
  position: relative;
  margin: 0.4em 0.2em 0;
  border: 1px solid #01A982;
  border-radius: 6px;
  cursor: pointer;
  padding: 1px 3px;
  min-width: 15px;
  vertical-align: text-top;
  text-align: center;
  line-height: 9px !important;
  font-family: 'Metric Semibold';
  font-size: 11px !important;
  font-style: normal;
}
.firefox hpe-note {
  padding: 0 3px 2px;
}
.tag-h1 hpe-note,
h1 hpe-note {
  margin-top: 1.7em;
}
.tag-h2 hpe-note,
h2 hpe-note {
  margin-top: 1.4em;
}
.tag-h2-small hpe-note {
  margin-top: 1.2em;
}
.tag-h3 hpe-note,
h3 hpe-note {
  margin-top: 1em;
}
.tag-h4 hpe-note,
h4 hpe-note {
  margin-top: 0.7em;
}
.tag-h5 hpe-note,
h5 hpe-note {
  margin-top: 0.5em;
}
.body-copy-large hpe-note {
  margin-top: 0.6em;
}
.tagline-small hpe-note,
.body-copy-xsmall hpe-note {
  margin-top: 0.3em;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .tag-h1 hpe-note,
  h1 hpe-note {
    margin-top: 1.4em;
  }
  .tag-h2 hpe-note,
  h2 hpe-note {
    margin-top: 1.1em;
  }
  .tag-h2-small hpe-note {
    margin-top: 0.8em;
  }
  .tag-h3 hpe-note,
  h3 hpe-note {
    margin-top: 0.7em;
  }
  .body-copy-large hpe-note {
    margin-top: 0.5em;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .tag-h1 hpe-note,
  h1 hpe-note {
    margin-top: 1em;
  }
  .tag-h2 hpe-note,
  h2 hpe-note {
    margin-top: 0.9em;
  }
  .tag-h2-small hpe-note {
    margin-top: 0.8em;
  }
  .tag-h3 hpe-note,
  h3 hpe-note {
    margin-top: 0.6em;
  }
  .tag-h4 hpe-note,
  h4 hpe-note {
    margin-top: 0.5em;
  }
  .tag-h5 hpe-note,
  h5 hpe-note {
    margin-top: 0.4em;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .tag-h1 hpe-note,
  h1 hpe-note {
    margin-top: 0.7em;
  }
  .tag-h4 hpe-note,
  h4 hpe-note {
    margin-top: 0.5em;
  }
  .body-copy-xsmall hpe-note {
    margin-top: 0;
  }
  .body-copy-large hpe-note {
    margin-top: 0.4em;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .tag-h2 hpe-note,
  h2 hpe-note {
    margin-top: 0.7em;
  }
  .tag-h2-small hpe-note {
    margin-top: 0.7em;
  }
  .tag-h3 hpe-note,
  h3 hpe-note {
    margin-top: 0.5em;
  }
  .tag-h5 hpe-note,
  h5 hpe-note {
    margin-top: 0.4em;
  }
}
hpe-note,
.light-theme hpe-note,
body .light-theme-prespecific hpe-note,
html body .light-theme-specific hpe-note {
  border-color: #01A982;
  background: #FFFFFF;
  color: #01A982;
}
hpe-note.active,
.light-theme hpe-note.active,
body .light-theme-prespecific hpe-note.active,
html body .light-theme-specific hpe-note.active,
hpe-note:hover,
.light-theme hpe-note:hover,
body .light-theme-prespecific hpe-note:hover,
html body .light-theme-specific hpe-note:hover,
hpe-note.highlight,
.light-theme hpe-note.highlight,
body .light-theme-prespecific hpe-note.highlight,
html body .light-theme-specific hpe-note.highlight {
  background: #01A982;
  color: #FFFFFF;
}
.dark-theme hpe-note,
body .dark-theme-prespecific hpe-note,
html body .dark-theme-specific hpe-note,
[class*="style-bg-"]:not(.style-bg-none):not([class*="style-bg-gray"]):not([class*="style-bg-grey"]):not(.style-bg-white):not(.style-bg-dark-steel) hpe-note {
  border-color: #FFFFFF;
  background: transparent;
  color: #FFFFFF;
}
.dark-theme hpe-note.active,
body .dark-theme-prespecific hpe-note.active,
html body .dark-theme-specific hpe-note.active,
[class*="style-bg-"]:not(.style-bg-none):not([class*="style-bg-gray"]):not([class*="style-bg-grey"]):not(.style-bg-white):not(.style-bg-dark-steel) hpe-note.active,
.dark-theme hpe-note:hover,
body .dark-theme-prespecific hpe-note:hover,
html body .dark-theme-specific hpe-note:hover,
[class*="style-bg-"]:not(.style-bg-none):not([class*="style-bg-gray"]):not([class*="style-bg-grey"]):not(.style-bg-white):not(.style-bg-dark-steel) hpe-note:hover,
.dark-theme hpe-note.highlight,
body .dark-theme-prespecific hpe-note.highlight,
html body .dark-theme-specific hpe-note.highlight,
[class*="style-bg-"]:not(.style-bg-none):not([class*="style-bg-gray"]):not([class*="style-bg-grey"]):not(.style-bg-white):not(.style-bg-dark-steel) hpe-note.highlight {
  background: #FFFFFF;
  color: #01A982;
}
.typo4 hpe-note {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
  margin-top: 0;
}
.typo4 h1 hpe-note,
.typo4 .tag-h1 hpe-note {
  margin-top: 0.4em;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h1 hpe-note,
  .typo4 .tag-h1 hpe-note {
    margin-top: 0.5em;
  }
}
@media (min-width: 1200px) {
  .typo4 h1 hpe-note,
  .typo4 .tag-h1 hpe-note {
    margin-top: 0.7em;
  }
}
.typo4 h1.xlarge hpe-note,
.typo4 .tag-h1.xlarge hpe-note {
  margin-top: 0.5em;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h1.xlarge hpe-note,
  .typo4 .tag-h1.xlarge hpe-note {
    margin-top: 1.2em;
  }
}
@media (min-width: 1200px) {
  .typo4 h1.xlarge hpe-note,
  .typo4 .tag-h1.xlarge hpe-note {
    margin-top: 1.95em;
  }
}
.typo4 h2 hpe-note,
.typo4 .tag-h2 hpe-note {
  margin-top: 0.4em;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h2 hpe-note,
  .typo4 .tag-h2 hpe-note {
    margin-top: 0.5em;
  }
}
@media (min-width: 1200px) {
  .typo4 h2 hpe-note,
  .typo4 .tag-h2 hpe-note {
    margin-top: 0.6em;
  }
}
.typo4 h2.small hpe-note,
.typo4 .tag-h2.small hpe-note {
  margin-top: 0.2em;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h2.small hpe-note,
  .typo4 .tag-h2.small hpe-note {
    margin-top: 0.35em;
  }
}
@media (min-width: 1200px) {
  .typo4 h2.small hpe-note,
  .typo4 .tag-h2.small hpe-note {
    margin-top: 0.5em;
  }
}
.typo4 h3 hpe-note,
.typo4 .tag-h3 hpe-note {
  margin-top: 0.2em;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h3 hpe-note,
  .typo4 .tag-h3 hpe-note {
    margin-top: 0.35em;
  }
}
@media (min-width: 1200px) {
  .typo4 h3 hpe-note,
  .typo4 .tag-h3 hpe-note {
    margin-top: 0.5em;
  }
}
.typo4 h4 hpe-note,
.typo4 .tag-h4 hpe-note {
  margin-top: 0.3em;
}
@media (min-width: 1200px) {
  .typo4 h4 hpe-note,
  .typo4 .tag-h4 hpe-note {
    margin-top: 0.4em;
  }
}
.typo4 h5 hpe-note,
.typo4 .tag-h5 hpe-note {
  margin-top: 0.2em;
}
@media (min-width: 1200px) {
  .typo4 h5 hpe-note,
  .typo4 .tag-h5 hpe-note {
    margin-top: 0.3em;
  }
}
.typo4 .body-copy hpe-note {
  margin-top: 0.2em;
}
.typo4 .body-copy-small hpe-note {
  margin-top: 0.2em;
}
.typo4 .body-copy-large hpe-note {
  margin-top: 0.2em;
}
@media (min-width: 1200px) {
  .typo4 .body-copy-large hpe-note {
    margin-top: 0.4em;
  }
}
.typo5 hpe-note {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
  margin-top: 0;
}
.typo5 hpe-note:not(#add-specific) {
  border-color: var(--note-border-color);
  background: var(--note-bg-color);
  color: var(--note-fg-color);
}
.typo5 hpe-note:not(#add-specific).active,
.typo5 hpe-note:not(#add-specific):hover,
.typo5 hpe-note:not(#add-specific).highlight {
  background: var(--note-bg-active-color);
  color: var(--note-fg-active-color);
}
.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --note-border-color: #FFFFFF;
  --note-bg-color: transparent;
  --note-fg-color: #FFFFFF;
  --note-bg-active-color: #FFFFFF;
  --note-fg-active-color: #01A982;
}
body,
.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --note-border-color: #01A982;
  --note-bg-color: #FFFFFF;
  --note-fg-color: #01A982;
  --note-bg-active-color: #01A982;
  --note-fg-active-color: #FFFFFF;
}
@media not print {
  .esl-note-link {
    border: none !important;
  }
  .esl-note-link,
  .esl-note-link:hover {
    color: inherit !important;
  }
}
@media print {
  hpe-note {
    border: none;
    padding: 0;
    min-width: auto;
    color: inherit !important;
  }
}
@media print {
  .esl-back-to-note-wrapper {
    display: none;
  }
}
[esl-scroll-lock] {
  overflow: hidden !important;
}
[esl-scroll-lock]:not(html[esl-scroll-lock='native'])::-webkit-scrollbar {
  display: none !important;
}
html[esl-scroll-lock] body {
  overflow: hidden !important;
}
html[esl-scroll-lock='native'] {
  overflow-y: scroll !important;
}
html[esl-scroll-lock='native'][esl-scroll-lock-passive] {
  overflow-y: hidden !important;
}
html[esl-scroll-lock='native'] body {
  max-width: 100vw !important;
  max-height: 100vh !important;
}
html[esl-scroll-lock='pseudo'],
html[esl-scroll-lock='background'] {
  --s-lock-offset: 0;
  padding-right: var(--s-lock-offset);
}
html[esl-scroll-lock='pseudo']:not([esl-scroll-lock-passive])::after,
html[esl-scroll-lock='background']:not([esl-scroll-lock-passive])::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 1;
}
html[esl-scroll-lock='pseudo']:not([esl-scroll-lock-passive])::after {
  z-index: 999999;
}
esl-trigger {
  cursor: pointer;
}
esl-toggleable {
  display: block;
}
esl-toggleable:not(.open) {
  display: none;
}
esl-toggleable-dispatcher {
  display: none;
}
esl-panel {
  display: block;
  height: auto;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, visibility 0s linear 0.5s;
}
esl-panel.open {
  visibility: visible;
  max-height: none;
  transition: max-height 0.5s ease-in-out, visibility 0s linear;
}
@media print {
  esl-panel:not(.print-closed) {
    visibility: visible;
    max-height: none;
  }
}
esl-panel-group {
  display: block;
  box-sizing: content-box;
}
esl-panel-group.animate {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}
esl-panel-group[current-mode='tabs'] > esl-panel {
  opacity: 0;
  transition: max-height 0.5s ease-in-out, visibility 0s linear 0.5s, opacity 0.5s ease-in-out;
}
esl-panel-group[current-mode='tabs'] > esl-panel.open {
  opacity: 1;
  transition: max-height 0.5s ease-in-out, visibility 0s linear, opacity 0.5s ease-in-out;
}
esl-panel-group[current-mode='tabs'].esl-tabs-equal-height {
  display: grid;
  grid-template-areas: 'stack';
}
esl-panel-group[current-mode='tabs'].esl-tabs-equal-height > esl-panel {
  grid-area: stack;
  max-height: none;
}
@media screen {
  esl-panel-group:not(.animate) esl-panel:not(.animate):not(.open) * {
    visibility: hidden !important;
  }
}
esl-tab {
  cursor: pointer;
}
esl-tabs {
  display: block;
}
esl-tabs .esl-tab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
esl-tabs.center-alignment > .esl-tab-container > :first-child {
  margin-left: auto;
}
esl-tabs.center-alignment > .esl-tab-container > :last-child {
  margin-right: auto;
}
esl-tabs.right-alignment > .esl-tab-container > :first-child {
  margin-left: auto;
}
esl-tabs[scrollable]:not(.scrollable-disabled) {
  display: flex;
  align-items: center;
}
esl-tabs[scrollable]:not(.scrollable-disabled) > .esl-tab-container {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}
esl-tabs[scrollable]:not(.scrollable-disabled) > .esl-tab-container.esl-scrollable-content {
  overflow: auto;
}
esl-tabs[scrollable]:not(.scrollable-disabled) .arrow-prev,
esl-tabs[scrollable]:not(.scrollable-disabled) .arrow-next {
  flex: 0 0 auto;
}
esl-tabs[scrollable][has-scroll] .arrow-prev,
esl-tabs[scrollable][has-scroll] .arrow-next {
  display: block;
}
esl-tabs[scrollable][has-scroll] .arrow-prev[disabled],
esl-tabs[scrollable][has-scroll] .arrow-next[disabled] {
  opacity: 0.1;
  cursor: auto;
}
esl-tabs[scrollable] .arrow-prev,
esl-tabs[scrollable] .arrow-next {
  display: none;
  z-index: 2;
  width: 35px;
  height: 35px;
  background: none;
  border: none;
  opacity: 0.5;
  cursor: pointer;
  appearance: none;
}
esl-tabs[scrollable] .arrow-next {
  right: 0;
}
esl-tabs[scrollable] .arrow-prev {
  left: 0;
}
esl-tabs[scrollable] .icon-arrow-next,
esl-tabs[scrollable] .icon-arrow-prev {
  display: block;
  height: 35px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWdvbiBwb2ludHM9IjMxIDQwIDE2IDI1IDMxIDEwIDMzIDEyIDIwIDI1IDMzIDM4Ii8+PC9zdmc+) no-repeat;
}
esl-tabs[scrollable] .icon-arrow-next {
  transform: scaleX(-1);
}
[dir='rtl'] esl-tabs[scrollable] .icon-arrow-prev,
esl-tabs[scrollable][dir='rtl'] .icon-arrow-prev {
  transform: scaleX(-1);
}
[dir='rtl'] esl-tabs[scrollable] .icon-arrow-next,
esl-tabs[scrollable][dir='rtl'] .icon-arrow-next {
  transform: none;
}
[esl-drag-to-scroll].is-draggable {
  cursor: grab;
}
[esl-drag-to-scroll].dragging {
  cursor: grabbing;
  user-select: none;
  scroll-behavior: auto !important;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #fff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}
figure {
  margin: 0;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
#initial-focused-element {
  opacity: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}
small,
.small {
  font-size: 85%;
}
mark,
.mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777777;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}
.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
  content: '';
}
.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}
address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1600px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0%;
  }
}
table {
  background-color: transparent;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}
th {
  text-align: left;
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-link {
  color: #337ab7;
  font-weight: normal;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.fade {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
  visibility: hidden;
}
.collapse.in {
  display: block;
  visibility: visible;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease;
}
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav > li.disabled > a {
  color: #777777;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}
.nav > li > a > img {
  max-width: none;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.tab-content > .tab-pane {
  display: none;
  visibility: hidden;
}
.tab-content > .active {
  display: block;
  visibility: visible;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #777777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #337ab7;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}
.label-success {
  background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}
.label-info {
  background-color: #5bc0de;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}
.label-danger {
  background-color: #d9534f;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  -webkit-overflow-scrolling: touch;
}
.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
}
.modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 0.5;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.nav:before,
.nav:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.nav:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
  visibility: hidden !important;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.alert-success {
  color: #2d4821 !important;
}
.alert-info {
  color: #214c62 !important;
}
.alert-warning {
  color: #6c4a00 !important;
  background-color: #f9f1c6;
}
.alert-danger {
  color: #d2322d !important;
}
.alert-danger:hover {
  color: #a82824;
}
.sr-only {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
}
.disabled-focus *:focus,
.disabled-focus .btn-v3:focus,
.disabled-focus .btn-typo4:focus,
.disabled-focus #hf-footer .hf-links-list .hf-link:focus .link-text,
.disabled-focus .carousel-item:focus .focusable-mask,
.disabled-focus .filterlist-filter-item .checkbox-input:focus .focusable-mask,
.disabled-focus .hpe-product-gallery .item-type-video .btn-play:focus .focusable-mask {
  outline: none;
}
*:focus {
  outline: 2px solid #2AD2C9;
}
.btn {
  background: #01A982;
  border-radius: 0;
  font-family: 'Metric Regular', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  white-space: normal;
  width: auto;
  text-transform: uppercase;
  margin-top: 15px;
  padding: 10px 30px;
}
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-contact,
.btn-icon {
  min-height: 40px;
  min-width: 200px;
  max-width: 400px;
}
@media screen and (max-width: 767px) {
  .btn-primary,
  .btn-secondary,
  .btn-tertiary,
  .btn-contact,
  .btn-icon {
    min-width: 125px;
    max-width: 250px;
  }
}
.btn-primary {
  color: #000000;
  background: #01A982;
  border: solid 2px #01A982;
  border-collapse: collapse;
}
.btn-primary:hover,
.btn-primary.hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary .open > .dropdown-toggle.btn-default {
  background: #01A982;
  border-color: #01A982;
  box-shadow: none;
  color: #000000;
  outline: 2px solid #01A982;
}
.btn-primary.alpha {
  border-color: transparent;
  background: rgba(1, 169, 130, 0.6);
  color: #FFFFFF;
}
.btn-primary.alpha:hover,
.btn-primary.alpha.hover,
.btn-primary.alpha:focus,
.btn-primary.alpha.focus,
.btn-primary.alpha:active,
.btn-primary.alpha.active,
.btn-primary.alpha .open > .dropdown-toggle.btn-default {
  outline: 2px solid rgba(1, 169, 130, 0.6);
}
.safari .btn-primary.alpha:hover,
.safari .btn-primary.alpha.hover,
.safari .btn-primary.alpha:focus,
.safari .btn-primary.alpha.focus,
.safari .btn-primary.alpha:active,
.safari .btn-primary.alpha.active,
.safari .btn-primary.alpha .open > .dropdown-toggle.btn-default {
  outline: none;
  box-shadow: 0 0 0 2px rgba(1, 169, 130, 0.6);
}
.btn-secondary {
  background: none;
  color: #000000;
  outline: none;
  border: solid 2px #01A982;
}
.btn-secondary:hover,
.btn-secondary.hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary .open > .dropdown-toggle.btn-default {
  background: none;
  box-shadow: none;
  color: #000000;
  outline: 2px solid #01A982;
}
.btn-secondary.alpha {
  background: rgba(0, 0, 0, 0.5);
  border-color: #01A982;
  color: #FFFFFF;
}
.btn-secondary.alpha:hover,
.btn-secondary.alpha.hover,
.btn-secondary.alpha:focus,
.btn-secondary.alpha.focus,
.btn-secondary.alpha:active,
.btn-secondary.alpha.active,
.btn-secondary.alpha .open > .dropdown-toggle.btn-default {
  outline: 2px solid #01A982;
}
.btn-tertiary {
  color: #000000;
  background: none;
  border: solid 2px rgba(0, 0, 0, 0.2);
}
.btn-tertiary:hover,
.btn-tertiary.hover,
.btn-tertiary:focus,
.btn-tertiary.focus,
.btn-tertiary:active,
.btn-tertiary.active,
.btn-tertiary .open > .dropdown-toggle.btn-default {
  background: none;
  outline: solid 2px rgba(0, 0, 0, 0.2);
  color: #000000;
  box-shadow: none;
}
.safari .btn-tertiary:hover,
.safari .btn-tertiary.hover,
.safari .btn-tertiary:focus,
.safari .btn-tertiary.focus,
.safari .btn-tertiary:active,
.safari .btn-tertiary.active,
.safari .btn-tertiary .open > .dropdown-toggle.btn-default {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}
.btn-tertiary.alpha {
  background: none;
  border-color: rgba(255, 255, 255, 0.6);
  color: #FFFFFF;
}
.btn-tertiary.alpha:hover,
.btn-tertiary.alpha.hover,
.btn-tertiary.alpha:focus,
.btn-tertiary.alpha.focus,
.btn-tertiary.alpha:active,
.btn-tertiary.alpha.active,
.btn-tertiary.alpha .open > .dropdown-toggle.btn-default {
  outline: 2px solid rgba(255, 255, 255, 0.6);
}
.safari .btn-tertiary.alpha:hover,
.safari .btn-tertiary.alpha.hover,
.safari .btn-tertiary.alpha:focus,
.safari .btn-tertiary.alpha.focus,
.safari .btn-tertiary.alpha:active,
.safari .btn-tertiary.alpha.active,
.safari .btn-tertiary.alpha .open > .dropdown-toggle.btn-default {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.6);
}
.btn-contact {
  color: #000000;
  background: none;
  border: solid 2px #FF8D6D;
}
.btn-contact:hover,
.btn-contact.hover,
.btn-contact:focus,
.btn-contact.focus,
.btn-contact:active,
.btn-contact.active,
.btn-contact .open > .dropdown-toggle.btn-default {
  background: #FF8D6D;
  border-color: #FF8D6D;
  box-shadow: none;
  color: #000000;
  outline: 2px solid #FF8D6D;
}
.disabled-focus .btn-primary:focus,
.disabled-focus .btn-primary.alpha:focus,
.disabled-focus .btn-secondary:focus,
.disabled-focus .btn-secondary.alpha:focus,
.disabled-focus .btn-tertiary:focus,
.disabled-focus .btn-tertiary.alpha:focus,
.disabled-focus .btn-contact:focus {
  outline: none;
  box-shadow: none;
}
.btn.disabled,
.btn[disabled],
.btn.disabled:hover,
.btn[disabled]:hover,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled.focus,
.btn[disabled].focus,
.btn.disabled:active,
.btn[disabled]:active,
.btn.disabled.active,
.btn[disabled].active {
  background: none;
  border-color: #C6C9CA;
  color: #C6C9CA;
}
.text-center .cta-link {
  display: inline-block;
}
.cta-link {
  font-family: 'Metric Regular', Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  color: #707070;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 15px;
  padding: 0;
  padding-left: 1.6em;
}
.cta-link .icon-arrow-circle-right,
.cta-link .icon {
  margin-right: 2px;
  padding-left: 1px;
  top: 1px;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: left;
}
.cta-link .icon-arrow-circle-right:before,
.cta-link .icon:before {
  color: #01A982;
}
.cta-link:hover,
.cta-link:focus,
.cta-link.focus,
.cta-link:active,
.cta-link.active {
  color: #707070;
  text-decoration: none;
}
.cta-link:hover .icon-arrow-circle-right:before,
.cta-link:focus .icon-arrow-circle-right:before,
.cta-link.focus .icon-arrow-circle-right:before,
.cta-link:active .icon-arrow-circle-right:before,
.cta-link.active .icon-arrow-circle-right:before,
.cta-link:hover .icon:before,
.cta-link:focus .icon:before,
.cta-link.focus .icon:before,
.cta-link:active .icon:before,
.cta-link.active .icon:before {
  color: rgba(112, 112, 112, 0.6);
}
.cta-link.alpha {
  color: #FFFFFF;
}
.cta-link.alpha .icon-arrow-circle-right:before {
  color: #FFFFFF;
}
.cta-link.alpha:hover,
.cta-link.alpha:focus,
.cta-link.alpha.focus,
.cta-link.alpha:active,
.cta-link.alpha.active {
  color: rgba(255, 255, 255, 0.6);
}
.cta-link.alpha:hover .icon-arrow-circle-right:before,
.cta-link.alpha:focus .icon-arrow-circle-right:before,
.cta-link.alpha.focus .icon-arrow-circle-right:before,
.cta-link.alpha:active .icon-arrow-circle-right:before,
.cta-link.alpha.active .icon-arrow-circle-right:before,
.cta-link.alpha:hover .icon:before,
.cta-link.alpha:focus .icon:before,
.cta-link.alpha.focus .icon:before,
.cta-link.alpha:active .icon:before,
.cta-link.alpha.active .icon:before {
  color: rgba(255, 255, 255, 0.6);
}
.primary-link {
  font-family: 'Metric Semibold', Arial, sans-serif;
  font-size: 19px;
  line-height: 23px;
  position: relative;
  color: #01A982;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
}
.primary-link:hover,
.primary-link:focus,
.primary-link.focus,
.primary-link:active,
.primary-link.active {
  color: #000000;
  text-decoration: none;
}
.primary-link.alpha {
  color: rgba(255, 255, 255, 0.6);
}
.primary-link.alpha:hover,
.primary-link.alpha:focus,
.primary-link.alpha.focus,
.primary-link.alpha:active,
.primary-link.alpha.active {
  color: #FFFFFF;
}
/* SECONDARY LINK */
.secondary-link {
  font-family: 'Metric Semibold', Arial, sans-serif;
  /* Default padding using Regular */
  padding-left: 23px;
  display: inline-block;
  transition: color 0.2s ease, fill 0.2s ease;
  /* Default State using Regular */
  /* Default Font Size using Regular */
  font-size: 18px;
  line-height: 22px;
  position: relative;
  color: #707070;
  text-decoration: none;
  cursor: pointer;
  /* --------------------------------------------------------------- */
  /* Secondary Link Font Size: Phone/Tablet/Phablet - 768px or 992px */
  /* --------------------------------------------------------------- */
  /* ------------------------------------------------- */
  /*  Secondary Link Font Size: Mobile - 767px or less */
  /* ------------------------------------------------- */
}
.secondary-link em {
  position: absolute;
  color: #01A982;
  font-size: 14px;
  /* Top Adjustment needed */
  top: 2px;
  left: 0;
  transition: left 0.2s ease;
}
.secondary-link span {
  font-family: 'Metric Semibold', Arial, sans-serif;
}
.secondary-link-wrapper:hover .secondary-link em,
.secondary-link-wrapper:focus .secondary-link em,
.secondary-link-wrapper:active .secondary-link em {
  left: 5px;
}
.secondary-link:hover em,
.secondary-link:focus em,
.secondary-link.focus em,
.secondary-link:active em,
.secondary-link.active em {
  left: 5px;
  color: #01A982;
}
.secondary-link.medium-font {
  font-family: 'Metric Medium', Arial, sans-serif;
}
.secondary-link.regular {
  font-size: 18px;
  line-height: 22px;
}
.secondary-link.regular em {
  font-size: 14px;
}
.secondary-link.small {
  font-size: 15px;
  line-height: 18px;
}
.secondary-link.small em {
  font-size: 12px;
}
.secondary-link:hover,
.secondary-link:focus,
.secondary-link.focus,
.secondary-link:active,
.secondary-link.active {
  color: #000000;
  text-decoration: none;
}
.secondary-link.alpha {
  color: rgba(255, 255, 255, 0.6);
}
.secondary-link.alpha em {
  color: rgba(255, 255, 255, 0.6);
}
.secondary-link.alpha:hover,
.secondary-link.alpha:focus,
.secondary-link.alpha.focus,
.secondary-link.alpha:active,
.secondary-link.alpha.active,
.secondary-link.alpha:hover em,
.secondary-link.alpha:focus em,
.secondary-link.alpha.focus em,
.secondary-link.alpha:active em,
.secondary-link.alpha.active em {
  color: #FFFFFF;
}
.secondary-link.regular {
  padding-left: 23px;
}
.secondary-link.regular:hover em,
.secondary-link.regular:focus em,
.secondary-link.regular.focus em,
.secondary-link.regular:active em,
.secondary-link.regular.active em {
  left: 5px;
}
.secondary-link.small {
  padding-left: 18px;
}
.secondary-link.small:hover em,
.secondary-link.small:focus em,
.secondary-link.small.focus em,
.secondary-link.small:active em,
.secondary-link.small.active em {
  left: 3px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .secondary-link {
    /* Default Font Size using Regular */
    font-size: 17px;
    line-height: 21px;
  }
  .secondary-link.regular {
    font-size: 17px;
    line-height: 21px;
  }
}
@media screen and (max-width: 767px) {
  .secondary-link {
    /* Default Font Size using Regular */
    font-size: 16px;
    line-height: 19px;
  }
  .secondary-link.regular {
    font-size: 16px;
    line-height: 19px;
  }
}
/* SECONDARY LINK in hovered components */
.hovered .secondary-link {
  color: #000000;
  text-decoration: none;
}
.hovered .secondary-link.alpha {
  color: #FFFFFF;
}
.hovered .secondary-link.alpha em {
  color: #FFFFFF;
}
.hovered .secondary-link em {
  left: 5px;
  color: #01A982;
}
.hovered .secondary-link.regular em {
  left: 5px;
}
.hovered .secondary-link.small em {
  left: 3px;
}
.secondary-link.button-cta {
  padding-left: 21px;
  margin-top: 9px;
  color: #333;
}
.secondary-link.button-cta:hover,
.secondary-link.button-cta:focus,
.secondary-link.button-cta.focus,
.secondary-link.button-cta:active,
.secondary-link.button-cta.active {
  color: #000000;
}
.secondary-link.button-cta.alpha {
  color: rgba(255, 255, 255, 0.9);
}
.secondary-link.button-cta.alpha:hover,
.secondary-link.button-cta.alpha:focus,
.secondary-link.button-cta.alpha.focus,
.secondary-link.button-cta.alpha:active,
.secondary-link.button-cta.alpha.active {
  color: #FFFFFF;
}
@media screen and (max-width: 1199px) {
  .secondary-link.button-cta {
    padding-left: 20px;
    font-size: 17px;
    line-height: 21px;
  }
  .secondary-link.button-cta em {
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .secondary-link.button-cta {
    padding-left: 19px;
    margin-top: 11px;
    font-size: 16px;
    line-height: 19px;
  }
  .secondary-link.button-cta em {
    font-size: 12px;
  }
}
/* TERTIARY LINK */
.tertiary-link {
  font-family: 'Metric Light', Arial, sans-serif;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease, border-color 0.2s ease, fill 0.2s ease;
}
.tertiary-link,
.tertiary-link.default {
  color: inherit;
  border-bottom: 1px solid #01A982;
}
.tertiary-link:hover,
.tertiary-link.default:hover,
.tertiary-link:focus-visible,
.tertiary-link.default:focus-visible,
.tertiary-link.focus,
.tertiary-link.default.focus,
.tertiary-link:active,
.tertiary-link.default:active,
.tertiary-link.active,
.tertiary-link.default.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.tertiary-link.alpha {
  color: #FFFFFF;
  border-bottom: 1px solid #707070;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.tertiary-link.alpha:hover,
.tertiary-link.alpha:focus-visible,
.tertiary-link.alpha.focus,
.tertiary-link.alpha:active,
.tertiary-link.alpha.active {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
.tertiary-link:focus {
  transform: translateZ(0);
}
.row .row {
  margin-left: 0;
  margin-right: 0;
}
.container-fluid {
  max-width: 1600px;
}
@media screen and (max-width: 767px) {
  .container-gutter {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .container-gutter {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container-gutter {
    padding-right: 75px;
    padding-left: 75px;
  }
}
@media screen and (min-width: 1200px) {
  .container-gutter {
    padding-right: 100px;
    padding-left: 100px;
  }
}
.style-bg-green {
  background-color: #01A982;
}
.style-bg-hpe-green {
  background-color: #01A982;
}
.style-bg-slate {
  background-color: #425563;
}
.style-bg-bronze {
  background-color: #80746E;
}
.style-bg-dark-steel {
  background-color: #5F7A76;
}
.style-bg-gray {
  background-color: #C6C9CA;
}
.style-bg-turquoise {
  background-color: #2AD2C9;
}
.style-bg-orange {
  background-color: #FF8D6D;
}
.style-bg-purple {
  background-color: #614767;
}
.style-bg-white {
  background-color: #FFFFFF;
}
.style-bg-black {
  background-color: #000000;
}
.style-bg-light-gray {
  background-color: #F6F6F6;
}
.style-bg-light-blue {
  background-color: #7FF9E2;
}
.style-bg-medium-blue {
  background-color: #32DAC8;
}
.style-bg-dark-blue {
  background-color: #0D5265;
}
.style-bg-aruba-orange {
  background-color: #FF8300;
}
.style-bg-light-purple {
  background-color: #C140FF;
}
.style-bg-medium-purple {
  background-color: #7630EA;
}
.style-bg-yellow {
  background-color: #FEC901;
}
.style-bg-error {
  background-color: #D93A09;
}
.style-bg-gray-1 {
  background-color: #707070;
}
.style-bg-gray-3 {
  background-color: #F6F6F6;
}
.style-bg-gray-4 {
  background-color: #E7E7E7;
}
.style-bg-gray-5 {
  background-color: #333333;
}
.style-color-green {
  color: #01A982;
}
.style-color-slate {
  color: #425563;
}
.style-color-bronze {
  color: #80746E;
}
.style-color-dark-steel {
  color: #5F7A76;
}
.style-color-gray {
  color: #C6C9CA;
}
.style-color-turquoise {
  color: #2AD2C9;
}
.style-color-orange {
  color: #FF8D6D;
}
.style-color-purple {
  color: #614767;
}
.style-color-white {
  color: #FFFFFF;
}
.style-color-black {
  color: #000000;
}
.style-color-error {
  color: #D93A09;
}
.style-color-gray-1 {
  color: #707070;
}
.style-color-gray-3 {
  color: #F6F6F6;
}
[class*="style-border-"] {
  border-top: 4px solid;
}
.style-border-green {
  border-color: #01A982;
}
.style-border-slate {
  border-color: #425563;
}
.style-border-bronze {
  border-color: #80746E;
}
.style-border-dark-steel {
  border-color: #5F7A76;
}
.style-border-gray {
  border-color: #C6C9CA;
}
.style-border-turquoise {
  border-color: #2AD2C9;
}
.style-border-orange {
  border-color: #FF8D6D;
}
.style-border-purple {
  border-color: #614767;
}
.style-border-white {
  border-color: #FFFFFF;
}
.style-border-black {
  border-color: #000000;
}
.style-border-error {
  border-color: #D93A09;
}
.style-border-gray-1 {
  border-color: #707070;
}
.style-border-gray-3 {
  border-color: #F6F6F6;
}
.button-reset {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
.unwrap {
  display: contents;
}
.break-words {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: inherit;
}
.nowrap {
  white-space: nowrap;
}
.inline-links a {
  color: inherit;
  border-bottom: 1px solid #01A982;
  font-family: inherit;
  font-weight: inherit;
  position: relative;
  transition: color 0.2s ease, border-color 0.2s ease, fill 0.2s ease;
}
.inline-links a:hover,
.inline-links a:focus-visible,
.inline-links a.focus,
.inline-links a:active,
.inline-links a.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.rich-text-container {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: inherit;
}
.rich-text-container a {
  color: inherit;
  border-bottom: 1px solid #01A982;
  font-family: inherit;
  font-weight: inherit;
  position: relative;
  transition: color 0.2s ease, border-color 0.2s ease, fill 0.2s ease;
}
.rich-text-container a:hover,
.rich-text-container a:focus-visible,
.rich-text-container a.focus,
.rich-text-container a:active,
.rich-text-container a.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.rich-text-container u {
  text-decoration: none;
  border-bottom: 1px solid #000000;
}
.rich-text-container ol > li {
  list-style-type: decimal;
}
.rich-text-container ol > li > ul,
.rich-text-container ol > li > ol {
  margin-left: 13px;
}
.dark-theme .bulleted-list ul li,
body .dark-theme-prespecific  .bulleted-list ul li,
html body .dark-theme-specific .bulleted-list ul li,
.style-bg-bronze .bulleted-list ul li,
.style-bg-dark-steel .bulleted-list ul li,
.style-bg-purple .bulleted-list ul li,
.style-bg-slate .bulleted-list ul li,
.style-bg-gray-5 .bulleted-list ul li,
.slate-bg-color .bulleted-list ul li,
.dark-steel-bg-color .bulleted-list ul li,
.bronze-bg-color .bulleted-list ul li,
.purple-bg-color .bulleted-list ul li {
  color: #FFFFFF;
}
.dark-theme .inline-links a,
body .dark-theme-prespecific  .inline-links a,
html body .dark-theme-specific .inline-links a,
.style-bg-bronze .inline-links a,
.style-bg-dark-steel .inline-links a,
.style-bg-purple .inline-links a,
.style-bg-slate .inline-links a,
.style-bg-gray-5 .inline-links a,
.slate-bg-color .inline-links a,
.dark-steel-bg-color .inline-links a,
.bronze-bg-color .inline-links a,
.purple-bg-color .inline-links a {
  color: #FFFFFF;
  border-bottom: 1px solid #707070;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.dark-theme .inline-links a:hover,
body .dark-theme-prespecific  .inline-links a:hover,
html body .dark-theme-specific .inline-links a:hover,
.style-bg-bronze .inline-links a:hover,
.style-bg-dark-steel .inline-links a:hover,
.style-bg-purple .inline-links a:hover,
.style-bg-slate .inline-links a:hover,
.style-bg-gray-5 .inline-links a:hover,
.slate-bg-color .inline-links a:hover,
.dark-steel-bg-color .inline-links a:hover,
.bronze-bg-color .inline-links a:hover,
.purple-bg-color .inline-links a:hover,
.dark-theme .inline-links a:focus-visible,
body .dark-theme-prespecific  .inline-links a:focus-visible,
html body .dark-theme-specific .inline-links a:focus-visible,
.style-bg-bronze .inline-links a:focus-visible,
.style-bg-dark-steel .inline-links a:focus-visible,
.style-bg-purple .inline-links a:focus-visible,
.style-bg-slate .inline-links a:focus-visible,
.style-bg-gray-5 .inline-links a:focus-visible,
.slate-bg-color .inline-links a:focus-visible,
.dark-steel-bg-color .inline-links a:focus-visible,
.bronze-bg-color .inline-links a:focus-visible,
.purple-bg-color .inline-links a:focus-visible,
.dark-theme .inline-links a.focus,
body .dark-theme-prespecific  .inline-links a.focus,
html body .dark-theme-specific .inline-links a.focus,
.style-bg-bronze .inline-links a.focus,
.style-bg-dark-steel .inline-links a.focus,
.style-bg-purple .inline-links a.focus,
.style-bg-slate .inline-links a.focus,
.style-bg-gray-5 .inline-links a.focus,
.slate-bg-color .inline-links a.focus,
.dark-steel-bg-color .inline-links a.focus,
.bronze-bg-color .inline-links a.focus,
.purple-bg-color .inline-links a.focus,
.dark-theme .inline-links a:active,
body .dark-theme-prespecific  .inline-links a:active,
html body .dark-theme-specific .inline-links a:active,
.style-bg-bronze .inline-links a:active,
.style-bg-dark-steel .inline-links a:active,
.style-bg-purple .inline-links a:active,
.style-bg-slate .inline-links a:active,
.style-bg-gray-5 .inline-links a:active,
.slate-bg-color .inline-links a:active,
.dark-steel-bg-color .inline-links a:active,
.bronze-bg-color .inline-links a:active,
.purple-bg-color .inline-links a:active,
.dark-theme .inline-links a.active,
body .dark-theme-prespecific  .inline-links a.active,
html body .dark-theme-specific .inline-links a.active,
.style-bg-bronze .inline-links a.active,
.style-bg-dark-steel .inline-links a.active,
.style-bg-purple .inline-links a.active,
.style-bg-slate .inline-links a.active,
.style-bg-gray-5 .inline-links a.active,
.slate-bg-color .inline-links a.active,
.dark-steel-bg-color .inline-links a.active,
.bronze-bg-color .inline-links a.active,
.purple-bg-color .inline-links a.active {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
.dark-theme .rich-text-container ul li,
body .dark-theme-prespecific  .rich-text-container ul li,
html body .dark-theme-specific .rich-text-container ul li,
.style-bg-bronze .rich-text-container ul li,
.style-bg-dark-steel .rich-text-container ul li,
.style-bg-purple .rich-text-container ul li,
.style-bg-slate .rich-text-container ul li,
.style-bg-gray-5 .rich-text-container ul li,
.slate-bg-color .rich-text-container ul li,
.dark-steel-bg-color .rich-text-container ul li,
.bronze-bg-color .rich-text-container ul li,
.purple-bg-color .rich-text-container ul li {
  color: #FFFFFF;
}
.dark-theme .rich-text-container a,
body .dark-theme-prespecific  .rich-text-container a,
html body .dark-theme-specific .rich-text-container a,
.style-bg-bronze .rich-text-container a,
.style-bg-dark-steel .rich-text-container a,
.style-bg-purple .rich-text-container a,
.style-bg-slate .rich-text-container a,
.style-bg-gray-5 .rich-text-container a,
.slate-bg-color .rich-text-container a,
.dark-steel-bg-color .rich-text-container a,
.bronze-bg-color .rich-text-container a,
.purple-bg-color .rich-text-container a {
  color: #FFFFFF;
  border-bottom: 1px solid #707070;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.dark-theme .rich-text-container a:hover,
body .dark-theme-prespecific  .rich-text-container a:hover,
html body .dark-theme-specific .rich-text-container a:hover,
.style-bg-bronze .rich-text-container a:hover,
.style-bg-dark-steel .rich-text-container a:hover,
.style-bg-purple .rich-text-container a:hover,
.style-bg-slate .rich-text-container a:hover,
.style-bg-gray-5 .rich-text-container a:hover,
.slate-bg-color .rich-text-container a:hover,
.dark-steel-bg-color .rich-text-container a:hover,
.bronze-bg-color .rich-text-container a:hover,
.purple-bg-color .rich-text-container a:hover,
.dark-theme .rich-text-container a:focus-visible,
body .dark-theme-prespecific  .rich-text-container a:focus-visible,
html body .dark-theme-specific .rich-text-container a:focus-visible,
.style-bg-bronze .rich-text-container a:focus-visible,
.style-bg-dark-steel .rich-text-container a:focus-visible,
.style-bg-purple .rich-text-container a:focus-visible,
.style-bg-slate .rich-text-container a:focus-visible,
.style-bg-gray-5 .rich-text-container a:focus-visible,
.slate-bg-color .rich-text-container a:focus-visible,
.dark-steel-bg-color .rich-text-container a:focus-visible,
.bronze-bg-color .rich-text-container a:focus-visible,
.purple-bg-color .rich-text-container a:focus-visible,
.dark-theme .rich-text-container a.focus,
body .dark-theme-prespecific  .rich-text-container a.focus,
html body .dark-theme-specific .rich-text-container a.focus,
.style-bg-bronze .rich-text-container a.focus,
.style-bg-dark-steel .rich-text-container a.focus,
.style-bg-purple .rich-text-container a.focus,
.style-bg-slate .rich-text-container a.focus,
.style-bg-gray-5 .rich-text-container a.focus,
.slate-bg-color .rich-text-container a.focus,
.dark-steel-bg-color .rich-text-container a.focus,
.bronze-bg-color .rich-text-container a.focus,
.purple-bg-color .rich-text-container a.focus,
.dark-theme .rich-text-container a:active,
body .dark-theme-prespecific  .rich-text-container a:active,
html body .dark-theme-specific .rich-text-container a:active,
.style-bg-bronze .rich-text-container a:active,
.style-bg-dark-steel .rich-text-container a:active,
.style-bg-purple .rich-text-container a:active,
.style-bg-slate .rich-text-container a:active,
.style-bg-gray-5 .rich-text-container a:active,
.slate-bg-color .rich-text-container a:active,
.dark-steel-bg-color .rich-text-container a:active,
.bronze-bg-color .rich-text-container a:active,
.purple-bg-color .rich-text-container a:active,
.dark-theme .rich-text-container a.active,
body .dark-theme-prespecific  .rich-text-container a.active,
html body .dark-theme-specific .rich-text-container a.active,
.style-bg-bronze .rich-text-container a.active,
.style-bg-dark-steel .rich-text-container a.active,
.style-bg-purple .rich-text-container a.active,
.style-bg-slate .rich-text-container a.active,
.style-bg-gray-5 .rich-text-container a.active,
.slate-bg-color .rich-text-container a.active,
.dark-steel-bg-color .rich-text-container a.active,
.bronze-bg-color .rich-text-container a.active,
.purple-bg-color .rich-text-container a.active {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
.dark-theme .rich-text-container u,
body .dark-theme-prespecific  .rich-text-container u,
html body .dark-theme-specific .rich-text-container u,
.style-bg-bronze .rich-text-container u,
.style-bg-dark-steel .rich-text-container u,
.style-bg-purple .rich-text-container u,
.style-bg-slate .rich-text-container u,
.style-bg-gray-5 .rich-text-container u,
.slate-bg-color .rich-text-container u,
.dark-steel-bg-color .rich-text-container u,
.bronze-bg-color .rich-text-container u,
.purple-bg-color .rich-text-container u {
  border-color: #FFFFFF;
}
.dark-theme.rich-text-container a {
  color: #FFFFFF;
  border-bottom: 1px solid #707070;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.dark-theme.rich-text-container a:hover,
.dark-theme.rich-text-container a:focus-visible,
.dark-theme.rich-text-container a.focus,
.dark-theme.rich-text-container a:active,
.dark-theme.rich-text-container a.active {
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}
.dark-theme.rich-text-container u {
  border-color: #FFFFFF;
}
.light-theme .bulleted-list ul li,
body .light-theme-prespecific  .bulleted-list ul li,
html body .light-theme-specific .bulleted-list ul li {
  color: #000000;
}
.light-theme .inline-links a,
body .light-theme-prespecific  .inline-links a,
html body .light-theme-specific .inline-links a {
  color: inherit;
  border-bottom: 1px solid #01A982;
}
.light-theme .inline-links a:hover,
body .light-theme-prespecific  .inline-links a:hover,
html body .light-theme-specific .inline-links a:hover,
.light-theme .inline-links a:focus-visible,
body .light-theme-prespecific  .inline-links a:focus-visible,
html body .light-theme-specific .inline-links a:focus-visible,
.light-theme .inline-links a.focus,
body .light-theme-prespecific  .inline-links a.focus,
html body .light-theme-specific .inline-links a.focus,
.light-theme .inline-links a:active,
body .light-theme-prespecific  .inline-links a:active,
html body .light-theme-specific .inline-links a:active,
.light-theme .inline-links a.active,
body .light-theme-prespecific  .inline-links a.active,
html body .light-theme-specific .inline-links a.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.light-theme .rich-text-container ul li,
body .light-theme-prespecific  .rich-text-container ul li,
html body .light-theme-specific .rich-text-container ul li {
  color: #000000;
}
.light-theme .rich-text-container a,
body .light-theme-prespecific  .rich-text-container a,
html body .light-theme-specific .rich-text-container a {
  color: inherit;
  border-bottom: 1px solid #01A982;
}
.light-theme .rich-text-container a:hover,
body .light-theme-prespecific  .rich-text-container a:hover,
html body .light-theme-specific .rich-text-container a:hover,
.light-theme .rich-text-container a:focus-visible,
body .light-theme-prespecific  .rich-text-container a:focus-visible,
html body .light-theme-specific .rich-text-container a:focus-visible,
.light-theme .rich-text-container a.focus,
body .light-theme-prespecific  .rich-text-container a.focus,
html body .light-theme-specific .rich-text-container a.focus,
.light-theme .rich-text-container a:active,
body .light-theme-prespecific  .rich-text-container a:active,
html body .light-theme-specific .rich-text-container a:active,
.light-theme .rich-text-container a.active,
body .light-theme-prespecific  .rich-text-container a.active,
html body .light-theme-specific .rich-text-container a.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.light-theme .rich-text-container u,
body .light-theme-prespecific  .rich-text-container u,
html body .light-theme-specific .rich-text-container u {
  border-color: #000000;
}
.light-theme.rich-text-container a {
  color: inherit;
  border-bottom: 1px solid #01A982;
}
.light-theme.rich-text-container a:hover,
.light-theme.rich-text-container a:focus-visible,
.light-theme.rich-text-container a.focus,
.light-theme.rich-text-container a:active,
.light-theme.rich-text-container a.active {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #707070;
}
.light-theme.rich-text-container u {
  border-color: #000000;
}
.text-light {
  font-family: 'Metric Light', Arial, sans-serif;
}
.text-regular {
  font-family: 'Metric Regular', Arial, sans-serif;
}
.text-semibold {
  font-family: 'Metric Semibold', Arial, sans-serif;
}
.text-bold {
  font-family: 'Metric Bold', Arial, sans-serif;
}
.simple-regular {
  font-family: 'Simple Regular', Arial, sans-serif;
}
.style-hpe-glyphicons {
  font-family: "hpe-glyphicons";
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.hide-it {
  display: none;
}
.show-it {
  display: block;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.is-hidden {
  visibility: hidden;
}
.is-visible {
  visibility: visible;
}
.force-display-block {
  display: block !important;
}
.force-display-flex {
  display: flex !important;
}
.coloredline,
.img-container-loaded.coloredline {
  border-top: 4px solid transparent;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hide-xs {
    display: none !important;
  }
  .hide-xs:not(.specific) {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hide-sm {
    display: none !important;
  }
  .hide-sm:not(.specific) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hide-md {
    display: none !important;
  }
  .hide-md:not(.specific) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .hide-lg {
    display: none !important;
  }
  .hide-lg:not(.specific) {
    display: none;
  }
}
@media (min-width: 1600px) {
  .hide-xl {
    display: none !important;
  }
  .hide-xl:not(.specific) {
    display: none;
  }
}
html {
  margin-top: 0 !important;
}
body .page-content {
  position: relative;
  z-index: auto;
  overflow: hidden;
  overflow: clip;
}
.page-container {
  position: relative;
}
html.disabled-scroll body {
  overflow: hidden !important;
  max-height: 100vh !important;
}
html.disabled-scroll:not(.disabled-scroll-style) {
  overflow-y: scroll;
}
.pagination-list {
  background-color: #FFFFFF;
}
.pagination-list a {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
  color: #707070;
  overflow: hidden;
}
.pagination-list a span {
  padding-top: 4em;
}
.pagination-list a:link,
.pagination-list a:hover {
  text-decoration: none;
}
.pagination-list a:hover {
  color: #000000;
}
.pagination-list a.disabled {
  pointer-events: none;
  cursor: default;
  color: #C6C9CA;
}
.pagination-list a:before {
  font-family: "hpe-glyphicons";
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  position: absolute;
  top: -4px;
  width: 80px;
  height: 40px;
  font-size: 25px;
  padding-top: 11px;
  background-color: #FFFFFF;
}
.pagination-list a.pagination-list-next:before {
  content: '\f11c';
  left: 0;
}
.pagination-list a.pagination-list-previous:before {
  content: '\f126';
  right: 0;
}
.pagination-list-offset-top {
  margin-top: 5px;
}
.pagination-list-content {
  display: inline-block;
  font-family: 'Metric Regular';
  padding: 10px;
}
.pagination-list-content a,
.pagination-list-content span,
.pagination-list-content em {
  vertical-align: middle;
  color: #707070;
  font-size: 15px;
  display: inline-block;
}
.pagination-list-content span,
.pagination-list-content em {
  height: 1.8em;
}
.pagination-list-content .pagination-list-em,
.pagination-list-content em {
  font-family: 'Metric Semibold';
  font-style: normal;
}
@media screen and (max-width: 767px) {
  .pagination-list a {
    width: 60px;
  }
}
table {
  border: 0;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
table.fixed-col {
  table-layout: fixed;
}
table th {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  vertical-align: bottom;
  padding: 8px 10px 8px 0;
}
table th.center {
  text-align: center;
}
table td {
  border-bottom: 1px solid #666;
  background: #fff;
  vertical-align: top;
  padding: 16px 10px 16px 0;
}
table tr td:first-child,
table tr th:first-child {
  padding-left: 10px;
}
table tr td:last-child,
table tr th:last-child {
  padding-right: 10px;
}
table tr:hover td,
table tr.active td {
  background: #eee;
}
table tr:last-child td {
  border-bottom-color: #999;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXSThin-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXSThin-Regular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXSLight-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXSLight-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXS-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXS-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXSMedium-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXSMedium-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXSSemibold-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXSSemibold-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXS-Bold.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXS-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPEXS';
  src: url('../fonts/hpexs/MetricHPEXSBlack-Regular.woff2') format('woff2'), url('../fonts/hpexs/MetricHPEXSBlack-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metric Light';
  src: url('../fonts/Metric-Light.woff2') format('woff2'), url('../fonts/Metric-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metric Regular';
  src: url('../fonts/Metric-Regular.woff2') format('woff2'), url('../fonts/Metric-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metric Medium';
  src: url('../fonts/Metric-Medium.woff2') format('woff2'), url('../fonts/Metric-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metric Semibold';
  src: url('../fonts/Metric-Semibold.woff2') format('woff2'), url('../fonts/Metric-Semibold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metric Bold';
  src: url('../fonts/MetricHPE-Web-Bold.woff2') format('woff2'), url('../fonts/MetricHPE-Web-Bold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MetricHPE Black';
  src: url('../fonts/MetricHPE-Web-Black.woff2') format('woff2'), url('../fonts/MetricHPE-Web-Black.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Simple Regular';
  src: url('../fonts/lineto-simple-pro-regular.woff2') format('woff2'), url('../fonts/lineto-simple-pro-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
body {
  font-family: 'Metric Light', Arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
}
.safari a,
.safari p,
.safari div,
.safari span,
.safari li,
.safari code,
.safari h1,
.safari h2,
.safari h3,
.safari h4,
.safari h5,
.safari h6 {
  -webkit-backface-visibility: hidden;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}
ul li {
  list-style-type: none;
  font-family: 'Metric Light';
}
ol li {
  color: #C6C9CA;
  font-family: 'Metric Light';
}
p a {
  color: #008767;
  text-decoration: none;
}
p a:hover {
  color: #006A51;
  text-decoration: underline;
}
pre {
  _white-space: pre;
  _white-space: pre-wrap;
  _word-wrap: break-word;
}
b,
strong {
  font-family: 'Metric Semibold', Arial, sans-serif;
}
label {
  font-family: 'Metric Light', Arial, sans-serif;
}
input {
  font-family: 'Metric Regular', Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.tag-h1,
.tag-h2,
.tag-h3,
.tag-h4,
.tag-h5,
.tag-h6 {
  color: #000000;
  font-family: 'Metric Semibold', Arial, sans-serif;
  font-style: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: inherit;
}
h1,
.tag-h1 {
  font-size: 60px;
  line-height: 55px;
}
h2,
.tag-h2 {
  font-size: 40px;
  line-height: 42px;
}
h3,
.tag-h3 {
  font-size: 30px;
  line-height: 33px;
}
h4,
.tag-h4 {
  font-size: 20px;
  line-height: 23px;
}
h5,
.tag-h5 {
  font-size: 15px;
  line-height: 19px;
}
h6,
.tag-h6 {
  font-size: 25px;
  line-height: 29px;
}
.body-copy-large {
  color: #000000;
  font-family: 'Metric Light', Arial, sans-serif;
  font-size: 23px;
  font-style: normal;
  line-height: 25px;
}
.body-copy {
  color: #000000;
  font-family: 'Metric Light', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
}
.body-copy-small {
  color: #000000;
  font-family: 'Metric Light', Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  line-height: 19px;
}
.caption {
  color: #000000;
  font-family: 'Metric Regular', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 17px;
    line-height: 21px;
  }
  h1,
  .tag-h1 {
    font-size: 45px;
    line-height: 45px;
  }
  h2,
  .tag-h2 {
    font-size: 32px;
    line-height: 34px;
  }
  h3,
  .tag-h3 {
    font-size: 25px;
    line-height: 27px;
  }
  h4,
  .tag-h4 {
    font-size: 18px;
    line-height: 20px;
  }
  h6,
  .tag-h6 {
    font-size: 22px;
    line-height: 23px;
  }
  .body-copy-large {
    font-size: 21px;
    line-height: 24px;
  }
  .body-copy {
    font-size: 18px;
    line-height: 21px;
  }
}
@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
    line-height: 20px;
  }
  h1,
  .tag-h1 {
    font-size: 45px;
    line-height: 45px;
  }
  h2,
  .tag-h2 {
    font-size: 25px;
    line-height: 26px;
  }
  h3,
  .tag-h3 {
    font-size: 20px;
    line-height: 22px;
  }
  h4,
  .tag-h4 {
    font-size: 17px;
    line-height: 19px;
  }
  h5,
  .tag-h5 {
    font-size: 12px;
    line-height: 16px;
  }
  h6,
  .tag-h6 {
    font-size: 19px;
    line-height: 21px;
  }
  .body-copy-large {
    font-size: 19px;
    line-height: 22px;
  }
  .body-copy {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 510px) {
  h1,
  .tag-h1 {
    font-size: 32px;
    line-height: 32px;
  }
}
/*
  Icon Font: hpe-glyphicons
*/

@font-face {
  font-family: "hpe-glyphicons";
  src: url("../fonts/hpe-glyphicons.woff2") format("woff2"),
       url("../fonts/hpe-glyphicons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "hpe-glyphicons";
    src: url("../fonts/hpe-glyphicons.svg#hpe-glyphicons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-arrow-bottom:before,
.icon-arrow-circle-right:before,
.icon-arrow-left:before,
.icon-arrow-link-right:before,
.icon-arrow-pointer-left:before,
.icon-arrow-right:before,
.icon-arrow-top:before,
.icon-camera_icon:before,
.icon-check-mark:before,
.icon-circle-full:before,
.icon-circle-half:before,
.icon-download:before,
.icon-downloader-embed-code:before,
.icon-filter:before,
.icon-grid:before,
.icon-hpe-logo:before,
.icon-link-external:before,
.icon-nav-close-menu:before,
.icon-nav-contrast:before,
.icon-nav-glass-search:before,
.icon-nav-glass-zoom-in:before,
.icon-play-btn:before,
.icon-play-btn-black:before,
.icon-play-circle:before,
.icon-plus-sign:before,
.icon-print-btn:before,
.icon-questionmark-circle-close:before,
.icon-quote-circle-open:before,
.icon-search-advanced:before,
.icon-share-global:before,
.icon-social-askfm:before,
.icon-social-facebook:before,
.icon-social-googleplus:before,
.icon-social-gree:before,
.icon-social-instagram:before,
.icon-social-lineme:before,
.icon-social-linkedin:before,
.icon-social-mail:before,
.icon-social-mail-v2:before,
.icon-social-mixi:before,
.icon-social-qzone:before,
.icon-social-reddit:before,
.icon-social-renren:before,
.icon-social-tencentwrbo:before,
.icon-social-twitter:before,
.icon-social-weibo:before,
.icon-social-youtube:before,
.icon-toggle-circle-close:before,
.icon-toggle-circle-open:before {
  display: inline-block;
  font-family: "hpe-glyphicons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-arrow-bottom:before { content: "\f11d"; }
.icon-arrow-circle-right:before { content: "\f130"; }
.icon-arrow-left:before { content: "\f126"; }
.icon-arrow-link-right:before { content: "\f152"; }
.icon-arrow-pointer-left:before { content: "\f12f"; }
.icon-arrow-right:before { content: "\f11c"; }
.icon-arrow-top:before { content: "\f127"; }
.icon-camera_icon:before { content: "\f153"; }
.icon-check-mark:before { content: "\f15e"; }
.icon-circle-full:before { content: "\f15f"; }
.icon-circle-half:before { content: "\f160"; }
.icon-download:before { content: "\f13d"; }
.icon-downloader-embed-code:before { content: "\f158"; }
.icon-filter:before { content: "\f15d"; }
.icon-grid:before { content: "\f162"; }
.icon-hpe-logo:before { content: "\f161"; }
.icon-link-external:before { content: "\f12e"; }
.icon-nav-close-menu:before { content: "\f128"; }
.icon-nav-contrast:before { content: "\f12a"; }
.icon-nav-glass-search:before { content: "\f13c"; }
.icon-nav-glass-zoom-in:before { content: "\f14e"; }
.icon-play-btn:before { content: "\f11b"; }
.icon-play-btn-black:before { content: "\f122"; }
.icon-play-circle:before { content: "\f163"; }
.icon-plus-sign:before { content: "\f151"; }
.icon-print-btn:before { content: "\f15a"; }
.icon-questionmark-circle-close:before { content: "\f13f"; }
.icon-quote-circle-open:before { content: "\f137"; }
.icon-search-advanced:before { content: "\f13e"; }
.icon-share-global:before { content: "\f140"; }
.icon-social-askfm:before { content: "\f141"; }
.icon-social-facebook:before { content: "\f142"; }
.icon-social-googleplus:before { content: "\f150"; }
.icon-social-gree:before { content: "\f143"; }
.icon-social-instagram:before { content: "\f144"; }
.icon-social-lineme:before { content: "\f15c"; }
.icon-social-linkedin:before { content: "\f145"; }
.icon-social-mail:before { content: "\f146"; }
.icon-social-mail-v2:before { content: "\f164"; }
.icon-social-mixi:before { content: "\f147"; }
.icon-social-qzone:before { content: "\f148"; }
.icon-social-reddit:before { content: "\f149"; }
.icon-social-renren:before { content: "\f14a"; }
.icon-social-tencentwrbo:before { content: "\f14b"; }
.icon-social-twitter:before { content: "\f14c"; }
.icon-social-weibo:before { content: "\f14f"; }
.icon-social-youtube:before { content: "\f14d"; }
.icon-toggle-circle-close:before { content: "\f139"; }
.icon-toggle-circle-open:before { content: "\f13a"; }

[hpe-chat]:not([hpe-chat-status]).hpe-chat-hide-offline,
.hpe-chat-scope:not([hpe-chat-status]).hpe-chat-hide-offline,
[hpe-chat]:not([hpe-chat-status]) .hpe-chat-hide-offline,
.hpe-chat-scope:not([hpe-chat-status]) .hpe-chat-hide-offline {
  display: none;
}
[hpe-chat-status='online'].hpe-chat-hide-online,
[hpe-chat-status='online'] .hpe-chat-hide-online {
  display: none;
}
[hpe-chat-status='offline'].hpe-chat-hide-offline,
[hpe-chat-status='offline'] .hpe-chat-hide-offline {
  display: none;
}
#HpeChatEmailButton {
  border: none;
  background: none;
  padding: 0;
  background-color: #FF8D6D;
  max-width: 44px;
  height: 44px;
  position: fixed;
  right: 20px;
  bottom: 50px;
  border-radius: 44px;
  cursor: pointer;
  padding: 8px 11px;
  z-index: 20;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
  box-shadow: 0 0 1px 1px #000000;
  animation: 5s expand 3s ease-in-out;
}
@keyframes expand {
  0%,
  100% {
    max-width: 44px;
  }
  6%,
  94% {
    max-width: 300px;
  }
}
#HpeChatEmailButton span {
  display: block;
  position: relative;
  font-size: 16px;
  overflow: hidden;
  height: 28px;
  line-height: 28px;
  color: #000000;
  text-transform: uppercase;
  padding-right: 11px;
}
#HpeChatEmailButton span::before {
  float: left;
  content: "\f164";
  font-size: 22px;
  font-family: "hpe-glyphicons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  color: #000000;
  position: relative;
  margin-right: 18px;
  top: 3px;
}
[dir="rtl"] #HpeChatEmailButton span {
  padding-left: 11px;
  padding-right: 0;
}
[dir="rtl"] #HpeChatEmailButton span::before {
  float: right;
  margin-left: 18px;
  margin-right: 0;
}
#HpeChatEmailButton:hover {
  max-width: 500px;
}
#HpeChatEmailButton:hover span::before {
  left: 0;
}
@media screen and (max-width: 992px) {
  #HpeChatEmailButton {
    display: block;
  }
  #HpeChatEmailButton:hover {
    max-width: 44px;
  }
}
@media screen and (max-width: 767px) {
  #HpeChatEmailButton {
    right: 10px !important;
  }
}
.iphone #HpeChatEmailButton span,
.ipad #HpeChatEmailButton span {
  top: 2px;
}
.on-mobile #HpeChatEmailButton:hover {
  max-width: 44px;
}
.hpe-chats-disabled #HpeChatEmailButton,
.hpe-chats-disabled #drift-frame-controller,
.hpe-chats-disabled .hpe-drift-icon {
  display: none !important;
}
.hpe-email-button-added #drift-frame-controller,
.hpe-email-button-added .hpe-drift-icon {
  display: none !important;
}
#drift-frame-controller:not(.drift-chat-open) {
  z-index: 1000 !important;
}
.gn-primary-mobile-opened.gn-search-opened #drift-frame-controller,
.gn-primary-mobile-opened.gn-search-opened #drift-frame-chat,
.gn-primary-mobile-opened.gn-search-opened .hpe-drift-icon {
  display: none !important;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .gn-flyout-opened #drift-frame-controller,
  .gn-flyout-opened #drift-frame-chat,
  .gn-flyout-opened .hpe-drift-icon {
    display: none !important;
  }
}
.scaffolding-card-preview {
  padding: 30px;
}
.scaffolding-card-preview .card {
  max-width: 450px;
  margin: 0 auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.video-meta {
  display: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 65px;
  height: 25px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  vertical-align: middle;
}
.video-meta > span {
  color: inherit !important;
}
@media (min-width: 1px) and (max-width: 991px) {
  .video-meta > span {
    vertical-align: middle;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .video-meta {
    right: 10px;
    bottom: 10px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .video-meta {
    bottom: 5px;
    right: 5px;
  }
}
.video-js-brightcove table td,
.video-js-brightcove table tr:active td,
.video-js-brightcove table tr:hover td {
  background: transparent;
}
@media screen and (max-width: 480px) {
  .vjs-control-bar .vjs-duration,
  .vjs-control-bar .vjs-time-divider {
    display: none;
  }
  .vjs-control-bar .vjs-current-time {
    margin-right: 6px;
  }
  .vjs-control-bar button.vjs-control {
    width: 40px;
  }
  .vjs-control-bar .vjs-remaining-time {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}
esl-alert {
  display: block;
  text-align: center;
  position: fixed;
  bottom: 20px;
  padding: 20px;
  width: 70%;
  right: 15%;
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
  z-index: 10002;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0.05s, visibility 0s linear 0.35s;
  background-color: #C140FF;
  color: #FFFFFF;
}
@media (min-width: 768px) and (max-width: 991px) {
  esl-alert {
    width: 40%;
    right: 30%;
  }
}
@media (min-width: 992px) {
  esl-alert {
    width: 30%;
    right: 35%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  esl-alert {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  esl-alert {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
esl-alert[open] {
  opacity: 1;
  visibility: visible;
}
.esl-alert-content {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  transform: translate3d(0, 0, 0);
}
.esl-alert-content > svg,
.esl-alert-content > .alert-icon {
  fill: #FFFFFF;
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.75em;
  background-repeat: no-repeat;
  background-position: center center;
}
.esl-alert-content > .alert-icon.alert-icon-sm {
  width: 1em;
  height: 1em;
}
html[dir=rtl] .esl-alert-content > svg,
html[dir=rtl] .esl-alert-content > .alert-icon {
  margin-left: 0.75em;
  margin-right: 0;
}
.embedded-video {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
  background: black;
}
.embedded-video .play-button {
  background: none;
  border: none;
  padding: 0;
  z-index: 2;
  transition: opacity 200ms;
}
.embedded-video .play-button.off {
  opacity: 0;
}
.embedded-video .absolute-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.embedded-video .centered-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 1250px;
  max-height: 100%;
}
.embedded-video .centered-wrapper::before {
  display: block;
  content: '';
  padding-top: 56.25%;
}
.embedded-video .player-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}
.embedded-video .embedded-player {
  width: 100%;
  height: 100%;
}
.embedded-video .video-bkg {
  transition: opacity 300ms;
  z-index: 1;
  position: relative;
}
.embedded-video .play-button.icon-right,
.embedded-video.icon-right .play-button {
  margin-left: 300px;
}
.embedded-video .play-button.icon-left,
.embedded-video.icon-left .play-button {
  margin-left: -300px;
}
.embedded-video.video-showing-animation .video-bkg {
  opacity: 0;
  z-index: 0;
}
.embedded-video.video-showing-animation .play-button {
  opacity: 0;
}
.embedded-video.video-showing-animation .player-wrapper {
  visibility: visible;
}
.embedded-video .close-button {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 10px;
  right: -45px;
  display: none;
}
.embedded-video .close-button::before,
.embedded-video .close-button::after {
  position: absolute;
  left: 12px;
  top: -3px;
  content: '';
  height: 33.35px;
  width: 2px;
  background-color: white;
  opacity: 0.9;
}
.embedded-video .close-button:hover::before,
.embedded-video .close-button:hover::after {
  opacity: 1;
}
.embedded-video .close-button::before {
  transform: rotate(45deg);
}
.embedded-video .close-button::after {
  transform: rotate(-45deg);
}
@media (min-width: 1px) and (max-width: 1199px) {
  .embedded-video .close-button {
    right: -40px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .embedded-video .close-button {
    right: 0;
    top: -30px;
    width: 20px;
    height: 20px;
  }
  .embedded-video .close-button::before,
  .embedded-video .close-button::after {
    left: 9px;
    top: -3px;
    height: 26.3px;
  }
}
.embedded-video esl-share {
  --icon-share-size: 25px;
  position: absolute;
  top: 55px;
  right: -47px;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .embedded-video esl-share {
    right: -43px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .embedded-video esl-share {
    --icon-share-size: 20px;
    top: -29px;
    right: 35px;
  }
}
.embedded-video esl-share .icon-share-global {
  opacity: 0.9;
}
.embedded-video esl-share:hover .icon-share-global {
  opacity: 1;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .embedded-video {
    height: 420px;
  }
  .embedded-video .play-button.icon-right,
  .embedded-video.icon-right .play-button {
    margin-left: 200px;
  }
  .embedded-video .play-button.icon-left,
  .embedded-video.icon-left .play-button {
    margin-left: -200px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .embedded-video {
    height: 280px;
  }
  .embedded-video .play-button.icon-right,
  .embedded-video.icon-right .play-button {
    margin-left: 0;
  }
  .embedded-video .play-button.icon-left,
  .embedded-video.icon-left .play-button {
    margin-left: 0;
  }
}
[class*="img-container-"].img-container > .embedded-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
}
.img-container > .embedded-video .close-button {
  z-index: 1;
}
.video-info-module {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 62px;
  padding: 15px 30px;
}
@media (min-width: 1px) and (max-width: 767px) {
  .video-info-module {
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: center;
  }
}
.video-info-module .vim-title {
  width: 100%;
  margin: 0;
}
@media (min-width: 768px) {
  .video-info-module .vim-title {
    flex: 1 1 auto;
    width: calc(100% - 95px);
  }
}
.video-info-module .vim-description p:last-child {
  margin-bottom: 0;
}
.video-info-module .vim-description {
  flex: 0 0 auto;
  width: 100%;
}
.video-info-module .vim-description p:last-of-type {
  margin-bottom: 0;
}
.video-info-module .vim-description .text-collapsible {
  overflow: hidden;
}
.video-info-module .vim-description .tc-trigger {
  position: relative;
  display: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
}
.video-info-module .vim-description .tc-trigger,
.video-info-module .vim-description .tc-trigger .label-collapsed,
.video-info-module .vim-description .tc-trigger .label-expanded {
  color: #01A982;
}
@media (min-width: 768px) {
  .video-info-module .vim-description .tc-trigger {
    font-size: 16px;
    line-height: 18px;
  }
}
.video-info-module .vim-description.tc-enabled .tc-trigger {
  display: inline-block;
  border-bottom: 1px solid #01A982;
}
.video-info-module .vim-description.tc-enabled:not(.tc-expanded) .label-expanded {
  display: none;
}
.video-info-module .vim-description.tc-enabled.tc-expanded .label-collapsed {
  display: none;
}
.video-info-module .vim-links {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .video-info-module .vim-links {
    flex: 1 1 auto;
    width: calc(100% - 95px);
    align-items: flex-start;
  }
}
.video-info-module .vim-links .vim-link {
  max-width: 100%;
}
.video-info-module .vim-links .vim-link + .vim-link {
  margin-top: 15px;
}
.video-info-module .vim-title + .vim-description {
  margin-top: 13px;
}
.video-info-module .vim-title + .vim-links,
.video-info-module .vim-description + .vim-links {
  margin-top: 15px;
}
.video-info-module .vim-utility-container {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
}
.video-info-module .vim-utility-container esl-share {
  opacity: 0;
  visibility: hidden;
}
.video-info-module .vim-utility-container esl-share[ready] {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear;
}
.video-info-module .vim-utility-container .esl-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  max-width: 80px;
  margin: -5px 0;
}
.video-info-module .vim-utility-container .icon-share-global {
  flex: 0 0 auto;
  height: 23px;
  color: #01A982;
  font-size: 23px;
  line-height: 1;
}
.video-info-module .vim-utility-container .share-text {
  text-align: center;
}
@media (min-width: 768px) {
  .video-info-module .vim-utility-container {
    margin-left: 15px;
  }
  .video-info-module .vim-title + .vim-utility-container {
    margin-top: 0;
  }
  .video-info-module .vim-description + .vim-utility-container {
    margin-top: 15px;
    margin-left: auto;
  }
}
video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 120;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.65);
  transition: opacity 0.3s ease 0.05s, visibility 0s linear 0.35s;
}
video-overlay::before,
video-overlay::after {
  content: '';
  flex: 1 0 auto;
  min-height: 40px;
}
video-overlay[active] {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease 0.05s, visibility 0s linear 0s;
}
video-overlay[loading] .video-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 url('/apps/hpeweb-ui/images/loader.gif') 50% 50% no-repeat;
  background-size: 40px;
}
video-overlay .video-info-wrapper:empty {
  min-height: 60px;
}
video-overlay .video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
  background-color: #000000;
  box-shadow: 0 1px 5px 2px rgba(198, 201, 202, 0.2);
}
video-overlay .video-content {
  flex: 0 0 auto;
  overflow: hidden;
  background: none;
  box-shadow: none;
}
video-overlay[is-audio] .video-content:before {
  display: block;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='60px' height='60px' viewBox='0 0 512 512'%3E%3Cg fill='%23fff'%3E%3Cpath d='M231.5 147.2c-.3.7-.4 50.3-.3 110.3l.3 109h20v-220l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM201.5 187.2c-.3.7-.4 32.3-.3 70.3l.3 69h20v-140l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM261.5 187.2c-.3.7-.4 32.3-.3 70.3l.3 69h20v-140l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM141 256.5V307h20V206h-20v50.5zM291.5 217.2c-.3.7-.4 18.9-.3 40.3l.3 39h20v-80l-9.8-.3c-7.2-.2-9.9.1-10.2 1zM382 256.5V297h20v-81h-20v40.5zM111 256.5V287h20v-61h-20v30.5zM171 256.5V287h20v-61h-20v30.5zM322 256.5V287h20v-61h-20v30.5zM412.5 232.2c-.3.8-.4 12.1-.3 25.3l.3 24h20v-50l-9.8-.3c-7.3-.2-9.9.1-10.2 1zM80.5 237.2c-.3.8-.4 9.9-.3 20.3l.3 19h20v-40l-9.8-.3c-7.3-.2-9.9.1-10.2 1zM352 256.5V277h20v-41h-20v20.5zM50.4 247.3c-.2.7-.4 5.4-.2 10.3l.3 8.9h20v-20l-9.8-.3c-7.5-.2-9.9 0-10.3 1.1zM442.4 247.3c-.2.7-.4 5.4-.2 10.3l.3 8.9h20v-20l-9.8-.3c-7.5-.2-9.9 0-10.3 1.1z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  width: 100%;
  height: 40px;
}
video-overlay[is-audio] smart-media {
  top: auto;
  bottom: 0;
}
video-overlay[is-audio] .video-content::after {
  padding-top: 42px;
}
video-overlay .close-video-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: -40px;
  right: -12px;
  width: 46px;
  height: 46px;
  padding: 13px;
  font-size: 17px;
  color: #FFFFFF;
}
@media (min-width: 768px) {
  video-overlay .close-video-btn {
    top: -37px;
    right: -3px;
    width: 34px;
    height: 34px;
    padding: 6px;
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  video-overlay .video-container {
    max-width: 65vw;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  video-overlay .video-container {
    max-width: 80vw;
  }
}
@media (min-width: 1px) and (max-width: 767px) and (min-width: 550px) and (max-height: 450px) {
  video-overlay::before,
  video-overlay::after {
    min-height: 10px;
  }
  video-overlay .video-container {
    max-width: 75vw;
  }
  video-overlay .close-video-btn {
    top: -12px;
    right: -40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) and (max-height: 600px), (min-width: 992px) and (max-width: 1199px) and (max-height: 700px) {
  video-overlay::before,
  video-overlay::after {
    min-height: 10px;
  }
  video-overlay .video-container {
    max-width: 75vw;
  }
  video-overlay .close-video-btn {
    top: -3px;
    right: -37px;
  }
}
.disabled-focus video-overlay .video-container iframe {
  outline: none;
}
.sticky.sticky-active {
  position: fixed;
  top: 0;
}
.slide-carousel-dots {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: auto;
  bottom: 30px;
  width: 100%;
  height: auto;
  line-height: 11px;
  font-size: 0;
  text-align: center;
}
.slide-carousel-dots > .dot-nav {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 6px;
  margin: 0 5px;
  padding: 0;
  vertical-align: middle;
  background: rgba(118, 118, 118, 0.5);
  border: none;
}
.slide-carousel-dots > .dot-nav.active {
  width: 11px;
  height: 11px;
  cursor: default;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
}
.slide-carousel.dots-outside .slide-carousel-dots {
  position: static;
  padding: 30px 0;
}
.dots-style-contrast.slide-dark .slide-carousel-dots .dot-nav {
  border: 1px solid #BABABA;
  background: rgba(118, 118, 118, 0.5);
}
.dots-style-contrast.slide-dark .slide-carousel-dots .dot-nav.active {
  background: rgba(255, 255, 255, 0.95);
}
.dots-style-contrast.slide-light .slide-carousel-dots .dot-nav {
  border: 1px solid #A3A3A3;
  background: rgba(118, 118, 118, 0.95);
}
.dots-style-contrast.slide-light .slide-carousel-dots .dot-nav.active {
  border: 1px solid #BABABA;
  background: rgba(255, 255, 255, 0.95);
}
.dots-style-contrast .slide-carousel-dots .dot-nav {
  transition: background-color 0.25s linear;
}
.controls .slide-carousel-dots {
  display: block;
}
.slide-carousel-control {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  text-align: center;
  width: 68px;
  height: 68px;
  line-height: 68px;
}
.slide-carousel-control,
.slide-dark .slide-carousel-control {
  color: rgba(0, 0, 0, 0.5);
}
.slide-carousel-control:hover,
.slide-dark .slide-carousel-control:hover {
  color: rgba(0, 0, 0, 0.8);
}
.slide-light .slide-carousel-control {
  color: rgba(255, 255, 255, 0.6);
}
.slide-light .slide-carousel-control:hover {
  color: rgba(255, 255, 255, 0.9);
}
.slide-carousel-control .icon-arrow-left,
.slide-carousel-control .icon-arrow-right {
  vertical-align: middle;
  font-size: 68px;
}
@media (min-width: 1600px) {
  .slide-carousel-control.left {
    left: 25px;
  }
  .slide-carousel-control.right {
    right: 25px;
  }
}
@media (min-width: 1800px) {
  .slide-carousel-control.left {
    left: calc(50% - 875px);
  }
  .slide-carousel-control.right {
    right: calc(50% - 875px);
  }
}
@media (min-width: 992px) and (max-width: 1599px) {
  .slide-carousel-control.left {
    left: 5px;
  }
  .slide-carousel-control.right {
    right: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slide-carousel-control {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .slide-carousel-control .icon-arrow-left,
  .slide-carousel-control .icon-arrow-right {
    font-size: 50px;
  }
  .slide-carousel-control.right {
    right: 0;
  }
  .slide-carousel-control.left {
    left: 0;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .slide-carousel-control {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .slide-carousel-control .icon-arrow-left,
  .slide-carousel-control .icon-arrow-right {
    font-size: 30px;
  }
  .slide-carousel-control.right {
    right: 0;
  }
  .slide-carousel-control.left {
    left: 0;
  }
}
.controls .slide-carousel-control {
  display: block;
}
.slide-carousel {
  position: relative;
  touch-action: pan-y;
  --sc-siblings-shift: 7%;
}
.slide-carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.slide-carousel-inner > .item {
  position: relative;
  display: none;
  backface-visibility: hidden;
}
.slide-carousel-inner > .item.left,
.slide-carousel-inner > .item.right {
  transition: transform 0.3s ease-in-out;
}
.slide-carousel-inner > .item.next,
.slide-carousel-inner > .item.active.right {
  left: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-carousel-inner > .item.prev,
.slide-carousel-inner > .item.active.left {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-carousel-inner > .item.next.left,
.slide-carousel-inner > .item.prev.right,
.slide-carousel-inner > .item.active {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.slide-carousel-inner > .item.geipreset:not(.active) {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  opacity: 0;
  height: auto;
  min-height: auto;
}
.slide-carousel-inner > .active,
.slide-carousel-inner > .next,
.slide-carousel-inner > .prev {
  display: block;
}
.slide-carousel-inner > .next,
.slide-carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.slide-carousel-inner > .next,
.slide-carousel-inner > .active.right {
  left: 100%;
}
.slide-carousel-inner > .prev,
.slide-carousel-inner > .active.left {
  left: -100%;
}
.slide-carousel-inner > .active,
.slide-carousel-inner > .next.left,
.slide-carousel-inner > .prev.right {
  left: 0;
}
.slide-carousel-inner.layout-3-items > .item.next.left,
.slide-carousel-inner.layout-3-items > .item.prev.right,
.slide-carousel-inner.layout-3-items > .item.active {
  z-index: 1;
}
.slide-carousel-inner.layout-3-items > .item.visible-next-next,
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev,
.slide-carousel-inner.layout-3-items > .item.visible-next,
.slide-carousel-inner.layout-3-items > .item.visible-prev {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
}
.slide-carousel-inner.layout-3-items > .item.visible-next,
.slide-carousel-inner.layout-3-items > .item.visible-prev {
  z-index: 2;
}
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev,
.slide-carousel-inner.layout-3-items > .item.visible-next-next {
  z-index: 3;
}
.slide-carousel-inner.layout-3-items > .item.next,
.slide-carousel-inner.layout-3-items > .item.active.right {
  left: 0;
  transform: translate3d(calc(100% - var(--sc-siblings-shift)), 0, 0);
}
.slide-carousel-inner.layout-3-items > .item.prev,
.slide-carousel-inner.layout-3-items > .item.active.left {
  left: 0;
  transform: translate3d(calc(var(--sc-siblings-shift) - 100%), 0, 0);
}
.slide-carousel-inner.layout-3-items > .next,
.slide-carousel-inner.layout-3-items > .active.right {
  left: calc(100% - var(--sc-siblings-shift));
}
.slide-carousel-inner.layout-3-items > .prev,
.slide-carousel-inner.layout-3-items > .active.left {
  left: calc(var(--sc-siblings-shift) - 100%);
}
.slide-carousel-inner.layout-3-items > .item.visible-prev {
  left: calc(var(--sc-siblings-shift) - 100%);
}
.slide-carousel-inner.layout-3-items > .item.visible-next {
  left: calc(100% - var(--sc-siblings-shift));
}
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev {
  left: calc((-2 * (100% - var(--sc-siblings-shift))));
}
.slide-carousel-inner.layout-3-items > .item.visible-next-next {
  left: calc((2 * (100% - var(--sc-siblings-shift))));
}
.slide-carousel-inner.layout-3-items > .item.visible-prev,
.slide-carousel-inner.layout-3-items > .item.visible-next,
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev,
.slide-carousel-inner.layout-3-items > .item.visible-next-next {
  transform: translate3d(0, 0, 0);
}
.slide-carousel-inner.layout-3-items > .item.visible-prev.left,
.slide-carousel-inner.layout-3-items > .item.visible-next.left,
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev.left,
.slide-carousel-inner.layout-3-items > .item.visible-next-next.left {
  transform: translate3d(calc(var(--sc-siblings-shift) - 100%), 0, 0);
}
.slide-carousel-inner.layout-3-items > .item.visible-prev.right,
.slide-carousel-inner.layout-3-items > .item.visible-next.right,
.slide-carousel-inner.layout-3-items > .item.visible-prev-prev.right,
.slide-carousel-inner.layout-3-items > .item.visible-next-next.right {
  transform: translate3d(calc(100% - var(--sc-siblings-shift)), 0, 0);
}
.slide-carousel-fade .slide-carousel-inner > .item {
  transition: opacity 0.7s ease-in-out;
}
.slide-carousel-fade .slide-carousel-inner > .item,
.slide-carousel-fade .slide-carousel-inner > .active.left,
.slide-carousel-fade .slide-carousel-inner > .active.right {
  z-index: 1;
  opacity: 0;
}
.slide-carousel-fade .slide-carousel-inner > .active,
.slide-carousel-fade .slide-carousel-inner > .next.left,
.slide-carousel-fade .slide-carousel-inner > .prev.right {
  z-index: 2;
  opacity: 1;
}
.slide-carousel-fade .slide-carousel-inner > .next,
.slide-carousel-fade .slide-carousel-inner > .prev,
.slide-carousel-fade .slide-carousel-inner > .active.left,
.slide-carousel-fade .slide-carousel-inner > .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.slide-carousel.whole-component-video > .embedded-video {
  display: none;
}
.slide-carousel.whole-component-video.video-is-playing {
  background-color: #000;
  position: relative;
}
.slide-carousel.whole-component-video.video-is-playing .slide-carousel-inner {
  opacity: 0;
}
.slide-carousel.whole-component-video.video-is-playing > .embedded-video.is-playing {
  display: block;
  position: absolute;
  top: 0;
  z-index: 9;
  bottom: 0;
  height: 100%;
}
.drawer-head {
  background-color: #E7E7E7;
  user-select: none;
  border-top-width: 3px;
  position: relative;
  align-items: center;
  display: flex;
  height: 77px;
  cursor: pointer;
  margin-top: 50px;
}
.drawer-head:not([class*="coloredline"]) {
  border-top: 3px solid #BFBFBF;
}
.drawer-head:first-child {
  margin-top: 0;
}
.drawer-head .tag-h4 {
  margin-left: 76px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .drawer-head .tag-h4 {
    margin-left: 74px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .drawer-head .tag-h4 {
    margin-left: 52px;
  }
}
.drawer-head .accordion-arrow-icon {
  margin: 0 30px;
}
@media (min-width: 1px) and (max-width: 767px) {
  .drawer-head .accordion-arrow-icon {
    margin: 0 20px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .drawer-head {
    height: 71px;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .drawer-head {
    margin-top: 40px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .drawer-head {
    height: 59px;
  }
}
.drawer-icon {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 22px;
  top: 20px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .drawer-icon {
    width: 34px;
    height: 34px;
    top: 18px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .drawer-icon {
    width: 22px;
    height: 22px;
    left: 17px;
    top: 17px;
  }
}
.drawer-body {
  background-color: #E7E7E7;
}
.drawer-body .hpe-carousel {
  margin: 0 74px;
}
.drawer-body .hpe-carousel.dots-outside:not(.no-dots) .owl-carousel {
  padding-bottom: 72px;
}
.drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-prev {
  left: -70px;
}
.drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-next {
  right: -70px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-prev,
  .drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-next {
    width: 50px;
    height: 50px;
    margin-top: -60.5px;
  }
  .drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-prev {
    left: -46px;
  }
  .drawer-body .hpe-carousel.arrows-outside .owl-nav .owl-next {
    right: -46px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .drawer-body .hpe-carousel {
    margin: 0 48px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .drawer-body .hpe-carousel {
    margin: 0 3px;
  }
  .drawer-body .hpe-carousel .owl-nav {
    display: none;
  }
}
.drawer-body .around-hpe-card {
  min-width: 200px;
}
.opp {
  position: relative;
  border: 4px solid #559ae6;
}
.opp .opp-info {
  background-color: #559ae6;
  color: #FFF;
  padding: 5px;
  font-size: 13px;
  width: 90%;
  max-width: 250px;
  word-break: break-all;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: auto;
  right: -4px;
  z-index: 10;
}
.opp .opp-info a {
  color: inherit;
}
.opp .opp-ref-id {
  display: block;
  margin-top: -5px;
}
.opp .opp-info-txt {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}
.opp .opp-info-txt:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(to right, rgba(85, 154, 230, 0), #559ae6 100%);
}
.opp:hover .opp-info-txt {
  white-space: normal;
  overflow: inherit;
}
.opp:hover .opp-info-txt:after {
  display: none;
}
.generic-modal {
  --modal-container-offset-y: 40px;
}
@media (min-width: 768px) {
  .generic-modal {
    --modal-container-offset-y: 60px;
  }
}
.generic-modal.base-modal {
  overflow: hidden;
  z-index: 120;
}
.generic-modal .generic-modal-dialog {
  position: relative;
  z-index: 1;
  width: 1600px;
  height: calc(100% - 2*100px);
  max-height: calc(100% - 2*100px);
}
@media (min-width: 1px) and (max-width: 991px) {
  .generic-modal .generic-modal-dialog {
    width: 100%;
    height: calc(100% - 2*30px);
    max-height: calc(100% - 2*30px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .generic-modal .generic-modal-dialog {
    width: calc(100% - 60px);
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .generic-modal .generic-modal-dialog {
    width: calc(100% - 120px);
  }
}
.generic-modal .generic-modal-content {
  position: relative;
  height: 100%;
  background-color: #fff;
  background-clip: padding-box;
}
.generic-modal .generic-modal-content .scrollable-content {
  height: 100%;
  padding: 0 60px;
}
.generic-modal .generic-modal-content .scrollable-content::before,
.generic-modal .generic-modal-content .scrollable-content::after {
  content: '';
  display: block;
  height: 0;
}
.generic-modal .generic-modal-content .scrollable-content::before {
  margin-bottom: var(--modal-container-offset-y, 0);
}
.generic-modal .generic-modal-content .scrollable-content::after {
  margin-top: var(--modal-container-offset-y, 0);
}
.generic-modal .generic-modal-content .scrollable-content.omit-top-offset::before {
  display: none;
}
.generic-modal .generic-modal-content .scrollable-content.omit-bottom-offset::after {
  display: none;
}
.generic-modal .esl-footnotes-items {
  margin-bottom: var(--modal-container-offset-y, 0);
}
.generic-modal .generic-modal-scrollbar {
  top: 45px;
  bottom: 15px;
  right: 16px;
  width: 16px;
  padding: 5px;
}
.generic-modal .generic-modal-scrollbar:dir(rtl) .generic-modal .generic-modal-scrollbar {
  left: 16px;
  right: auto;
}
.generic-modal .generic-modal-scrollbar .scrollbar-track {
  margin: 0;
  background-color: #E7E7E7;
}
.generic-modal .generic-modal-scrollbar .scrollbar-thumb {
  background-color: #b5b5b5;
}
.generic-modal .generic-modal-header {
  padding-top: 60px;
}
.generic-modal .generic-modal-title {
  margin-top: -0.24818182em;
}
.generic-modal .generic-modal-description {
  margin-top: calc(20px - 0.31636363636363635em);
  margin-bottom: -0.31636364em;
}
.generic-modal .generic-modal-close {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
  color: #000000;
  font-size: 17px;
  line-height: 0;
  padding: 6px;
  text-shadow: #eee 0 0 5px;
}
.generic-modal.dark-theme .generic-modal-content {
  background-color: #000000;
}
.generic-modal.dark-theme .generic-modal-scrollbar .scrollbar-track {
  background-color: #404040;
}
.generic-modal.dark-theme .generic-modal-scrollbar .scrollbar-thumb {
  background-color: #888;
}
.generic-modal.dark-theme .generic-modal-close {
  color: #FFFFFF;
  text-shadow: #111 0 0 5px;
}
.generic-modal .container-fluid.container-gutter {
  margin: 0;
  padding: 0;
  max-width: none;
}
.generic-modal.uc-modal {
  --container-offset-x: 60px;
}
.generic-modal.uc-modal .generic-modal-dialog {
  height: auto;
}
.generic-modal.uc-modal .generic-modal-content .scrollable-content {
  padding-inline: 0;
}
.cta.hpe-arrow-link.live-chat-cta .btn-arrow::after,
.hpe-arrow-link .arrow,
.btn-v3[class*="sec-"] .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-orange .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FF8300%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-light-purple .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23C140FF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-medium-blue .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%2332DAC8%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-medium-purple .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237630EA%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-dark-blue .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%230D5265%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-light-blue .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237FF9E2%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-yellow .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FEC901%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-v3.sec-white .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.cta.hpe-arrow-link.white-arrow .arrow,
.btn-v3 .btn-arrow::after,
.btn-v3.dark-bkg .btn-arrow::after {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
[cta-group-mixin] .cta.cta {
  width: var(--width);
  height: var(--height);
}
[cta-group-mixin] .cta > :is(a, button) {
  width: 100%;
}
[cta-group-mixin] .cta > :is(a, button):not(.hpe-arrow-link) {
  height: 100%;
}
.play-icon {
  height: auto;
  display: inline-block;
  line-height: 0;
  position: relative;
  width: 44px;
}
button.play-icon {
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
  appearance: none;
}
.play-icon::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='rgba(0, 0, 0, 0.6)' stroke='white' stroke-width='6px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='36,58 60,44 36,30 '%3e%3c/polygon%3e%3c/svg%3e");
  display: inline-block;
  width: 100%;
  height: auto;
  line-height: 0;
}
.play-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='none' stroke='white' stroke-width='8px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='35,28.3 35,59.7 62,44'%3e%3c/polygon%3e%3c/svg%3e");
  opacity: 0;
  height: auto;
  line-height: 0;
  transition: opacity 200ms;
}
.clickable-area + .clickable-area-wrapper .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  max-width: 120px;
}
.clickable-area:hover + .clickable-area-wrapper .play-icon::after,
.play-icon:hover::after {
  opacity: 1;
}
.play-button-extended.cta {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (min-width: 992px) {
  .play-button-extended.cta {
    gap: 20px;
  }
}
.play-button-extended.cta .play-button-icon {
  line-height: 0;
  position: relative;
  width: 44px;
}
@media (min-width: 992px) {
  .play-button-extended.cta .play-button-icon {
    width: 70px;
  }
}
.play-button-extended.cta .play-button-icon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 71 71'%3E%3Cpath fill='%23fff' d='M35.472 68.801c18.225 0 32.999-14.845 32.999-33.158 0-18.313-14.774-33.158-32.999-33.158-18.224 0-32.998 14.845-32.998 33.158 0 18.313 14.774 33.158 32.998 33.158Z' opacity='.8'/%3E%3Cpath fill='%23000' d='m28.873 47.25 19.8-11.605-19.8-11.606V47.25Z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 100%;
}
.play-button-extended.cta .play-button-icon::after {
  position: absolute;
  inset: 0;
  width: 100%;
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='white' stroke='white' stroke-width='8px' cx='44' cy='44' r='40' %3e%3c/circle%3e%3cpolygon fill='black' points='35,28.3 35,59.7 62,44'%3e%3c/polygon%3e%3c/svg%3e");
  opacity: 0;
  transition: opacity 200ms;
}
.play-button-extended.cta:hover .play-button-icon::after,
.clickable-area:hover + .clickable-area-wrapper .play-button-extended.cta .play-button-icon::after {
  opacity: 1;
}
.play-button-extended.cta .play-button-content {
  text-align: left;
}
.play-button-extended.cta .play-button-content .play-button-label {
  font-weight: 700;
}
.cta-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cta {
  display: inline-block;
  appearance: none;
  border: none;
  background: none;
  box-shadow: none;
}
.cta.pull-center {
  align-self: center;
  justify-content: center;
  text-align: center;
}
.cta.pull-right {
  align-self: flex-end;
  text-align: right;
  float: none !important;
}
.cta.btn-v3.pull-right {
  text-align: center;
}
.cta.pull-right.hpe-arrow-link {
  justify-content: flex-end;
}
.cta.pull-right.hpe-arrow-link.btn-v3 {
  justify-content: center;
}
.cta.pull-left {
  float: none !important;
}
.cta.hpe-arrow-link.live-chat-cta .btn-arrow::after {
  content: "";
  display: inline-block;
  min-width: 1em;
  min-height: 1em;
  margin-left: 5px;
  vertical-align: middle;
  transform: translateX(0);
  transition: transform 150ms ease-in-out;
}
.cta.hpe-arrow-link.live-chat-cta:visited .btn-arrow::after,
.cta.hpe-arrow-link.live-chat-cta.hovered .btn-arrow::after,
.cta.hpe-arrow-link.live-chat-cta:hover .btn-arrow::after,
.cta.hpe-arrow-link.live-chat-cta:focus .btn-arrow::after {
  transform: translateX(5px);
}
.cta.hpe-arrow-link.hpe-icon-link {
  max-width: 100%;
  min-width: 0;
}
.cta.hpe-arrow-link.hpe-icon-link .icon {
  display: inline-block;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  vertical-align: middle;
  margin-right: 10px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .cta.hpe-arrow-link.hpe-icon-link .icon {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
  }
}
.cta.hpe-arrow-link.hpe-icon-link .icon svg {
  width: 100%;
  height: 100%;
}
.cta.hpe-arrow-link.hpe-icon-link .text {
  vertical-align: middle;
}
.cta.btn-v3.hpe-arrow-link .btn-arrow {
  padding-right: 0;
}
.cta.btn-v3.hpe-arrow-link .btn-arrow::after {
  content: none;
}
#truste-consent-track {
  z-index: 1 !important;
}
base-modal {
  display: none;
}
.base-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 121;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: var(--100vh, 100vh);
  overflow-y: scroll;
  animation: fade-in 0.2s linear;
}
.base-modal:not(.open) {
  display: none;
}
.base-modal::before,
.base-modal::after {
  content: '';
  flex: 1 0 30px;
}
.base-modal .close-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #707070;
  font-size: 21px;
  text-shadow: none;
  line-height: 0;
}
.base-modal .base-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.base-modal .base-modal-container {
  flex: 0 0 auto;
  position: relative;
  padding: 40px 50px 50px;
  width: 700px;
  max-width: calc(100vw - 40px);
  background-color: #fefefe;
  z-index: 0;
}
.base-modal .base-modal-container::after {
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
}
@media (min-width: 1px) and (max-width: 767px) {
  .base-modal .base-modal-container {
    margin: 30px 20px;
  }
}
.base-modal.container-gutter {
  margin: 0;
  padding: 0;
  max-width: 100vw;
}
.base-modal.container-gutter .base-modal-container {
  width: 100%;
  max-width: calc(100% - 30px);
}
@media (min-width: 768px) and (max-width: 1199px) {
  .base-modal.container-gutter .base-modal-container {
    max-width: calc(100% - 60px);
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .base-modal.container-gutter .base-modal-container {
    max-width: calc(100% - 100px);
  }
}
@media (min-width: 1600px) {
  .base-modal.container-gutter .base-modal-container {
    max-width: 1400px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .base-modal.xs-full-page .base-modal-container {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .base-modal.xs-full-page::before,
  .base-modal.xs-full-page::after {
    display: none;
  }
}
.black-gradient-modal-bd .base-modal .base-modal-backdrop {
  background: linear-gradient(to bottom, #000000 0%, #000000 100%);
  opacity: 0.4;
}
@media (min-width: 768px) {
  .black-gradient-modal-bd .base-modal .base-modal-backdrop {
    background-color: #000000;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.img-accent-gradient {
  display: none;
}
.img-container[hpe-img-accent] {
  --img-size-rel: 1;
  --img-accent-size: 0px;
  --img-accent-x: 0px;
  --img-accent-y: 0px;
  display: block;
}
.img-container[hpe-img-accent][hpe-img-accent*="right"] {
  --img-accent-x: var(--img-accent-size);
}
.img-container[hpe-img-accent][hpe-img-accent*="bottom"] {
  --img-accent-y: var(--img-accent-size);
}
.img-container[hpe-img-accent] .img-accent-gradient,
.img-container[hpe-img-accent] > .accented-content,
.img-container[hpe-img-accent] > :is(img, smart-image, smart-media) {
  position: absolute;
  width: calc(100% - var(--img-accent-size));
  height: calc(100% - var(--img-accent-size));
}
.img-container[hpe-img-accent] .img-accent-gradient {
  display: block;
  transition: opacity 0.4s;
  top: var(--img-accent-y);
  left: var(--img-accent-x);
}
.img-container[hpe-img-accent] > .accented-content,
.img-container[hpe-img-accent] > :is(img, smart-image, smart-media) {
  top: calc(var(--img-accent-size) - var(--img-accent-y));
  left: calc(var(--img-accent-size) - var(--img-accent-x));
}
.img-container[hpe-img-accent]:not(.img-container-loaded),
.img-container[hpe-img-accent][hpe-img-accent="none"] {
  --img-accent-x: 0px !important;
  --img-accent-y: 0px !important;
  --img-accent-size: 0px !important;
}
.img-container[hpe-img-accent]:not(.img-container-loaded) .img-accent-gradient,
.img-container[hpe-img-accent][hpe-img-accent="none"] .img-accent-gradient {
  opacity: 0;
}
.img-container[hpe-img-accent][hpe-img-accent="none"] .img-accent-gradient {
  opacity: 1;
}
.img-container[hpe-img-accent].img-container-1-1::after {
  padding-top: calc(100% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-3-1::after {
  padding-top: calc(33.333333333% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-6-9::after {
  padding-top: calc(150% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-9-6::after {
  padding-top: calc(66.666666666% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-16-9::after {
  padding-top: calc(56.25% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-4-3::after {
  padding-top: calc(75% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-26-9::after {
  padding-top: calc(34.615384615385% * var(--img-size-rel) + var(--img-accent-size));
}
.img-container[hpe-img-accent].img-container-original::after {
  padding-top: calc(var(--img-ratio) * var(--img-size-rel) + var(--img-accent-size));
}
[hpe-gradient-mixin] {
  --gradient-offset: 0;
  --gradient-offset-top: var(--gradient-offset);
  --gradient-offset-left: var(--gradient-offset);
  --gradient-offset-bottom: 0;
  --gradient-offset-right: 0;
  position: relative;
  padding: var(--gradient-offset-bottom) var(--gradient-offset-left) var(--gradient-offset-top) var(--gradient-offset-right);
}
[hpe-gradient-mixin] > .hpe-gradient-accent {
  position: absolute;
  top: var(--gradient-offset-top);
  bottom: var(--gradient-offset-bottom);
  left: var(--gradient-offset-left);
  right: var(--gradient-offset-right);
  z-index: -1;
}
[hpe-gradient-mixin][hpe-gradient-mixin*="top"] {
  --gradient-offset-top: 0;
  --gradient-offset-bottom: var(--gradient-offset);
}
[hpe-gradient-mixin][hpe-gradient-mixin*="left"] {
  --gradient-offset-left: 0;
  --gradient-offset-right: var(--gradient-offset);
}
esl-share-button {
  display: inline-flex;
  cursor: pointer;
}
esl-share-button:hover {
  opacity: 0.6;
}
esl-share-button[unavailable] {
  display: none;
}
esl-share-button .esl-share-icon {
  overflow: hidden;
  width: var(--esl-share-icon-size, 40px);
  height: var(--esl-share-icon-size, 40px);
}
esl-share-list {
  display: inline-flex;
  flex-wrap: wrap;
}
esl-share {
  display: inline-flex;
  cursor: pointer;
}
esl-share-popup.esl-popup {
  display: flex;
  flex-wrap: wrap;
  max-width: var(--esl-share-popup-max-width, 202px);
}
esl-share {
  color: #01A982;
}
esl-share .icon-share-global.icon-share-global {
  color: inherit;
  font-size: var(--icon-share-size, 20px);
}
esl-share-popup.esl-popup {
  --esl-share-icon-size: 28px;
  justify-content: center;
  padding: 20px;
  width: auto;
  min-width: 70px;
  max-width: 262px;
  gap: 20px;
  border-block: 4px solid var(--hpe-share-accent-color, #01A982);
}
@media (min-width: 992px) {
  esl-share-popup.esl-popup {
    padding: 15px;
    min-width: 60px;
    max-width: 212px;
    gap: 10px;
  }
}
esl-share-popup.esl-popup.open.open {
  z-index: 16384;
}
esl-share-popup.esl-popup[placed-at="top"] {
  border-bottom: none;
}
esl-share-popup.esl-popup[placed-at="bottom"] {
  border-top: none;
}
esl-share-button .esl-share-icon {
  fill: #fff;
}
.esl-share-alert .esl-alert-content::before {
  content: '';
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 9.69l-7.43 7.43 1.18 1.18 7.43-7.43L17 9.69z' fill='%23FFFFFF'/%3E%3Cpath d='M4.31 17.8c-.481.481-.48 1.29.00138 1.77l4.02 4.02c.481.481 1.29.483 1.77.00138l4.95-4.95c.481-.481.481-1.29-7e-7-1.78l-4.02-4.02c-.481-.481-1.29-.481-1.78 0l-4.95 4.95zm1.47.887l4.36-4.36 3.44 3.44-4.36 4.36-3.44-3.44zm7-9.37c-.481.481-.481 1.29 2.8e-7 1.78l4.02 4.02c.481.481 1.29.481 1.78 0l4.95-4.95c.481-.481.48-1.29-.00138-1.77l-4.02-4.02c-.481-.481-1.29-.483-1.77-.00138l-4.95 4.95zm1.47.889l4.36-4.36 3.44 3.44-4.36 4.36-3.44-3.44z' fill='%23FFFFFF'/%3E%3C/svg%3E");
  width: 26px;
  height: 26px;
  background-position: center center;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-modal-form::before,
  .hpe-modal-form::after {
    flex: 1 1 auto;
  }
}
.hpe-modal-form .error-message {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-modal-form .error-message {
    position: relative;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
  }
}
.hpe-modal-form > .base-modal-container {
  min-width: 600px;
  width: auto;
  max-width: 830px;
  min-height: 300px;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-modal-form > .base-modal-container {
    margin: 0;
    min-width: 100%;
    min-height: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .hpe-modal-form > .base-modal-container .close-btn {
    left: 30px;
    right: auto;
  }
  .hpe-modal-form > .base-modal-container .close-btn::before {
    content: "\f12f";
  }
}
@media (min-width: 768px) {
  .hpe-modal-form > .base-modal-container .form-modal-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dark-theme p,
body .dark-theme-prespecific p,
html body .dark-theme-specific p,
.dark-theme span,
body .dark-theme-prespecific span,
html body .dark-theme-specific span,
.dark-theme ul,
body .dark-theme-prespecific ul,
html body .dark-theme-specific ul,
.dark-theme li,
body .dark-theme-prespecific li,
html body .dark-theme-specific li,
.dark-theme div,
body .dark-theme-prespecific div,
html body .dark-theme-specific div,
.dark-theme strong,
body .dark-theme-prespecific strong,
html body .dark-theme-specific strong,
.dark-theme b,
body .dark-theme-prespecific b,
html body .dark-theme-specific b,
.dark-theme i,
body .dark-theme-prespecific i,
html body .dark-theme-specific i,
.dark-theme em,
body .dark-theme-prespecific em,
html body .dark-theme-specific em,
.dark-theme u,
body .dark-theme-prespecific u,
html body .dark-theme-specific u,
.dark-theme h2,
body .dark-theme-prespecific h2,
html body .dark-theme-specific h2,
.dark-theme h3,
body .dark-theme-prespecific h3,
html body .dark-theme-specific h3,
.dark-theme h4,
body .dark-theme-prespecific h4,
html body .dark-theme-specific h4,
.dark-theme h5,
body .dark-theme-prespecific h5,
html body .dark-theme-specific h5,
.dark-theme h6,
body .dark-theme-prespecific h6,
html body .dark-theme-specific h6,
.dark-theme .body-copy-large,
body .dark-theme-prespecific .body-copy-large,
html body .dark-theme-specific .body-copy-large,
.dark-theme .body-copy,
body .dark-theme-prespecific .body-copy,
html body .dark-theme-specific .body-copy,
.dark-theme .body-copy-small,
body .dark-theme-prespecific .body-copy-small,
html body .dark-theme-specific .body-copy-small,
.dark-theme .caption,
body .dark-theme-prespecific .caption,
html body .dark-theme-specific .caption,
.dark-theme a,
body .dark-theme-prespecific a,
html body .dark-theme-specific a,
.dark-theme a:hover,
body .dark-theme-prespecific a:hover,
html body .dark-theme-specific a:hover,
.dark-theme a:focus,
body .dark-theme-prespecific a:focus,
html body .dark-theme-specific a:focus,
.dark-theme a:visited,
body .dark-theme-prespecific a:visited,
html body .dark-theme-specific a:visited {
  color: #FFFFFF;
}
.light-theme p,
body .light-theme-prespecific p,
html body .light-theme-specific p,
.light-theme span,
body .light-theme-prespecific span,
html body .light-theme-specific span,
.light-theme ul,
body .light-theme-prespecific ul,
html body .light-theme-specific ul,
.light-theme li,
body .light-theme-prespecific li,
html body .light-theme-specific li,
.light-theme div,
body .light-theme-prespecific div,
html body .light-theme-specific div,
.light-theme strong,
body .light-theme-prespecific strong,
html body .light-theme-specific strong,
.light-theme b,
body .light-theme-prespecific b,
html body .light-theme-specific b,
.light-theme i,
body .light-theme-prespecific i,
html body .light-theme-specific i,
.light-theme em,
body .light-theme-prespecific em,
html body .light-theme-specific em,
.light-theme u,
body .light-theme-prespecific u,
html body .light-theme-specific u,
.light-theme h2,
body .light-theme-prespecific h2,
html body .light-theme-specific h2,
.light-theme h3,
body .light-theme-prespecific h3,
html body .light-theme-specific h3,
.light-theme h4,
body .light-theme-prespecific h4,
html body .light-theme-specific h4,
.light-theme h5,
body .light-theme-prespecific h5,
html body .light-theme-specific h5,
.light-theme h6,
body .light-theme-prespecific h6,
html body .light-theme-specific h6,
.light-theme .body-copy-large,
body .light-theme-prespecific .body-copy-large,
html body .light-theme-specific .body-copy-large,
.light-theme .body-copy,
body .light-theme-prespecific .body-copy,
html body .light-theme-specific .body-copy,
.light-theme .body-copy-small,
body .light-theme-prespecific .body-copy-small,
html body .light-theme-specific .body-copy-small,
.light-theme .caption,
body .light-theme-prespecific .caption,
html body .light-theme-specific .caption,
.light-theme a,
body .light-theme-prespecific a,
html body .light-theme-specific a,
.light-theme a:hover,
body .light-theme-prespecific a:hover,
html body .light-theme-specific a:hover,
.light-theme a:focus,
body .light-theme-prespecific a:focus,
html body .light-theme-specific a:focus,
.light-theme a:visited,
body .light-theme-prespecific a:visited,
html body .light-theme-specific a:visited {
  color: #000000;
}
a,
a:hover,
a:focus {
  text-decoration: none;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}
ul li {
  list-style-type: none;
}
.inline-link,
.inline-link-small {
  font-family: 'Metric Light', Arial, sans-serif;
  color: #000000;
  border-bottom: 1px solid #01A982;
  transition: all 0.1s ease;
}
.inline-link:hover,
.inline-link-small:hover {
  color: #000000;
  border-color: #707070;
}
.dark-theme .inline-link,
.dark-theme .inline-link-small,
body .dark-theme-prespecific .inline-link,
body .dark-theme-prespecific .inline-link-small,
html body .dark-theme-specific .inline-link,
html body .dark-theme-specific .inline-link-small {
  border-color: rgba(255, 255, 255, 0.6);
}
.dark-theme .inline-link:hover,
.dark-theme .inline-link-small:hover,
body .dark-theme-prespecific .inline-link:hover,
body .dark-theme-prespecific .inline-link-small:hover,
html body .dark-theme-specific .inline-link:hover,
html body .dark-theme-specific .inline-link-small:hover {
  border-color: #ffffff;
}
.light-theme .inline-link,
.light-theme .inline-link-small,
body .light-theme-prespecific .inline-link,
body .light-theme-prespecific .inline-link-small,
html body .light-theme-specific .inline-link,
html body .light-theme-specific .inline-link-small {
  border-color: #01A982;
}
.light-theme .inline-link:hover,
.light-theme .inline-link-small:hover,
body .light-theme-prespecific .inline-link:hover,
body .light-theme-prespecific .inline-link-small:hover,
html body .light-theme-specific .inline-link:hover,
html body .light-theme-specific .inline-link-small:hover {
  border-color: #707070;
}
.hpe-arrow-link {
  position: relative;
  font-size: 20px;
  line-height: 1.2em;
  --lh: 1.2em;
  --f-base-size: 20px;
  font-family: 'Metric Semibold', Arial, sans-serif;
  cursor: pointer;
  color: #01A982;
  fill: #01A982;
  text-decoration: none;
  padding-right: 5px;
}
.hpe-arrow-link .text {
  color: inherit;
}
.hpe-arrow-link:visited,
.hpe-arrow-link.hovered,
.hpe-arrow-link:hover,
.hpe-arrow-link:focus {
  color: #01A982;
  fill: #01A982;
  text-decoration: none;
}
.hpe-arrow-link:visited .arrow,
.hpe-arrow-link.hovered .arrow,
.hpe-arrow-link:hover .arrow,
body:not(.disabled-focus) .hpe-arrow-link:focus .arrow {
  transform: translateX(5px);
}
.hpe-arrow-link.white-type:not(#add-spec) {
  color: #FFFFFF;
  fill: #01A982;
}
.hpe-arrow-link.white-type:not(#add-spec):visited,
.hpe-arrow-link.white-type:not(#add-spec).hovered,
.hpe-arrow-link.white-type:not(#add-spec):hover,
.hpe-arrow-link.white-type:not(#add-spec):focus {
  color: #FFFFFF;
  fill: #01A982;
}
.hpe-arrow-link.white-type:not(#add-spec) .arrow,
.hpe-arrow-link.white-type:not(#add-spec) .arrow-wrapper {
  color: inherit;
  fill: #01A982;
}
.hpe-arrow-link .arrow-wrapper {
  white-space: nowrap;
}
.hpe-arrow-link .arrow {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 5px;
  vertical-align: text-bottom;
  transition: transform 150ms ease-in-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hpe-arrow-link {
    font-size: 19px;
    line-height: 1.21052632em;
    --lh: 1.21052632em;
    --f-base-size: 19px;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .hpe-arrow-link {
    font-size: 18px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 18px;
  }
}
@media (min-width: 768px) {
  .hpe-arrow-link.body-copy-small {
    font-size: 18px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 18px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-arrow-link.body-copy-small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
.hpe-arrow-link:hover .arrow,
body:not(.disabled-focus) .hpe-arrow-link:focus .arrow,
:is(.clickable-area:hover, .clickable-area:focus) ~ .clickable-area-wrapper .hpe-arrow-link .arrow {
  transform: translateX(5px);
}
.hpe-arrow-link.color-light-blue {
  color: #7FF9E2;
  fill: #7FF9E2;
}
.hpe-arrow-link.color-light-blue:visited,
.hpe-arrow-link.color-light-blue.hovered,
.hpe-arrow-link.color-light-blue:hover,
.hpe-arrow-link.color-light-blue:focus {
  color: #7FF9E2;
  fill: #7FF9E2;
}
.hpe-arrow-link.color-light-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237FF9E2%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-medium-blue {
  color: #32DAC8;
  fill: #32DAC8;
}
.hpe-arrow-link.color-medium-blue:visited,
.hpe-arrow-link.color-medium-blue.hovered,
.hpe-arrow-link.color-medium-blue:hover,
.hpe-arrow-link.color-medium-blue:focus {
  color: #32DAC8;
  fill: #32DAC8;
}
.hpe-arrow-link.color-medium-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%2332DAC8%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-dark-blue {
  color: #0D5265;
  fill: #0D5265;
}
.hpe-arrow-link.color-dark-blue:visited,
.hpe-arrow-link.color-dark-blue.hovered,
.hpe-arrow-link.color-dark-blue:hover,
.hpe-arrow-link.color-dark-blue:focus {
  color: #0D5265;
  fill: #0D5265;
}
.hpe-arrow-link.color-dark-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%230D5265%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-aruba-orange {
  color: #FF8300;
  fill: #FF8300;
}
.hpe-arrow-link.color-aruba-orange:visited,
.hpe-arrow-link.color-aruba-orange.hovered,
.hpe-arrow-link.color-aruba-orange:hover,
.hpe-arrow-link.color-aruba-orange:focus {
  color: #FF8300;
  fill: #FF8300;
}
.hpe-arrow-link.color-aruba-orange .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FF8300%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-light-purple {
  color: #C140FF;
  fill: #C140FF;
}
.hpe-arrow-link.color-light-purple:visited,
.hpe-arrow-link.color-light-purple.hovered,
.hpe-arrow-link.color-light-purple:hover,
.hpe-arrow-link.color-light-purple:focus {
  color: #C140FF;
  fill: #C140FF;
}
.hpe-arrow-link.color-light-purple .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23C140FF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-medium-purple {
  color: #7630EA;
  fill: #7630EA;
}
.hpe-arrow-link.color-medium-purple:visited,
.hpe-arrow-link.color-medium-purple.hovered,
.hpe-arrow-link.color-medium-purple:hover,
.hpe-arrow-link.color-medium-purple:focus {
  color: #7630EA;
  fill: #7630EA;
}
.hpe-arrow-link.color-medium-purple .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237630EA%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-yellow {
  color: #FEC901;
  fill: #FEC901;
}
.hpe-arrow-link.color-yellow:visited,
.hpe-arrow-link.color-yellow.hovered,
.hpe-arrow-link.color-yellow:hover,
.hpe-arrow-link.color-yellow:focus {
  color: #FEC901;
  fill: #FEC901;
}
.hpe-arrow-link.color-yellow .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FEC901%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-black {
  color: #000000;
  fill: #000000;
}
.hpe-arrow-link.color-black:visited,
.hpe-arrow-link.color-black.hovered,
.hpe-arrow-link.color-black:hover,
.hpe-arrow-link.color-black:focus {
  color: #000000;
  fill: #000000;
}
.hpe-arrow-link.color-black .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.color-white {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.hpe-arrow-link.color-white:visited,
.hpe-arrow-link.color-white.hovered,
.hpe-arrow-link.color-white:hover,
.hpe-arrow-link.color-white:focus {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.hpe-arrow-link.color-white .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.typo3,
.hpe-arrow-link.btn-v3 {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .hpe-arrow-link.typo3,
  .hpe-arrow-link.btn-v3 {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .hpe-arrow-link.typo3,
  .hpe-arrow-link.btn-v3 {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
.hpe-arrow-link.typo3.body-copy-small,
.hpe-arrow-link.btn-v3.body-copy-small {
  font-family: 'Metric Semibold', Arial, sans-serif;
  color: #01A982;
  font-size: 17px;
  line-height: 1.17647059em;
  --lh: 1.17647059em;
  --f-base-size: 17px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .hpe-arrow-link.typo3.body-copy-small,
  .hpe-arrow-link.btn-v3.body-copy-small {
    font-size: 16px;
    line-height: 1.1875em;
    --lh: 1.1875em;
    --f-base-size: 16px;
  }
}
.hpe-arrow-link.typo3.large,
.hpe-arrow-link.btn-v3.large {
  font-size: 24px;
  line-height: 1.08333333em;
  --lh: 1.08333333em;
  --f-base-size: 24px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .hpe-arrow-link.typo3.large,
  .hpe-arrow-link.btn-v3.large {
    font-size: 22px;
    line-height: 1.09090909em;
    --lh: 1.09090909em;
    --f-base-size: 22px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .hpe-arrow-link.typo3.large,
  .hpe-arrow-link.btn-v3.large {
    font-size: 16px;
    line-height: 1.125em;
    --lh: 1.125em;
    --f-base-size: 16px;
  }
}
.dark-theme .hpe-arrow-link,
.light-theme .hpe-arrow-link,
body .dark-theme-prespecific  .hpe-arrow-link,
body .light-theme-prespecific  .hpe-arrow-link,
html body .dark-theme-specific .hpe-arrow-link,
html body .light-theme-specific .hpe-arrow-link {
  color: #01A982;
  fill: #01A982;
}
.dark-theme .hpe-arrow-link:visited,
.light-theme .hpe-arrow-link:visited,
body .dark-theme-prespecific  .hpe-arrow-link:visited,
body .light-theme-prespecific  .hpe-arrow-link:visited,
html body .dark-theme-specific .hpe-arrow-link:visited,
html body .light-theme-specific .hpe-arrow-link:visited,
.dark-theme .hpe-arrow-link.hovered,
.light-theme .hpe-arrow-link.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.hovered,
body .light-theme-prespecific  .hpe-arrow-link.hovered,
html body .dark-theme-specific .hpe-arrow-link.hovered,
html body .light-theme-specific .hpe-arrow-link.hovered,
.dark-theme .hpe-arrow-link:hover,
.light-theme .hpe-arrow-link:hover,
body .dark-theme-prespecific  .hpe-arrow-link:hover,
body .light-theme-prespecific  .hpe-arrow-link:hover,
html body .dark-theme-specific .hpe-arrow-link:hover,
html body .light-theme-specific .hpe-arrow-link:hover,
.dark-theme .hpe-arrow-link:focus,
.light-theme .hpe-arrow-link:focus,
body .dark-theme-prespecific  .hpe-arrow-link:focus,
body .light-theme-prespecific  .hpe-arrow-link:focus,
html body .dark-theme-specific .hpe-arrow-link:focus,
html body .light-theme-specific .hpe-arrow-link:focus {
  color: #01A982;
  fill: #01A982;
}
.dark-theme .hpe-arrow-link .arrow,
.light-theme .hpe-arrow-link .arrow,
body .dark-theme-prespecific  .hpe-arrow-link .arrow,
body .light-theme-prespecific  .hpe-arrow-link .arrow,
html body .dark-theme-specific .hpe-arrow-link .arrow,
html body .light-theme-specific .hpe-arrow-link .arrow,
.dark-theme .hpe-arrow-link .arrow-wrapper,
.light-theme .hpe-arrow-link .arrow-wrapper,
body .dark-theme-prespecific  .hpe-arrow-link .arrow-wrapper,
body .light-theme-prespecific  .hpe-arrow-link .arrow-wrapper,
html body .dark-theme-specific .hpe-arrow-link .arrow-wrapper,
html body .light-theme-specific .hpe-arrow-link .arrow-wrapper,
.dark-theme .hpe-arrow-link .hpe-chat-hide-online,
.light-theme .hpe-arrow-link .hpe-chat-hide-online,
body .dark-theme-prespecific  .hpe-arrow-link .hpe-chat-hide-online,
body .light-theme-prespecific  .hpe-arrow-link .hpe-chat-hide-online,
html body .dark-theme-specific .hpe-arrow-link .hpe-chat-hide-online,
html body .light-theme-specific .hpe-arrow-link .hpe-chat-hide-online,
.dark-theme .hpe-arrow-link .hpe-chat-hide-offline,
.light-theme .hpe-arrow-link .hpe-chat-hide-offline,
body .dark-theme-prespecific  .hpe-arrow-link .hpe-chat-hide-offline,
body .light-theme-prespecific  .hpe-arrow-link .hpe-chat-hide-offline,
html body .dark-theme-specific .hpe-arrow-link .hpe-chat-hide-offline,
html body .light-theme-specific .hpe-arrow-link .hpe-chat-hide-offline {
  color: inherit;
  fill: #01A982;
}
.dark-theme .hpe-arrow-link.color-light-blue,
.light-theme .hpe-arrow-link.color-light-blue,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue,
html body .light-theme-specific .hpe-arrow-link.color-light-blue {
  color: #7FF9E2;
  fill: #7FF9E2;
}
.dark-theme .hpe-arrow-link.color-light-blue:visited,
.light-theme .hpe-arrow-link.color-light-blue:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue:visited,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue:visited,
html body .light-theme-specific .hpe-arrow-link.color-light-blue:visited,
.dark-theme .hpe-arrow-link.color-light-blue.hovered,
.light-theme .hpe-arrow-link.color-light-blue.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue.hovered,
html body .light-theme-specific .hpe-arrow-link.color-light-blue.hovered,
.dark-theme .hpe-arrow-link.color-light-blue:hover,
.light-theme .hpe-arrow-link.color-light-blue:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue:hover,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue:hover,
html body .light-theme-specific .hpe-arrow-link.color-light-blue:hover,
.dark-theme .hpe-arrow-link.color-light-blue:focus,
.light-theme .hpe-arrow-link.color-light-blue:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue:focus,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue:focus,
html body .light-theme-specific .hpe-arrow-link.color-light-blue:focus {
  color: #7FF9E2;
  fill: #7FF9E2;
}
.dark-theme .hpe-arrow-link.color-light-blue .arrow,
.light-theme .hpe-arrow-link.color-light-blue .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-blue .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-light-blue .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-light-blue .arrow,
html body .light-theme-specific .hpe-arrow-link.color-light-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237FF9E2%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-medium-blue,
.light-theme .hpe-arrow-link.color-medium-blue,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue {
  color: #32DAC8;
  fill: #32DAC8;
}
.dark-theme .hpe-arrow-link.color-medium-blue:visited,
.light-theme .hpe-arrow-link.color-medium-blue:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue:visited,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue:visited,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue:visited,
.dark-theme .hpe-arrow-link.color-medium-blue.hovered,
.light-theme .hpe-arrow-link.color-medium-blue.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue.hovered,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue.hovered,
.dark-theme .hpe-arrow-link.color-medium-blue:hover,
.light-theme .hpe-arrow-link.color-medium-blue:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue:hover,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue:hover,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue:hover,
.dark-theme .hpe-arrow-link.color-medium-blue:focus,
.light-theme .hpe-arrow-link.color-medium-blue:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue:focus,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue:focus,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue:focus {
  color: #32DAC8;
  fill: #32DAC8;
}
.dark-theme .hpe-arrow-link.color-medium-blue .arrow,
.light-theme .hpe-arrow-link.color-medium-blue .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-blue .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-blue .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-medium-blue .arrow,
html body .light-theme-specific .hpe-arrow-link.color-medium-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%2332DAC8%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-dark-blue,
.light-theme .hpe-arrow-link.color-dark-blue,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue {
  color: #0D5265;
  fill: #0D5265;
}
.dark-theme .hpe-arrow-link.color-dark-blue:visited,
.light-theme .hpe-arrow-link.color-dark-blue:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue:visited,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue:visited,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue:visited,
.dark-theme .hpe-arrow-link.color-dark-blue.hovered,
.light-theme .hpe-arrow-link.color-dark-blue.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue.hovered,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue.hovered,
.dark-theme .hpe-arrow-link.color-dark-blue:hover,
.light-theme .hpe-arrow-link.color-dark-blue:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue:hover,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue:hover,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue:hover,
.dark-theme .hpe-arrow-link.color-dark-blue:focus,
.light-theme .hpe-arrow-link.color-dark-blue:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue:focus,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue:focus,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue:focus {
  color: #0D5265;
  fill: #0D5265;
}
.dark-theme .hpe-arrow-link.color-dark-blue .arrow,
.light-theme .hpe-arrow-link.color-dark-blue .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-dark-blue .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-dark-blue .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-dark-blue .arrow,
html body .light-theme-specific .hpe-arrow-link.color-dark-blue .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%230D5265%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-aruba-orange,
.light-theme .hpe-arrow-link.color-aruba-orange,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange {
  color: #FF8300;
  fill: #FF8300;
}
.dark-theme .hpe-arrow-link.color-aruba-orange:visited,
.light-theme .hpe-arrow-link.color-aruba-orange:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange:visited,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange:visited,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange:visited,
.dark-theme .hpe-arrow-link.color-aruba-orange.hovered,
.light-theme .hpe-arrow-link.color-aruba-orange.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange.hovered,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange.hovered,
.dark-theme .hpe-arrow-link.color-aruba-orange:hover,
.light-theme .hpe-arrow-link.color-aruba-orange:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange:hover,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange:hover,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange:hover,
.dark-theme .hpe-arrow-link.color-aruba-orange:focus,
.light-theme .hpe-arrow-link.color-aruba-orange:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange:focus,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange:focus,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange:focus {
  color: #FF8300;
  fill: #FF8300;
}
.dark-theme .hpe-arrow-link.color-aruba-orange .arrow,
.light-theme .hpe-arrow-link.color-aruba-orange .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-aruba-orange .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-aruba-orange .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-aruba-orange .arrow,
html body .light-theme-specific .hpe-arrow-link.color-aruba-orange .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FF8300%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-light-purple,
.light-theme .hpe-arrow-link.color-light-purple,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple,
html body .light-theme-specific .hpe-arrow-link.color-light-purple {
  color: #C140FF;
  fill: #C140FF;
}
.dark-theme .hpe-arrow-link.color-light-purple:visited,
.light-theme .hpe-arrow-link.color-light-purple:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple:visited,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple:visited,
html body .light-theme-specific .hpe-arrow-link.color-light-purple:visited,
.dark-theme .hpe-arrow-link.color-light-purple.hovered,
.light-theme .hpe-arrow-link.color-light-purple.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple.hovered,
html body .light-theme-specific .hpe-arrow-link.color-light-purple.hovered,
.dark-theme .hpe-arrow-link.color-light-purple:hover,
.light-theme .hpe-arrow-link.color-light-purple:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple:hover,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple:hover,
html body .light-theme-specific .hpe-arrow-link.color-light-purple:hover,
.dark-theme .hpe-arrow-link.color-light-purple:focus,
.light-theme .hpe-arrow-link.color-light-purple:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple:focus,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple:focus,
html body .light-theme-specific .hpe-arrow-link.color-light-purple:focus {
  color: #C140FF;
  fill: #C140FF;
}
.dark-theme .hpe-arrow-link.color-light-purple .arrow,
.light-theme .hpe-arrow-link.color-light-purple .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-light-purple .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-light-purple .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-light-purple .arrow,
html body .light-theme-specific .hpe-arrow-link.color-light-purple .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23C140FF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-medium-purple,
.light-theme .hpe-arrow-link.color-medium-purple,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple {
  color: #7630EA;
  fill: #7630EA;
}
.dark-theme .hpe-arrow-link.color-medium-purple:visited,
.light-theme .hpe-arrow-link.color-medium-purple:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple:visited,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple:visited,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple:visited,
.dark-theme .hpe-arrow-link.color-medium-purple.hovered,
.light-theme .hpe-arrow-link.color-medium-purple.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple.hovered,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple.hovered,
.dark-theme .hpe-arrow-link.color-medium-purple:hover,
.light-theme .hpe-arrow-link.color-medium-purple:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple:hover,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple:hover,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple:hover,
.dark-theme .hpe-arrow-link.color-medium-purple:focus,
.light-theme .hpe-arrow-link.color-medium-purple:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple:focus,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple:focus,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple:focus {
  color: #7630EA;
  fill: #7630EA;
}
.dark-theme .hpe-arrow-link.color-medium-purple .arrow,
.light-theme .hpe-arrow-link.color-medium-purple .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-medium-purple .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-medium-purple .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-medium-purple .arrow,
html body .light-theme-specific .hpe-arrow-link.color-medium-purple .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%237630EA%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-yellow,
.light-theme .hpe-arrow-link.color-yellow,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow,
html body .dark-theme-specific .hpe-arrow-link.color-yellow,
html body .light-theme-specific .hpe-arrow-link.color-yellow {
  color: #FEC901;
  fill: #FEC901;
}
.dark-theme .hpe-arrow-link.color-yellow:visited,
.light-theme .hpe-arrow-link.color-yellow:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow:visited,
html body .dark-theme-specific .hpe-arrow-link.color-yellow:visited,
html body .light-theme-specific .hpe-arrow-link.color-yellow:visited,
.dark-theme .hpe-arrow-link.color-yellow.hovered,
.light-theme .hpe-arrow-link.color-yellow.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-yellow.hovered,
html body .light-theme-specific .hpe-arrow-link.color-yellow.hovered,
.dark-theme .hpe-arrow-link.color-yellow:hover,
.light-theme .hpe-arrow-link.color-yellow:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow:hover,
html body .dark-theme-specific .hpe-arrow-link.color-yellow:hover,
html body .light-theme-specific .hpe-arrow-link.color-yellow:hover,
.dark-theme .hpe-arrow-link.color-yellow:focus,
.light-theme .hpe-arrow-link.color-yellow:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow:focus,
html body .dark-theme-specific .hpe-arrow-link.color-yellow:focus,
html body .light-theme-specific .hpe-arrow-link.color-yellow:focus {
  color: #FEC901;
  fill: #FEC901;
}
.dark-theme .hpe-arrow-link.color-yellow .arrow,
.light-theme .hpe-arrow-link.color-yellow .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-yellow .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-yellow .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-yellow .arrow,
html body .light-theme-specific .hpe-arrow-link.color-yellow .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FEC901%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-black,
.light-theme .hpe-arrow-link.color-black,
body .dark-theme-prespecific  .hpe-arrow-link.color-black,
body .light-theme-prespecific  .hpe-arrow-link.color-black,
html body .dark-theme-specific .hpe-arrow-link.color-black,
html body .light-theme-specific .hpe-arrow-link.color-black {
  color: #000000;
  fill: #000000;
}
.dark-theme .hpe-arrow-link.color-black:visited,
.light-theme .hpe-arrow-link.color-black:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-black:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-black:visited,
html body .dark-theme-specific .hpe-arrow-link.color-black:visited,
html body .light-theme-specific .hpe-arrow-link.color-black:visited,
.dark-theme .hpe-arrow-link.color-black.hovered,
.light-theme .hpe-arrow-link.color-black.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-black.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-black.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-black.hovered,
html body .light-theme-specific .hpe-arrow-link.color-black.hovered,
.dark-theme .hpe-arrow-link.color-black:hover,
.light-theme .hpe-arrow-link.color-black:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-black:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-black:hover,
html body .dark-theme-specific .hpe-arrow-link.color-black:hover,
html body .light-theme-specific .hpe-arrow-link.color-black:hover,
.dark-theme .hpe-arrow-link.color-black:focus,
.light-theme .hpe-arrow-link.color-black:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-black:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-black:focus,
html body .dark-theme-specific .hpe-arrow-link.color-black:focus,
html body .light-theme-specific .hpe-arrow-link.color-black:focus {
  color: #000000;
  fill: #000000;
}
.dark-theme .hpe-arrow-link.color-black .arrow,
.light-theme .hpe-arrow-link.color-black .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-black .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-black .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-black .arrow,
html body .light-theme-specific .hpe-arrow-link.color-black .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.dark-theme .hpe-arrow-link.color-white,
.light-theme .hpe-arrow-link.color-white,
body .dark-theme-prespecific  .hpe-arrow-link.color-white,
body .light-theme-prespecific  .hpe-arrow-link.color-white,
html body .dark-theme-specific .hpe-arrow-link.color-white,
html body .light-theme-specific .hpe-arrow-link.color-white {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.dark-theme .hpe-arrow-link.color-white:visited,
.light-theme .hpe-arrow-link.color-white:visited,
body .dark-theme-prespecific  .hpe-arrow-link.color-white:visited,
body .light-theme-prespecific  .hpe-arrow-link.color-white:visited,
html body .dark-theme-specific .hpe-arrow-link.color-white:visited,
html body .light-theme-specific .hpe-arrow-link.color-white:visited,
.dark-theme .hpe-arrow-link.color-white.hovered,
.light-theme .hpe-arrow-link.color-white.hovered,
body .dark-theme-prespecific  .hpe-arrow-link.color-white.hovered,
body .light-theme-prespecific  .hpe-arrow-link.color-white.hovered,
html body .dark-theme-specific .hpe-arrow-link.color-white.hovered,
html body .light-theme-specific .hpe-arrow-link.color-white.hovered,
.dark-theme .hpe-arrow-link.color-white:hover,
.light-theme .hpe-arrow-link.color-white:hover,
body .dark-theme-prespecific  .hpe-arrow-link.color-white:hover,
body .light-theme-prespecific  .hpe-arrow-link.color-white:hover,
html body .dark-theme-specific .hpe-arrow-link.color-white:hover,
html body .light-theme-specific .hpe-arrow-link.color-white:hover,
.dark-theme .hpe-arrow-link.color-white:focus,
.light-theme .hpe-arrow-link.color-white:focus,
body .dark-theme-prespecific  .hpe-arrow-link.color-white:focus,
body .light-theme-prespecific  .hpe-arrow-link.color-white:focus,
html body .dark-theme-specific .hpe-arrow-link.color-white:focus,
html body .light-theme-specific .hpe-arrow-link.color-white:focus {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.dark-theme .hpe-arrow-link.color-white .arrow,
.light-theme .hpe-arrow-link.color-white .arrow,
body .dark-theme-prespecific  .hpe-arrow-link.color-white .arrow,
body .light-theme-prespecific  .hpe-arrow-link.color-white .arrow,
html body .dark-theme-specific .hpe-arrow-link.color-white .arrow,
html body .light-theme-specific .hpe-arrow-link.color-white .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20471.2%20471.2%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M456.57%20248.48a18.45%2018.45%200%200%200%205.43-12.9%2017.88%2017.88%200%200%200-5.43-12.9L340.08%20106.19a18.34%2018.34%200%200%200-25.93%2025.93l85.26%2085.13H27.53a18.33%2018.33%200%201%200%200%2036.66h371.88l-85.26%2085.26a18.34%2018.34%200%200%200%2025.93%2025.93z%22/%3E%3C/svg%3E') no-repeat;
}
.icon-lnk.typo3 .icon-wrap::before {
  font-size: 25px;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
}
.dark-theme .icon-lnk.typo3 .icon-wrap::after,
body .dark-theme-prespecific  .icon-lnk.typo3 .icon-wrap::after,
html body .dark-theme-specific .icon-lnk.typo3 .icon-wrap::after {
  border-color: #01A982;
}
.hpe-arrow-link-back {
  display: inline-block;
  padding: 0;
}
.hpe-arrow-link-back .arrow {
  transform: rotate(180deg) translateX(5px);
}
.hpe-arrow-link-back:hover .arrow {
  transform: rotate(180deg) translateX(10px);
}
.hpe-arrow-link.typo5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .hpe-arrow-link.typo5 {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1600px) {
  .hpe-arrow-link.typo5 {
    font-size: 24px;
    line-height: 1.25em;
    --lh: 1.25em;
    --f-base-size: 24px;
  }
}
.hpe-arrow-link.typo5 .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M9.58579%206.00005L5.89289%202.30716L7.30711%200.892944L12.7071%206.29294L13.4142%207.00005L12.7071%207.70716L7.30711%2013.1072L5.89289%2011.6929L9.58579%208.00005H0V6.00005H9.58579Z%22/%3E%3C/svg%3E') no-repeat;
  width: 0.7em;
  height: 0.85em;
  transition: transform 150ms ease-in-out;
  margin-left: 8px;
}
@media (min-width: 768px) {
  .hpe-arrow-link.typo5 .arrow {
    margin-left: 10px;
  }
}
.hpe-arrow-link.typo5 .link-text {
  color: inherit;
}
.hpe-arrow-link.typo5.large {
  font-size: 24px;
  line-height: 1.25em;
  --lh: 1.25em;
  --f-base-size: 24px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .hpe-arrow-link.typo5.large {
    font-size: 28px;
    line-height: 1.14285714em;
    --lh: 1.14285714em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1600px) {
  .hpe-arrow-link.typo5.large {
    font-size: 36px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 36px;
  }
}
.hpe-arrow-link.typo5.large .arrow {
  margin-left: 10px;
}
@media (min-width: 768px) {
  .hpe-arrow-link.typo5.large .arrow {
    margin-left: 12px;
  }
}
.hpe-arrow-link.typo5.small {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .hpe-arrow-link.typo5.small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1600px) {
  .hpe-arrow-link.typo5.small {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
@media (min-width: 992px) {
  .hpe-arrow-link.typo5.small .arrow {
    margin-left: 8px;
  }
}
.hpe-arrow-link.typo5.white-link:not(.add-spec) {
  color: #fff;
}
.hpe-arrow-link.typo5.white-link:not(.add-spec) .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M9.58579%206.00005L5.89289%202.30716L7.30711%200.892944L12.7071%206.29294L13.4142%207.00005L12.7071%207.70716L7.30711%2013.1072L5.89289%2011.6929L9.58579%208.00005H0V6.00005H9.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.hpe-arrow-link.typo5.black-link:not(.add-spec) {
  color: #444444;
}
.hpe-arrow-link.typo5.black-link:not(.add-spec) .arrow {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%23444444%22%20d%3D%22M9.58579%206.00005L5.89289%202.30716L7.30711%200.892944L12.7071%206.29294L13.4142%207.00005L12.7071%207.70716L7.30711%2013.1072L5.89289%2011.6929L9.58579%208.00005H0V6.00005H9.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.typo3.rich-text-container {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
@media (min-width: 1px) and (max-width: 1599px) {
  .typo3.rich-text-container {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .typo3.rich-text-container {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
.typo3 h1,
.typo3 h2,
.typo3 h3,
.typo3 h4,
.typo3 h5,
.typo3 h6,
.typo3 .tag-h1,
.typo3 .tag-h2,
.typo3 .tag-h3,
.typo3 .tag-h4,
.typo3 .tag-h5,
.typo3 .tag-h6 {
  font-family: 'Metric Semibold', Arial, sans-serif;
  color: #000000;
}
.typo3 [class*='body-copy'] {
  font-family: 'Metric Light', Arial, sans-serif;
  font-style: normal;
  color: #000000;
}
.typo3 .tag-h1,
.typo3 h1 {
  font-size: 60px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 60px;
  font-family: 'MetricHPE Black', Arial, sans-serif;
  text-transform: uppercase;
}
.typo3 .tag-h2,
.typo3 h2 {
  font-size: 50px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 50px;
  font-family: 'MetricHPE Black', Arial, sans-serif;
  text-transform: uppercase;
}
.typo3 .tag-h3,
.typo3 h3 {
  font-size: 40px;
  line-height: 1.075em;
  --lh: 1.075em;
  --f-base-size: 40px;
}
.typo3 .tag-h4,
.typo3 h4 {
  font-size: 28px;
  line-height: 1.10714286em;
  --lh: 1.10714286em;
  --f-base-size: 28px;
}
.typo3 .tag-h5,
.typo3 h5 {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
.typo3 .tag-h6,
.typo3 h6 {
  font-size: 17px;
  line-height: 1.17647059em;
  --lh: 1.17647059em;
  --f-base-size: 17px;
}
.typo3 .body-copy-large {
  font-size: 28px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 28px;
}
.typo3 .body-copy,
.typo3 .inline-link {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
.typo3 .body-copy-small,
.typo3 .inline-link-small {
  font-size: 17px;
  line-height: 1.17647059em;
  --lh: 1.17647059em;
  --f-base-size: 17px;
}
.typo3 .body-copy-xsmall {
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
}
.typo3 .tagline {
  font-family: 'Metric Light', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.11111111em;
  --lh: 1.11111111em;
  --f-base-size: 18px;
}
.typo3 .tagline-small {
  font-family: 'Metric Light', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
}
.typo3 .tag-h2-small {
  font-family: 'MetricHPE Black', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 40px;
}
.typo3 .tag-h3-large {
  font-family: 'Metric Semibold', Arial, sans-serif;
  font-size: 40px;
  line-height: 1.075em;
  --lh: 1.075em;
  --f-base-size: 40px;
}
@media (min-width: 1px) and (max-width: 1599px) {
  .typo3 .tag-h1,
  .typo3 h1 {
    font-size: 50px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 50px;
  }
  .typo3 .tag-h2,
  .typo3 h2 {
    font-size: 40px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 40px;
  }
  .typo3 .tag-h3,
  .typo3 h3 {
    font-size: 30px;
    line-height: 1.1em;
    --lh: 1.1em;
    --f-base-size: 30px;
  }
  .typo3 .tag-h4,
  .typo3 h4 {
    font-size: 26px;
    line-height: 1.11538462em;
    --lh: 1.11538462em;
    --f-base-size: 26px;
  }
  .typo3 .tag-h5,
  .typo3 h5 {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
  .typo3 .body-copy-large {
    font-size: 24px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 24px;
  }
  .typo3 .body-copy,
  .typo3 .inline-link {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
  .typo3 .body-copy-small,
  .typo3 .inline-link-small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
  .typo3 .tagline {
    font-size: 18px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 18px;
  }
  .typo3 .tag-h2-small {
    font-size: 30px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 30px;
  }
  .typo3 .tag-h3-large {
    font-size: 40px;
    line-height: 1.075em;
    --lh: 1.075em;
    --f-base-size: 40px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .typo3 .tag-h1,
  .typo3 h1 {
    font-size: 37px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 37px;
  }
  .typo3 .tag-h2,
  .typo3 h2 {
    font-size: 34px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 34px;
  }
  .typo3 .tag-h3,
  .typo3 h3 {
    font-size: 28px;
    line-height: 1.10714286em;
    --lh: 1.10714286em;
    --f-base-size: 28px;
  }
  .typo3 .tag-h4,
  .typo3 h4 {
    font-size: 22px;
    line-height: 1.13636364em;
    --lh: 1.13636364em;
    --f-base-size: 22px;
  }
  .typo3 .tag-h5,
  .typo3 h5 {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
  .typo3 .tag-h6,
  .typo3 h6 {
    font-size: 16px;
    line-height: 1.1875em;
    --lh: 1.1875em;
    --f-base-size: 16px;
  }
  .typo3 .body-copy-large {
    font-size: 20px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 20px;
  }
  .typo3 .body-copy,
  .typo3 .inline-link {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
  .typo3 .body-copy-small,
  .typo3 .inline-link-small {
    font-size: 16px;
    line-height: 1.1875em;
    --lh: 1.1875em;
    --f-base-size: 16px;
  }
  .typo3 .tagline {
    font-size: 16px;
    line-height: 1.125em;
    --lh: 1.125em;
    --f-base-size: 16px;
  }
  .typo3 .tag-h2-small {
    font-size: 28px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 28px;
  }
  .typo3 .tag-h3-large {
    font-size: 28px;
    line-height: 1.10714286em;
    --lh: 1.10714286em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .typo3 .tag-h1,
  .typo3 h1 {
    font-size: 28px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 28px;
  }
  .typo3 .body-copy-large {
    font-size: 18px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 18px;
  }
  .typo3 .body-copy-xsmall {
    font-size: 12px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 12px;
  }
  .typo3 .tagline-small {
    font-size: 12px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 12px;
  }
  .typo3 .tag-h3-large {
    font-size: 28px;
    line-height: 1.10714286em;
    --lh: 1.10714286em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .typo3 .tag-h2,
  .typo3 h2 {
    font-size: 28px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 28px;
  }
  .typo3 .tag-h3,
  .typo3 h3 {
    font-size: 24px;
    line-height: 1.125em;
    --lh: 1.125em;
    --f-base-size: 24px;
  }
  .typo3 .tag-h4,
  .typo3 h4 {
    font-size: 20px;
    line-height: 1.15em;
    --lh: 1.15em;
    --f-base-size: 20px;
  }
  .typo3 .body-copy-large {
    font-size: 17px;
    line-height: 1.11764706em;
    --lh: 1.11764706em;
    --f-base-size: 17px;
  }
  .typo3 .tag-h2-small {
    font-size: 24px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 24px;
  }
  .typo3 .tag-h3-large {
    font-size: 40px;
    line-height: 1.075em;
    --lh: 1.075em;
    --f-base-size: 40px;
  }
}
.typo3 slim-text {
  font-family: 'Metric Light', Arial, sans-serif;
  color: inherit !important;
}
.dark-theme h1,
body .dark-theme-prespecific h1,
html body .dark-theme-specific h1,
.dark-theme h2,
body .dark-theme-prespecific h2,
html body .dark-theme-specific h2,
.dark-theme h3,
body .dark-theme-prespecific h3,
html body .dark-theme-specific h3,
.dark-theme h4,
body .dark-theme-prespecific h4,
html body .dark-theme-specific h4,
.dark-theme h5,
body .dark-theme-prespecific h5,
html body .dark-theme-specific h5,
.dark-theme h6,
body .dark-theme-prespecific h6,
html body .dark-theme-specific h6,
.dark-theme .tag-h1,
body .dark-theme-prespecific .tag-h1,
html body .dark-theme-specific .tag-h1,
.dark-theme .tag-h2,
body .dark-theme-prespecific .tag-h2,
html body .dark-theme-specific .tag-h2,
.dark-theme .tag-h3,
body .dark-theme-prespecific .tag-h3,
html body .dark-theme-specific .tag-h3,
.dark-theme .tag-h3b,
body .dark-theme-prespecific .tag-h3b,
html body .dark-theme-specific .tag-h3b,
.dark-theme .tag-h4,
body .dark-theme-prespecific .tag-h4,
html body .dark-theme-specific .tag-h4,
.dark-theme .tag-h5,
body .dark-theme-prespecific .tag-h5,
html body .dark-theme-specific .tag-h5,
.dark-theme .tag-h6,
body .dark-theme-prespecific .tag-h6,
html body .dark-theme-specific .tag-h6,
.dark-theme .tag-h2-small,
body .dark-theme-prespecific .tag-h2-small,
html body .dark-theme-specific .tag-h2-small,
.dark-theme .tag-h3-large,
body .dark-theme-prespecific .tag-h3-large,
html body .dark-theme-specific .tag-h3-large,
.dark-theme [class*='body-copy'],
body .dark-theme-prespecific [class*='body-copy'],
html body .dark-theme-specific [class*='body-copy'] {
  color: #FFFFFF;
}
.light-theme h1,
body .light-theme-prespecific h1,
html body .light-theme-specific h1,
.light-theme h2,
body .light-theme-prespecific h2,
html body .light-theme-specific h2,
.light-theme h3,
body .light-theme-prespecific h3,
html body .light-theme-specific h3,
.light-theme h4,
body .light-theme-prespecific h4,
html body .light-theme-specific h4,
.light-theme h5,
body .light-theme-prespecific h5,
html body .light-theme-specific h5,
.light-theme h6,
body .light-theme-prespecific h6,
html body .light-theme-specific h6,
.light-theme .tag-h1,
body .light-theme-prespecific .tag-h1,
html body .light-theme-specific .tag-h1,
.light-theme .tag-h2,
body .light-theme-prespecific .tag-h2,
html body .light-theme-specific .tag-h2,
.light-theme .tag-h3,
body .light-theme-prespecific .tag-h3,
html body .light-theme-specific .tag-h3,
.light-theme .tag-h3b,
body .light-theme-prespecific .tag-h3b,
html body .light-theme-specific .tag-h3b,
.light-theme .tag-h4,
body .light-theme-prespecific .tag-h4,
html body .light-theme-specific .tag-h4,
.light-theme .tag-h5,
body .light-theme-prespecific .tag-h5,
html body .light-theme-specific .tag-h5,
.light-theme .tag-h6,
body .light-theme-prespecific .tag-h6,
html body .light-theme-specific .tag-h6,
.light-theme .tag-h2-small,
body .light-theme-prespecific .tag-h2-small,
html body .light-theme-specific .tag-h2-small,
.light-theme .tag-h3-large,
body .light-theme-prespecific .tag-h3-large,
html body .light-theme-specific .tag-h3-large,
.light-theme [class*='body-copy'],
body .light-theme-prespecific [class*='body-copy'],
html body .light-theme-specific [class*='body-copy'] {
  color: #000000;
}
.typo4,
.typo4 [class*='body-copy'] {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.typo4 .rich-text-container,
.typo4.rich-text-container {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .rich-text-container,
  .typo4.rich-text-container {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1200px) {
  .typo4 .rich-text-container,
  .typo4.rich-text-container {
    font-size: 22px;
    line-height: 1.27272727em;
    --lh: 1.27272727em;
    --f-base-size: 22px;
  }
}
.typo4 .rich-text-container,
.typo4.rich-text-container,
.typo4 .rich-text-container a,
.typo4.rich-text-container a,
.typo4 .rich-text-container p,
.typo4.rich-text-container p,
.typo4 .rich-text-container i,
.typo4.rich-text-container i,
.typo4 .rich-text-container b,
.typo4.rich-text-container b,
.typo4 .rich-text-container strong,
.typo4.rich-text-container strong,
.typo4 .rich-text-container sub,
.typo4.rich-text-container sub,
.typo4 .rich-text-container sup,
.typo4.rich-text-container sup {
  font-family: 'MetricHPEXS', Arial, sans-serif;
}
.typo4 .inline-link {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}
.typo4.btn-v3 {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.typo4.btn-v3 .btn-arrow::after {
  top: 0;
}
.typo4 .body-copy {
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .body-copy {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1200px) {
  .typo4 .body-copy {
    font-size: 22px;
    line-height: 1.27272727em;
    --lh: 1.27272727em;
    --f-base-size: 22px;
  }
}
.typo4 .body-copy-large {
  font-size: 20px;
  line-height: 1.2em;
  --lh: 1.2em;
  --f-base-size: 20px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .body-copy-large {
    font-size: 22px;
    line-height: 1.18181818em;
    --lh: 1.18181818em;
    --f-base-size: 22px;
  }
}
@media (min-width: 1200px) {
  .typo4 .body-copy-large {
    font-size: 26px;
    line-height: 1.26923077em;
    --lh: 1.26923077em;
    --f-base-size: 26px;
  }
}
.typo4 .body-copy-small {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .body-copy-small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  .typo4 .body-copy-small {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
.typo4 .body-copy-semi-small {
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .body-copy-semi-small {
    font-size: 15px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 15px;
  }
}
@media (min-width: 1200px) {
  .typo4 .body-copy-semi-small {
    font-size: 16px;
    line-height: 1.375em;
    --lh: 1.375em;
    --f-base-size: 16px;
  }
}
.typo4 .body-copy-xsmall {
  font-size: 12px;
  line-height: 1.16666667em;
  --lh: 1.16666667em;
  --f-base-size: 12px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 .body-copy-xsmall {
    font-size: 13px;
    line-height: 1.23076923em;
    --lh: 1.23076923em;
    --f-base-size: 13px;
  }
}
@media (min-width: 1200px) {
  .typo4 .body-copy-xsmall {
    font-size: 14px;
    line-height: 1.42857143em;
    --lh: 1.42857143em;
    --f-base-size: 14px;
  }
}
.typo4 h1,
.typo4 h2,
.typo4 h3,
.typo4 h4,
.typo4 h5,
.typo4 h6,
.typo4 .tag-h1,
.typo4 .tag-h2,
.typo4 .tag-h3,
.typo4 .tag-h4,
.typo4 .tag-h5,
.typo4 .tag-h6 {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  text-transform: inherit;
}
.typo4 .text-uppercase {
  text-transform: uppercase;
}
.typo4 h1,
.typo4 .tag-h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.13333333em;
  --lh: 1.13333333em;
  --f-base-size: 30px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h1,
  .typo4 .tag-h1 {
    font-size: 38px;
    line-height: 1.10526316em;
    --lh: 1.10526316em;
    --f-base-size: 38px;
  }
}
@media (min-width: 1200px) {
  .typo4 h1,
  .typo4 .tag-h1 {
    font-size: 53px;
    line-height: 1.11320755em;
    --lh: 1.11320755em;
    --f-base-size: 53px;
  }
}
.typo4 h1.large,
.typo4 .tag-h1.large {
  font-size: 38px;
  line-height: 1.10526316em;
  --lh: 1.10526316em;
  --f-base-size: 38px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h1.large,
  .typo4 .tag-h1.large {
    font-size: 65px;
    line-height: 1.06153846em;
    --lh: 1.06153846em;
    --f-base-size: 65px;
  }
}
@media (min-width: 1200px) {
  .typo4 h1.large,
  .typo4 .tag-h1.large {
    font-size: 88px;
    line-height: 1.06818182em;
    --lh: 1.06818182em;
    --f-base-size: 88px;
  }
}
.typo4 h1.xlarge,
.typo4 .tag-h1.xlarge {
  font-size: 40px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 40px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h1.xlarge,
  .typo4 .tag-h1.xlarge {
    font-size: 75px;
    line-height: 0.93333333em;
    --lh: 0.93333333em;
    --f-base-size: 75px;
  }
}
@media (min-width: 1200px) {
  .typo4 h1.xlarge,
  .typo4 .tag-h1.xlarge {
    font-size: 124px;
    line-height: 0.83870968em;
    --lh: 0.83870968em;
    --f-base-size: 124px;
  }
}
.typo4 h2,
.typo4 .tag-h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 1.15384615em;
  --lh: 1.15384615em;
  --f-base-size: 26px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h2,
  .typo4 .tag-h2 {
    font-size: 34px;
    line-height: 1.11764706em;
    --lh: 1.11764706em;
    --f-base-size: 34px;
  }
}
@media (min-width: 1200px) {
  .typo4 h2,
  .typo4 .tag-h2 {
    font-size: 44px;
    line-height: 1.13636364em;
    --lh: 1.13636364em;
    --f-base-size: 44px;
  }
}
.typo4 h2.small,
.typo4 .tag-h2.small {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h2.small,
  .typo4 .tag-h2.small {
    font-size: 25px;
    line-height: 1.16em;
    --lh: 1.16em;
    --f-base-size: 25px;
  }
}
@media (min-width: 1200px) {
  .typo4 h2.small,
  .typo4 .tag-h2.small {
    font-size: 31px;
    line-height: 1.19354839em;
    --lh: 1.19354839em;
    --f-base-size: 31px;
  }
}
.typo4 h2.xlarge,
.typo4 .tag-h2.xlarge {
  font-size: 36px;
  line-height: 1.11111111em;
  --lh: 1.11111111em;
  --f-base-size: 36px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h2.xlarge,
  .typo4 .tag-h2.xlarge {
    font-size: 55px;
    line-height: 1.07272727em;
    --lh: 1.07272727em;
    --f-base-size: 55px;
  }
}
@media (min-width: 1200px) {
  .typo4 h2.xlarge,
  .typo4 .tag-h2.xlarge {
    font-size: 71px;
    line-height: 1.08450704em;
    --lh: 1.08450704em;
    --f-base-size: 71px;
  }
}
.typo4 h3,
.typo4 .tag-h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.16666667em;
  --lh: 1.16666667em;
  --f-base-size: 24px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h3,
  .typo4 .tag-h3 {
    font-size: 28px;
    line-height: 1.14285714em;
    --lh: 1.14285714em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1200px) {
  .typo4 h3,
  .typo4 .tag-h3 {
    font-size: 36px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 36px;
  }
}
.typo4 h3.xlarge,
.typo4 .tag-h3.xlarge {
  font-size: 30px;
  line-height: 1.13333333em;
  --lh: 1.13333333em;
  --f-base-size: 30px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h3.xlarge,
  .typo4 .tag-h3.xlarge {
    font-size: 38px;
    line-height: 1.10526316em;
    --lh: 1.10526316em;
    --f-base-size: 38px;
  }
}
@media (min-width: 1200px) {
  .typo4 h3.xlarge,
  .typo4 .tag-h3.xlarge {
    font-size: 53px;
    line-height: 1.11320755em;
    --lh: 1.11320755em;
    --f-base-size: 53px;
  }
}
.typo4 h4,
.typo4 .tag-h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2em;
  --lh: 1.2em;
  --f-base-size: 20px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h4,
  .typo4 .tag-h4 {
    font-size: 22px;
    line-height: 1.18181818em;
    --lh: 1.18181818em;
    --f-base-size: 22px;
  }
}
@media (min-width: 1200px) {
  .typo4 h4,
  .typo4 .tag-h4 {
    font-size: 27px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 27px;
  }
}
.typo4 h4.xlarge,
.typo4 .tag-h4.xlarge {
  font-size: 24px;
  line-height: 1.16666667em;
  --lh: 1.16666667em;
  --f-base-size: 24px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h4.xlarge,
  .typo4 .tag-h4.xlarge {
    font-size: 28px;
    line-height: 1.14285714em;
    --lh: 1.14285714em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1200px) {
  .typo4 h4.xlarge,
  .typo4 .tag-h4.xlarge {
    font-size: 36px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 36px;
  }
}
.typo4 h4.large,
.typo4 .tag-h4.large {
  font-size: 22px;
  line-height: 1.18181818em;
  --lh: 1.18181818em;
  --f-base-size: 22px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h4.large,
  .typo4 .tag-h4.large {
    font-size: 25px;
    line-height: 1.16em;
    --lh: 1.16em;
    --f-base-size: 25px;
  }
}
@media (min-width: 1200px) {
  .typo4 h4.large,
  .typo4 .tag-h4.large {
    font-size: 31px;
    line-height: 1.19354839em;
    --lh: 1.19354839em;
    --f-base-size: 31px;
  }
}
.typo4 h4.small,
.typo4 .tag-h4.small {
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h4.small,
  .typo4 .tag-h4.small {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1200px) {
  .typo4 h4.small,
  .typo4 .tag-h4.small {
    font-size: 22px;
    line-height: 1.27272727em;
    --lh: 1.27272727em;
    --f-base-size: 22px;
  }
}
.typo4 h5,
.typo4 .tag-h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h5,
  .typo4 .tag-h5 {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1200px) {
  .typo4 h5,
  .typo4 .tag-h5 {
    font-size: 22px;
    line-height: 1.27272727em;
    --lh: 1.27272727em;
    --f-base-size: 22px;
  }
}
.typo4 h6,
.typo4 .tag-h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 h6,
  .typo4 .tag-h6 {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  .typo4 h6,
  .typo4 .tag-h6 {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
.typo4 ul li,
.typo4 ol li {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: inherit;
}
.typo4 ol li {
  color: inherit;
}
.typo4 b,
.typo4 strong {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.typo4.hpe-arrow-link {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4.hpe-arrow-link {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1200px) {
  .typo4.hpe-arrow-link {
    font-size: 22px;
    line-height: 1.27272727em;
    --lh: 1.27272727em;
    --f-base-size: 22px;
  }
}
.typo4.hpe-arrow-link.small {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4.hpe-arrow-link.small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  .typo4.hpe-arrow-link.small {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
.typo4.hpe-arrow-link.large {
  font-size: 24px;
  line-height: 1.08333333em;
  --lh: 1.08333333em;
  --f-base-size: 24px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .typo4.hpe-arrow-link.large {
    font-size: 22px;
    line-height: 1.09090909em;
    --lh: 1.09090909em;
    --f-base-size: 22px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .typo4.hpe-arrow-link.large {
    font-size: 16px;
    line-height: 1.125em;
    --lh: 1.125em;
    --f-base-size: 16px;
  }
}
.typo4.hpe-arrow-link .arrow {
  vertical-align: middle;
  margin-top: -2px;
}
.typo4 slim-text {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 300;
}
.typo4 .icon-lnk {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.typo4 .text-light {
  font-family: inherit;
  font-weight: 300;
}
.typo4 .text-regular {
  font-family: inherit;
  font-weight: 400;
}
.typo4 .text-semibold {
  font-family: inherit;
  font-weight: 600;
}
.typo4 .text-bold {
  font-family: inherit;
  font-weight: 700;
}
.typo4 smart-image {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .typo4 smart-image {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  .typo4 smart-image {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
[hpe-corner-style] {
  border-radius: var(--rounded-style-radius, none);
}
[hpe-corner-style="rounded"] {
  --rounded-style-radius: 12px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  [hpe-corner-style="rounded"] {
    --rounded-style-radius: 24px;
  }
}
@media (min-width: 1200px) {
  [hpe-corner-style="rounded"] {
    --rounded-style-radius: 36px;
  }
}
[hpe-corner-style="square"] {
  --rounded-style-radius: 0;
}
.typo5 {
  /** HPE Metrics font:
      em-size: 1000
      ascent: 794
      descent: 206
      cap-height: 614
      x-height: 444
      line-gap: 200

      highest point: 660 (relatively to baseline, letter f)
      lowest point: -180 (relatively to baseline, letter g)
  */
  --f-crop-enable: 1;
  /** ratio to crop to X-HEIGHT */
  --f-extra-space-top-ratio: 0.134;
  --f-extra-space-bottom-ratio: 0.026;
  /** ratio to crop to CAPITAL */
  --f-extra-space-top-ratio-cap: 0.18;
  --f-extra-space-bottom-ratio-cap: 0.206;
}
.typo5,
.typo5 [class*='body-copy'] {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.typo5 .rich-text-container,
.typo5.rich-text-container {
  color: var(--typo-text-color, inherit);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .rich-text-container,
  .typo5.rich-text-container {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1600px) {
  .typo5 .rich-text-container,
  .typo5.rich-text-container {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.25em;
    --lh: 1.25em;
    --f-base-size: 24px;
  }
}
.typo5 .rich-text-container,
.typo5.rich-text-container,
.typo5 .rich-text-container a,
.typo5.rich-text-container a,
.typo5 .rich-text-container p,
.typo5.rich-text-container p,
.typo5 .rich-text-container i,
.typo5.rich-text-container i,
.typo5 .rich-text-container b,
.typo5.rich-text-container b,
.typo5 .rich-text-container strong,
.typo5.rich-text-container strong,
.typo5 .rich-text-container u,
.typo5.rich-text-container u,
.typo5 .rich-text-container sub,
.typo5.rich-text-container sub,
.typo5 .rich-text-container sup,
.typo5.rich-text-container sup,
.typo5 .rich-text-container ul,
.typo5.rich-text-container ul,
.typo5 .rich-text-container ol,
.typo5.rich-text-container ol,
.typo5 .rich-text-container li,
.typo5.rich-text-container li,
.typo5 .rich-text-container span,
.typo5.rich-text-container span,
.typo5 .rich-text-container div,
.typo5.rich-text-container div {
  font-family: 'MetricHPEXS', Arial, sans-serif;
}
.typo5 .rich-text-container.rich-text-container p,
.typo5 .rich-text-container.rich-text-container a,
.typo5 .rich-text-container.rich-text-container i,
.typo5 .rich-text-container.rich-text-container b,
.typo5 .rich-text-container.rich-text-container strong,
.typo5 .rich-text-container.rich-text-container u,
.typo5 .rich-text-container.rich-text-container sub,
.typo5 .rich-text-container.rich-text-container sup,
.typo5 .rich-text-container.rich-text-container ul,
.typo5 .rich-text-container.rich-text-container ol,
.typo5 .rich-text-container.rich-text-container li,
.typo5 .rich-text-container.rich-text-container span,
.typo5 .rich-text-container.rich-text-container div {
  color: inherit;
}
.typo5 .rich-text-container.rich-text-container u {
  border-color: inherit;
}
.typo5 .rich-text-container.rich-text-container a {
  border-bottom-color: var(--typo-link-underline-color);
}
.typo5 .rich-text-container.rich-text-container a:hover {
  border-bottom-color: var(--typo-link-hover-underline-color);
}
.typo5 .inline-link {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.typo5 .body-copy {
  color: var(--typo-text-color, inherit);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .body-copy {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1600px) {
  .typo5 .body-copy {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.25em;
    --lh: 1.25em;
    --f-base-size: 24px;
  }
}
.typo5 .body-copy-large {
  color: var(--typo-text-color, inherit);
  font-weight: 300;
  font-size: 24px;
  line-height: 1.25em;
  --lh: 1.25em;
  --f-base-size: 24px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .body-copy-large {
    font-size: 28px;
    line-height: 1.14285714em;
    --lh: 1.14285714em;
    --f-base-size: 28px;
  }
}
@media (min-width: 1600px) {
  .typo5 .body-copy-large {
    font-size: 36px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 36px;
  }
}
.typo5 .body-copy-small {
  color: var(--typo-text-color, inherit);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .body-copy-small {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1600px) {
  .typo5 .body-copy-small {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
.typo5 .body-copy-xsmall {
  color: var(--typo-text-color, inherit);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .body-copy-xsmall {
    font-size: 15px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 15px;
  }
}
@media (min-width: 1600px) {
  .typo5 .body-copy-xsmall {
    font-size: 16px;
    line-height: 1.25em;
    --lh: 1.25em;
    --f-base-size: 16px;
  }
}
.typo5 .quote {
  color: var(--typo-quote-color, inherit);
  font-weight: 300;
  font-size: 36px;
  line-height: 1.16666667em;
  --lh: 1.16666667em;
  --f-base-size: 36px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .quote {
    font-size: 48px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 48px;
  }
}
@media (min-width: 1600px) {
  .typo5 .quote {
    font-size: 60px;
    line-height: 1.13333333em;
    --lh: 1.13333333em;
    --f-base-size: 60px;
  }
}
.typo5 .quote.large {
  color: var(--typo-quote-color, inherit);
  font-size: 48px;
  line-height: 1.16666667em;
  --lh: 1.16666667em;
  --f-base-size: 48px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .quote.large {
    font-size: 60px;
    line-height: 1.13333333em;
    --lh: 1.13333333em;
    --f-base-size: 60px;
  }
}
@media (min-width: 1600px) {
  .typo5 .quote.large {
    font-size: 72px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 72px;
  }
}
.typo5 .quote.small {
  color: var(--typo-quote-color, inherit);
  font-size: 28px;
  line-height: 1.21428571em;
  --lh: 1.21428571em;
  --f-base-size: 28px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 .quote.small {
    font-size: 36px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 36px;
  }
}
@media (min-width: 1600px) {
  .typo5 .quote.small {
    font-size: 48px;
    line-height: 1.16666667em;
    --lh: 1.16666667em;
    --f-base-size: 48px;
  }
}
.typo5 h1,
.typo5 h2,
.typo5 h3,
.typo5 h4,
.typo5 h5,
.typo5 h6,
.typo5 .tag-h1,
.typo5 .tag-h2,
.typo5 .tag-h3,
.typo5 .tag-h4,
.typo5 .tag-h5,
.typo5 .tag-h6 {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  text-transform: inherit;
  color: var(--typo-heading-color, inherit);
}
.typo5 h1,
.typo5 .tag-h1 {
  font-weight: 400;
  font-size: 48px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 48px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h1,
  .typo5 .tag-h1 {
    font-size: 60px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 60px;
  }
}
@media (min-width: 1600px) {
  .typo5 h1,
  .typo5 .tag-h1 {
    font-size: 72px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 72px;
  }
}
.typo5 h1.large,
.typo5 .tag-h1.large {
  font-size: 48px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 48px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h1.large,
  .typo5 .tag-h1.large {
    font-size: 72px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 72px;
  }
}
@media (min-width: 1600px) {
  .typo5 h1.large,
  .typo5 .tag-h1.large {
    font-size: 96px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 96px;
  }
}
.typo5 h2,
.typo5 .tag-h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 36px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h2,
  .typo5 .tag-h2 {
    font-size: 48px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 48px;
  }
}
@media (min-width: 1600px) {
  .typo5 h2,
  .typo5 .tag-h2 {
    font-size: 60px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 60px;
  }
}
.typo5 h3,
.typo5 .tag-h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 24px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h3,
  .typo5 .tag-h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 36px;
  }
}
@media (min-width: 1600px) {
  .typo5 h3,
  .typo5 .tag-h3 {
    font-weight: 400;
    font-size: 48px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 48px;
  }
}
.typo5 h4,
.typo5 .tag-h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 18px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h4,
  .typo5 .tag-h4 {
    font-size: 24px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 24px;
  }
}
@media (min-width: 1600px) {
  .typo5 h4,
  .typo5 .tag-h4 {
    font-weight: 400;
    font-size: 36px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 36px;
  }
}
.typo5 h5,
.typo5 .tag-h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22222222em;
  --lh: 1.22222222em;
  --f-base-size: 18px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h5,
  .typo5 .tag-h5 {
    font-size: 20px;
    line-height: 1.2em;
    --lh: 1.2em;
    --f-base-size: 20px;
  }
}
@media (min-width: 1600px) {
  .typo5 h5,
  .typo5 .tag-h5 {
    font-size: 24px;
    line-height: 1.25em;
    --lh: 1.25em;
    --f-base-size: 24px;
  }
}
.typo5 h6,
.typo5 .tag-h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 h6,
  .typo5 .tag-h6 {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1600px) {
  .typo5 h6,
  .typo5 .tag-h6 {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
.typo5 ul li,
.typo5 ol li {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: inherit;
}
.typo5 ol li {
  color: inherit;
}
.typo5 b,
.typo5 strong {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.typo5 slim-text {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 300;
}
.typo5 .icon-lnk {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.typo5 .text-light {
  font-family: inherit;
  font-weight: 300;
}
.typo5 .text-regular {
  font-family: inherit;
  font-weight: 400;
}
.typo5 .text-semibold {
  font-family: inherit;
  font-weight: 600;
}
.typo5 .text-uppercase {
  text-transform: uppercase;
}
.typo5 .text-bold {
  font-family: inherit;
  font-weight: 700;
}
.typo5 smart-image {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .typo5 smart-image {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1600px) {
  .typo5 smart-image {
    font-size: 18px;
    line-height: 1.22222222em;
    --lh: 1.22222222em;
    --f-base-size: 18px;
  }
}
.typo5 .hpe-note-wrapper {
  color: inherit;
}
.typo5 :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) i,
.typo5 :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) b,
.typo5 :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) strong,
.typo5 :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) u,
.typo5 :is(h1, h2, h3, h4, h5, h6, .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6) span {
  color: inherit;
}
.typo5 .crop-top-extra-space {
  --_ratioT: var(--f-extra-space-top-ratio, 0);
  margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.typo5 .crop-bottom-extra-space {
  --_ratioB: var(--f-extra-space-bottom-ratio, 0);
  margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.typo5 .crop-extra-space {
  --_ratioT: var(--f-extra-space-top-ratio, 0);
  margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
  --_ratioB: var(--f-extra-space-bottom-ratio, 0);
  margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.typo5 .crop-to-capital.crop-top-extra-space {
  --_ratioT: var(--f-extra-space-top-ratio-cap, 0);
  margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.typo5 .crop-to-capital.crop-bottom-extra-space {
  --_ratioB: var(--f-extra-space-bottom-ratio-cap, 0);
  margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.typo5 .crop-to-capital.crop-extra-space {
  --_ratioT: var(--f-extra-space-top-ratio-cap, 0);
  margin-top: calc(var(--crop-margin-top, 0px) - (1em * var(--_ratioT) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
  --_ratioB: var(--f-extra-space-bottom-ratio-cap, 0);
  margin-bottom: calc(var(--crop-margin-bottom, 0px) - (1em * var(--_ratioB) + (var(--lh) - 1em) / 2) * var(--f-crop-enable, 0));
}
.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --typo-text-color: #FFFFFF;
  --typo-quote-color: #FFFFFF;
  --typo-heading-color: #FFFFFF;
  --typo-icons-color: #FFFFFF;
  --typo-link-underline-color: rgba(255, 255, 255, 0.6);
  --typo-link-hover-underline-color: #FFFFFF;
}
body,
.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --typo-text-color: #6f6f6f;
  --typo-quote-color: #444444;
  --typo-heading-color: #444444;
  --typo-icons-color: #000000;
  --typo-link-underline-color: #01A982;
  --typo-link-hover-underline-color: #707070;
}
/** @deprecated */
.close-icon-sm {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 20px;
  line-height: 1;
  margin: 15px 15px 0 0;
  opacity: 0.9;
}
.close-icon-sm::after {
  content: "\f128";
  font-family: 'hpe-glyphicons';
}
.close-icon-sm:hover {
  opacity: 1;
}
.search-icon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M22.3 23.9l-7.2-7.2c-1.6 1.3-3.6 2-5.8 2-5.1 0-9.3-4.2-9.3-9.3C0 4.3 4.2.1 9.3.1c5.1 0 9.3 4.2 9.3 9.3 0 2.2-.7 4.2-2 5.8l7.2 7.2-1.5 1.5zM9.4 2.3c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 1em;
  height: 1em;
}
.play-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20%;
  max-width: 120px;
  height: auto;
  line-height: 0;
  transform: translate(-50%, -50%);
}
.play-video-icon::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='rgba(0, 0, 0, 0.6)' stroke='white' stroke-width='6px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='36,58 60,44 36,30 '%3e%3c/polygon%3e%3c/svg%3e");
  display: inline-block;
  width: 100%;
  height: auto;
  line-height: 0;
}
.play-video-icon::after {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg focusable='false' viewBox='0 0 88 88' style='enable-background:new 0 0 88 88;max-width:120px;max-height:120px;display:block' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3e%3ccircle fill='none' stroke='white' stroke-width='8px' cx='44' cy='44' r='40'%3e%3c/circle%3e%3cpolygon fill='white' points='35,28.3 35,59.7 62,44'%3e%3c/polygon%3e%3c/svg%3e");
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  line-height: 0;
  transition: opacity 200ms;
}
.play-video-icon.inline-icon {
  width: 64px;
  height: 64px;
}
@media (min-width: 1200px) {
  .play-video-icon.inline-icon {
    width: 104px;
    height: 104px;
  }
}
a:hover.play-video-icon::after,
button:hover.play-video-icon::after,
a:hover .play-video-icon::after,
button:hover .play-video-icon::after {
  opacity: 1;
}
.close-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  color: #01A982;
  text-decoration: none;
  font-size: 20px;
  line-height: 1;
}
.close-icon::after {
  content: "\f128";
  font-family: 'hpe-glyphicons';
}
.chevron-arrow {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%2301A982' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 16px;
  height: 9px;
}
.chevron-arrow.right {
  rotate: -90deg;
}
.chevron-arrow.left {
  rotate: 90deg;
}
.chevron-arrow.top {
  rotate: 180deg;
}
.chevron-arrow.gray {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23757575' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
}
.dark-theme .chevron-arrow.gray {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23BBBBBB' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
}
.chevron-arrow.black {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23444' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
}
.dark-theme .chevron-arrow.black {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23FFF' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
}
.pencil-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.04 23.04'%3E%3Cpath d='M6.46 16.59l8-8m-12 10l2 2M21.75 4.86a1 1 0 010 1.42L8.46 19.59l-7 2 2-7L16.75 1.3a1 1 0 011.41 0zm-8.29-1.27l6 6z' fill='none' stroke='%2301A982' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}
.bookmark-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2012.08%2014.45%22%3E%3Cpolygon%20points%3D%220.52%200.52%200.52%2013.55%206.04%2010.45%2011.56%2013.55%2011.56%200.52%200.52%200.52%22%20fill%3D%22none%22%20stroke%3D%22%2301A982%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.05%22/%3E%3C/svg%3E') no-repeat;
}
.bookmark-white-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2012.08%2014.45%22%3E%3Cpolygon%20points%3D%220.52%200.52%200.52%2013.55%206.04%2010.45%2011.56%2013.55%2011.56%200.52%200.52%200.52%22%20fill%3D%22%23FFFFFF%22%20stroke%3D%22%23FFFFFF%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.05%22/%3E%3C/svg%3E') no-repeat;
}
.active-bookmark-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2012.08%2014.45%22%3E%3Cpolygon%20points%3D%220.52%200.52%200.52%2013.55%206.04%2010.45%2011.56%2013.55%2011.56%200.52%200.52%200.52%22%20fill%3D%22%2301A982%22%20stroke%3D%22%2301A982%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.05%22/%3E%3C/svg%3E') no-repeat;
}
.element-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22%2301A982%22%20viewBox%3D%220%200%20138%2039.64%22%3E%3Cpath%20d%3D%22M0,0V39.64H138V0ZM129.38,31H8.63V8.63H129.38Z%22/%3E%3C/svg%3E') no-repeat;
}
.white-element-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20fill%3D%22%23FFFFFF%22%20viewBox%3D%220%200%20138%2039.64%22%3E%3Cpath%20d%3D%22M0,0V39.64H138V0ZM129.38,31H8.63V8.63H129.38Z%22/%3E%3C/svg%3E') no-repeat;
}
.accordion-arrow-icon {
  width: 28px;
  height: 17px;
  display: inline-block;
  position: relative;
}
.accordion-arrow-icon:after,
.accordion-arrow-icon:before {
  content: '';
  top: 6px;
  position: absolute;
  width: 20px;
  height: 3px;
  background-color: black;
  display: inline-block;
  transition: transform 0.2s ease;
  will-change: transfrom;
}
.accordion-arrow-icon:after {
  left: -2px;
  transform: rotate(45deg);
}
.accordion-arrow-icon:before {
  right: -2px;
  transform: rotate(-45deg);
}
.active .accordion-arrow-icon:after {
  transform: rotate(-45deg);
}
.active .accordion-arrow-icon:before {
  transform: rotate(45deg);
}
@media (min-width: 1px) and (max-width: 1199px) {
  .accordion-arrow-icon {
    width: 25px;
    height: 15px;
  }
  .accordion-arrow-icon:after,
  .accordion-arrow-icon:before {
    width: 18px;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .accordion-arrow-icon {
    width: 22px;
    height: 12px;
  }
  .accordion-arrow-icon:after,
  .accordion-arrow-icon:before {
    top: 5px;
    width: 16px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .accordion-arrow-icon {
    width: 15px;
    height: 9px;
  }
  .accordion-arrow-icon:after,
  .accordion-arrow-icon:before {
    top: 3px;
    width: 12px;
  }
}
.zoom-icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  cursor: pointer;
}
.zoom-icon::after {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-size: 42px;
  background-position: center;
  width: 21px;
  height: 21px;
}
.zoom-icon.zoom-in::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23707070' d='M17.426 19.926a6 6 0 1 1 1.5-1.5L23 22.5 21.5 24l-4.074-4.074z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M11 16v-2h6v2z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M13 12h2v6h-2z'%3E%3C/path%3E%3C/svg%3E%0A");
}
.zoom-icon.zoom-out::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23707070' d='M17.426 19.926a6 6 0 1 1 1.5-1.5L23 22.5 21.5 24l-4.074-4.074z'%3E%3C/path%3E%3Cpath fill='%23c6c9ca' d='M11 16v-2h6v2z'%3E%3C/path%3E%3C/svg%3E");
}
.download-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2013%2014%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M7.59992%209.58579L11.2928%205.89289L12.707%207.30711L7.30703%2012.7071L6.59992%2013.4142L5.89282%2012.7071L0.492818%207.30711L1.90703%205.89289L5.59992%209.58579L5.59992%200L7.59992%200L7.59992%209.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.white-download-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2013%2014%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M7.59992%209.58579L11.2928%205.89289L12.707%207.30711L7.30703%2012.7071L6.59992%2013.4142L5.89282%2012.7071L0.492818%207.30711L1.90703%205.89289L5.59992%209.58579L5.59992%200L7.59992%200L7.59992%209.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.video-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2015%2016%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M2.179%201.204%201.25.688V15.312l.929-.515%2011.25-6.25.983-.547-.984-.546-11.25-6.25Z%22/%3E%3C/svg%3E') no-repeat;
}
.white-video-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2015%2016%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M2.179%201.204%201.25.688V15.312l.929-.515%2011.25-6.25.983-.547-.984-.546-11.25-6.25Z%22/%3E%3C/svg%3E') no-repeat;
}
.typo3 hr,
.typo4 hr {
  margin: 0;
  border-color: #e5e5e5;
}
.style-bg-gray-3 .typo3 hr,
.style-bg-gray-3 .typo4 hr {
  border-color: #dadada;
}
.typo3.style-bg-gray-3 hr,
.typo4.style-bg-gray-3 hr {
  border-color: #dadada;
}
.coloredline.color-light-blue {
  border-top-color: #7FF9E2;
}
.coloredborder.color-light-blue {
  border-color: #7FF9E2;
}
.coloredbackground.color-light-blue {
  background-color: #7FF9E2;
}
.coloredbackground-before.color-light-blue::before {
  background-color: #7FF9E2;
}
.coloredbackground-after.color-light-blue::after {
  background-color: #7FF9E2;
}
.coloredtext.color-light-blue {
  color: #7FF9E2;
}
.coloredsvg-fill.color-light-blue circle,
.coloredsvg-fill.color-light-blue ellipse,
.coloredsvg-fill.color-light-blue line,
.coloredsvg-fill.color-light-blue polygon,
.coloredsvg-fill.color-light-blue polyline,
.coloredsvg-fill.color-light-blue rect,
.coloredsvg-fill.color-light-blue path {
  fill: #7FF9E2;
}
.coloredsvg-stroke.color-light-blue circle,
.coloredsvg-stroke.color-light-blue ellipse,
.coloredsvg-stroke.color-light-blue line,
.coloredsvg-stroke.color-light-blue polygon,
.coloredsvg-stroke.color-light-blue polyline,
.coloredsvg-stroke.color-light-blue rect,
.coloredsvg-stroke.color-light-blue path {
  stroke: #7FF9E2;
}
.dashed-text.color-light-blue::after {
  background: #7FF9E2;
}
.coloredtheme-light-blue .coloredline,
body .coloredtheme-specific-light-blue .coloredline {
  border-top-color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredborder,
body .coloredtheme-specific-light-blue .coloredborder {
  border-color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredbackground,
body .coloredtheme-specific-light-blue .coloredbackground {
  background-color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredbackground-before::before,
body .coloredtheme-specific-light-blue .coloredbackground-before::before {
  background-color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredbackground-after::after,
body .coloredtheme-specific-light-blue .coloredbackground-after::after {
  background-color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredtext,
body .coloredtheme-specific-light-blue .coloredtext {
  color: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredsvg-fill circle,
body .coloredtheme-specific-light-blue .coloredsvg-fill circle,
.coloredtheme-light-blue .coloredsvg-fill ellipse,
body .coloredtheme-specific-light-blue .coloredsvg-fill ellipse,
.coloredtheme-light-blue .coloredsvg-fill line,
body .coloredtheme-specific-light-blue .coloredsvg-fill line,
.coloredtheme-light-blue .coloredsvg-fill polygon,
body .coloredtheme-specific-light-blue .coloredsvg-fill polygon,
.coloredtheme-light-blue .coloredsvg-fill polyline,
body .coloredtheme-specific-light-blue .coloredsvg-fill polyline,
.coloredtheme-light-blue .coloredsvg-fill rect,
body .coloredtheme-specific-light-blue .coloredsvg-fill rect,
.coloredtheme-light-blue .coloredsvg-fill path,
body .coloredtheme-specific-light-blue .coloredsvg-fill path {
  fill: #7FF9E2 !important;
}
.coloredtheme-light-blue .coloredsvg-stroke circle,
body .coloredtheme-specific-light-blue .coloredsvg-stroke circle,
.coloredtheme-light-blue .coloredsvg-stroke ellipse,
body .coloredtheme-specific-light-blue .coloredsvg-stroke ellipse,
.coloredtheme-light-blue .coloredsvg-stroke line,
body .coloredtheme-specific-light-blue .coloredsvg-stroke line,
.coloredtheme-light-blue .coloredsvg-stroke polygon,
body .coloredtheme-specific-light-blue .coloredsvg-stroke polygon,
.coloredtheme-light-blue .coloredsvg-stroke polyline,
body .coloredtheme-specific-light-blue .coloredsvg-stroke polyline,
.coloredtheme-light-blue .coloredsvg-stroke rect,
body .coloredtheme-specific-light-blue .coloredsvg-stroke rect,
.coloredtheme-light-blue .coloredsvg-stroke path,
body .coloredtheme-specific-light-blue .coloredsvg-stroke path {
  stroke: #7FF9E2 !important;
}
.coloredtheme-light-blue .dashed-text::after,
body .coloredtheme-specific-light-blue .dashed-text::after {
  background: #7FF9E2;
}
.coloredtheme-light-blue .rich-text-container ul li::before,
body .coloredtheme-specific-light-blue .rich-text-container ul li::before {
  background: #7FF9E2;
}
.coloredtheme-light-blue esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-light-blue esl-scrollbar .scrollbar-thumb {
  background-color: #7FF9E2;
}
.coloredline.color-medium-blue {
  border-top-color: #32DAC8;
}
.coloredborder.color-medium-blue {
  border-color: #32DAC8;
}
.coloredbackground.color-medium-blue {
  background-color: #32DAC8;
}
.coloredbackground-before.color-medium-blue::before {
  background-color: #32DAC8;
}
.coloredbackground-after.color-medium-blue::after {
  background-color: #32DAC8;
}
.coloredtext.color-medium-blue {
  color: #32DAC8;
}
.coloredsvg-fill.color-medium-blue circle,
.coloredsvg-fill.color-medium-blue ellipse,
.coloredsvg-fill.color-medium-blue line,
.coloredsvg-fill.color-medium-blue polygon,
.coloredsvg-fill.color-medium-blue polyline,
.coloredsvg-fill.color-medium-blue rect,
.coloredsvg-fill.color-medium-blue path {
  fill: #32DAC8;
}
.coloredsvg-stroke.color-medium-blue circle,
.coloredsvg-stroke.color-medium-blue ellipse,
.coloredsvg-stroke.color-medium-blue line,
.coloredsvg-stroke.color-medium-blue polygon,
.coloredsvg-stroke.color-medium-blue polyline,
.coloredsvg-stroke.color-medium-blue rect,
.coloredsvg-stroke.color-medium-blue path {
  stroke: #32DAC8;
}
.dashed-text.color-medium-blue::after {
  background: #32DAC8;
}
.coloredtheme-medium-blue .coloredline,
body .coloredtheme-specific-medium-blue .coloredline {
  border-top-color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredborder,
body .coloredtheme-specific-medium-blue .coloredborder {
  border-color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredbackground,
body .coloredtheme-specific-medium-blue .coloredbackground {
  background-color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredbackground-before::before,
body .coloredtheme-specific-medium-blue .coloredbackground-before::before {
  background-color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredbackground-after::after,
body .coloredtheme-specific-medium-blue .coloredbackground-after::after {
  background-color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredtext,
body .coloredtheme-specific-medium-blue .coloredtext {
  color: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredsvg-fill circle,
body .coloredtheme-specific-medium-blue .coloredsvg-fill circle,
.coloredtheme-medium-blue .coloredsvg-fill ellipse,
body .coloredtheme-specific-medium-blue .coloredsvg-fill ellipse,
.coloredtheme-medium-blue .coloredsvg-fill line,
body .coloredtheme-specific-medium-blue .coloredsvg-fill line,
.coloredtheme-medium-blue .coloredsvg-fill polygon,
body .coloredtheme-specific-medium-blue .coloredsvg-fill polygon,
.coloredtheme-medium-blue .coloredsvg-fill polyline,
body .coloredtheme-specific-medium-blue .coloredsvg-fill polyline,
.coloredtheme-medium-blue .coloredsvg-fill rect,
body .coloredtheme-specific-medium-blue .coloredsvg-fill rect,
.coloredtheme-medium-blue .coloredsvg-fill path,
body .coloredtheme-specific-medium-blue .coloredsvg-fill path {
  fill: #32DAC8 !important;
}
.coloredtheme-medium-blue .coloredsvg-stroke circle,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke circle,
.coloredtheme-medium-blue .coloredsvg-stroke ellipse,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke ellipse,
.coloredtheme-medium-blue .coloredsvg-stroke line,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke line,
.coloredtheme-medium-blue .coloredsvg-stroke polygon,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke polygon,
.coloredtheme-medium-blue .coloredsvg-stroke polyline,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke polyline,
.coloredtheme-medium-blue .coloredsvg-stroke rect,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke rect,
.coloredtheme-medium-blue .coloredsvg-stroke path,
body .coloredtheme-specific-medium-blue .coloredsvg-stroke path {
  stroke: #32DAC8 !important;
}
.coloredtheme-medium-blue .dashed-text::after,
body .coloredtheme-specific-medium-blue .dashed-text::after {
  background: #32DAC8;
}
.coloredtheme-medium-blue .rich-text-container ul li::before,
body .coloredtheme-specific-medium-blue .rich-text-container ul li::before {
  background: #32DAC8;
}
.coloredtheme-medium-blue esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-medium-blue esl-scrollbar .scrollbar-thumb {
  background-color: #32DAC8;
}
.coloredline.color-dark-blue {
  border-top-color: #0D5265;
}
.coloredborder.color-dark-blue {
  border-color: #0D5265;
}
.coloredbackground.color-dark-blue {
  background-color: #0D5265;
}
.coloredbackground-before.color-dark-blue::before {
  background-color: #0D5265;
}
.coloredbackground-after.color-dark-blue::after {
  background-color: #0D5265;
}
.coloredtext.color-dark-blue {
  color: #0D5265;
}
.coloredsvg-fill.color-dark-blue circle,
.coloredsvg-fill.color-dark-blue ellipse,
.coloredsvg-fill.color-dark-blue line,
.coloredsvg-fill.color-dark-blue polygon,
.coloredsvg-fill.color-dark-blue polyline,
.coloredsvg-fill.color-dark-blue rect,
.coloredsvg-fill.color-dark-blue path {
  fill: #0D5265;
}
.coloredsvg-stroke.color-dark-blue circle,
.coloredsvg-stroke.color-dark-blue ellipse,
.coloredsvg-stroke.color-dark-blue line,
.coloredsvg-stroke.color-dark-blue polygon,
.coloredsvg-stroke.color-dark-blue polyline,
.coloredsvg-stroke.color-dark-blue rect,
.coloredsvg-stroke.color-dark-blue path {
  stroke: #0D5265;
}
.dashed-text.color-dark-blue::after {
  background: #0D5265;
}
.coloredtheme-dark-blue .coloredline,
body .coloredtheme-specific-dark-blue .coloredline {
  border-top-color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredborder,
body .coloredtheme-specific-dark-blue .coloredborder {
  border-color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredbackground,
body .coloredtheme-specific-dark-blue .coloredbackground {
  background-color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredbackground-before::before,
body .coloredtheme-specific-dark-blue .coloredbackground-before::before {
  background-color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredbackground-after::after,
body .coloredtheme-specific-dark-blue .coloredbackground-after::after {
  background-color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredtext,
body .coloredtheme-specific-dark-blue .coloredtext {
  color: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredsvg-fill circle,
body .coloredtheme-specific-dark-blue .coloredsvg-fill circle,
.coloredtheme-dark-blue .coloredsvg-fill ellipse,
body .coloredtheme-specific-dark-blue .coloredsvg-fill ellipse,
.coloredtheme-dark-blue .coloredsvg-fill line,
body .coloredtheme-specific-dark-blue .coloredsvg-fill line,
.coloredtheme-dark-blue .coloredsvg-fill polygon,
body .coloredtheme-specific-dark-blue .coloredsvg-fill polygon,
.coloredtheme-dark-blue .coloredsvg-fill polyline,
body .coloredtheme-specific-dark-blue .coloredsvg-fill polyline,
.coloredtheme-dark-blue .coloredsvg-fill rect,
body .coloredtheme-specific-dark-blue .coloredsvg-fill rect,
.coloredtheme-dark-blue .coloredsvg-fill path,
body .coloredtheme-specific-dark-blue .coloredsvg-fill path {
  fill: #0D5265 !important;
}
.coloredtheme-dark-blue .coloredsvg-stroke circle,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke circle,
.coloredtheme-dark-blue .coloredsvg-stroke ellipse,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke ellipse,
.coloredtheme-dark-blue .coloredsvg-stroke line,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke line,
.coloredtheme-dark-blue .coloredsvg-stroke polygon,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke polygon,
.coloredtheme-dark-blue .coloredsvg-stroke polyline,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke polyline,
.coloredtheme-dark-blue .coloredsvg-stroke rect,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke rect,
.coloredtheme-dark-blue .coloredsvg-stroke path,
body .coloredtheme-specific-dark-blue .coloredsvg-stroke path {
  stroke: #0D5265 !important;
}
.coloredtheme-dark-blue .dashed-text::after,
body .coloredtheme-specific-dark-blue .dashed-text::after {
  background: #0D5265;
}
.coloredtheme-dark-blue .rich-text-container ul li::before,
body .coloredtheme-specific-dark-blue .rich-text-container ul li::before {
  background: #0D5265;
}
.coloredtheme-dark-blue esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-dark-blue esl-scrollbar .scrollbar-thumb {
  background-color: #0D5265;
}
.coloredline.color-aruba-orange {
  border-top-color: #FF8300;
}
.coloredborder.color-aruba-orange {
  border-color: #FF8300;
}
.coloredbackground.color-aruba-orange {
  background-color: #FF8300;
}
.coloredbackground-before.color-aruba-orange::before {
  background-color: #FF8300;
}
.coloredbackground-after.color-aruba-orange::after {
  background-color: #FF8300;
}
.coloredtext.color-aruba-orange {
  color: #FF8300;
}
.coloredsvg-fill.color-aruba-orange circle,
.coloredsvg-fill.color-aruba-orange ellipse,
.coloredsvg-fill.color-aruba-orange line,
.coloredsvg-fill.color-aruba-orange polygon,
.coloredsvg-fill.color-aruba-orange polyline,
.coloredsvg-fill.color-aruba-orange rect,
.coloredsvg-fill.color-aruba-orange path {
  fill: #FF8300;
}
.coloredsvg-stroke.color-aruba-orange circle,
.coloredsvg-stroke.color-aruba-orange ellipse,
.coloredsvg-stroke.color-aruba-orange line,
.coloredsvg-stroke.color-aruba-orange polygon,
.coloredsvg-stroke.color-aruba-orange polyline,
.coloredsvg-stroke.color-aruba-orange rect,
.coloredsvg-stroke.color-aruba-orange path {
  stroke: #FF8300;
}
.dashed-text.color-aruba-orange::after {
  background: #FF8300;
}
.coloredtheme-aruba-orange .coloredline,
body .coloredtheme-specific-aruba-orange .coloredline {
  border-top-color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredborder,
body .coloredtheme-specific-aruba-orange .coloredborder {
  border-color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredbackground,
body .coloredtheme-specific-aruba-orange .coloredbackground {
  background-color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredbackground-before::before,
body .coloredtheme-specific-aruba-orange .coloredbackground-before::before {
  background-color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredbackground-after::after,
body .coloredtheme-specific-aruba-orange .coloredbackground-after::after {
  background-color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredtext,
body .coloredtheme-specific-aruba-orange .coloredtext {
  color: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredsvg-fill circle,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill circle,
.coloredtheme-aruba-orange .coloredsvg-fill ellipse,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill ellipse,
.coloredtheme-aruba-orange .coloredsvg-fill line,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill line,
.coloredtheme-aruba-orange .coloredsvg-fill polygon,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill polygon,
.coloredtheme-aruba-orange .coloredsvg-fill polyline,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill polyline,
.coloredtheme-aruba-orange .coloredsvg-fill rect,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill rect,
.coloredtheme-aruba-orange .coloredsvg-fill path,
body .coloredtheme-specific-aruba-orange .coloredsvg-fill path {
  fill: #FF8300 !important;
}
.coloredtheme-aruba-orange .coloredsvg-stroke circle,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke circle,
.coloredtheme-aruba-orange .coloredsvg-stroke ellipse,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke ellipse,
.coloredtheme-aruba-orange .coloredsvg-stroke line,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke line,
.coloredtheme-aruba-orange .coloredsvg-stroke polygon,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke polygon,
.coloredtheme-aruba-orange .coloredsvg-stroke polyline,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke polyline,
.coloredtheme-aruba-orange .coloredsvg-stroke rect,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke rect,
.coloredtheme-aruba-orange .coloredsvg-stroke path,
body .coloredtheme-specific-aruba-orange .coloredsvg-stroke path {
  stroke: #FF8300 !important;
}
.coloredtheme-aruba-orange .dashed-text::after,
body .coloredtheme-specific-aruba-orange .dashed-text::after {
  background: #FF8300;
}
.coloredtheme-aruba-orange .rich-text-container ul li::before,
body .coloredtheme-specific-aruba-orange .rich-text-container ul li::before {
  background: #FF8300;
}
.coloredtheme-aruba-orange esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-aruba-orange esl-scrollbar .scrollbar-thumb {
  background-color: #FF8300;
}
.coloredline.color-light-purple {
  border-top-color: #C140FF;
}
.coloredborder.color-light-purple {
  border-color: #C140FF;
}
.coloredbackground.color-light-purple {
  background-color: #C140FF;
}
.coloredbackground-before.color-light-purple::before {
  background-color: #C140FF;
}
.coloredbackground-after.color-light-purple::after {
  background-color: #C140FF;
}
.coloredtext.color-light-purple {
  color: #C140FF;
}
.coloredsvg-fill.color-light-purple circle,
.coloredsvg-fill.color-light-purple ellipse,
.coloredsvg-fill.color-light-purple line,
.coloredsvg-fill.color-light-purple polygon,
.coloredsvg-fill.color-light-purple polyline,
.coloredsvg-fill.color-light-purple rect,
.coloredsvg-fill.color-light-purple path {
  fill: #C140FF;
}
.coloredsvg-stroke.color-light-purple circle,
.coloredsvg-stroke.color-light-purple ellipse,
.coloredsvg-stroke.color-light-purple line,
.coloredsvg-stroke.color-light-purple polygon,
.coloredsvg-stroke.color-light-purple polyline,
.coloredsvg-stroke.color-light-purple rect,
.coloredsvg-stroke.color-light-purple path {
  stroke: #C140FF;
}
.dashed-text.color-light-purple::after {
  background: #C140FF;
}
.coloredtheme-light-purple .coloredline,
body .coloredtheme-specific-light-purple .coloredline {
  border-top-color: #C140FF !important;
}
.coloredtheme-light-purple .coloredborder,
body .coloredtheme-specific-light-purple .coloredborder {
  border-color: #C140FF !important;
}
.coloredtheme-light-purple .coloredbackground,
body .coloredtheme-specific-light-purple .coloredbackground {
  background-color: #C140FF !important;
}
.coloredtheme-light-purple .coloredbackground-before::before,
body .coloredtheme-specific-light-purple .coloredbackground-before::before {
  background-color: #C140FF !important;
}
.coloredtheme-light-purple .coloredbackground-after::after,
body .coloredtheme-specific-light-purple .coloredbackground-after::after {
  background-color: #C140FF !important;
}
.coloredtheme-light-purple .coloredtext,
body .coloredtheme-specific-light-purple .coloredtext {
  color: #C140FF !important;
}
.coloredtheme-light-purple .coloredsvg-fill circle,
body .coloredtheme-specific-light-purple .coloredsvg-fill circle,
.coloredtheme-light-purple .coloredsvg-fill ellipse,
body .coloredtheme-specific-light-purple .coloredsvg-fill ellipse,
.coloredtheme-light-purple .coloredsvg-fill line,
body .coloredtheme-specific-light-purple .coloredsvg-fill line,
.coloredtheme-light-purple .coloredsvg-fill polygon,
body .coloredtheme-specific-light-purple .coloredsvg-fill polygon,
.coloredtheme-light-purple .coloredsvg-fill polyline,
body .coloredtheme-specific-light-purple .coloredsvg-fill polyline,
.coloredtheme-light-purple .coloredsvg-fill rect,
body .coloredtheme-specific-light-purple .coloredsvg-fill rect,
.coloredtheme-light-purple .coloredsvg-fill path,
body .coloredtheme-specific-light-purple .coloredsvg-fill path {
  fill: #C140FF !important;
}
.coloredtheme-light-purple .coloredsvg-stroke circle,
body .coloredtheme-specific-light-purple .coloredsvg-stroke circle,
.coloredtheme-light-purple .coloredsvg-stroke ellipse,
body .coloredtheme-specific-light-purple .coloredsvg-stroke ellipse,
.coloredtheme-light-purple .coloredsvg-stroke line,
body .coloredtheme-specific-light-purple .coloredsvg-stroke line,
.coloredtheme-light-purple .coloredsvg-stroke polygon,
body .coloredtheme-specific-light-purple .coloredsvg-stroke polygon,
.coloredtheme-light-purple .coloredsvg-stroke polyline,
body .coloredtheme-specific-light-purple .coloredsvg-stroke polyline,
.coloredtheme-light-purple .coloredsvg-stroke rect,
body .coloredtheme-specific-light-purple .coloredsvg-stroke rect,
.coloredtheme-light-purple .coloredsvg-stroke path,
body .coloredtheme-specific-light-purple .coloredsvg-stroke path {
  stroke: #C140FF !important;
}
.coloredtheme-light-purple .dashed-text::after,
body .coloredtheme-specific-light-purple .dashed-text::after {
  background: #C140FF;
}
.coloredtheme-light-purple .rich-text-container ul li::before,
body .coloredtheme-specific-light-purple .rich-text-container ul li::before {
  background: #C140FF;
}
.coloredtheme-light-purple esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-light-purple esl-scrollbar .scrollbar-thumb {
  background-color: #C140FF;
}
.coloredline.color-medium-purple {
  border-top-color: #7630EA;
}
.coloredborder.color-medium-purple {
  border-color: #7630EA;
}
.coloredbackground.color-medium-purple {
  background-color: #7630EA;
}
.coloredbackground-before.color-medium-purple::before {
  background-color: #7630EA;
}
.coloredbackground-after.color-medium-purple::after {
  background-color: #7630EA;
}
.coloredtext.color-medium-purple {
  color: #7630EA;
}
.coloredsvg-fill.color-medium-purple circle,
.coloredsvg-fill.color-medium-purple ellipse,
.coloredsvg-fill.color-medium-purple line,
.coloredsvg-fill.color-medium-purple polygon,
.coloredsvg-fill.color-medium-purple polyline,
.coloredsvg-fill.color-medium-purple rect,
.coloredsvg-fill.color-medium-purple path {
  fill: #7630EA;
}
.coloredsvg-stroke.color-medium-purple circle,
.coloredsvg-stroke.color-medium-purple ellipse,
.coloredsvg-stroke.color-medium-purple line,
.coloredsvg-stroke.color-medium-purple polygon,
.coloredsvg-stroke.color-medium-purple polyline,
.coloredsvg-stroke.color-medium-purple rect,
.coloredsvg-stroke.color-medium-purple path {
  stroke: #7630EA;
}
.dashed-text.color-medium-purple::after {
  background: #7630EA;
}
.coloredtheme-medium-purple .coloredline,
body .coloredtheme-specific-medium-purple .coloredline {
  border-top-color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredborder,
body .coloredtheme-specific-medium-purple .coloredborder {
  border-color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredbackground,
body .coloredtheme-specific-medium-purple .coloredbackground {
  background-color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredbackground-before::before,
body .coloredtheme-specific-medium-purple .coloredbackground-before::before {
  background-color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredbackground-after::after,
body .coloredtheme-specific-medium-purple .coloredbackground-after::after {
  background-color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredtext,
body .coloredtheme-specific-medium-purple .coloredtext {
  color: #7630EA !important;
}
.coloredtheme-medium-purple .coloredsvg-fill circle,
body .coloredtheme-specific-medium-purple .coloredsvg-fill circle,
.coloredtheme-medium-purple .coloredsvg-fill ellipse,
body .coloredtheme-specific-medium-purple .coloredsvg-fill ellipse,
.coloredtheme-medium-purple .coloredsvg-fill line,
body .coloredtheme-specific-medium-purple .coloredsvg-fill line,
.coloredtheme-medium-purple .coloredsvg-fill polygon,
body .coloredtheme-specific-medium-purple .coloredsvg-fill polygon,
.coloredtheme-medium-purple .coloredsvg-fill polyline,
body .coloredtheme-specific-medium-purple .coloredsvg-fill polyline,
.coloredtheme-medium-purple .coloredsvg-fill rect,
body .coloredtheme-specific-medium-purple .coloredsvg-fill rect,
.coloredtheme-medium-purple .coloredsvg-fill path,
body .coloredtheme-specific-medium-purple .coloredsvg-fill path {
  fill: #7630EA !important;
}
.coloredtheme-medium-purple .coloredsvg-stroke circle,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke circle,
.coloredtheme-medium-purple .coloredsvg-stroke ellipse,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke ellipse,
.coloredtheme-medium-purple .coloredsvg-stroke line,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke line,
.coloredtheme-medium-purple .coloredsvg-stroke polygon,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke polygon,
.coloredtheme-medium-purple .coloredsvg-stroke polyline,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke polyline,
.coloredtheme-medium-purple .coloredsvg-stroke rect,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke rect,
.coloredtheme-medium-purple .coloredsvg-stroke path,
body .coloredtheme-specific-medium-purple .coloredsvg-stroke path {
  stroke: #7630EA !important;
}
.coloredtheme-medium-purple .dashed-text::after,
body .coloredtheme-specific-medium-purple .dashed-text::after {
  background: #7630EA;
}
.coloredtheme-medium-purple .rich-text-container ul li::before,
body .coloredtheme-specific-medium-purple .rich-text-container ul li::before {
  background: #7630EA;
}
.coloredtheme-medium-purple esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-medium-purple esl-scrollbar .scrollbar-thumb {
  background-color: #7630EA;
}
.coloredline.color-yellow {
  border-top-color: #FEC901;
}
.coloredborder.color-yellow {
  border-color: #FEC901;
}
.coloredbackground.color-yellow {
  background-color: #FEC901;
}
.coloredbackground-before.color-yellow::before {
  background-color: #FEC901;
}
.coloredbackground-after.color-yellow::after {
  background-color: #FEC901;
}
.coloredtext.color-yellow {
  color: #FEC901;
}
.coloredsvg-fill.color-yellow circle,
.coloredsvg-fill.color-yellow ellipse,
.coloredsvg-fill.color-yellow line,
.coloredsvg-fill.color-yellow polygon,
.coloredsvg-fill.color-yellow polyline,
.coloredsvg-fill.color-yellow rect,
.coloredsvg-fill.color-yellow path {
  fill: #FEC901;
}
.coloredsvg-stroke.color-yellow circle,
.coloredsvg-stroke.color-yellow ellipse,
.coloredsvg-stroke.color-yellow line,
.coloredsvg-stroke.color-yellow polygon,
.coloredsvg-stroke.color-yellow polyline,
.coloredsvg-stroke.color-yellow rect,
.coloredsvg-stroke.color-yellow path {
  stroke: #FEC901;
}
.dashed-text.color-yellow::after {
  background: #FEC901;
}
.coloredtheme-yellow .coloredline,
body .coloredtheme-specific-yellow .coloredline {
  border-top-color: #FEC901 !important;
}
.coloredtheme-yellow .coloredborder,
body .coloredtheme-specific-yellow .coloredborder {
  border-color: #FEC901 !important;
}
.coloredtheme-yellow .coloredbackground,
body .coloredtheme-specific-yellow .coloredbackground {
  background-color: #FEC901 !important;
}
.coloredtheme-yellow .coloredbackground-before::before,
body .coloredtheme-specific-yellow .coloredbackground-before::before {
  background-color: #FEC901 !important;
}
.coloredtheme-yellow .coloredbackground-after::after,
body .coloredtheme-specific-yellow .coloredbackground-after::after {
  background-color: #FEC901 !important;
}
.coloredtheme-yellow .coloredtext,
body .coloredtheme-specific-yellow .coloredtext {
  color: #FEC901 !important;
}
.coloredtheme-yellow .coloredsvg-fill circle,
body .coloredtheme-specific-yellow .coloredsvg-fill circle,
.coloredtheme-yellow .coloredsvg-fill ellipse,
body .coloredtheme-specific-yellow .coloredsvg-fill ellipse,
.coloredtheme-yellow .coloredsvg-fill line,
body .coloredtheme-specific-yellow .coloredsvg-fill line,
.coloredtheme-yellow .coloredsvg-fill polygon,
body .coloredtheme-specific-yellow .coloredsvg-fill polygon,
.coloredtheme-yellow .coloredsvg-fill polyline,
body .coloredtheme-specific-yellow .coloredsvg-fill polyline,
.coloredtheme-yellow .coloredsvg-fill rect,
body .coloredtheme-specific-yellow .coloredsvg-fill rect,
.coloredtheme-yellow .coloredsvg-fill path,
body .coloredtheme-specific-yellow .coloredsvg-fill path {
  fill: #FEC901 !important;
}
.coloredtheme-yellow .coloredsvg-stroke circle,
body .coloredtheme-specific-yellow .coloredsvg-stroke circle,
.coloredtheme-yellow .coloredsvg-stroke ellipse,
body .coloredtheme-specific-yellow .coloredsvg-stroke ellipse,
.coloredtheme-yellow .coloredsvg-stroke line,
body .coloredtheme-specific-yellow .coloredsvg-stroke line,
.coloredtheme-yellow .coloredsvg-stroke polygon,
body .coloredtheme-specific-yellow .coloredsvg-stroke polygon,
.coloredtheme-yellow .coloredsvg-stroke polyline,
body .coloredtheme-specific-yellow .coloredsvg-stroke polyline,
.coloredtheme-yellow .coloredsvg-stroke rect,
body .coloredtheme-specific-yellow .coloredsvg-stroke rect,
.coloredtheme-yellow .coloredsvg-stroke path,
body .coloredtheme-specific-yellow .coloredsvg-stroke path {
  stroke: #FEC901 !important;
}
.coloredtheme-yellow .dashed-text::after,
body .coloredtheme-specific-yellow .dashed-text::after {
  background: #FEC901;
}
.coloredtheme-yellow .rich-text-container ul li::before,
body .coloredtheme-specific-yellow .rich-text-container ul li::before {
  background: #FEC901;
}
.coloredtheme-yellow esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-yellow esl-scrollbar .scrollbar-thumb {
  background-color: #FEC901;
}
.coloredline.color-hpe-green {
  border-top-color: #01A982;
}
.coloredborder.color-hpe-green {
  border-color: #01A982;
}
.coloredbackground.color-hpe-green {
  background-color: #01A982;
}
.coloredbackground-before.color-hpe-green::before {
  background-color: #01A982;
}
.coloredbackground-after.color-hpe-green::after {
  background-color: #01A982;
}
.coloredtext.color-hpe-green {
  color: #01A982;
}
.coloredsvg-fill.color-hpe-green circle,
.coloredsvg-fill.color-hpe-green ellipse,
.coloredsvg-fill.color-hpe-green line,
.coloredsvg-fill.color-hpe-green polygon,
.coloredsvg-fill.color-hpe-green polyline,
.coloredsvg-fill.color-hpe-green rect,
.coloredsvg-fill.color-hpe-green path {
  fill: #01A982;
}
.coloredsvg-stroke.color-hpe-green circle,
.coloredsvg-stroke.color-hpe-green ellipse,
.coloredsvg-stroke.color-hpe-green line,
.coloredsvg-stroke.color-hpe-green polygon,
.coloredsvg-stroke.color-hpe-green polyline,
.coloredsvg-stroke.color-hpe-green rect,
.coloredsvg-stroke.color-hpe-green path {
  stroke: #01A982;
}
.dashed-text.color-hpe-green::after {
  background: #01A982;
}
.coloredtheme-hpe-green .coloredline,
body .coloredtheme-specific-hpe-green .coloredline {
  border-top-color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredborder,
body .coloredtheme-specific-hpe-green .coloredborder {
  border-color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredbackground,
body .coloredtheme-specific-hpe-green .coloredbackground {
  background-color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredbackground-before::before,
body .coloredtheme-specific-hpe-green .coloredbackground-before::before {
  background-color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredbackground-after::after,
body .coloredtheme-specific-hpe-green .coloredbackground-after::after {
  background-color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredtext,
body .coloredtheme-specific-hpe-green .coloredtext {
  color: #01A982 !important;
}
.coloredtheme-hpe-green .coloredsvg-fill circle,
body .coloredtheme-specific-hpe-green .coloredsvg-fill circle,
.coloredtheme-hpe-green .coloredsvg-fill ellipse,
body .coloredtheme-specific-hpe-green .coloredsvg-fill ellipse,
.coloredtheme-hpe-green .coloredsvg-fill line,
body .coloredtheme-specific-hpe-green .coloredsvg-fill line,
.coloredtheme-hpe-green .coloredsvg-fill polygon,
body .coloredtheme-specific-hpe-green .coloredsvg-fill polygon,
.coloredtheme-hpe-green .coloredsvg-fill polyline,
body .coloredtheme-specific-hpe-green .coloredsvg-fill polyline,
.coloredtheme-hpe-green .coloredsvg-fill rect,
body .coloredtheme-specific-hpe-green .coloredsvg-fill rect,
.coloredtheme-hpe-green .coloredsvg-fill path,
body .coloredtheme-specific-hpe-green .coloredsvg-fill path {
  fill: #01A982 !important;
}
.coloredtheme-hpe-green .coloredsvg-stroke circle,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke circle,
.coloredtheme-hpe-green .coloredsvg-stroke ellipse,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke ellipse,
.coloredtheme-hpe-green .coloredsvg-stroke line,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke line,
.coloredtheme-hpe-green .coloredsvg-stroke polygon,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke polygon,
.coloredtheme-hpe-green .coloredsvg-stroke polyline,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke polyline,
.coloredtheme-hpe-green .coloredsvg-stroke rect,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke rect,
.coloredtheme-hpe-green .coloredsvg-stroke path,
body .coloredtheme-specific-hpe-green .coloredsvg-stroke path {
  stroke: #01A982 !important;
}
.coloredtheme-hpe-green .dashed-text::after,
body .coloredtheme-specific-hpe-green .dashed-text::after {
  background: #01A982;
}
.coloredtheme-hpe-green .rich-text-container ul li::before,
body .coloredtheme-specific-hpe-green .rich-text-container ul li::before {
  background: #01A982;
}
.coloredtheme-hpe-green esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-hpe-green esl-scrollbar .scrollbar-thumb {
  background-color: #01A982;
}
.coloredline.color-gray {
  border-top-color: #C6C9CA;
}
.coloredborder.color-gray {
  border-color: #C6C9CA;
}
.coloredbackground.color-gray {
  background-color: #C6C9CA;
}
.coloredbackground-before.color-gray::before {
  background-color: #C6C9CA;
}
.coloredbackground-after.color-gray::after {
  background-color: #C6C9CA;
}
.coloredtext.color-gray {
  color: #C6C9CA;
}
.coloredsvg-fill.color-gray circle,
.coloredsvg-fill.color-gray ellipse,
.coloredsvg-fill.color-gray line,
.coloredsvg-fill.color-gray polygon,
.coloredsvg-fill.color-gray polyline,
.coloredsvg-fill.color-gray rect,
.coloredsvg-fill.color-gray path {
  fill: #C6C9CA;
}
.coloredsvg-stroke.color-gray circle,
.coloredsvg-stroke.color-gray ellipse,
.coloredsvg-stroke.color-gray line,
.coloredsvg-stroke.color-gray polygon,
.coloredsvg-stroke.color-gray polyline,
.coloredsvg-stroke.color-gray rect,
.coloredsvg-stroke.color-gray path {
  stroke: #C6C9CA;
}
.dashed-text.color-gray::after {
  background: #C6C9CA;
}
.coloredtheme-gray .coloredline,
body .coloredtheme-specific-gray .coloredline {
  border-top-color: #C6C9CA !important;
}
.coloredtheme-gray .coloredborder,
body .coloredtheme-specific-gray .coloredborder {
  border-color: #C6C9CA !important;
}
.coloredtheme-gray .coloredbackground,
body .coloredtheme-specific-gray .coloredbackground {
  background-color: #C6C9CA !important;
}
.coloredtheme-gray .coloredbackground-before::before,
body .coloredtheme-specific-gray .coloredbackground-before::before {
  background-color: #C6C9CA !important;
}
.coloredtheme-gray .coloredbackground-after::after,
body .coloredtheme-specific-gray .coloredbackground-after::after {
  background-color: #C6C9CA !important;
}
.coloredtheme-gray .coloredtext,
body .coloredtheme-specific-gray .coloredtext {
  color: #C6C9CA !important;
}
.coloredtheme-gray .coloredsvg-fill circle,
body .coloredtheme-specific-gray .coloredsvg-fill circle,
.coloredtheme-gray .coloredsvg-fill ellipse,
body .coloredtheme-specific-gray .coloredsvg-fill ellipse,
.coloredtheme-gray .coloredsvg-fill line,
body .coloredtheme-specific-gray .coloredsvg-fill line,
.coloredtheme-gray .coloredsvg-fill polygon,
body .coloredtheme-specific-gray .coloredsvg-fill polygon,
.coloredtheme-gray .coloredsvg-fill polyline,
body .coloredtheme-specific-gray .coloredsvg-fill polyline,
.coloredtheme-gray .coloredsvg-fill rect,
body .coloredtheme-specific-gray .coloredsvg-fill rect,
.coloredtheme-gray .coloredsvg-fill path,
body .coloredtheme-specific-gray .coloredsvg-fill path {
  fill: #C6C9CA !important;
}
.coloredtheme-gray .coloredsvg-stroke circle,
body .coloredtheme-specific-gray .coloredsvg-stroke circle,
.coloredtheme-gray .coloredsvg-stroke ellipse,
body .coloredtheme-specific-gray .coloredsvg-stroke ellipse,
.coloredtheme-gray .coloredsvg-stroke line,
body .coloredtheme-specific-gray .coloredsvg-stroke line,
.coloredtheme-gray .coloredsvg-stroke polygon,
body .coloredtheme-specific-gray .coloredsvg-stroke polygon,
.coloredtheme-gray .coloredsvg-stroke polyline,
body .coloredtheme-specific-gray .coloredsvg-stroke polyline,
.coloredtheme-gray .coloredsvg-stroke rect,
body .coloredtheme-specific-gray .coloredsvg-stroke rect,
.coloredtheme-gray .coloredsvg-stroke path,
body .coloredtheme-specific-gray .coloredsvg-stroke path {
  stroke: #C6C9CA !important;
}
.coloredtheme-gray .dashed-text::after,
body .coloredtheme-specific-gray .dashed-text::after {
  background: #C6C9CA;
}
.coloredtheme-gray .rich-text-container ul li::before,
body .coloredtheme-specific-gray .rich-text-container ul li::before {
  background: #C6C9CA;
}
.coloredtheme-gray esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-gray esl-scrollbar .scrollbar-thumb {
  background-color: #C6C9CA;
}
.coloredline.color-black {
  border-top-color: #000000;
}
.coloredborder.color-black {
  border-color: #000000;
}
.coloredbackground.color-black {
  background-color: #000000;
}
.coloredbackground-before.color-black::before {
  background-color: #000000;
}
.coloredbackground-after.color-black::after {
  background-color: #000000;
}
.coloredtext.color-black {
  color: #000000;
}
.coloredsvg-fill.color-black circle,
.coloredsvg-fill.color-black ellipse,
.coloredsvg-fill.color-black line,
.coloredsvg-fill.color-black polygon,
.coloredsvg-fill.color-black polyline,
.coloredsvg-fill.color-black rect,
.coloredsvg-fill.color-black path {
  fill: #000000;
}
.coloredsvg-stroke.color-black circle,
.coloredsvg-stroke.color-black ellipse,
.coloredsvg-stroke.color-black line,
.coloredsvg-stroke.color-black polygon,
.coloredsvg-stroke.color-black polyline,
.coloredsvg-stroke.color-black rect,
.coloredsvg-stroke.color-black path {
  stroke: #000000;
}
.dashed-text.color-black::after {
  background: #000000;
}
.coloredtheme-black .coloredline,
body .coloredtheme-specific-black .coloredline {
  border-top-color: #000000 !important;
}
.coloredtheme-black .coloredborder,
body .coloredtheme-specific-black .coloredborder {
  border-color: #000000 !important;
}
.coloredtheme-black .coloredbackground,
body .coloredtheme-specific-black .coloredbackground {
  background-color: #000000 !important;
}
.coloredtheme-black .coloredbackground-before::before,
body .coloredtheme-specific-black .coloredbackground-before::before {
  background-color: #000000 !important;
}
.coloredtheme-black .coloredbackground-after::after,
body .coloredtheme-specific-black .coloredbackground-after::after {
  background-color: #000000 !important;
}
.coloredtheme-black .coloredtext,
body .coloredtheme-specific-black .coloredtext {
  color: #000000 !important;
}
.coloredtheme-black .coloredsvg-fill circle,
body .coloredtheme-specific-black .coloredsvg-fill circle,
.coloredtheme-black .coloredsvg-fill ellipse,
body .coloredtheme-specific-black .coloredsvg-fill ellipse,
.coloredtheme-black .coloredsvg-fill line,
body .coloredtheme-specific-black .coloredsvg-fill line,
.coloredtheme-black .coloredsvg-fill polygon,
body .coloredtheme-specific-black .coloredsvg-fill polygon,
.coloredtheme-black .coloredsvg-fill polyline,
body .coloredtheme-specific-black .coloredsvg-fill polyline,
.coloredtheme-black .coloredsvg-fill rect,
body .coloredtheme-specific-black .coloredsvg-fill rect,
.coloredtheme-black .coloredsvg-fill path,
body .coloredtheme-specific-black .coloredsvg-fill path {
  fill: #000000 !important;
}
.coloredtheme-black .coloredsvg-stroke circle,
body .coloredtheme-specific-black .coloredsvg-stroke circle,
.coloredtheme-black .coloredsvg-stroke ellipse,
body .coloredtheme-specific-black .coloredsvg-stroke ellipse,
.coloredtheme-black .coloredsvg-stroke line,
body .coloredtheme-specific-black .coloredsvg-stroke line,
.coloredtheme-black .coloredsvg-stroke polygon,
body .coloredtheme-specific-black .coloredsvg-stroke polygon,
.coloredtheme-black .coloredsvg-stroke polyline,
body .coloredtheme-specific-black .coloredsvg-stroke polyline,
.coloredtheme-black .coloredsvg-stroke rect,
body .coloredtheme-specific-black .coloredsvg-stroke rect,
.coloredtheme-black .coloredsvg-stroke path,
body .coloredtheme-specific-black .coloredsvg-stroke path {
  stroke: #000000 !important;
}
.coloredtheme-black .dashed-text::after,
body .coloredtheme-specific-black .dashed-text::after {
  background: #000000;
}
.coloredtheme-black .rich-text-container ul li::before,
body .coloredtheme-specific-black .rich-text-container ul li::before {
  background: #000000;
}
.coloredtheme-black esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-black esl-scrollbar .scrollbar-thumb {
  background-color: #000000;
}
.coloredline.color-light-gray {
  border-top-color: #F6F6F6;
}
.coloredborder.color-light-gray {
  border-color: #F6F6F6;
}
.coloredbackground.color-light-gray {
  background-color: #F6F6F6;
}
.coloredbackground-before.color-light-gray::before {
  background-color: #F6F6F6;
}
.coloredbackground-after.color-light-gray::after {
  background-color: #F6F6F6;
}
.coloredtext.color-light-gray {
  color: #F6F6F6;
}
.coloredsvg-fill.color-light-gray circle,
.coloredsvg-fill.color-light-gray ellipse,
.coloredsvg-fill.color-light-gray line,
.coloredsvg-fill.color-light-gray polygon,
.coloredsvg-fill.color-light-gray polyline,
.coloredsvg-fill.color-light-gray rect,
.coloredsvg-fill.color-light-gray path {
  fill: #F6F6F6;
}
.coloredsvg-stroke.color-light-gray circle,
.coloredsvg-stroke.color-light-gray ellipse,
.coloredsvg-stroke.color-light-gray line,
.coloredsvg-stroke.color-light-gray polygon,
.coloredsvg-stroke.color-light-gray polyline,
.coloredsvg-stroke.color-light-gray rect,
.coloredsvg-stroke.color-light-gray path {
  stroke: #F6F6F6;
}
.dashed-text.color-light-gray::after {
  background: #F6F6F6;
}
.coloredtheme-light-gray .coloredline,
body .coloredtheme-specific-light-gray .coloredline {
  border-top-color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredborder,
body .coloredtheme-specific-light-gray .coloredborder {
  border-color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredbackground,
body .coloredtheme-specific-light-gray .coloredbackground {
  background-color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredbackground-before::before,
body .coloredtheme-specific-light-gray .coloredbackground-before::before {
  background-color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredbackground-after::after,
body .coloredtheme-specific-light-gray .coloredbackground-after::after {
  background-color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredtext,
body .coloredtheme-specific-light-gray .coloredtext {
  color: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredsvg-fill circle,
body .coloredtheme-specific-light-gray .coloredsvg-fill circle,
.coloredtheme-light-gray .coloredsvg-fill ellipse,
body .coloredtheme-specific-light-gray .coloredsvg-fill ellipse,
.coloredtheme-light-gray .coloredsvg-fill line,
body .coloredtheme-specific-light-gray .coloredsvg-fill line,
.coloredtheme-light-gray .coloredsvg-fill polygon,
body .coloredtheme-specific-light-gray .coloredsvg-fill polygon,
.coloredtheme-light-gray .coloredsvg-fill polyline,
body .coloredtheme-specific-light-gray .coloredsvg-fill polyline,
.coloredtheme-light-gray .coloredsvg-fill rect,
body .coloredtheme-specific-light-gray .coloredsvg-fill rect,
.coloredtheme-light-gray .coloredsvg-fill path,
body .coloredtheme-specific-light-gray .coloredsvg-fill path {
  fill: #F6F6F6 !important;
}
.coloredtheme-light-gray .coloredsvg-stroke circle,
body .coloredtheme-specific-light-gray .coloredsvg-stroke circle,
.coloredtheme-light-gray .coloredsvg-stroke ellipse,
body .coloredtheme-specific-light-gray .coloredsvg-stroke ellipse,
.coloredtheme-light-gray .coloredsvg-stroke line,
body .coloredtheme-specific-light-gray .coloredsvg-stroke line,
.coloredtheme-light-gray .coloredsvg-stroke polygon,
body .coloredtheme-specific-light-gray .coloredsvg-stroke polygon,
.coloredtheme-light-gray .coloredsvg-stroke polyline,
body .coloredtheme-specific-light-gray .coloredsvg-stroke polyline,
.coloredtheme-light-gray .coloredsvg-stroke rect,
body .coloredtheme-specific-light-gray .coloredsvg-stroke rect,
.coloredtheme-light-gray .coloredsvg-stroke path,
body .coloredtheme-specific-light-gray .coloredsvg-stroke path {
  stroke: #F6F6F6 !important;
}
.coloredtheme-light-gray .dashed-text::after,
body .coloredtheme-specific-light-gray .dashed-text::after {
  background: #F6F6F6;
}
.coloredtheme-light-gray .rich-text-container ul li::before,
body .coloredtheme-specific-light-gray .rich-text-container ul li::before {
  background: #F6F6F6;
}
.coloredtheme-light-gray esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-light-gray esl-scrollbar .scrollbar-thumb {
  background-color: #F6F6F6;
}
.coloredline.color-white {
  border-top-color: #FFFFFF;
}
.coloredborder.color-white {
  border-color: #FFFFFF;
}
.coloredbackground.color-white {
  background-color: #FFFFFF;
}
.coloredbackground-before.color-white::before {
  background-color: #FFFFFF;
}
.coloredbackground-after.color-white::after {
  background-color: #FFFFFF;
}
.coloredtext.color-white {
  color: #FFFFFF;
}
.coloredsvg-fill.color-white circle,
.coloredsvg-fill.color-white ellipse,
.coloredsvg-fill.color-white line,
.coloredsvg-fill.color-white polygon,
.coloredsvg-fill.color-white polyline,
.coloredsvg-fill.color-white rect,
.coloredsvg-fill.color-white path {
  fill: #FFFFFF;
}
.coloredsvg-stroke.color-white circle,
.coloredsvg-stroke.color-white ellipse,
.coloredsvg-stroke.color-white line,
.coloredsvg-stroke.color-white polygon,
.coloredsvg-stroke.color-white polyline,
.coloredsvg-stroke.color-white rect,
.coloredsvg-stroke.color-white path {
  stroke: #FFFFFF;
}
.dashed-text.color-white::after {
  background: #FFFFFF;
}
.coloredtheme-white .coloredline,
body .coloredtheme-specific-white .coloredline {
  border-top-color: #FFFFFF !important;
}
.coloredtheme-white .coloredborder,
body .coloredtheme-specific-white .coloredborder {
  border-color: #FFFFFF !important;
}
.coloredtheme-white .coloredbackground,
body .coloredtheme-specific-white .coloredbackground {
  background-color: #FFFFFF !important;
}
.coloredtheme-white .coloredbackground-before::before,
body .coloredtheme-specific-white .coloredbackground-before::before {
  background-color: #FFFFFF !important;
}
.coloredtheme-white .coloredbackground-after::after,
body .coloredtheme-specific-white .coloredbackground-after::after {
  background-color: #FFFFFF !important;
}
.coloredtheme-white .coloredtext,
body .coloredtheme-specific-white .coloredtext {
  color: #FFFFFF !important;
}
.coloredtheme-white .coloredsvg-fill circle,
body .coloredtheme-specific-white .coloredsvg-fill circle,
.coloredtheme-white .coloredsvg-fill ellipse,
body .coloredtheme-specific-white .coloredsvg-fill ellipse,
.coloredtheme-white .coloredsvg-fill line,
body .coloredtheme-specific-white .coloredsvg-fill line,
.coloredtheme-white .coloredsvg-fill polygon,
body .coloredtheme-specific-white .coloredsvg-fill polygon,
.coloredtheme-white .coloredsvg-fill polyline,
body .coloredtheme-specific-white .coloredsvg-fill polyline,
.coloredtheme-white .coloredsvg-fill rect,
body .coloredtheme-specific-white .coloredsvg-fill rect,
.coloredtheme-white .coloredsvg-fill path,
body .coloredtheme-specific-white .coloredsvg-fill path {
  fill: #FFFFFF !important;
}
.coloredtheme-white .coloredsvg-stroke circle,
body .coloredtheme-specific-white .coloredsvg-stroke circle,
.coloredtheme-white .coloredsvg-stroke ellipse,
body .coloredtheme-specific-white .coloredsvg-stroke ellipse,
.coloredtheme-white .coloredsvg-stroke line,
body .coloredtheme-specific-white .coloredsvg-stroke line,
.coloredtheme-white .coloredsvg-stroke polygon,
body .coloredtheme-specific-white .coloredsvg-stroke polygon,
.coloredtheme-white .coloredsvg-stroke polyline,
body .coloredtheme-specific-white .coloredsvg-stroke polyline,
.coloredtheme-white .coloredsvg-stroke rect,
body .coloredtheme-specific-white .coloredsvg-stroke rect,
.coloredtheme-white .coloredsvg-stroke path,
body .coloredtheme-specific-white .coloredsvg-stroke path {
  stroke: #FFFFFF !important;
}
.coloredtheme-white .dashed-text::after,
body .coloredtheme-specific-white .dashed-text::after {
  background: #FFFFFF;
}
.coloredtheme-white .rich-text-container ul li::before,
body .coloredtheme-specific-white .rich-text-container ul li::before {
  background: #FFFFFF;
}
.coloredtheme-white esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-white esl-scrollbar .scrollbar-thumb {
  background-color: #FFFFFF;
}
.coloredline.color-teal-light {
  border-top-color: #82FFF2;
}
.coloredborder.color-teal-light {
  border-color: #82FFF2;
}
.coloredbackground.color-teal-light {
  background-color: #82FFF2;
}
.coloredbackground-before.color-teal-light::before {
  background-color: #82FFF2;
}
.coloredbackground-after.color-teal-light::after {
  background-color: #82FFF2;
}
.coloredtext.color-teal-light {
  color: #82FFF2;
}
.coloredsvg-fill.color-teal-light circle,
.coloredsvg-fill.color-teal-light ellipse,
.coloredsvg-fill.color-teal-light line,
.coloredsvg-fill.color-teal-light polygon,
.coloredsvg-fill.color-teal-light polyline,
.coloredsvg-fill.color-teal-light rect,
.coloredsvg-fill.color-teal-light path {
  fill: #82FFF2;
}
.coloredsvg-stroke.color-teal-light circle,
.coloredsvg-stroke.color-teal-light ellipse,
.coloredsvg-stroke.color-teal-light line,
.coloredsvg-stroke.color-teal-light polygon,
.coloredsvg-stroke.color-teal-light polyline,
.coloredsvg-stroke.color-teal-light rect,
.coloredsvg-stroke.color-teal-light path {
  stroke: #82FFF2;
}
.dashed-text.color-teal-light::after {
  background: #82FFF2;
}
.coloredtheme-teal-light .coloredline,
body .coloredtheme-specific-teal-light .coloredline {
  border-top-color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredborder,
body .coloredtheme-specific-teal-light .coloredborder {
  border-color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredbackground,
body .coloredtheme-specific-teal-light .coloredbackground {
  background-color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredbackground-before::before,
body .coloredtheme-specific-teal-light .coloredbackground-before::before {
  background-color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredbackground-after::after,
body .coloredtheme-specific-teal-light .coloredbackground-after::after {
  background-color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredtext,
body .coloredtheme-specific-teal-light .coloredtext {
  color: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredsvg-fill circle,
body .coloredtheme-specific-teal-light .coloredsvg-fill circle,
.coloredtheme-teal-light .coloredsvg-fill ellipse,
body .coloredtheme-specific-teal-light .coloredsvg-fill ellipse,
.coloredtheme-teal-light .coloredsvg-fill line,
body .coloredtheme-specific-teal-light .coloredsvg-fill line,
.coloredtheme-teal-light .coloredsvg-fill polygon,
body .coloredtheme-specific-teal-light .coloredsvg-fill polygon,
.coloredtheme-teal-light .coloredsvg-fill polyline,
body .coloredtheme-specific-teal-light .coloredsvg-fill polyline,
.coloredtheme-teal-light .coloredsvg-fill rect,
body .coloredtheme-specific-teal-light .coloredsvg-fill rect,
.coloredtheme-teal-light .coloredsvg-fill path,
body .coloredtheme-specific-teal-light .coloredsvg-fill path {
  fill: #82FFF2 !important;
}
.coloredtheme-teal-light .coloredsvg-stroke circle,
body .coloredtheme-specific-teal-light .coloredsvg-stroke circle,
.coloredtheme-teal-light .coloredsvg-stroke ellipse,
body .coloredtheme-specific-teal-light .coloredsvg-stroke ellipse,
.coloredtheme-teal-light .coloredsvg-stroke line,
body .coloredtheme-specific-teal-light .coloredsvg-stroke line,
.coloredtheme-teal-light .coloredsvg-stroke polygon,
body .coloredtheme-specific-teal-light .coloredsvg-stroke polygon,
.coloredtheme-teal-light .coloredsvg-stroke polyline,
body .coloredtheme-specific-teal-light .coloredsvg-stroke polyline,
.coloredtheme-teal-light .coloredsvg-stroke rect,
body .coloredtheme-specific-teal-light .coloredsvg-stroke rect,
.coloredtheme-teal-light .coloredsvg-stroke path,
body .coloredtheme-specific-teal-light .coloredsvg-stroke path {
  stroke: #82FFF2 !important;
}
.coloredtheme-teal-light .dashed-text::after,
body .coloredtheme-specific-teal-light .dashed-text::after {
  background: #82FFF2;
}
.coloredtheme-teal-light .rich-text-container ul li::before,
body .coloredtheme-specific-teal-light .rich-text-container ul li::before {
  background: #82FFF2;
}
.coloredtheme-teal-light esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-teal-light esl-scrollbar .scrollbar-thumb {
  background-color: #82FFF2;
}
.coloredline.color-blue-light {
  border-top-color: #00C8FF;
}
.coloredborder.color-blue-light {
  border-color: #00C8FF;
}
.coloredbackground.color-blue-light {
  background-color: #00C8FF;
}
.coloredbackground-before.color-blue-light::before {
  background-color: #00C8FF;
}
.coloredbackground-after.color-blue-light::after {
  background-color: #00C8FF;
}
.coloredtext.color-blue-light {
  color: #00C8FF;
}
.coloredsvg-fill.color-blue-light circle,
.coloredsvg-fill.color-blue-light ellipse,
.coloredsvg-fill.color-blue-light line,
.coloredsvg-fill.color-blue-light polygon,
.coloredsvg-fill.color-blue-light polyline,
.coloredsvg-fill.color-blue-light rect,
.coloredsvg-fill.color-blue-light path {
  fill: #00C8FF;
}
.coloredsvg-stroke.color-blue-light circle,
.coloredsvg-stroke.color-blue-light ellipse,
.coloredsvg-stroke.color-blue-light line,
.coloredsvg-stroke.color-blue-light polygon,
.coloredsvg-stroke.color-blue-light polyline,
.coloredsvg-stroke.color-blue-light rect,
.coloredsvg-stroke.color-blue-light path {
  stroke: #00C8FF;
}
.dashed-text.color-blue-light::after {
  background: #00C8FF;
}
.coloredtheme-blue-light .coloredline,
body .coloredtheme-specific-blue-light .coloredline {
  border-top-color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredborder,
body .coloredtheme-specific-blue-light .coloredborder {
  border-color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredbackground,
body .coloredtheme-specific-blue-light .coloredbackground {
  background-color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredbackground-before::before,
body .coloredtheme-specific-blue-light .coloredbackground-before::before {
  background-color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredbackground-after::after,
body .coloredtheme-specific-blue-light .coloredbackground-after::after {
  background-color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredtext,
body .coloredtheme-specific-blue-light .coloredtext {
  color: #00C8FF !important;
}
.coloredtheme-blue-light .coloredsvg-fill circle,
body .coloredtheme-specific-blue-light .coloredsvg-fill circle,
.coloredtheme-blue-light .coloredsvg-fill ellipse,
body .coloredtheme-specific-blue-light .coloredsvg-fill ellipse,
.coloredtheme-blue-light .coloredsvg-fill line,
body .coloredtheme-specific-blue-light .coloredsvg-fill line,
.coloredtheme-blue-light .coloredsvg-fill polygon,
body .coloredtheme-specific-blue-light .coloredsvg-fill polygon,
.coloredtheme-blue-light .coloredsvg-fill polyline,
body .coloredtheme-specific-blue-light .coloredsvg-fill polyline,
.coloredtheme-blue-light .coloredsvg-fill rect,
body .coloredtheme-specific-blue-light .coloredsvg-fill rect,
.coloredtheme-blue-light .coloredsvg-fill path,
body .coloredtheme-specific-blue-light .coloredsvg-fill path {
  fill: #00C8FF !important;
}
.coloredtheme-blue-light .coloredsvg-stroke circle,
body .coloredtheme-specific-blue-light .coloredsvg-stroke circle,
.coloredtheme-blue-light .coloredsvg-stroke ellipse,
body .coloredtheme-specific-blue-light .coloredsvg-stroke ellipse,
.coloredtheme-blue-light .coloredsvg-stroke line,
body .coloredtheme-specific-blue-light .coloredsvg-stroke line,
.coloredtheme-blue-light .coloredsvg-stroke polygon,
body .coloredtheme-specific-blue-light .coloredsvg-stroke polygon,
.coloredtheme-blue-light .coloredsvg-stroke polyline,
body .coloredtheme-specific-blue-light .coloredsvg-stroke polyline,
.coloredtheme-blue-light .coloredsvg-stroke rect,
body .coloredtheme-specific-blue-light .coloredsvg-stroke rect,
.coloredtheme-blue-light .coloredsvg-stroke path,
body .coloredtheme-specific-blue-light .coloredsvg-stroke path {
  stroke: #00C8FF !important;
}
.coloredtheme-blue-light .dashed-text::after,
body .coloredtheme-specific-blue-light .dashed-text::after {
  background: #00C8FF;
}
.coloredtheme-blue-light .rich-text-container ul li::before,
body .coloredtheme-specific-blue-light .rich-text-container ul li::before {
  background: #00C8FF;
}
.coloredtheme-blue-light esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-blue-light esl-scrollbar .scrollbar-thumb {
  background-color: #00C8FF;
}
.coloredline.color-orange-light {
  border-top-color: #FFBC44;
}
.coloredborder.color-orange-light {
  border-color: #FFBC44;
}
.coloredbackground.color-orange-light {
  background-color: #FFBC44;
}
.coloredbackground-before.color-orange-light::before {
  background-color: #FFBC44;
}
.coloredbackground-after.color-orange-light::after {
  background-color: #FFBC44;
}
.coloredtext.color-orange-light {
  color: #FFBC44;
}
.coloredsvg-fill.color-orange-light circle,
.coloredsvg-fill.color-orange-light ellipse,
.coloredsvg-fill.color-orange-light line,
.coloredsvg-fill.color-orange-light polygon,
.coloredsvg-fill.color-orange-light polyline,
.coloredsvg-fill.color-orange-light rect,
.coloredsvg-fill.color-orange-light path {
  fill: #FFBC44;
}
.coloredsvg-stroke.color-orange-light circle,
.coloredsvg-stroke.color-orange-light ellipse,
.coloredsvg-stroke.color-orange-light line,
.coloredsvg-stroke.color-orange-light polygon,
.coloredsvg-stroke.color-orange-light polyline,
.coloredsvg-stroke.color-orange-light rect,
.coloredsvg-stroke.color-orange-light path {
  stroke: #FFBC44;
}
.dashed-text.color-orange-light::after {
  background: #FFBC44;
}
.coloredtheme-orange-light .coloredline,
body .coloredtheme-specific-orange-light .coloredline {
  border-top-color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredborder,
body .coloredtheme-specific-orange-light .coloredborder {
  border-color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredbackground,
body .coloredtheme-specific-orange-light .coloredbackground {
  background-color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredbackground-before::before,
body .coloredtheme-specific-orange-light .coloredbackground-before::before {
  background-color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredbackground-after::after,
body .coloredtheme-specific-orange-light .coloredbackground-after::after {
  background-color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredtext,
body .coloredtheme-specific-orange-light .coloredtext {
  color: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredsvg-fill circle,
body .coloredtheme-specific-orange-light .coloredsvg-fill circle,
.coloredtheme-orange-light .coloredsvg-fill ellipse,
body .coloredtheme-specific-orange-light .coloredsvg-fill ellipse,
.coloredtheme-orange-light .coloredsvg-fill line,
body .coloredtheme-specific-orange-light .coloredsvg-fill line,
.coloredtheme-orange-light .coloredsvg-fill polygon,
body .coloredtheme-specific-orange-light .coloredsvg-fill polygon,
.coloredtheme-orange-light .coloredsvg-fill polyline,
body .coloredtheme-specific-orange-light .coloredsvg-fill polyline,
.coloredtheme-orange-light .coloredsvg-fill rect,
body .coloredtheme-specific-orange-light .coloredsvg-fill rect,
.coloredtheme-orange-light .coloredsvg-fill path,
body .coloredtheme-specific-orange-light .coloredsvg-fill path {
  fill: #FFBC44 !important;
}
.coloredtheme-orange-light .coloredsvg-stroke circle,
body .coloredtheme-specific-orange-light .coloredsvg-stroke circle,
.coloredtheme-orange-light .coloredsvg-stroke ellipse,
body .coloredtheme-specific-orange-light .coloredsvg-stroke ellipse,
.coloredtheme-orange-light .coloredsvg-stroke line,
body .coloredtheme-specific-orange-light .coloredsvg-stroke line,
.coloredtheme-orange-light .coloredsvg-stroke polygon,
body .coloredtheme-specific-orange-light .coloredsvg-stroke polygon,
.coloredtheme-orange-light .coloredsvg-stroke polyline,
body .coloredtheme-specific-orange-light .coloredsvg-stroke polyline,
.coloredtheme-orange-light .coloredsvg-stroke rect,
body .coloredtheme-specific-orange-light .coloredsvg-stroke rect,
.coloredtheme-orange-light .coloredsvg-stroke path,
body .coloredtheme-specific-orange-light .coloredsvg-stroke path {
  stroke: #FFBC44 !important;
}
.coloredtheme-orange-light .dashed-text::after,
body .coloredtheme-specific-orange-light .dashed-text::after {
  background: #FFBC44;
}
.coloredtheme-orange-light .rich-text-container ul li::before,
body .coloredtheme-specific-orange-light .rich-text-container ul li::before {
  background: #FFBC44;
}
.coloredtheme-orange-light esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-orange-light esl-scrollbar .scrollbar-thumb {
  background-color: #FFBC44;
}
.coloredline.color-yellow-light {
  border-top-color: #FFEB59;
}
.coloredborder.color-yellow-light {
  border-color: #FFEB59;
}
.coloredbackground.color-yellow-light {
  background-color: #FFEB59;
}
.coloredbackground-before.color-yellow-light::before {
  background-color: #FFEB59;
}
.coloredbackground-after.color-yellow-light::after {
  background-color: #FFEB59;
}
.coloredtext.color-yellow-light {
  color: #FFEB59;
}
.coloredsvg-fill.color-yellow-light circle,
.coloredsvg-fill.color-yellow-light ellipse,
.coloredsvg-fill.color-yellow-light line,
.coloredsvg-fill.color-yellow-light polygon,
.coloredsvg-fill.color-yellow-light polyline,
.coloredsvg-fill.color-yellow-light rect,
.coloredsvg-fill.color-yellow-light path {
  fill: #FFEB59;
}
.coloredsvg-stroke.color-yellow-light circle,
.coloredsvg-stroke.color-yellow-light ellipse,
.coloredsvg-stroke.color-yellow-light line,
.coloredsvg-stroke.color-yellow-light polygon,
.coloredsvg-stroke.color-yellow-light polyline,
.coloredsvg-stroke.color-yellow-light rect,
.coloredsvg-stroke.color-yellow-light path {
  stroke: #FFEB59;
}
.dashed-text.color-yellow-light::after {
  background: #FFEB59;
}
.coloredtheme-yellow-light .coloredline,
body .coloredtheme-specific-yellow-light .coloredline {
  border-top-color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredborder,
body .coloredtheme-specific-yellow-light .coloredborder {
  border-color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredbackground,
body .coloredtheme-specific-yellow-light .coloredbackground {
  background-color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredbackground-before::before,
body .coloredtheme-specific-yellow-light .coloredbackground-before::before {
  background-color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredbackground-after::after,
body .coloredtheme-specific-yellow-light .coloredbackground-after::after {
  background-color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredtext,
body .coloredtheme-specific-yellow-light .coloredtext {
  color: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredsvg-fill circle,
body .coloredtheme-specific-yellow-light .coloredsvg-fill circle,
.coloredtheme-yellow-light .coloredsvg-fill ellipse,
body .coloredtheme-specific-yellow-light .coloredsvg-fill ellipse,
.coloredtheme-yellow-light .coloredsvg-fill line,
body .coloredtheme-specific-yellow-light .coloredsvg-fill line,
.coloredtheme-yellow-light .coloredsvg-fill polygon,
body .coloredtheme-specific-yellow-light .coloredsvg-fill polygon,
.coloredtheme-yellow-light .coloredsvg-fill polyline,
body .coloredtheme-specific-yellow-light .coloredsvg-fill polyline,
.coloredtheme-yellow-light .coloredsvg-fill rect,
body .coloredtheme-specific-yellow-light .coloredsvg-fill rect,
.coloredtheme-yellow-light .coloredsvg-fill path,
body .coloredtheme-specific-yellow-light .coloredsvg-fill path {
  fill: #FFEB59 !important;
}
.coloredtheme-yellow-light .coloredsvg-stroke circle,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke circle,
.coloredtheme-yellow-light .coloredsvg-stroke ellipse,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke ellipse,
.coloredtheme-yellow-light .coloredsvg-stroke line,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke line,
.coloredtheme-yellow-light .coloredsvg-stroke polygon,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke polygon,
.coloredtheme-yellow-light .coloredsvg-stroke polyline,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke polyline,
.coloredtheme-yellow-light .coloredsvg-stroke rect,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke rect,
.coloredtheme-yellow-light .coloredsvg-stroke path,
body .coloredtheme-specific-yellow-light .coloredsvg-stroke path {
  stroke: #FFEB59 !important;
}
.coloredtheme-yellow-light .dashed-text::after,
body .coloredtheme-specific-yellow-light .dashed-text::after {
  background: #FFEB59;
}
.coloredtheme-yellow-light .rich-text-container ul li::before,
body .coloredtheme-specific-yellow-light .rich-text-container ul li::before {
  background: #FFEB59;
}
.coloredtheme-yellow-light esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-yellow-light esl-scrollbar .scrollbar-thumb {
  background-color: #FFEB59;
}
.coloredline.color-green-light {
  border-top-color: #17EBA0;
}
.coloredborder.color-green-light {
  border-color: #17EBA0;
}
.coloredbackground.color-green-light {
  background-color: #17EBA0;
}
.coloredbackground-before.color-green-light::before {
  background-color: #17EBA0;
}
.coloredbackground-after.color-green-light::after {
  background-color: #17EBA0;
}
.coloredtext.color-green-light {
  color: #17EBA0;
}
.coloredsvg-fill.color-green-light circle,
.coloredsvg-fill.color-green-light ellipse,
.coloredsvg-fill.color-green-light line,
.coloredsvg-fill.color-green-light polygon,
.coloredsvg-fill.color-green-light polyline,
.coloredsvg-fill.color-green-light rect,
.coloredsvg-fill.color-green-light path {
  fill: #17EBA0;
}
.coloredsvg-stroke.color-green-light circle,
.coloredsvg-stroke.color-green-light ellipse,
.coloredsvg-stroke.color-green-light line,
.coloredsvg-stroke.color-green-light polygon,
.coloredsvg-stroke.color-green-light polyline,
.coloredsvg-stroke.color-green-light rect,
.coloredsvg-stroke.color-green-light path {
  stroke: #17EBA0;
}
.dashed-text.color-green-light::after {
  background: #17EBA0;
}
.coloredtheme-green-light .coloredline,
body .coloredtheme-specific-green-light .coloredline {
  border-top-color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredborder,
body .coloredtheme-specific-green-light .coloredborder {
  border-color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredbackground,
body .coloredtheme-specific-green-light .coloredbackground {
  background-color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredbackground-before::before,
body .coloredtheme-specific-green-light .coloredbackground-before::before {
  background-color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredbackground-after::after,
body .coloredtheme-specific-green-light .coloredbackground-after::after {
  background-color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredtext,
body .coloredtheme-specific-green-light .coloredtext {
  color: #17EBA0 !important;
}
.coloredtheme-green-light .coloredsvg-fill circle,
body .coloredtheme-specific-green-light .coloredsvg-fill circle,
.coloredtheme-green-light .coloredsvg-fill ellipse,
body .coloredtheme-specific-green-light .coloredsvg-fill ellipse,
.coloredtheme-green-light .coloredsvg-fill line,
body .coloredtheme-specific-green-light .coloredsvg-fill line,
.coloredtheme-green-light .coloredsvg-fill polygon,
body .coloredtheme-specific-green-light .coloredsvg-fill polygon,
.coloredtheme-green-light .coloredsvg-fill polyline,
body .coloredtheme-specific-green-light .coloredsvg-fill polyline,
.coloredtheme-green-light .coloredsvg-fill rect,
body .coloredtheme-specific-green-light .coloredsvg-fill rect,
.coloredtheme-green-light .coloredsvg-fill path,
body .coloredtheme-specific-green-light .coloredsvg-fill path {
  fill: #17EBA0 !important;
}
.coloredtheme-green-light .coloredsvg-stroke circle,
body .coloredtheme-specific-green-light .coloredsvg-stroke circle,
.coloredtheme-green-light .coloredsvg-stroke ellipse,
body .coloredtheme-specific-green-light .coloredsvg-stroke ellipse,
.coloredtheme-green-light .coloredsvg-stroke line,
body .coloredtheme-specific-green-light .coloredsvg-stroke line,
.coloredtheme-green-light .coloredsvg-stroke polygon,
body .coloredtheme-specific-green-light .coloredsvg-stroke polygon,
.coloredtheme-green-light .coloredsvg-stroke polyline,
body .coloredtheme-specific-green-light .coloredsvg-stroke polyline,
.coloredtheme-green-light .coloredsvg-stroke rect,
body .coloredtheme-specific-green-light .coloredsvg-stroke rect,
.coloredtheme-green-light .coloredsvg-stroke path,
body .coloredtheme-specific-green-light .coloredsvg-stroke path {
  stroke: #17EBA0 !important;
}
.coloredtheme-green-light .dashed-text::after,
body .coloredtheme-specific-green-light .dashed-text::after {
  background: #17EBA0;
}
.coloredtheme-green-light .rich-text-container ul li::before,
body .coloredtheme-specific-green-light .rich-text-container ul li::before {
  background: #17EBA0;
}
.coloredtheme-green-light esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-green-light esl-scrollbar .scrollbar-thumb {
  background-color: #17EBA0;
}
.coloredline.color-mid-light-gray {
  border-top-color: #EEEEEE;
}
.coloredborder.color-mid-light-gray {
  border-color: #EEEEEE;
}
.coloredbackground.color-mid-light-gray {
  background-color: #EEEEEE;
}
.coloredbackground-before.color-mid-light-gray::before {
  background-color: #EEEEEE;
}
.coloredbackground-after.color-mid-light-gray::after {
  background-color: #EEEEEE;
}
.coloredtext.color-mid-light-gray {
  color: #EEEEEE;
}
.coloredsvg-fill.color-mid-light-gray circle,
.coloredsvg-fill.color-mid-light-gray ellipse,
.coloredsvg-fill.color-mid-light-gray line,
.coloredsvg-fill.color-mid-light-gray polygon,
.coloredsvg-fill.color-mid-light-gray polyline,
.coloredsvg-fill.color-mid-light-gray rect,
.coloredsvg-fill.color-mid-light-gray path {
  fill: #EEEEEE;
}
.coloredsvg-stroke.color-mid-light-gray circle,
.coloredsvg-stroke.color-mid-light-gray ellipse,
.coloredsvg-stroke.color-mid-light-gray line,
.coloredsvg-stroke.color-mid-light-gray polygon,
.coloredsvg-stroke.color-mid-light-gray polyline,
.coloredsvg-stroke.color-mid-light-gray rect,
.coloredsvg-stroke.color-mid-light-gray path {
  stroke: #EEEEEE;
}
.dashed-text.color-mid-light-gray::after {
  background: #EEEEEE;
}
.coloredtheme-mid-light-gray .coloredline,
body .coloredtheme-specific-mid-light-gray .coloredline {
  border-top-color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredborder,
body .coloredtheme-specific-mid-light-gray .coloredborder {
  border-color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredbackground,
body .coloredtheme-specific-mid-light-gray .coloredbackground {
  background-color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredbackground-before::before,
body .coloredtheme-specific-mid-light-gray .coloredbackground-before::before {
  background-color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredbackground-after::after,
body .coloredtheme-specific-mid-light-gray .coloredbackground-after::after {
  background-color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredtext,
body .coloredtheme-specific-mid-light-gray .coloredtext {
  color: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredsvg-fill circle,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill circle,
.coloredtheme-mid-light-gray .coloredsvg-fill ellipse,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill ellipse,
.coloredtheme-mid-light-gray .coloredsvg-fill line,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill line,
.coloredtheme-mid-light-gray .coloredsvg-fill polygon,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill polygon,
.coloredtheme-mid-light-gray .coloredsvg-fill polyline,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill polyline,
.coloredtheme-mid-light-gray .coloredsvg-fill rect,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill rect,
.coloredtheme-mid-light-gray .coloredsvg-fill path,
body .coloredtheme-specific-mid-light-gray .coloredsvg-fill path {
  fill: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .coloredsvg-stroke circle,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke circle,
.coloredtheme-mid-light-gray .coloredsvg-stroke ellipse,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke ellipse,
.coloredtheme-mid-light-gray .coloredsvg-stroke line,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke line,
.coloredtheme-mid-light-gray .coloredsvg-stroke polygon,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke polygon,
.coloredtheme-mid-light-gray .coloredsvg-stroke polyline,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke polyline,
.coloredtheme-mid-light-gray .coloredsvg-stroke rect,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke rect,
.coloredtheme-mid-light-gray .coloredsvg-stroke path,
body .coloredtheme-specific-mid-light-gray .coloredsvg-stroke path {
  stroke: #EEEEEE !important;
}
.coloredtheme-mid-light-gray .dashed-text::after,
body .coloredtheme-specific-mid-light-gray .dashed-text::after {
  background: #EEEEEE;
}
.coloredtheme-mid-light-gray .rich-text-container ul li::before,
body .coloredtheme-specific-mid-light-gray .rich-text-container ul li::before {
  background: #EEEEEE;
}
.coloredtheme-mid-light-gray esl-scrollbar .scrollbar-thumb,
body .coloredtheme-specific-mid-light-gray esl-scrollbar .scrollbar-thumb {
  background-color: #EEEEEE;
}
.rich-text-container:not(#specific) ul:first-child .check-item:first-child {
  margin-top: 0;
}
.rich-text-container:not(#specific) ul:last-child .check-item:last-child {
  margin-bottom: 0;
}
.rich-text-container:not(#specific) ul:last-child > li:last-child:empty {
  display: none;
}
.rich-text-container:not(#specific) ul .check-item {
  display: list-item;
  position: relative;
  text-align: left;
  padding-left: 0;
  margin-left: 27px;
  margin-bottom: 15px;
}
.rich-text-container:not(#specific) ul .check-item:last-child {
  margin-bottom: 22px;
}
.rich-text-container:not(#specific) ul .check-item:first-child {
  margin-top: 27px;
}
.rich-text-container:not(#specific) ul .check-item::before {
  content: "";
  position: absolute;
  background-color: transparent;
}
.rich-text-container:not(#specific) ul .check-item .tag-h5,
.rich-text-container:not(#specific) ul .check-item .body-copy {
  display: block;
  transform: translateY(-2px);
  margin-bottom: -4px;
}
@media (min-width: 1600px) {
  .rich-text-container:not(#specific) ul .check-item .tag-h5,
  .rich-text-container:not(#specific) ul .check-item .body-copy {
    transform: translateY(-3px);
  }
}
.rich-text-container:not(#specific) ul .check-item .tag-h5 {
  font-weight: normal;
}
.rich-text-container:not(#specific) ul .check-item .body-copy-large {
  display: block;
  transform: translateY(-7px);
  margin-bottom: -5px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .rich-text-container:not(#specific) ul .check-item .body-copy-large {
    transform: translateY(-4px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rich-text-container:not(#specific) ul .check-item .body-copy-large {
    transform: translateY(-2px);
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .rich-text-container:not(#specific) ul .check-item .body-copy-large {
    transform: translateY(-1px);
    margin-bottom: 0;
  }
}
.rich-text-container:not(#specific) ul .checked {
  font-weight: bold;
}
.rich-text-container:not(#specific) ul .checked::before {
  height: 15.95px;
  width: 21.87px;
  top: 0.08em;
  left: -27px;
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%2301A982%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
@media (min-width: 1px) and (max-width: 1199px) {
  .rich-text-container:not(#specific) ul .checked::before {
    top: -0.1em;
  }
}
.rich-text-container:not(#specific) ul .unchecked::before {
  height: 13px;
  width: 13px;
  top: 0.23em;
  left: -24px;
  opacity: 60%;
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%23999999%22%20viewBox%3D%220%200%2013%2013%22%3E%3Cline%20x1%3D%221.06%22%20y1%3D%221.06%22%20x2%3D%2211.88%22%20y2%3D%2211.88%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3Cline%20x1%3D%221.06%22%20y1%3D%2211.88%22%20x2%3D%2211.88%22%20y2%3D%221.06%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
@media (min-width: 992px) and (max-width: 1199px) {
  .rich-text-container:not(#specific) ul .unchecked::before {
    top: 0.15em;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .rich-text-container:not(#specific) ul .unchecked::before {
    top: 0.13em;
  }
}
.coloredtheme-dark-blue .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-dark-blue:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%230D5265%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-medium-blue .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-medium-blue:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%2332DAC8%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-light-blue .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-light-blue:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%237FF9E2%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-medium-purple .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-medium-purple:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%237630EA%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-light-purple .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-light-purple:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%23C140FF%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-aruba-orange .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-aruba-orange:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%23FF8300%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-yellow .rich-text-container:not(#specific) .checked::before,
.coloredtheme-specific-yellow:not(#specific) .rich-text-container:not(#specific) .checked::before {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20stroke%3D%22%23FEC901%22%20width%3D%2221.87%22%20height%3D%2215.95%22%20viewBox%3D%220%200%2021.87%2015.95%22%3E%3Cpolyline%20points%3D%221.06%206.64%208.19%2013.82%2020.81%201.05%22%20fill%3D%22none%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%223%22/%3E%3C/svg%3E');
}
.coloredtheme-dark-blue .rich-text-container ol li::before,
.coloredtheme-specific-dark-blue:not(#specific) .rich-text-container ol li::before {
  color: #0D5265;
}
.coloredtheme-medium-blue ol li::before,
.coloredtheme-specific-medium-blue:not(#specific) ol li::before {
  color: #32DAC8;
}
.coloredtheme-light-blue ol li::before,
.coloredtheme-specific-light-blue:not(#specific) ol li::before {
  color: #7FF9E2;
}
.coloredtheme-medium-purple ol li::before,
.coloredtheme-specific-medium-purple:not(#specific) ol li::before {
  color: #7630EA;
}
.coloredtheme-light-purple .rich-text-container ol li::before,
.coloredtheme-specific-light-purple:not(#specific) .rich-text-container ol li::before {
  color: #C140FF;
}
.coloredtheme-aruba-orange .rich-text-container ol li::before,
.coloredtheme-specific-aruba-orange:not(#specific) .rich-text-container ol li::before {
  color: #FF8300;
}
.coloredtheme-yellow .rich-text-container ol li::before,
.coloredtheme-specific-yellow:not(#specific) .rich-text-container ol li::before {
  color: #FEC901;
}
.coloredtheme-specific-none:not(#specific) ol li::before {
  color: inherit !important;
}
.coloredtheme-specific-none:not(#specific) ul li::before {
  background-color: #C6C9CA !important;
}
.dashed-text {
  margin-bottom: 30px;
  padding-bottom: 28px;
  position: relative;
}
.dashed-text:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90px;
  height: 6px;
  background: #01A982;
}
.dash-center.dashed-text:after,
.text-center.dashed-text:after {
  left: 50%;
  margin-left: -45px;
}
@media (min-width: 1px) and (max-width: 1599px) {
  .dashed-text {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .dashed-text:after {
    width: 70px;
  }
  .dash-center.dashed-text:after,
  .text-center.dashed-text:after {
    margin-left: -35px;
  }
}
@media (min-width: 1px) and (max-width: 991px) {
  .dashed-text:after {
    width: 60px;
  }
  .dash-center.dashed-text:after,
  .text-center.dashed-text:after {
    margin-left: -30px;
  }
}
h1.disable-dash,
h2.disable-dash,
h3.disable-dash,
h4.disable-dash,
h5.disable-dash {
  margin-bottom: 20px;
}
@media (min-width: 1px) and (max-width: 991px) {
  h1.disable-dash,
  h2.disable-dash,
  h3.disable-dash,
  h4.disable-dash,
  h5.disable-dash {
    margin-bottom: 25px;
  }
}
.bookmark-btn {
  padding: 0;
  border: none;
  background-color: #FFFFFF;
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2012.08%2014.45%22%3E%3Cpolygon%20points%3D%220.52%200.52%200.52%2013.55%206.04%2010.45%2011.56%2013.55%2011.56%200.52%200.52%200.52%22%20fill%3D%22none%22%20stroke%3D%22%2301A982%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.05%22/%3E%3C/svg%3E') no-repeat;
}
.bookmark-btn:hover,
.bookmark-btn.active {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2012.08%2014.45%22%3E%3Cpolygon%20points%3D%220.52%200.52%200.52%2013.55%206.04%2010.45%2011.56%2013.55%2011.56%200.52%200.52%200.52%22%20fill%3D%22%2301A982%22%20stroke%3D%22%2301A982%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.05%22/%3E%3C/svg%3E') no-repeat;
}
@media (min-width: 1600px) {
  :root {
    --container-offset-x: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1599px) {
  :root {
    --container-offset-x: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  :root {
    --container-offset-x: 50px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  :root {
    --container-offset-x: 30px;
  }
}
.hf-centered-content {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1800px;
}
@media (min-width: 1px) and (max-width: 991px) {
  .hf-centered-content {
    display: block;
  }
}
.hf-centered-content,
.centered-content {
  margin: 0 auto;
  padding: 0 var(--container-offset-x, 0);
}
@media (min-width: 1600px) {
  .hf-centered-content,
  .centered-content {
    max-width: 1800px;
  }
}
.container-fluid.container-gutter {
  padding-left: var(--container-offset-x, 0);
  padding-right: var(--container-offset-x, 0);
}
@media (min-width: 1600px) {
  .container-fluid.container-gutter {
    max-width: 1800px;
  }
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

.owl-carousel {
  touch-action: pan-y;
}
.hpe-carousel.no-arrows .owl-nav,
.hpe-carousel.no-dots .owl-dots {
  display: none;
}
.hpe-carousel .owl-prev,
.hpe-carousel .owl-next,
.hpe-carousel .owl-dots {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.is-ready.hpe-carousel .owl-prev,
.is-ready.hpe-carousel .owl-next,
.is-ready.hpe-carousel .owl-dots {
  opacity: 1;
}
*:not([class*="style-bg-"]) .hpe-carousel .owl-nav {
  color: #ccc;
}
.hpe-carousel .owl-nav button.owl-prev,
.hpe-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  display: block;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  font-size: 50px;
}
.hpe-carousel .owl-nav button.owl-prev.disabled,
.hpe-carousel .owl-nav button.owl-next.disabled {
  opacity: 0;
  cursor: default;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-carousel .owl-nav button.owl-prev,
  .hpe-carousel .owl-nav button.owl-next {
    width: 36px;
    height: 36px;
    margin-top: -18px;
    font-size: 36px;
  }
}
.hpe-carousel .owl-prev {
  left: 0;
}
.arrows-outside.hpe-carousel .owl-prev {
  left: -35px;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-carousel .owl-prev {
    left: -8px;
  }
  .container-gutter .hpe-carousel .owl-prev,
  .hf-centered-content .hpe-carousel .owl-prev {
    left: -25.2px !important;
  }
}
.hpe-carousel .owl-next {
  right: 0;
}
.arrows-outside.hpe-carousel .owl-next {
  right: -35px;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-carousel .owl-next {
    right: -8px;
  }
  .container-gutter .hpe-carousel .owl-next,
  .hf-centered-content .hpe-carousel .owl-next {
    right: -25.2px !important;
  }
}
.hpe-carousel .owl-carousel .owl-dots {
  position: absolute;
  width: 100%;
  height: 14px;
  line-height: 0;
  bottom: 6px;
  text-align: center;
}
.hpe-carousel .owl-carousel button.owl-dot {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 5px;
  background: transparent;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 24px;
  vertical-align: middle;
}
.hpe-carousel .owl-carousel button.owl-dot.active {
  background: #ccc;
  cursor: default;
}
.hpe-carousel.dots-outside:not(.no-dots) .owl-carousel {
  padding-bottom: 25px;
}
.hpe-carousel:not([data-carousel-mode]):not(.is-ready) {
  overflow: hidden;
}
.hpe-carousel:not([data-carousel-mode]) .owl-carousel {
  display: block;
  white-space: nowrap;
}
.hpe-carousel:not([data-carousel-mode]) .owl-carousel > .carousel-item {
  display: inline-block;
  vertical-align: top;
  white-space: initial;
  float: none;
}
.hpe-carousel:not([data-carousel-mode]) .owl-carousel .owl-item > .carousel-item {
  width: 100%;
  white-space: initial;
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-carousel:not([data-carousel-mode]) .owl-carousel .owl-item > .carousel-item.col-xs-12 {
    padding: 0;
  }
}
.hpe-carousel[data-carousel-mode="slider"] .owl-carousel {
  display: block;
}
.hpe-carousel[data-carousel-mode="slider"] .owl-carousel:not(.owl-loaded) .carousel-item:not([data-first]) {
  display: none;
}
.hpe-carousel.a11y .owl-carousel .owl-nav:not(.cloned) .owl-prev,
.hpe-carousel.a11y .owl-carousel .owl-nav.cloned .owl-next {
  display: none;
}
body:not(.disabled-focus) .hpe-carousel.a11y:not([data-carousel-mode]):not(.transition) .owl-item {
  visibility: hidden;
  transition: visibility 0ms 500ms;
}
body:not(.disabled-focus) .hpe-carousel.a11y:not([data-carousel-mode]).transition .owl-item,
body:not(.disabled-focus) .hpe-carousel.a11y:not([data-carousel-mode]) .owl-item.active {
  visibility: visible !important;
  transition: visibility 0ms 0ms !important;
}
body:not(.disabled-focus) .hpe-carousel.a11y[data-carousel-mode="slider"] .owl-item {
  visibility: hidden;
  transition: visibility 0ms 300ms;
}
body:not(.disabled-focus) .hpe-carousel.a11y[data-carousel-mode="slider"] .owl-item.active {
  visibility: visible;
  transition: visibility 0ms 0ms;
}
.hpe-carousel .owl-carousel button.owl-dot {
  background: rgba(118, 118, 118, 0.5);
  border: none;
  width: 9px;
  height: 9px;
}
.hpe-carousel .owl-carousel button.owl-dot.active {
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  width: 11px;
  height: 11px;
}
.hpe-carousel .owl-carousel .owl-dots {
  height: auto;
}
.hpe-carousel.dots-outside:not(.no-dots) .owl-carousel {
  padding-bottom: 71px;
}
.hpe-carousel.dots-outside:not(.no-dots) .owl-carousel .owl-dots {
  bottom: 30px;
}
.hpe-carousel .owl-nav button.owl-prev,
.hpe-carousel .owl-nav button.owl-next {
  width: 68px;
  height: 68px;
  margin-top: -69.5px;
}
.arrows-outside.hpe-carousel .owl-nav .owl-prev {
  left: -75px;
}
.arrows-outside.hpe-carousel .owl-nav .owl-next {
  right: -75px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hpe-carousel .owl-nav button.owl-prev,
  .hpe-carousel .owl-nav button.owl-next {
    width: 50px;
    height: 50px;
    margin-top: -60.5px;
  }
  .arrows-outside.hpe-carousel .owl-nav .owl-prev {
    left: -50px;
  }
  .arrows-outside.hpe-carousel .owl-nav .owl-next {
    right: -50px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-carousel .owl-nav button.owl-prev,
  .hpe-carousel .owl-nav button.owl-next {
    width: 30px !important;
    height: 30px !important;
    margin-top: -50.5px !important;
  }
  .arrows-outside.hpe-carousel .owl-nav .owl-prev {
    left: -30px !important;
  }
  .arrows-outside.hpe-carousel .owl-nav .owl-next {
    right: -30px !important;
  }
}
.hpe-carousel.is-ready .owl-nav > button {
  transition-duration: 0.01s;
}
.owl-nav > button,
.slide-carousel > button {
  opacity: 0.5 !important;
}
.owl-nav > button:hover,
.slide-carousel > button:hover {
  opacity: 0.8 !important;
}
.slide-light.owl-nav > button,
.slide-light.slide-carousel > button {
  opacity: 0.6 !important;
}
.slide-light.owl-nav > button:hover,
.slide-light.slide-carousel > button:hover {
  opacity: 0.9 !important;
}
.slide-carousel .icon-arrow-left,
.hpe-carousel .icon-arrow-left,
.slide-carousel .icon-arrow-right,
.hpe-carousel .icon-arrow-right {
  display: block;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='%23000' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.slide-carousel .icon-arrow-left::before,
.hpe-carousel .icon-arrow-left::before,
.slide-carousel .icon-arrow-right::before,
.hpe-carousel .icon-arrow-right::before {
  content: '';
}
.slide-carousel .icon-arrow-right,
.hpe-carousel .icon-arrow-right {
  transform: scaleX(-1);
}
.slide-carousel.slide-light .icon-arrow-left,
.hpe-carousel.slide-light .icon-arrow-left,
.dark-theme .slide-carousel .icon-arrow-left,
.dark-theme .hpe-carousel .icon-arrow-left,
.slide-carousel.slide-light .icon-arrow-right,
.hpe-carousel.slide-light .icon-arrow-right,
.dark-theme .slide-carousel .icon-arrow-right,
.dark-theme .hpe-carousel .icon-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='white' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
}
.light-theme-prespecific .slide-carousel .icon-arrow-left,
.light-theme-prespecific .hpe-carousel .icon-arrow-left,
.light-theme-prespecific .slide-carousel .icon-arrow-right,
.light-theme-prespecific .hpe-carousel .icon-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='%23000' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
}
.slide-carousel.slide-dark .icon-arrow-left,
.hpe-carousel.slide-dark .icon-arrow-left,
.slide-carousel.slide-dark .icon-arrow-right,
.hpe-carousel.slide-dark .icon-arrow-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='black' viewBox='0 0 50 50'%3E%3Cpath d='M31 40L16 25l15-15 2 2-13 13 13 13z'/%3E%3C/svg%3E") !important;
}
.hpe-card {
  white-space: normal;
  cursor: pointer;
  position: relative;
  display: block;
  background: #FFFFFF;
}
.hpe-card.imageless {
  min-height: 100px !important;
}
.hpe-card.imageless .text-content .body-copy-small {
  padding-bottom: 0;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .hpe-card.imageless .text-content {
    transform: none;
    margin-top: 0;
  }
}
.hpe-card.imageless .link-holder {
  background-color: transparent;
}
.hpe-card.animate-nav:hover .text-content,
.hpe-card.animate-nav.hover .text-content {
  transform: translateY(-68px);
  -ms-transform: translateY(-68px);
}
.hpe-card.animate-nav .nav-block {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}
.hpe-card div.logo {
  height: 30px;
  margin-bottom: 20px;
}
.hpe-card div.logo img {
  max-height: 100%;
  height: 30px;
  width: auto;
}
.hpe-card .link-holder {
  padding: 0 45px 0 20px;
  background: #f1f1f1;
  height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hpe-card .link-holder .link-text {
  color: #01A982;
}
.hpe-card .link-holder .link-text .arrow-wrapper {
  color: #01A982;
}
.hpe-card .text-content {
  z-index: 1;
  position: relative;
  padding: 20px 20px 0 20px;
  background: #FFFFFF;
  transition: transform 0.5s ease;
}
.hpe-card .text-content .body-copy-small {
  padding-bottom: 25px;
}
.hpe-card .text-content .title {
  margin-bottom: 16px;
  margin-top: 0;
}
.hpe-card .text-content .title.only-title {
  padding-bottom: 25px;
  margin-bottom: 0;
}
.hpe-card .asset-type {
  font-family: 'Metric Light';
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: -5px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.hpe-card time,
.hpe-card .time,
.hpe-card .tagline-wrapper {
  font-family: 'Metric Light';
  font-size: 14px;
  line-height: 1.14285714em;
  --lh: 1.14285714em;
  --f-base-size: 14px;
  color: #707070;
  margin-bottom: 7px;
  margin-top: -3px;
}
.typo4 .hpe-card .asset-type,
.typo4 .hpe-card time,
.typo4 .hpe-card .time,
.typo4 .hpe-card .tagline-wrapper {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 400;
}
.hpe-card time,
.hpe-card .time {
  display: block;
}
.hpe-card .tagline-wrapper time,
.hpe-card .tagline-wrapper span:not(.text-hide) {
  display: inline;
  font-family: inherit;
  color: inherit;
}
.hpe-card .tagline-wrapper time,
.hpe-card .tagline-wrapper span:not(.text-hide, .body-copy-xsmall) {
  font-size: inherit;
}
.hpe-card .tagline-wrapper span:not(.text-hide) {
  text-transform: uppercase;
}
.hpe-card .img-container {
  box-shadow: none;
}
.hpe-card .label-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  max-width: 100%;
}
.hpe-card .label {
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.hpe-card .label:not(.body-copy-xsmall) {
  font-size: 1px;
}
.hpe-card .label-text {
  position: relative;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 7px 20px;
  border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}
.hpe-card .label-text:not(.body-copy-xsmall) {
  font-size: 12px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 12px;
}
.hpe-card.label-red .label {
  background: #ff0000;
}
.hpe-card.label-purple .label {
  background: #71008b;
}
.hpe-card.label-gray .label {
  background: #425563;
}
.hpe-card esl-share {
  --icon-share-size: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.hpe-card esl-share .icon-share-global {
  color: inherit;
}
@media (min-width: 1px) and (max-width: 991px) {
  .hpe-card esl-share {
    --icon-share-size: 17px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .hpe-card esl-share {
    --icon-share-size: 18px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .hpe-card .text-content {
    transform: translateY(-68px);
    -ms-transform: translateY(-68px);
    margin-top: 68px;
  }
  .hpe-card .text-content .body-copy-small {
    padding-bottom: 0;
  }
  .hpe-card .link-holder {
    background-color: #FFFFFF;
  }
}
body:not(.disabled-focus) .hpe-card.animate-nav:focus-within .text-content {
  transform: translateY(-68px);
  -ms-transform: translateY(-68px);
}
.icon-lnk {
  color: #333;
  display: inline-flex;
  align-items: center;
  font-family: 'Metric Semibold', Arial, sans-serif;
  font-size: 19px;
  line-height: 1.2em;
  --lh: 1.2em;
  padding: 2px;
  margin: -2px;
}
.icon-lnk .icon-wrap {
  position: relative;
  flex-basis: 46px;
  flex-shrink: 0;
  height: 46px;
  width: 46px;
  text-align: center;
}
.icon-lnk .icon-wrap::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #01A982;
  width: 46px;
  height: 46px;
  top: calc(50% - 23px);
  z-index: 1;
}
.icon-lnk .icon-wrap img.icon {
  vertical-align: top;
}
.icon-lnk .icon-wrap .icon {
  top: calc(50% - 12px);
  position: relative;
  width: 24px;
  height: 24px;
}
.icon-lnk .icon-wrap::before {
  position: absolute;
  font-size: 26px;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
}
.icon-lnk.circle-off .icon-wrap::after {
  display: none;
}
.icon-lnk .icon-desc {
  padding-left: 10px;
}
.icon-lnk:hover .icon-wrap::after,
.icon-lnk:focus .icon-wrap::after {
  border-width: 4px;
  left: -2px;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
}
.dark-theme .icon-lnk .icon-wrap::after,
body .dark-theme-prespecific  .icon-lnk .icon-wrap::after,
html body .dark-theme-specific .icon-lnk .icon-wrap::after {
  border-color: #333333;
}
.light-theme .icon-lnk .icon-wrap::after,
body .light-theme-prespecific  .icon-lnk .icon-wrap::after,
html body .light-theme-specific .icon-lnk .icon-wrap::after {
  border-color: #01A982;
}
.icon-lnk:hover,
.icon-lnk:focus {
  color: #000000;
}
.btn-v3 {
  --btnMinHeight: 60px;
  --btnMinWidth: 242px;
  --btnMaxWidth: 450px;
  padding: 10px 22px;
  min-height: var(--btnMinHeight);
  min-width: var(--btnMinWidth);
  max-width: var(--btnMaxWidth);
  font-size: 20px;
  line-height: 1.1em;
  --lh: 1.1em;
  --f-base-size: 20px;
  background: #01A982;
  font-family: 'Metric Semibold', Arial, sans-serif;
  text-align: center;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 2px solid #01A982;
  border-radius: 4px;
  cursor: pointer;
}
.btn-v3 .grommet-spinner {
  margin: 2px 0 0 -22px;
  display: none;
  width: 22px;
  height: 22px;
}
.btn-v3 .grommet-spinner circle {
  stroke: #82cec0;
}
.btn-v3.spinner-shown {
  pointer-events: none;
  flex-wrap: nowrap;
}
.btn-v3.spinner-shown .button-text {
  opacity: 0.5;
}
.btn-v3.spinner-shown .grommet-spinner {
  display: block;
}
@media (min-width: 1px) and (max-width: 1599px) {
  .btn-v3 {
    font-size: 18px;
    line-height: 1.11111111em;
    --lh: 1.11111111em;
    --f-base-size: 18px;
    --btnMinHeight: 44px;
    --btnMaxWidth: 400px;
    content: '';
  }
  .btn-v3 .grommet-spinner {
    margin: 2px 0 0 -20px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .btn-v3 {
    font-size: 16px;
    line-height: 1.125em;
    --lh: 1.125em;
    --f-base-size: 16px;
    --btnMinHeight: 40px;
    --btnMinWidth: 200px;
    content: '';
  }
  .btn-v3 .grommet-spinner {
    margin: 2px 0 0 -18px;
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 400px) {
  .btn-v3 {
    width: 100%;
    content: '';
  }
}
.btn-v3::after {
  display: none;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 0 4px #01A982;
  pointer-events: none;
}
.btn-v3:hover::after,
.btn-v3.hover::after {
  display: inline-block;
}
.btn-v3:hover .button-icon.slide-right::before,
.btn-v3.hover .button-icon.slide-right::before {
  margin-left: 5px;
  margin-right: 5px;
}
.btn-v3 .button-icon {
  position: relative;
}
.btn-v3 .button-icon.slide-right::before {
  transition: all 0.2s ease;
}
.btn-v3 .button-icon::before {
  margin-right: 10px;
}
.btn-v3[class*="sec-"] {
  background: transparent;
}
.btn-v3[class*="sec-"] .grommet-spinner circle {
  stroke: #000000;
}
.btn-v3.sec-white {
  border-color: #000000;
}
.btn-v3.sec-white::after {
  box-shadow: 0 0 0 4px #000000;
}
.btn-v3.sec-white.dark-bkg {
  border-color: #FFFFFF;
}
.btn-v3.sec-white.dark-bkg::after {
  box-shadow: 0 0 0 4px #FFFFFF;
}
.btn-v3.sec-orange {
  border-color: #FF8300;
}
.btn-v3.sec-orange::after {
  box-shadow: 0 0 0 4px #FF8300;
}
.btn-v3.sec-light-purple {
  border-color: #C140FF;
}
.btn-v3.sec-light-purple::after {
  box-shadow: 0 0 0 4px #C140FF;
}
.btn-v3.sec-medium-blue {
  border-color: #32DAC8;
}
.btn-v3.sec-medium-blue::after {
  box-shadow: 0 0 0 4px #32DAC8;
}
.btn-v3.sec-medium-purple {
  border-color: #7630EA;
}
.btn-v3.sec-medium-purple::after {
  box-shadow: 0 0 0 4px #7630EA;
}
.btn-v3.sec-light-blue {
  border-color: #7FF9E2;
}
.btn-v3.sec-light-blue::after {
  box-shadow: 0 0 0 4px #7FF9E2;
}
.btn-v3.sec-dark-blue {
  border-color: #0D5265;
}
.btn-v3.sec-dark-blue::after {
  box-shadow: 0 0 0 4px #0D5265;
}
.btn-v3.sec-yellow {
  border-color: #FEC901;
}
.btn-v3.sec-yellow::after {
  box-shadow: 0 0 0 4px #FEC901;
}
.btn-v3 .button-text {
  color: #FFFFFF;
}
.btn-v3[class*="sec-"] .button-text {
  color: inherit;
}
.btn-v3 .btn-arrow {
  position: relative;
  white-space: nowrap;
  padding-right: 1.33em;
  color: inherit;
}
.btn-v3 .btn-arrow::after {
  content: "";
  position: absolute;
  right: 0;
  top: 2px;
  width: 1em;
  height: 1em;
  transition: transform 150ms ease-in-out;
}
@media (min-width: 1600px) {
  .btn-v3 .btn-arrow::after {
    top: 3px;
  }
}
.btn-v3:hover .btn-arrow::after,
.btn-v3:focus .btn-arrow::after {
  transform: translateX(5px);
}
.btn-white {
  background: #FFFFFF;
  color: #000000;
}
.btn-white::after {
  box-shadow: 0 0 0 4px #FFFFFF;
}
.btn-v3,
.btn-v3:hover,
.btn-v3.hover,
.btn-v3:focus,
.btn-v3:visited {
  color: #FFFFFF;
}
.btn-v3:focus {
  outline: 2px solid #2AD2C9;
}
.btn-v3[class*="sec-"]:not(.dark-bkg),
.btn-v3[class*="sec-"]:not(.dark-bkg):hover,
.btn-v3[class*="sec-"]:not(.dark-bkg).hover,
.btn-v3[class*="sec-"]:not(.dark-bkg):focus,
.btn-v3[class*="sec-"]:not(.dark-bkg):visited {
  color: #000000;
}
.btn-white:hover,
.btn-white.hover,
.btn-white:focus,
.btn-white:visited {
  color: #000000;
}
.light-theme .btn-v3,
body .light-theme-prespecific  .btn-v3,
html body .light-theme-specific .btn-v3 {
  color: #FFFFFF;
}
.light-theme .btn-v3:focus,
body .light-theme-prespecific  .btn-v3:focus,
html body .light-theme-specific .btn-v3:focus,
.light-theme .btn-v3:hover,
body .light-theme-prespecific  .btn-v3:hover,
html body .light-theme-specific .btn-v3:hover,
.light-theme .btn-v3:active,
body .light-theme-prespecific  .btn-v3:active,
html body .light-theme-specific .btn-v3:active,
.light-theme .btn-v3:visited,
body .light-theme-prespecific  .btn-v3:visited,
html body .light-theme-specific .btn-v3:visited {
  color: #FFFFFF;
}
.btn-typo4 {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5625em;
  --lh: 1.5625em;
  --f-base-size: 16px;
  height: 37px;
  padding: 4px 23px;
}
.btn-typo4,
.btn-typo4.typo4,
.btn-typo4.typo5 {
  font-family: 'MetricHPEXS', Arial, sans-serif;
  font-weight: 600;
}
.btn-typo4.rounded {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-width: 0;
  font-size: 18px;
  line-height: 1.33333333em;
  --lh: 1.33333333em;
  --f-base-size: 18px;
  min-height: 36px;
  padding: 4px calc(18px - var(--btn-v4-border-width));
  border-radius: 100px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .btn-typo4.rounded {
    font-size: 20px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 20px;
    min-height: 44px;
    padding: 9px calc(24px - var(--btn-v4-border-width));
  }
}
@media (min-width: 1600px) {
  .btn-typo4.rounded {
    font-size: 24px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 24px;
    min-height: 60px;
    padding: 14px calc(36px - var(--btn-v4-border-width));
  }
}
.btn-typo4.rounded:not(.adaptive) {
  max-width: 400px;
}
@media (min-width: 1200px) {
  .btn-typo4.rounded:not(.adaptive) {
    max-width: 650px;
  }
}
@media (min-width: 1px) and (max-width: 767px) {
  .btn-typo4.rounded:not(.small) {
    width: 100%;
    max-width: 100%;
  }
}
.btn-typo4.rounded .text {
  text-align: left;
}
body:not(.disabled-focus) .btn-typo4.rounded:focus::before {
  content: '';
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  box-shadow: 0 0 4px 1px #17EBA0;
  border-radius: inherit;
}
.btn-typo4.primary,
.btn-typo4.secondary,
.btn-typo4.rounded {
  --btn-v4-border-width: 2px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .btn-typo4.primary,
  .btn-typo4.secondary,
  .btn-typo4.rounded {
    --btn-v4-border-width: 3px;
  }
}
@media (min-width: 1600px) {
  .btn-typo4.primary,
  .btn-typo4.secondary,
  .btn-typo4.rounded {
    --btn-v4-border-width: 4px;
  }
}
.btn-typo4.primary.small,
.btn-typo4.secondary.small,
.btn-typo4.rounded.small {
  --btn-v4-border-width: 2px;
}
@media (min-width: 1600px) {
  .btn-typo4.primary.small,
  .btn-typo4.secondary.small,
  .btn-typo4.rounded.small {
    --btn-v4-border-width: 3px;
  }
}
.btn-typo4.primary {
  color: #FFFFFF;
  border: var(--btn-v4-border-width) solid transparent;
  background: linear-gradient(60deg, #01A982 0% 13%, #1cd3a8 20%, #01A982 27%, #01A982);
  background-size: 200% 200%;
  background-position: 99% 50%;
  transition: background-position 0.5s;
}
.btn-typo4.primary:hover,
.clickable-area:hover ~ .clickable-area-wrapper .btn-typo4.primary {
  background-position: 0 50%;
  color: #FFFFFF;
}
.btn-typo4.primary:focus,
.btn-typo4.primary:visited {
  color: #FFFFFF;
}
.btn-typo4.primary.grey {
  color: #444444;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.8) 0% 17%, #FFFFFF 20%, rgba(255, 255, 255, 0.8) 23%, rgba(255, 255, 255, 0.8));
  backdrop-filter: blur(24px);
  background-size: 200% 200%;
  background-position: 99% 50%;
}
.btn-typo4.primary.grey:hover,
.clickable-area:hover ~ .clickable-area-wrapper .btn-typo4.primary.grey {
  background-position: 0 50%;
  color: #444444;
}
.btn-typo4.primary.grey:focus,
.btn-typo4.primary.grey:visited {
  color: #444444;
}
.btn-typo4.primary.green .icon {
  filter: brightness(0) invert(1);
}
.btn-typo4.secondary {
  border: var(--btn-v4-border-width) solid #01A982;
  background-color: transparent;
}
.btn-typo4.secondary::before {
  content: '';
  position: absolute;
  inset: calc(var(--btn-v4-border-width) * -1);
  border: var(--btn-v4-border-width) solid transparent;
  border-radius: inherit;
  background: linear-gradient(60deg, #1cd3a8 0% 13%, #01A982 20%, #1cd3a8 40%, #01A982 50%) border-box;
  background-size: 200% 200%;
  background-position: 99% 0;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  transition: background-position ease-in-out 0.5s;
}
.btn-typo4.secondary .icon {
  transition: filter ease-in-out 0.5s;
}
body:not(.disabled-focus) .btn-typo4.secondary:focus::before,
body:not(.disabled-focus) .clickable-area:focus ~ .clickable-area-wrapper .btn-typo4.secondary::before,
.btn-typo4.secondary:hover::before,
.clickable-area:hover ~ .clickable-area-wrapper .btn-typo4.secondary::before {
  background-position: 0 0;
}
body:not(.disabled-focus) .btn-typo4.secondary:focus .icon,
body:not(.disabled-focus) .clickable-area:focus ~ .clickable-area-wrapper .btn-typo4.secondary .icon,
.btn-typo4.secondary:hover .icon,
.clickable-area:hover ~ .clickable-area-wrapper .btn-typo4.secondary .icon {
  filter: brightness(130%);
}
.btn-typo4.secondary,
.btn-typo4.secondary:hover,
.btn-typo4.secondary:focus,
.btn-typo4.secondary:visited,
.clickable-area:hover ~ .clickable-area-wrapper .btn-typo4.secondary {
  color: var(--btn-v4-color);
}
.btn-typo4 .text,
.btn-typo4 .hpe-chat-hide-online,
.btn-typo4 .hpe-chat-hide-offline {
  color: inherit;
}
.btn-typo4 .icon {
  width: 0.7em;
  height: 0.7em;
  min-width: 0.7em;
  min-height: 0.7em;
  transition: transform 150ms ease-in-out;
  margin-left: 12px;
}
@media (min-width: 1600px) {
  .btn-typo4 .icon {
    margin-left: 18px;
  }
}
.btn-typo4 .icon.arrow-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%2301A982%22%20d%3D%22M9.58579%206.00005L5.89289%202.30716L7.30711%200.892944L12.7071%206.29294L13.4142%207.00005L12.7071%207.70716L7.30711%2013.1072L5.89289%2011.6929L9.58579%208.00005H0V6.00005H9.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-typo4 .icon.white-arrow-icon {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M9.58579%206.00005L5.89289%202.30716L7.30711%200.892944L12.7071%206.29294L13.4142%207.00005L12.7071%207.70716L7.30711%2013.1072L5.89289%2011.6929L9.58579%208.00005H0V6.00005H9.58579Z%22/%3E%3C/svg%3E') no-repeat;
}
.btn-typo4 .element-icon,
.btn-typo4 .white-element-icon {
  transition: none;
  height: 15px;
  min-height: 15px;
  width: 50px;
  min-width: 50px;
}
@media (min-width: 1600px) {
  .btn-typo4 .element-icon,
  .btn-typo4 .white-element-icon {
    height: 21px;
    min-height: 21px;
    width: 75px;
    min-width: 75px;
  }
}
@media (min-width: 1600px) {
  .btn-typo4.small .element-icon,
  .btn-typo4.small .white-element-icon {
    height: 15px;
    min-height: 15px;
    width: 50px;
    min-width: 50px;
  }
}
.btn-typo4 .none-icon {
  display: none;
}
.btn-typo4.small {
  font-size: 18px;
  line-height: 1.33333333em;
  --lh: 1.33333333em;
  --f-base-size: 18px;
  min-height: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
}
@media (min-width: 992px) {
  .btn-typo4.small {
    font-size: 18px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 18px;
    min-height: 42px;
    padding: 9px calc(24px - var(--btn-v4-border-width));
  }
}
.btn-typo4.small .icon {
  margin-left: 12px;
}
.btn-typo4.large {
  font-size: 20px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 20px;
  min-height: 42px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  .btn-typo4.large {
    font-size: 24px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 24px;
    min-height: 48px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
@media (min-width: 1600px) {
  .btn-typo4.large {
    font-size: 30px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 30px;
    min-height: 66px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.btn-typo4.xlarge {
  height: 48px;
  padding: 8px 28px;
  line-height: 28px;
}
.btn-typo4.color-aruba-orange {
  background: #FF8300;
  border-color: #FF8300;
}
.has-hover .btn-typo4.color-aruba-orange:hover {
  background: #7F4001;
  border-color: #7F4001;
}
.btn-typo4.color-medium-purple {
  background: #7630EA;
  border-color: #7630EA;
}
.has-hover .btn-typo4.color-medium-purple:hover {
  background: #3A0975;
  border-color: #3A0975;
}
.btn-typo4.color-hpe-green {
  background: #01A982;
  border-color: #01A982;
}
.has-hover .btn-typo4.color-hpe-green:hover {
  background: #085541;
  border-color: #085541;
}
.btn-typo4.color-blue {
  background: #00739D;
  border-color: #00739D;
}
.has-hover .btn-typo4.color-blue:hover {
  background: #03394F;
  border-color: #03394F;
}
.btn-cta-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.btn-cta-group.left-alignment {
  align-items: flex-start;
}
.btn-cta-group.center-alignment {
  align-items: center;
}
.btn-cta-group.right-alignment {
  align-items: flex-end;
}
.btn-cta-group.inline-layout {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.btn-cta-group.inline-layout.left-alignment {
  justify-content: flex-start;
}
.btn-cta-group.inline-layout.center-alignment {
  justify-content: center;
}
.btn-cta-group.inline-layout.right-alignment {
  justify-content: flex-end;
}
.dark-theme,
.dark-theme-prespecific,
.dark-theme-specific {
  --btn-v4-color: #FFFFFF;
}
.light-theme,
.light-theme-prespecific,
.light-theme-specific {
  --btn-v4-color: #444444;
}
.service-link {
  display: flex;
}
.service-link .sl-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}
.service-link .sl-icon smart-image {
  border-radius: 25%;
  font-size: 10px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 10px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .service-link .sl-icon smart-image {
    font-size: 8px;
    line-height: 1em;
    --lh: 1em;
    --f-base-size: 8px;
  }
}
.service-link:not(.sl-layout-column) .sl-icon {
  margin-right: 10px;
}
.service-link .sl-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  line-height: 1em;
  --lh: 1em;
  --f-base-size: 16px;
}
.service-link .sl-text-content .hpe-arrow-link {
  font-size: inherit;
  line-height: inherit;
}
.service-link .sl-title {
  font-weight: 600;
}
.service-link .sl-description {
  font-weight: 400;
  color: #757575;
}
@media (min-width: 1px) and (max-width: 991px) {
  .service-link .sl-description {
    color: #7C7C7C;
  }
}
.service-link .sl-link .hpe-arrow-link {
  color: #000000;
  padding-right: 0;
}
.service-link .sl-link .hpe-arrow-link .arrow {
  position: absolute;
  bottom: 0;
  visibility: hidden;
  margin: 0.15em 0 0;
}
.service-link:hover .sl-link .hpe-arrow-link,
.service-link:active .sl-link .hpe-arrow-link,
body:not(.disabled-focus) .service-link:focus .sl-link .hpe-arrow-link {
  color: #01A982;
  text-decoration: none;
}
.service-link:hover .sl-link .hpe-arrow-link .arrow,
.service-link:active .sl-link .hpe-arrow-link .arrow,
body:not(.disabled-focus) .service-link:focus .sl-link .hpe-arrow-link .arrow {
  visibility: visible;
  transform: translateX(5px);
}
.service-link.sl-layout-column {
  flex-direction: column;
  align-items: center;
}
.service-link.sl-layout-column .sl-icon {
  margin-bottom: 10px;
}
.service-link.sl-layout-column .sl-description,
.service-link.sl-layout-column .sl-link {
  text-align: center;
}
.service-link.sl-layout-column .sl-link {
  justify-content: center;
}
.dark-theme .service-link .sl-link .hpe-arrow-link {
  color: #FFFFFF;
}
.dark-theme .service-link:hover .sl-link .hpe-arrow-link,
.dark-theme .service-link:focus .sl-link .hpe-arrow-link {
  color: #01A982;
}
.esl-popup {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s 0.2s;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #dedcdc;
  background: #FFFFFF;
  cursor: default;
  will-change: auto;
  /* 0.2071 = (sqrt(2) - 1) / 2  (Don't ask why, it's an axiom)*/
}
.esl-popup[open] {
  z-index: 15;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.15s, transform 0.2s;
}
.esl-popup:not([open]) {
  display: none;
}
.esl-popup .esl-popup-arrow {
  position: absolute;
  top: -11px;
  bottom: 100%;
  z-index: -1;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  margin-left: 3.142px;
  border-left: 1px solid #dedcdc;
  border-top: 1px solid #dedcdc;
  background: #FFFFFF;
}
.esl-popup:is([placed-at='top'], [placed-at='bottom-inner']) .esl-popup-arrow {
  top: auto;
  bottom: -11px;
  transform: rotate(225deg);
}
.esl-popup:is([placed-at='left'], [placed-at='right-inner']) .esl-popup-arrow {
  right: -11px;
  left: auto;
  transform: rotate(135deg);
  margin-top: 3.142px;
}
.esl-popup:is([placed-at='right'], [placed-at='left-inner']) .esl-popup-arrow {
  left: -14.142px;
  right: auto;
  transform: rotate(315deg);
  margin-top: 3.142px;
}
.esl-popup.disable-arrow .esl-popup-arrow {
  display: none;
}
.esl-popup {
  padding: 15px;
  width: 100%;
}
.modal-open .esl-popup[open],
.base-modal-opened .esl-popup[open] {
  z-index: 1100;
}
.esl-tooltip {
  max-width: 300px;
  border-bottom: 4px solid #01A982;
}
.esl-tooltip.esl-tooltip.typo4 {
  font-size: 16px;
  line-height: 1.1875em;
  --lh: 1.1875em;
  --f-base-size: 16px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .esl-tooltip.esl-tooltip.typo4 {
    font-size: 17px;
    line-height: 1.17647059em;
    --lh: 1.17647059em;
    --f-base-size: 17px;
  }
}
@media (min-width: 1200px) {
  .esl-tooltip.esl-tooltip.typo4 {
    font-size: 18px;
    line-height: 1.33333333em;
    --lh: 1.33333333em;
    --f-base-size: 18px;
  }
}
.esl-tooltip[placed-at=top] {
  border-top: 4px solid #01A982;
  border-bottom: none;
  z-index: 1100;
}
.esl-tooltip[placed-at=left] {
  border-left: 4px solid #01A982;
  border-bottom: none;
}
.esl-tooltip[placed-at=right] {
  border-right: 4px solid #01A982;
  border-bottom: none;
}
.esl-tooltip:focus {
  outline: none;
}
.bulleted-list ul li,
.rich-text-container ul li {
  padding-left: 13px;
  position: relative;
  margin-bottom: 11px;
}
.bulleted-list ul li:before,
.rich-text-container ul li:before {
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  top: 9px;
  background-color: #C6C9CA;
}
@media (min-width: 1px) and (max-width: 991px) {
  .bulleted-list ul li:before,
  .rich-text-container ul li:before {
    top: 8px;
  }
}
.bulleted-list ul li:last-child,
.rich-text-container ul li:last-child {
  margin-bottom: 0;
}
.body-copy .bulleted-list ul li::before,
.rich-text-container.body-copy ul li::before,
.rich-text-container.typo3 ul li::before {
  top: 11px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .body-copy .bulleted-list ul li::before,
  .rich-text-container.body-copy ul li::before,
  .rich-text-container.typo3 ul li::before {
    top: 10px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .body-copy .bulleted-list ul li::before,
  .rich-text-container.body-copy ul li::before,
  .rich-text-container.typo3 ul li::before {
    top: 9px;
  }
}
.body-copy-small .bulleted-list ul li::before,
.rich-text-container.body-copy-small ul li::before {
  top: 8px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .body-copy-small .bulleted-list ul li::before,
  .rich-text-container.body-copy-small ul li::before {
    top: 7px;
  }
}
.body-copy-xsmall .bulleted-list ul li::before,
.rich-text-container.body-copy-xsmall ul li::before {
  top: 6px;
}
@media (min-width: 1px) and (max-width: 1199px) {
  .body-copy-xsmall .bulleted-list ul li::before,
  .rich-text-container.body-copy-xsmall ul li::before {
    top: 5px;
  }
}
.body-copy-large .bulleted-list ul li::before,
.rich-text-container.body-copy-large ul li::before {
  top: 13px;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .body-copy-large .bulleted-list ul li::before,
  .rich-text-container.body-copy-large ul li::before {
    top: 10px;
  }
}
@media (min-width: 1px) and (max-width: 1199px) {
  .body-copy-large .bulleted-list ul li::before,
  .rich-text-container.body-copy-large ul li::before {
    top: 8px;
  }
}
.typo5 .rich-text-container ul {
  list-style-type: none;
  list-style-position: outside;
}
.typo5 .rich-text-container ul li {
  padding-left: calc(var(--bullet-width) + var(--list-padding));
}
.typo5 .rich-text-container ul li::before {
  width: var(--bullet-width, 0px);
  height: var(--bullet-height, 0px);
  background-color: var(--bullet-color, #01A982);
}
.typo5 .rich-text-container ul.type-checkmark li::before {
  clip-path: polygon(30% 77%, 90% 0, 100% 13%, 33% 100%, 0 70%, 10% 57%);
}
.typo5 .rich-text-container:not(.add-spec) ul li::before {
  --bullet-top: calc((var(--lh) - var(--bullet-height)) / 2);
  top: var(--bullet-top, 0);
}
.typo5 .rich-text-container:not(.add-spec) ul li::before > span {
  vertical-align: middle;
}
.typo5,
.typo5 .body-copy {
  --list-padding: 10px;
}
@media (min-width: 1600px) {
  .typo5,
  .typo5 .body-copy {
    --list-padding: 12px;
  }
}
.typo5 .body-copy-large {
  --list-padding: 12px;
}
@media (min-width: 1600px) {
  .typo5 .body-copy-large {
    --list-padding: 18px;
  }
}
.typo5 .body-copy-small {
  --list-padding: 8px;
}
@media (min-width: 1600px) {
  .typo5 .body-copy-small {
    --list-padding: 10px;
  }
}
ul:not(.type-checkmark) > li {
  --bullet-height: var(--bullet-width);
}
ul:not(.type-checkmark),
.body-copy > ul:not(.type-checkmark),
ul:not(.type-checkmark) > li.body-copy {
  --bullet-width: 6px;
}
@media (min-width: 1600px) {
  ul:not(.type-checkmark),
  .body-copy > ul:not(.type-checkmark),
  ul:not(.type-checkmark) > li.body-copy {
    --bullet-width: 7px;
  }
}
.body-copy-large > ul:not(.type-checkmark),
ul:not(.type-checkmark) > li.body-copy-large {
  --bullet-width: 7px;
}
@media (min-width: 1600px) {
  .body-copy-large > ul:not(.type-checkmark),
  ul:not(.type-checkmark) > li.body-copy-large {
    --bullet-width: 9px;
  }
}
.body-copy-small > ul:not(.type-checkmark),
ul:not(.type-checkmark) > li.body-copy-small {
  --bullet-width: 5px;
}
@media (min-width: 1600px) {
  .body-copy-small > ul:not(.type-checkmark),
  ul:not(.type-checkmark) > li.body-copy-small {
    --bullet-width: 6px;
  }
}
ul.type-checkmark > li {
  --bullet-height: calc(var(--bullet-width) - 3px);
}
ul.type-checkmark,
.body-copy > ul.type-checkmark,
ul.type-checkmark > li.body-copy {
  --bullet-width: 20px;
}
@media (min-width: 992px) and (max-width: 1599px) {
  ul.type-checkmark,
  .body-copy > ul.type-checkmark,
  ul.type-checkmark > li.body-copy {
    --bullet-width: 22px;
  }
}
@media (min-width: 1600px) {
  ul.type-checkmark,
  .body-copy > ul.type-checkmark,
  ul.type-checkmark > li.body-copy {
    --bullet-width: 26px;
  }
}
.body-copy-large > ul.type-checkmark,
ul.type-checkmark > li.body-copy-large {
  --bullet-width: 26px;
}
@media (min-width: 1600px) {
  .body-copy-large > ul.type-checkmark,
  ul.type-checkmark > li.body-copy-large {
    --bullet-width: 32px;
    --bullet-height: calc(var(--bullet-width) - 4px);
  }
}
.body-copy-small > ul.type-checkmark,
ul.type-checkmark > li.body-copy-small {
  --bullet-width: 18px;
}
@media (min-width: 1600px) {
  .body-copy-small > ul.type-checkmark,
  ul.type-checkmark > li.body-copy-small {
    --bullet-width: 20px;
  }
}
@-webkit-keyframes drawAndReverse {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: -131px;
  }
}
@keyframes drawAndReverse {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: -131px;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    opacity: 1;
    transform: rotate(0);
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}
.hpe-spinner,
.grommet-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 16;
}
.hpe-spinner {
  background: rgba(255, 255, 255, 0.9) url('/apps/hpeweb-ui/images/loader.gif') 50% 50% no-repeat;
  background-size: 40px;
  pointer-events: none;
}
.hpe-spinner.in {
  pointer-events: auto;
}
.hpe-spinner.hpe-spinner-dark {
  background-color: rgba(0, 0, 0, 0.4);
}
body > .hpe-spinner {
  position: fixed;
  z-index: 99999;
}
.spinner-container {
  width: 100%;
}
.hpe-grommet-spinner {
  margin: 10px auto;
  z-index: 100;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  border-width: 4px;
  border-style: solid;
  border-color: #01A982 #01A982 rgba(0, 0, 0, 0.04);
  border-image: initial;
  min-width: 0;
  min-height: 0;
  flex-direction: column;
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 100%;
  transform: rotate(0);
  animation: 1s linear 0s infinite normal none running rotate;
}
.grommet-spinner {
  width: 24px;
  height: 24px;
  stroke-dasharray: 151px 151px;
  stroke-dashoffset: 0;
  transform: rotate(90deg);
  -webkit-animation: drawAndReverse 4s alternate infinite ease-in-out;
  animation: drawAndReverse 4s alternate infinite ease-in-out;
}
.grommet-spinner circle {
  stroke: #979797;
}
hpe-notify {
  display: none;
  margin-left: 10px;
}
hpe-notify.ready {
  display: inline;
}
hpe-notify::before {
  background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cpath%20d%3D%22M22%2011c0%206.08-4.92%2011-11%2011S0%2017.08%200%2011%204.92%200%2011%200s11%204.92%2011%2011Zm-11%209c4.97%200%209-4.03%209-9s-4.03-9-9-9-9%204.03-9%209%204.03%209%209%209Zm1-15v8h-2V5h2Zm0%2010v2h-2v-2h2Z%22%20fill%3D%22%23FF8300%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E') no-repeat;
  content: '';
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  cursor: pointer;
}
.esl-popup.nf-popup {
  width: 240px;
  border-bottom: 4px solid #01A982;
  font-family: 'Metric Light';
  font-size: 17px;
  line-height: 20px;
}
.esl-popup.nf-popup[placed-at=top] {
  border-top: 4px solid #01A982;
  border-bottom: none;
}
.esl-popup.nf-popup[placed-at=left] {
  border-left: 4px solid #01A982;
  border-bottom: none;
}
.esl-popup.nf-popup[placed-at=right] {
  border-right: 4px solid #01A982;
  border-bottom: none;
}
.columns-component {
  margin: 0;
  padding: 0;
}
.columns-component .container-gutter {
  max-width: 1600px;
  margin: 0 auto;
}
.columns-component .container-gutter .container-gutter {
  padding-left: 0;
  padding-right: 0;
}
.columns-component .container-gutter.container-fluid {
  max-width: 1800px;
}
.columns-component-col {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.columns-component-col.valign-txt-only .textOnlyComponent {
  height: 100%;
}
.column-content-2 > .container-gutter > .row > .columns-component-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-2 > .container-gutter > .row > .columns-component-col {
    float: left;
    width: 50%;
  }
}
.column-content-2-33-66 > .container-gutter > .row > .columns-component-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-2-33-66 > .container-gutter > .row > .columns-component-col {
    float: left;
    width: 66.66666667%;
  }
}
.column-content-2-33-66 > .container-gutter > .row > .columns-component-col:first-child {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-2-33-66 > .container-gutter > .row > .columns-component-col:first-child {
    float: left;
    width: 33.33333333%;
  }
}
.column-content-2-66-33 > .container-gutter > .row > .columns-component-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-2-66-33 > .container-gutter > .row > .columns-component-col {
    float: left;
    width: 33.33333333%;
  }
}
.column-content-2-66-33 > .container-gutter > .row > .columns-component-col:first-child {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-2-66-33 > .container-gutter > .row > .columns-component-col:first-child {
    float: left;
    width: 66.66666667%;
  }
}
.column-content-3 > .container-gutter > .row > .columns-component-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-3 > .container-gutter > .row > .columns-component-col {
    float: left;
    width: 33.33333333%;
  }
}
.column-content-4 .columns-component-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .column-content-4 .columns-component-col {
    float: left;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .column-content-4 .columns-component-col {
    float: left;
    width: 25%;
  }
}
.columns-component .hpe-text-only {
  word-wrap: break-word;
}
@media screen and (max-width: 767px) {
  .columns-component .container-gutter .container-gutter .columns-component-col {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .columns-component .container-gutter .container-gutter {
    margin-right: -15px;
    margin-left: -15px;
  }
  .columns-component .hpe-rule-hz .container-gutter {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .column-content-4 .columns-component-col {
    float: none;
    display: inline-block;
    margin: 0 -0.125em;
    vertical-align: top;
  }
}
.generic-modal .columns-component {
  background-color: transparent;
}
.generic-modal .columns-component .container-gutter {
  padding: 0;
  max-width: none;
  margin: 0;
}
