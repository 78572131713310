.hpe-carousel[data-carousel-mode="slider"] {
  .owl-carousel {
    display: block;
    // Initializing state
    &:not(.owl-loaded) {
      .carousel-item:not([data-first]) {
        display: none;
      }
    }
  }
}
