[hpe-corner-style] {
  border-radius: var(--rounded-style-radius, none);
}

[hpe-corner-style="rounded"] {
  --rounded-style-radius: 12px;
  @media @mq--SM-MD {
    --rounded-style-radius: 24px;
  }
  @media @mq--LG-XL {
    --rounded-style-radius: 36px;
  }
}

[hpe-corner-style="square"] {
  --rounded-style-radius: 0;
}
