@import (reference) "../../../../core/ui/dependencies.less";

@TOOLTIP_MAX_WIDTH: 300px;
@TOOLTIP_ACCENT_COLOR: @primary1;

.esl-tooltip {
  max-width: @TOOLTIP_MAX_WIDTH;
  border-bottom: 4px solid @TOOLTIP_ACCENT_COLOR;

  &.esl-tooltip.typo4 {
    .setFontByList(@typo4-text-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-desktop);
    }
  }

  &[placed-at=top] {
    border-top: 4px solid @TOOLTIP_ACCENT_COLOR;
    border-bottom: none;
    z-index: 1100; // to overlap sticky elements
  }

  &[placed-at=left] {
    border-left: 4px solid @TOOLTIP_ACCENT_COLOR;
    border-bottom: none;
  }

  &[placed-at=right] {
    border-right: 4px solid @TOOLTIP_ACCENT_COLOR;
    border-bottom: none;
  }

  &:focus {
    outline: none;
  }
}
