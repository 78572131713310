html {
  margin-top: 0 !important;
}

body {
  .page-content {
    position: relative;
    z-index: auto;
    overflow: hidden;
    overflow: clip;
  }
}

.page-container {
  position: relative;
}

html.disabled-scroll body {
  overflow: hidden !important;
  max-height: 100vh !important;
}
html.disabled-scroll:not(.disabled-scroll-style) {
  overflow-y: scroll;
}

// Commented before raising question with RTL
// Basically it works correctly (recolor default scroll) everywhere except EDGE
//html.disabled-scroll-style {
//  &::-webkit-scrollbar {
//    background: #555;
//  }
//  scrollbar-color: #555 #555; // Firefox
//  -ms-scrollbar-track-color: #555;
//  -ms-scrollbar-arrow-color: #555 ;
//}
