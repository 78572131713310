@import (reference) "../../core/ui/reference.less";

@typo4-h1-xlarge-desktop: 124px, 104px; // Reduced from original 130px line height
@typo4-h1-xlarge-tablet: 75px, 70px; // Reduced from original 75px line height
@typo4-h1-xlarge-mobile: 40px, 40px;

@typo4-h1-large-desktop: 88px, 94px;
@typo4-h1-large-tablet: 65px, 69px;
@typo4-h1-large-mobile: 38px, 42px;

@typo4-h1-desktop: 53px, 59px;
@typo4-h1-tablet: 38px, 42px;
@typo4-h1-mobile: 30px, 34px;

@typo4-h2-xlarge-desktop: 71px, 77px;
@typo4-h2-xlarge-tablet: 55px, 59px;
@typo4-h2-xlarge-mobile: 36px, 40px;

@typo4-h2-desktop: 44px, 50px;
@typo4-h2-tablet: 34px, 38px;
@typo4-h2-mobile: 26px, 30px;

@typo4-h2-small-desktop: 31px, 37px;
@typo4-h2-small-tablet: 25px, 29px;
@typo4-h2-small-mobile: 22px, 26px;

@typo4-h3-xlarge-desktop: 53px, 59px;
@typo4-h3-xlarge-tablet: 38px, 42px;
@typo4-h3-xlarge-mobile: 30px, 34px;

@typo4-h3-desktop: 36px, 42px;
@typo4-h3-tablet: 28px, 32px;
@typo4-h3-mobile: 24px, 28px;

@typo4-h4-xlarge-desktop: 36px, 42px;
@typo4-h4-xlarge-tablet: 28px, 32px;
@typo4-h4-xlarge-mobile: 24px, 28px;

@typo4-h4-large-desktop: 31px, 37px;
@typo4-h4-large-tablet: 25px, 29px;
@typo4-h4-large-mobile: 22px, 26px;

@typo4-h4-desktop: 27px, 33px;
@typo4-h4-tablet: 22px, 26px;
@typo4-h4-mobile: 20px, 24px;

@typo4-h4-small-desktop: 22px, 28px;
@typo4-h4-small-tablet: 20px, 24px;
@typo4-h4-small-mobile: 18px, 22px;

// Body Copy Large
@typo4-text-xlarge-desktop: 26px, 33px;
@typo4-text-xlarge-tablet: 22px, 26px;
@typo4-text-xlarge-mobile: 20px, 24px;

// Body Copy
@typo4-text-large-desktop: 22px, 28px;
@typo4-text-large-tablet: 20px, 24px;
@typo4-text-large-mobile: 18px, 22px;

// Body Copy Small
@typo4-text-desktop: 18px, 24px;
@typo4-text-tablet: 17px, 20px;
@typo4-text-mobile: 16px, 19px;

// Body Copy Semi-Small
@typo4-text-small-desktop: 16px, 22px;
@typo4-text-small-tablet: 15px, 18px;
@typo4-text-small-mobile: 14px, 16px;

// Body Copy XSmall
@typo4-text-xsmall-desktop: 14px, 20px;
@typo4-text-xsmall-tablet: 13px, 16px;
@typo4-text-xsmall-mobile: 12px, 14px;

.typo4 {
  &, [class*='body-copy'] {
    font-family: @f-typo4;
    font-weight: @fw-regular;
    font-style: normal;
  }

  .rich-text-container,
  &.rich-text-container {
    font-weight: @fw-regular;

    .setFontByList(@typo4-text-large-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-large-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-large-desktop);
    }

    &, a, p, i, b, strong, sub, sup {
      font-family: @f-typo4;
    }
  }

  .inline-link {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  &.btn-v3 {
    font-family: @f-typo4;
    font-weight: @fw-semibold;

    .btn-arrow::after {
      top: 0;
    }
  }

  .body-copy {
    // Body Copy = Text Large
    & {
      .setFontByList(@typo4-text-large-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-large-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-large-desktop);
      }
    }
    // Body Copy Large = Text XLarge
    &-large {
      .setFontByList(@typo4-text-xlarge-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-xlarge-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-xlarge-desktop);
      }
    }
    // Body Copy Small = Text
    &-small {
      .setFontByList(@typo4-text-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-desktop);
      }
    }
    // Body Copy Semi-Small = Text Small
    &-semi-small {
      .setFontByList(@typo4-text-small-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-small-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-small-desktop);
      }
    }
    // Body Copy XSmall = Text XSmall
    &-xsmall {
      .setFontByList(@typo4-text-xsmall-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-xsmall-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-xsmall-desktop);
      }
    }
  }

  h1, h2, h3, h4, h5, h6,
  .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6 {
    font-family: @f-typo4;
    text-transform: inherit;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  h1, .tag-h1 {
    font-weight: @fw-bold;
    .setFontByList(@typo4-h1-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-h1-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-h1-desktop);
    }

    &.large {
      .setFontByList(@typo4-h1-large-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h1-large-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h1-large-desktop);
      }
    }

    &.xlarge {
      .setFontByList(@typo4-h1-xlarge-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h1-xlarge-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h1-xlarge-desktop);
      }
    }
  }

  h2, .tag-h2 {
    font-weight: @fw-bold;
    .setFontByList(@typo4-h2-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-h2-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-h2-desktop);
    }

    &.small {
      .setFontByList(@typo4-h2-small-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h2-small-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h2-small-desktop);
      }
    }

    &.xlarge {
      .setFontByList(@typo4-h2-xlarge-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h2-xlarge-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h2-xlarge-desktop);
      }
    }
  }

  h3, .tag-h3 {
    font-weight: @fw-bold;
    .setFontByList(@typo4-h3-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-h3-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-h3-desktop);
    }

    &.xlarge {
      .setFontByList(@typo4-h3-xlarge-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h3-xlarge-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h3-xlarge-desktop);
      }
    }
  }

  h4, .tag-h4 {
    font-weight: @fw-bold;
    .setFontByList(@typo4-h4-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-h4-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-h4-desktop);
    }

    &.xlarge {
      .setFontByList(@typo4-h4-xlarge-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h4-xlarge-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h4-xlarge-desktop);
      }
    }

    &.large {
      .setFontByList(@typo4-h4-large-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h4-large-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h4-large-desktop);
      }
    }

    &.small {
      .setFontByList(@typo4-h4-small-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-h4-small-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-h4-small-desktop);
      }
    }
  }

  h5, .tag-h5 {
    font-weight: @fw-semibold;
    .setFontByList(@typo4-text-large-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-large-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-large-desktop);
    }
  }

  h6, .tag-h6 {
    font-weight: @fw-semibold;
    .setFontByList(@typo4-text-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-desktop);
    }
  }

  ul li,
  ol li {
    font-family: @f-typo4;
    font-weight: inherit;
  }
  ol li {
    color: inherit;
  }

  b, strong {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
  }

  // TODO: move separately with arrow-link-base refactoring
  &.hpe-arrow-link {
    font-family: @f-typo4;
    font-weight: @fw-semibold;

    // = Body Copy (Text Large)
    .setFontByList(@typo4-text-large-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-large-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-large-desktop);
    }

    // = Body Copy Small (Text)
    &.small {
      .setFontByList(@typo4-text-mobile);
      @media @mq--SM-MD {
        .setFontByList(@typo4-text-tablet);
      }
      @media @mq--LG-XL {
        .setFontByList(@typo4-text-desktop);
      }
    }

    &.large {
      .setFont(24px, 26px);
      @media @mq--LG {
        .setFont(22px, 24px);
      }
      @media @mq--XS-MD {
        .setFont(16px, 18px);
      }
    }

    .arrow {
      vertical-align: middle;
      margin-top: -2px;
    }
  }

  // Slim text modifier definition
  slim-text {
    font-family: @f-typo4;
    font-weight: @fw-light;
  }

  .icon-lnk {
    font-family: @f-typo4;
    font-weight: @fw-semibold;
  }

  .text-light {
    font-family: inherit;
    font-weight: @fw-light;
  }

  .text-regular {
    font-family: inherit;
    font-weight: @fw-regular;
  }

  .text-semibold {
    font-family: inherit;
    font-weight: @fw-semibold;
  }

  .text-bold {
    font-family: inherit;
    font-weight: @fw-bold;
  }

  // set to alt text Default text values
  smart-image {
    .setFontByList(@typo4-text-mobile);
    @media @mq--SM-MD {
      .setFontByList(@typo4-text-tablet);
    }
    @media @mq--LG-XL {
      .setFontByList(@typo4-text-desktop);
    }
  }
}
