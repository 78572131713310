.chevron-arrow {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%2301A982' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 16px;
  height: 9px;

  &.right {
    rotate: -90deg;
  }
  &.left {
    rotate: 90deg;
  }
  &.top {
    rotate: 180deg;
  }

  &.gray {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23757575' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
    .dark-theme & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23BBBBBB' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
    }
  }

  &.black {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23444' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
    .dark-theme & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' xml:space='preserve'%3E%3Cpolyline points='0,0 8,8 16,0' fill='none' stroke='%23FFF' stroke-width='2'%3E%3C/polyline%3E%3C/svg%3E");
    }
  }
}
