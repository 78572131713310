@import (reference) "../../core/ui/reference.less";

//---------------------------------------
// HEADINGS
//---------------------------------------
//NOTE: .typo3 style
// X-LARGE
@typo3-h1-xl: 60px, 60px;
@typo3-h2-xl: 50px, 50px;
@typo3-h3-xl: 40px, 43px;
@typo3-h4-xl: 28px, 31px;
@typo3-h5-xl: 22px, 26px;
@typo3-h6-xl: 17px, 20px;
@typo3-body-copy-large-xl: 28px, 28px;
@typo3-body-copy-xl: 22px, 26px;
@typo3-body-copy-small-xl: 17px, 20px;
@typo3-body-copy-xsmall-xl: 14px, 16px;
@typo3-tagline-xl: 18px, 20px;
@typo3-tagline-small-xl: 14px, 16px;
@typo3-h1-large-xl: 120px, 90px;
@typo3-h2-small-xl: 40px, 40px;
@typo3-h3-large-xl: 40px, 43px;

// LARGE
@typo3-h1-lg: 50px, 50px;
@typo3-h2-lg: 40px, 40px;
@typo3-h3-lg: 30px, 33px;
@typo3-h4-lg: 26px, 29px;
@typo3-h5-lg: 20px, 24px;
@typo3-h6-lg: @typo3-h6-xl;
@typo3-body-copy-large-lg: 24px, 24px;
@typo3-body-copy-lg: 20px, 24px;
@typo3-body-copy-small-lg: 17px, 20px;
@typo3-body-copy-xsmall-lg: @typo3-body-copy-xsmall-xl;
@typo3-tagline-lg: 18px, 20px;
@typo3-tagline-small-lg: @typo3-tagline-small-xl;
@typo3-h1-large-lg: 90px, 70px;
@typo3-h2-small-lg: 30px, 30px;
@typo3-h3-large-lg: @typo3-h3-large-xl;

// MEDIUM
@typo3-h1-md: 37px, 37px;
@typo3-h2-md: 34px, 34px;
@typo3-h3-md: 28px, 31px;
@typo3-h4-md: 22px, 25px;
@typo3-h5-md: 18px, 22px;
@typo3-h6-md: 16px, 19px;
@typo3-body-copy-large-md: 20px, 20px;
@typo3-body-copy-md: 18px, 22px;
@typo3-body-copy-small-md: 16px, 19px;
@typo3-body-copy-xsmall-md: @typo3-body-copy-xsmall-lg;
@typo3-tagline-md: 16px, 18px;
@typo3-tagline-small-md: @typo3-tagline-small-lg;
@typo3-h1-large-md: 75px, 55px;
@typo3-h2-small-md: 28px, 28px;
@typo3-h3-large-md: 28px, 31px;

// SMALL
@typo3-h1-sm: 28px, 28px;
@typo3-h2-sm: @typo3-h2-md;
@typo3-h3-sm: @typo3-h3-md;
@typo3-h4-sm: @typo3-h4-md;
@typo3-h5-sm: @typo3-h5-md;
@typo3-h6-sm: @typo3-h6-md;
@typo3-body-copy-large-sm: 18px, 20px;
@typo3-body-copy-sm: @typo3-body-copy-md;
@typo3-body-copy-small-sm: @typo3-body-copy-small-md;
@typo3-body-copy-xsmall-sm: 12px, 14px;
@typo3-tagline-sm: @typo3-tagline-md;
@typo3-tagline-small-sm: 12px, 14px;
@typo3-h1-large-sm: 55px, 40px;
@typo3-h2-small-sm: @typo3-h2-small-md;
@typo3-h3-large-sm: @typo3-h3-large-md;

// X-SMALL
@typo3-h1-xs: @typo3-h1-sm;
@typo3-h2-xs: 28px, 28px;
@typo3-h3-xs: 24px, 27px;
@typo3-h4-xs: 20px, 23px;
@typo3-h5-xs: @typo3-h5-sm;
@typo3-h6-xs: @typo3-h6-sm;
@typo3-body-copy-large-xs: 17px, 19px;
@typo3-body-copy-xs: @typo3-body-copy-sm;
@typo3-body-copy-small-xs: @typo3-body-copy-small-sm;
@typo3-body-copy-xsmall-xs: @typo3-body-copy-xsmall-sm;
@typo3-tagline-xs: @typo3-tagline-sm;
@typo3-tagline-small-xs: @typo3-tagline-small-sm;
@typo3-h1-large-xs: 40px, 30px;
@typo3-h2-small-xs: 24px, 24px;
@typo3-h3-large-xs: @typo3-h3-large-xl;

.typo3 {
  &.rich-text-container {
    .setFontByList(@typo3-body-copy-xl);
    @media @mq--XS-LG {
      .setFontByList(@typo3-body-copy-lg);
    }
    @media @mq--XS-MD {
      .setFontByList(@typo3-body-copy-md);
    }
  }

  .defineHStyles(@tag, @config, @otherProps: {}) {
    .tag-@{tag}, @{tag} {
      .setFont(extract(@config, 1), extract(@config, 2));
      @otherProps();
    }
  }

  h1, h2, h3, h4, h5, h6,
  .tag-h1, .tag-h2, .tag-h3, .tag-h4, .tag-h5, .tag-h6 {
    font-family: @boldFont, Arial, sans-serif;
    color: @primary10;
  }

  [class*='body-copy'] {
    font-family: @lightFont, Arial, sans-serif;
    font-style: normal;
    color: @primary10;
  }

  .defineHStyles(h1, @typo3-h1-xl, {
    font-family: @blackFont, Arial, sans-serif;
    text-transform: uppercase;
  });

  .defineHStyles(h2, @typo3-h2-xl, {
    font-family: @blackFont, Arial, sans-serif;
    text-transform: uppercase;
  });

  .defineHStyles(h3, @typo3-h3-xl);
  .defineHStyles(h4, @typo3-h4-xl);
  .defineHStyles(h5, @typo3-h5-xl);
  .defineHStyles(h6, @typo3-h6-xl);

  .body-copy-large {
    .setFontByList(@typo3-body-copy-large-xl);
  }

  .body-copy, .inline-link {
    .setFontByList(@typo3-body-copy-xl);
  }

  .body-copy-small, .inline-link-small {
    .setFontByList(@typo3-body-copy-small-xl);
  }

  .body-copy-xsmall {
    .setFontByList(@typo3-body-copy-xsmall-xl);
  }

  .tagline {
    font-family: @lightFont, Arial, sans-serif;
    text-transform: uppercase;
    .setFontByList(@typo3-tagline-xl);
  }

  .tagline-small {
    font-family: @lightFont, Arial, sans-serif;
    text-transform: uppercase;
    .setFontByList(@typo3-tagline-small-xl);
  }

  .tag-h2-small {
    font-family: @blackFont, Arial, sans-serif;
    text-transform: uppercase;
    .setFontByList(@typo3-h2-small-xl);
  }

  .tag-h3-large {
    font-family: @boldFont, Arial, sans-serif;
    .setFontByList(@typo3-h3-large-xl);
  }

  //---------------------------------------
  // Typography: LG (1599px - 1200px)
  //---------------------------------------
  @media @mq--XS-LG {
    .defineHStyles(h1, @typo3-h1-lg);
    .defineHStyles(h2, @typo3-h2-lg);
    .defineHStyles(h3, @typo3-h3-lg);
    .defineHStyles(h4, @typo3-h4-lg);
    .defineHStyles(h5, @typo3-h5-lg);

    .body-copy-large {
      .setFontByList(@typo3-body-copy-large-lg);
    }

    .body-copy, .inline-link {
      .setFontByList(@typo3-body-copy-lg);
    }

    .body-copy-small, .inline-link-small {
      .setFontByList(@typo3-body-copy-small-lg);
    }

    .tagline {
      .setFontByList(@typo3-tagline-lg);
    }

    .tag-h2-small {
      .setFontByList(@typo3-h2-small-lg);
    }

    .tag-h3-large {
      .setFontByList(@typo3-h3-large-lg);
    }
  }

  //---------------------------------------
  // Typography: MD (1199px - 992px)
  //---------------------------------------
  @media @mq--XS-MD {
    .defineHStyles(h1, @typo3-h1-md);
    .defineHStyles(h2, @typo3-h2-md);
    .defineHStyles(h3, @typo3-h3-md);
    .defineHStyles(h4, @typo3-h4-md);
    .defineHStyles(h5, @typo3-h5-md);
    .defineHStyles(h6, @typo3-h6-md);

    .body-copy-large {
      .setFontByList(@typo3-body-copy-large-md);
    }

    .body-copy, .inline-link {
      .setFontByList(@typo3-body-copy-md);
    }

    .body-copy-small, .inline-link-small {
      .setFontByList(@typo3-body-copy-small-md);
    }

    .tagline {
      .setFontByList(@typo3-tagline-md);
    }

    .tag-h2-small {
      .setFontByList(@typo3-h2-small-md);
    }

    .tag-h3-large {
      .setFontByList(@typo3-h3-large-md);
    }
  }

  //---------------------------------------
  // Typography: SM (991px - 768px)
  //---------------------------------------
  @media @mq--XS-SM {
    .defineHStyles(h1, @typo3-h1-sm);

    .body-copy-large {
      .setFontByList(@typo3-body-copy-large-sm);
    }

    .body-copy-xsmall {
      .setFontByList(@typo3-body-copy-xsmall-sm);
    }

    .tagline-small {
      .setFontByList(@typo3-tagline-small-sm);
    }

    .tag-h3-large {
      .setFontByList(@typo3-h3-large-sm);
    }
  }

  //---------------------------------------
  // Typography: XS (< 768px)
  //---------------------------------------
  @media @mq--XS {
    .defineHStyles(h2, @typo3-h2-xs);
    .defineHStyles(h3, @typo3-h3-xs);
    .defineHStyles(h4, @typo3-h4-xs);

    .body-copy-large {
      .setFontByList(@typo3-body-copy-large-xs);
    }

    .tag-h2-small {
      .setFontByList(@typo3-h2-small-xs);
    }

    .tag-h3-large {
      .setFontByList(@typo3-h3-large-xs);
    }
  }

  // Slim text modifier definition
  slim-text {
    font-family: @lightFont, Arial, sans-serif;
    color: inherit !important;
  }
}

.dark-theme {
  &, body &-prespecific, html body &-specific {
    h1, h2, h3, h4, h5, h6,
    .tag-h1, .tag-h2, .tag-h3, .tag-h3b, .tag-h4, .tag-h5, .tag-h6, .tag-h2-small, .tag-h3-large, [class*='body-copy'] {
      color: @primary9;
    }
  }
}

.light-theme {
  &, body &-prespecific, html body &-specific {
    h1, h2, h3, h4, h5, h6,
    .tag-h1, .tag-h2, .tag-h3, .tag-h3b, .tag-h4, .tag-h5, .tag-h6, .tag-h2-small, .tag-h3-large, [class*='body-copy'] {
      color: @primary10;
    }
  }
}
